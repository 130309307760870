import { DeliveryAddress } from "./features/Cart/types";
import { PublicStore } from "./features/Stores/types";
import { Cta, Image, Media } from "./graphql/types";

type DisplayHours = {
  date: string;
  hours: string;
};

export type Location = {
  id: number;
  name: string;
  display_name: string;
  zipcode: string;
  slug: string;
  street: string;
  state: string;
  timezone?: string;
  share_link?: string;
  tax_rate?: number;
  is_active?: boolean;
  is_open?: boolean;
  lat: number;
  lng: number;
  display_hours?: DisplayHours[];
  stores?: PublicStore[];
  is_dine_in_available?: string;
  phone_number?: string;
};

export interface LocationWithDistance extends Location {
  distance?: number;
  deliverable: boolean;
  fee?: number | null;
}

export type Customer = {
  auth0_user_id: string;
  email: string;
  first_name: string;
  id: number;
  is_phone_number_verified: boolean;
  last_name: string;
  phone_number: string;
  should_verify_phone_number: boolean;
  last_order_location: Location;
  signup_provider_id?: number;
  location: Location;
  order_type: string;
  is_new_user: boolean;
};

export enum OrderType {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
}

export enum Page {
  ORDER_TYPE = "select",
  SELECT_LOCATION = "select_location",
  NO_LOCATION = "no_location",
}

export type BrandContent = {
  brand_id?: number;
  tag_id?: number;
  name: string;
  slug: string;
  logo?: Image;
  hero_image?: Image;
  about?: string;
  tagline?: string;
  cuisine?: string;
  about_media?: Media[];
  gallery_media?: Media[];
  locations: Partial<Location>[];
  cta?: Cta;
  is_exclusive?: boolean;
  exclusive_description?: string;
  feature_section?: Section;
  coming_soon?: ComingSoon;
};

export type ComingSoon = {
  text: string;
  date: string;
  notifyDescriptionWeb: string;
  notifyMeHeaderWeb: string;
  notifyMeTitleWeb: string;
  notifyMeBodyWeb: string;
  notifyMePlaceholderWeb: string;
};

export type CTA = {
  text: string;
  url: string;
};

export type Section = {
  header: string;
  body: string;
  image?: Image;
  cta?: CTA;
};

export type CashRewardLoyalty = {
  credits: number;
  cash_reward_progress: number;
  cash_rewards_available: number;
  total_cash_rewards_received_count: number;
  total_cash_rewards_used_count: number;
  total_signup_rewards_received_count: number;
};

export type ActiveOrder = {
  id: number;
  status: string;
  is_pickup: boolean;
  customer_name: string;
  customer_id: number;
  cooking_at: number;
  prep_time: number;
  accepted_at: number;
  pickup_time: number;
  location: Location;
  provider_id: number;
  delivery_status: DeliveryStatus;
  delivery_address: DeliveryAddress;
  external_id: string;
  brand_name: string;
  brand_ids: number[];
  guest_feedback_url: string;
};

export type DeliveryStatus = {
  status: string;
  url: string;
  estimated_delivery_time: number;
};

export type TipRateType = {
  value: number;
  displayValue: string;
};
