import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { UserGroupOrderIcon } from "../../../ui/Icons/UserGroupOrderIcon";
import { Button } from "../../../ui/NewButton/Button";
import { classNames } from "../../../utils/classNames";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { useAuth } from "../../Authentication/hooks/useAuth";
import { useLoginContext } from "../../Authentication/contexts/LoginContext";
import { CLICK_SOURCE } from "../types";
interface MakeGroupOrderCTAProps {
  handleClick: () => void;
  className?: string;
  label?: string;
  clickSource?: CLICK_SOURCE;
  iconOnly?: boolean;
}
const DEFAULT_CLICK_SOURCE = "brand-page";
const MakeGroupOrderCTA = ({
  className,
  handleClick,
  label,
  clickSource,
  iconOnly
}: MakeGroupOrderCTAProps) => {
  const {
    login
  } = useLoginContext();
  const {
    user
  } = useAuth();
  const {
    track
  } = useTrackWithFlags();
  const isNavbar = clickSource === "navbar";
  const navbarClassNameButton = isNavbar ? "relative hidden groupOrderNavbar:block" : "";
  const navbarClassNameIcon = isNavbar ? "" : "hidden";
  const {
    cart
  } = useCartContext();
  const isGroupOrder = Boolean(cart?.is_group_order);
  const handleGroupOrder = () => {
    if (!user) {
      login();
      track({
        event: "Clicked Group Order Login",
        properties: {
          clickSource: !clickSource ? DEFAULT_CLICK_SOURCE : clickSource
        }
      });
    } else {
      handleClick();
    }
  };
  if (isGroupOrder) return null;
  return <div className={className} data-sentry-component="MakeGroupOrderCTA" data-sentry-source-file="MakeGroupOrderCTA.tsx">
      <div className={navbarClassNameButton}>
        <Button onPress={handleGroupOrder} mode="secondary" size="small" label={label || "Start group order"} labelStyle="text-new-black-base text-sm" iconOnly={iconOnly} icon={<UserGroupOrderIcon className="h-5 w-5 text-new-black-base" aria-hidden="true" />} leadingIcon={<UserGroupOrderIcon className="h-5 w-5 text-new-black-base" aria-hidden="true" />} data-test-id="make-group-order-cta" data-sentry-element="Button" data-sentry-source-file="MakeGroupOrderCTA.tsx" />
      </div>
      <div className={classNames("flex items-center cursor-pointer text-new-black-base groupOrderNavbar:hidden", navbarClassNameIcon)} onClick={handleGroupOrder}>
        <UserGroupOrderIcon className="h-6 w-6 -ml-1" aria-hidden="true" data-sentry-element="UserGroupOrderIcon" data-sentry-source-file="MakeGroupOrderCTA.tsx" />
      </div>
    </div>;
};
export default MakeGroupOrderCTA;