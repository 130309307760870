import { FC, useEffect, useState } from "react";
interface MarkerProps extends google.maps.MarkerOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
}
export const GoogleMapMarker: FC<MarkerProps> = ({
  onClick,
  ...options
}) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  useEffect(() => {
    if (marker) {
      ["click"].forEach(eventName => google.maps.event.clearListeners(marker, eventName));
      if (onClick) {
        marker.addListener("click", onClick);
      }
    }
  }, [marker, onClick]);
  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};