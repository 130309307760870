import { uniq } from "lodash";
import { CartMenuItem } from "../features/Cart/types";

export const getBrandCount = (items: CartMenuItem[]) => {
  return items
    ? uniq(
        items
          .map((item) => item?.brand_name)
          .filter((brandName) => brandName && brandName !== "Local Kitchens"),
      ).length
    : 0;
};
