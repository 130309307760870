import { faXmarkLarge } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { classNames } from "@utils/classNames";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { BrandContent } from "../../../types";
import { ResponsiveImage } from "../../../ui/ResponsiveImage";
import { Select } from "../../../ui/Select";
import { useLocationContext } from "../../../utils/contexts/LocationContext";
interface BrandSelectorMobileProps {
  isActive: boolean;
  toggleActive: () => void;
  brands: BrandContent[];
  selected: BrandContent[];
  setSelected: Dispatch<SetStateAction<BrandContent[]>>;
}
const BrandSelectorMobile = ({
  isActive,
  toggleActive,
  selected,
  setSelected,
  brands
}: BrandSelectorMobileProps) => {
  const {
    locations
  } = useLocationContext();
  const [searchValue, setSearchValue] = useState<string>("");
  const handleRemoveSelected = useCallback((optionSelected: BrandContent) => {
    setSelected(selectedOptions => {
      return selectedOptions.filter(option => option.brand_id !== optionSelected.brand_id);
    });
  }, [setSelected]);
  const filteredOptions = useMemo(() => {
    return brands.filter(brand => {
      if (selected.length && selected.find(brandSelected => brandSelected.brand_id === brand.brand_id)) {
        return false;
      }
      return brand?.name?.toLowerCase?.()?.includes(searchValue.toLowerCase());
    });
  }, [brands, selected, searchValue]);
  return <div className="block sm:hidden" onClick={e => e.stopPropagation()} data-sentry-component="BrandSelectorMobile" data-sentry-source-file="BrandSelectorMobile.tsx">
      <Transition show={isActive} enter="transform transition ease-in-out duration-700" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-700" leaveFrom="translate-x-0" leaveTo="translate-x-full" className="h-full flex flex-col justify-between bg-surface-lighter fixed inset-0 z-50 " data-sentry-element="Transition" data-sentry-source-file="BrandSelectorMobile.tsx">
        <div className="p-4 pointer-events-auto flex bg-surface-lighter border-l z-50 flex-grow overflow-hidden">
          <FontAwesomeIcon icon={faXmarkLarge} className="absolute h-6 w-6 cursor-pointer font-semibold hover:opacity-80 right-4 top-4" onClick={toggleActive} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="BrandSelectorMobile.tsx" />
          <div className="h-full w-full overflow-y-auto" onClick={toggleActive}>
            <h2 className="text-lg sm:text-2xl font-semibold">Filter brands</h2>
            <div onClick={e => e.stopPropagation()}>
              <Select multi placeholder="Search our brands..." emptyMessage="No brands found" debounceSearchMS={50} options={(filteredOptions.filter(brand => brand.brand_id) as BrandContent[])} setSearchValue={setSearchValue} renderOption={(option: BrandContent) => <div key={option.brand_id} onClick={() => {
              setSelected(selectedOptions => {
                if (selectedOptions) {
                  const selectedValues = new Set([...selectedOptions.map(o => o.brand_id), option.brand_id]);
                  return brands.filter(o => selectedValues.has(o.brand_id));
                }
                return [option];
              });
            }} className={classNames("hover:bg-button-outlined-secondary_hover cursor-pointer flex items-center gap-2 p-2", selected?.find(opt => opt.brand_id === option.brand_id) && "bg-gray-300 hover:bg-gray-400")}>
                    <ResponsiveImage imageUrl={option?.logo?.url} className="w-7 h-7 rounded-full object-contain bg-surface-lighter" />
                    {option.name}
                  </div>} value={selected} getTextLabel={(val: BrandContent) => val.name} onChange={(val?: BrandContent | BrandContent[]) => setSelected((val as BrandContent[]))} renderSelected={(optionSelected: BrandContent) => {
              return <span key={`opt-sel-${optionSelected.brand_id}`} className="px-2 pl-8 py-1 my-1 flex-grow-0 rounded-full relative text-text-secondary border border-border-secondary text-sm flex align-center items-center w-max cursor-pointer hover:bg-button-outlined-secondary_hover transition duration-300 ease">
                      <ResponsiveImage imageUrl={optionSelected?.logo?.url} className="w-7 h-7 rounded-full object-contain left-0 top-0 absolute bg-surface-lighter" />
                      {optionSelected.name}
                      <FontAwesomeIcon icon={faXmarkLarge} className="w-4 h-4 ml-1 bg-transparent hover focus:outline-none" onClick={() => handleRemoveSelected(optionSelected)} />
                    </span>;
            }} data-sentry-element="Select" data-sentry-source-file="BrandSelectorMobile.tsx" />
            </div>
          </div>
        </div>
        {Boolean(selected.length && !locations.length) && <div className="text w-full flex items-start justify-center text-text-secondary mb-5 px-8 text-center">
            No locations match selected brands.
          </div>}
        {Boolean(selected.length && locations.length) && <div className="text w-full flex items-start justify-center text-text-secondary mb-5 px-8 text-center">
            {`${locations.length} location${locations.length > 1 ? "s" : ""} match${locations.length > 1 ? "" : "es"} selected brands.`}
          </div>}
        <div className="border-t flex sm:hidden w-screen bg-surface-lighter p-4 z-50">
          <div onClick={toggleActive} className={`cursor-pointer hover:opacity-80 flex items-center justify-center rounded-md bg-lfg-primary w-full p-2 text-white`}>
            Continue
          </div>
        </div>
      </Transition>
    </div>;
};
export default BrandSelectorMobile;