import { useMemo } from "react";
import { useCartContext } from "../app-components/hooks/useCartContext";
import { useAuth } from "../features/Authentication/hooks/useAuth";
import { Cart } from "../features/Cart/types";
import { StoreLocation } from "../features/ChoosePickupDelivery/types";
import { useLocationContext } from "./contexts/LocationContext";
import { sha256 } from "./hash";

type SegmentAddress = {
  city?: string;
  country?: string;
  postalCode?: string;
  state?: string;
  street?: string;
};

/**
 * Returns the current cart's delivery address for delivery orders,
 * or the store's location for pickup orders.
 *
 * Adheres to the Segment spec: https://segment.com/docs/connections/spec/identify/
 */
function getSegmentAddress(
  storeLocation?: StoreLocation,
  cart?: Cart,
): SegmentAddress {
  if (cart?.is_delivery && cart?.delivery_address) {
    return {
      city: cart.delivery_address.city,
      postalCode: cart.delivery_address.zipcode,
      state: cart.delivery_address.state,
      street: cart.delivery_address.street,
    };
  }

  if (storeLocation) {
    return {
      city: storeLocation.name,
      postalCode: storeLocation.zipcode,
      state: storeLocation.state,
      street: storeLocation.street,
    };
  }
  return {};
}

export function useSegmentAddress(): SegmentAddress {
  const { storeLocation } = useLocationContext();
  const { cart } = useCartContext();

  const address = useMemo(
    () => getSegmentAddress(storeLocation, cart),
    [storeLocation, cart],
  );

  return address;
}

/*
 * Traits spec: https://segment.com/docs/connections/spec/identify/#traits
 */
type SegmentUserTraits = {
  email?: string;
  emailHashed?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  address?: SegmentAddress;
  id?: string;
};

/**
 * Reads customer information from cart or from the logged in user object
 * If both are available, it will prefer the logged in user as the source of truth.
 *
 * Only sets fields with truthy values!
 *
 * @returns PII to send to Segment
 */
function useSegmentUserTraits(): SegmentUserTraits {
  const { user } = useAuth();
  const address = useSegmentAddress();
  const { cart } = useCartContext();

  let userTraits: SegmentUserTraits = {};

  // Start by reading cart if it exists (this captures some % of logged out guests)
  if (cart) {
    userTraits = {
      ...(cart.phone_number && { phone: cart.phone_number }),
      ...(cart.first_name && { firstName: cart.first_name }),
      ...(cart.last_name && { lastName: cart.last_name }),
      ...(cart.email && { email: cart.email }),
    };
  }

  // Overwrite with logged in information if it exists
  if (user) {
    userTraits = {
      ...(user.phone_number && { phone: user.phone_number }),
      ...(user.first_name && { firstName: user.first_name }),
      ...(user.last_name && { lastName: user.last_name }),
      ...(user.email && { email: user.email }),
      ...(Object.keys(address ?? {}).length > 0 && { address }),
    };
  }

  if (userTraits.email) {
    // Some destinations don't permit userIDs that have PII, so we want the option of using a hashed email
    // TODO: update this once we move to UUIDs
    sha256(userTraits.email).then((emailHashed) => {
      userTraits.emailHashed = emailHashed;
    });
  }

  return userTraits;
}

/**
 * Context spec: https://segment.com/docs/connections/spec/common/#context
 */
type SegmentContext = {
  traits: SegmentUserTraits;
};

/**
 * Returns the context object used in Segment track calls.
 * Currently only used for the trait property.
 *
 * Context docs: https://segment.com/docs/connections/spec/common/#context
 */
export function useSegmentContext(): SegmentContext {
  const traits = useSegmentUserTraits();

  return {
    traits,
  };
}
