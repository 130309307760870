import { useRouter } from "next/router";
import { Cart } from "../../features/Cart/types";
import { PublicStore } from "../../features/Stores/types";
import { useRouterQueryParam } from "../../utils/hooks/useRouterQueryParam";
import { NAVBAR_MOBILE_LOGO_HEIGHT } from "../../utils/navbar";
import { LKLogo } from "../Logo/LKLogo";
import { useNavbarContext } from "./NavbarContext";
import { BrandPageMobileNavbar } from "./components/BrandPageMobileNavbar";
import CheckoutMobileNavbar from "./components/CheckoutMobileNavbar";
import { NavbarHeader } from "./components/NavbarHeader";
import { PrimaryNavbar, PrimaryNavbarProps } from "./components/PrimaryNavbar";
import SecondaryNavbar from "./components/SecondaryNavbar";
import { useLocationContext } from "@utils/contexts/LocationContext";
type NavbarProps = {
  showNavHeaderDesktop: boolean;
  showNavHeaderMobile: boolean;
  cart?: Cart;
  stores?: PublicStore[];
  pageDisplayName?: string;
} & Omit<PrimaryNavbarProps, "isMobile">;
export const Navbar = (props: NavbarProps) => {
  const router = useRouter();
  const {
    navbarRef
  } = useNavbarContext();
  const {
    storeLocation: location
  } = useLocationContext();
  const brandSlug = useRouterQueryParam("params");
  const isPopularPage = brandSlug === "popular";
  const showBrandPageNavbar = !!brandSlug && !isPopularPage;
  const showCheckoutNavbar = router.pathname.includes("/checkout");
  const showSecondaryNavbar = router.pathname.includes("/checkout");
  const DesktopNavbar = showSecondaryNavbar ? SecondaryNavbar : PrimaryNavbar;
  const MobileNavbar = (() => {
    if (showBrandPageNavbar) {
      return BrandPageMobileNavbar;
    }
    if (showCheckoutNavbar) {
      return CheckoutMobileNavbar;
    }
    if (showSecondaryNavbar) {
      return SecondaryNavbar;
    }
    return PrimaryNavbar;
  })();
  const {
    showNavHeaderDesktop,
    showNavHeaderMobile
  } = props;
  return <>
      {/* Local Kitchens branding with black background that only shows on mobile */}
      {!showSecondaryNavbar && showNavHeaderMobile && <div className="sm:hidden sticky flex justify-center items-center w-full bg-new-black-base z-20" style={{
      height: NAVBAR_MOBILE_LOGO_HEIGHT
    }}>
          <LKLogo color="white" locationSlug={location?.slug} />
        </div>}
      <nav className="sticky top-0 z-50" ref={navbarRef}>
        <div id="desktop-navbar" className={showNavHeaderDesktop ? "hidden sm:flex" : "hidden"}>
          <NavbarHeader data-sentry-element="NavbarHeader" data-sentry-source-file="Navbar.tsx">
            <DesktopNavbar {...props} isMobile={false} data-sentry-element="DesktopNavbar" data-sentry-source-file="Navbar.tsx" />
          </NavbarHeader>
        </div>
        <div id="mobile-navbar" className={showNavHeaderMobile ? "sm:hidden" : "hidden"}>
          <NavbarHeader data-sentry-element="NavbarHeader" data-sentry-source-file="Navbar.tsx">
            <MobileNavbar {...props} isMobile data-sentry-element="MobileNavbar" data-sentry-source-file="Navbar.tsx" />
          </NavbarHeader>
        </div>
      </nav>
    </>;
};