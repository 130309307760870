import { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { textStyles } from "../../../ui/Typography/Text";
import { classNames } from "../../../utils/classNames";
interface CodeCellProps {
  value: string;
  shouldFocus: boolean;
  onFocus: (e: FocusEvent<HTMLInputElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: KeyboardEvent) => void;
}
interface CodeInputProps {
  code: string[];
  className?: string;
  allowFocus?: boolean;
  hasError?: boolean;
  clearError?: () => void;
  setCode: (newCode: string[]) => void;
}
export const CODE_LENGTH = 6;
const CodeCell = ({
  value,
  shouldFocus,
  onFocus,
  onChange,
  onKeyDown
}: CodeCellProps) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (shouldFocus) {
      ref.current?.focus();
    }
  }, [shouldFocus]);
  return <input className={classNames(textStyles.xlarge, `py-4 px-0 flex-1 text-center focus:outline-none ring-1
        ring-border-secondary border-0 rounded-lg focus:ring-new-black-base
        sm:hover:bg-surface-light active:bg-surface-light
        active:ring-new-grey-2-darken-1 focus:ring-2 bg-surface-lighter`)} ref={ref} size={1} type="tel" onChange={onChange} onKeyDown={onKeyDown} onFocus={onFocus} value={value} data-sentry-component="CodeCell" data-sentry-source-file="CodeInput.tsx" />;
};
const CodeInput = ({
  code,
  className,
  allowFocus = true,
  hasError = false,
  clearError,
  setCode
}: CodeInputProps) => {
  const [focusedIdx, setFocusedIdx] = useState(0);
  const incrementFocusedIdx = (idx: number) => setFocusedIdx(Math.min(idx + 1, CODE_LENGTH - 1));
  const decrementFocusedIdx = (idx: number) => setFocusedIdx(Math.max(idx - 1, 0));
  const onChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    const {
      value
    } = e.target;
    if (value === "") {
      return;
    }
    const cleanCode = value.replace(/\D/g, "");
    const {
      length
    } = cleanCode;

    // Paste multiple code
    if (length > 1) {
      const nextIndex = Math.min(length + idx, CODE_LENGTH) - 1;
      cleanCode.split("").forEach((item, valueIdx) => {
        code[idx + valueIdx] = item;
      });
      setFocusedIdx(nextIndex);
    } else {
      // Single Input
      code[idx] = cleanCode;
      incrementFocusedIdx(idx);
    }
    setCode([...code].slice(0, CODE_LENGTH));
  };
  const onFocus = () => {
    if (hasError) {
      clearError?.();
      setCode(Array.from({
        length: CODE_LENGTH
      }, () => ""));
      setFocusedIdx(0);
    }
  };
  const onKeyDown = ({
    key
  }: KeyboardEvent, idx: number) => {
    switch (key) {
      case "Backspace":
        code[idx] = "";
        decrementFocusedIdx(idx);
        setCode([...code]);
        break;
      case "ArrowLeft":
        decrementFocusedIdx(idx);
        break;
      case "ArrowRight":
        incrementFocusedIdx(idx);
        break;
    }
  };
  return <div className={classNames("flex flex-row gap-2", className)} data-sentry-component="CodeInput" data-sentry-source-file="CodeInput.tsx">
      {Array.from({
      length: CODE_LENGTH
    }, (_, idx) => <CodeCell key={`code-cell-${idx}`} value={code[idx]} shouldFocus={allowFocus && focusedIdx === idx} onFocus={onFocus} onChange={e => onChange(e, idx)} onKeyDown={e => onKeyDown(e, idx)} />)}
    </div>;
};
export default CodeInput;