import { useMutation } from "@tanstack/react-query";
import { useToast } from "../../../app-components/hooks/useToast";
import { ShopBackendClient } from "../../../utils/shopBackend/apiClient";
import { PostPasscodeSignupData } from "@utils/shopBackend/types";
import { User } from "../types";
import { useAuth } from "../hooks/useAuth";

interface UsePostPasscodeProps {
  onSuccess: (data: User) => void;
  onCodeError: () => void;
  onCodeExpired: () => void;
  onNotFound?: (data: PostPasscodeSignupData) => void;
}

// TODO: Replace with shopapi package hook
const usePostPasscode = ({
  onSuccess,
  onCodeError,
  onCodeExpired,
  onNotFound,
}: UsePostPasscodeProps) => {
  const { showToast } = useToast();
  const { isAuthenticated } = useAuth();

  return useMutation({
    mutationFn: isAuthenticated
      ? ShopBackendClient.updateIdentifierPasscode
      : ShopBackendClient.passcodeLogin,
    onSettled: (data, error) => {
      if (data?.response.ok && data?.data) {
        onSuccess(data.data);
        return;
      }

      if (data?.response.status === 404 && data?.data) {
        onNotFound?.(data.data);
        return;
      }

      if (data?.response.status === 401) {
        onCodeError();
        return;
      }

      if (data?.response.status === 403) {
        onCodeExpired();
        return;
      }

      showToast({
        description: "Something went wrong. Please try again.",
        variant: "error",
      });
      throw new Error(error?.message || "Unknown error");
    },
  });
};

export default usePostPasscode;
