import { useCallback } from "react";
import { CartMenuItem } from "../Cart/types";
import { CartItemAction, UPDATE_ITEM_QUANTITY } from "./reducers/itemReducer";
import { PublicMenuItem } from "./types";
import { classNames } from "@utils/classNames";
type QuantityAndDescriptionProps = {
  cartItem: CartMenuItem;
  cartItemDispatch: React.Dispatch<CartItemAction>;
  publicMenuItem: PublicMenuItem | undefined;
  brandId?: number;
  hideModal?: () => void;
};
type QuantitySelectorProps = {
  quantity: number;
  decreaseQuantity: () => void;
  increaseQuantity: () => void;
};
export const QuantitySelector = ({
  quantity,
  decreaseQuantity,
  increaseQuantity
}: QuantitySelectorProps) => {
  const DecreaseQuantityButton = () => <button type="button" disabled={quantity === 0} onClick={decreaseQuantity} className={classNames(quantity === 0 && "opacity-50 cursor-not-allowed", `cursor-pointer h-14 relative inline-flex items-center px-2 py-2
        rounded-l-md border border-border-secondary bg-surface-lighter text-sm font-lfg-normal
        text-text-secondary hover:bg-button-outlined-secondary_hover focus:outline-none`)} data-sentry-component="DecreaseQuantityButton" data-sentry-source-file="QuantitySelectorAddToCart.tsx">
      <span className="sr-only">Less</span>
      <svg className="h-5 w-5" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-source-file="QuantitySelectorAddToCart.tsx">
        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="QuantitySelectorAddToCart.tsx" />
      </svg>
    </button>;
  const IncreaseQuantityButton = () => <button onClick={increaseQuantity} className="cursor-pointer h-14 relative inline-flex items-center px-2 py-2 rounded-r-md border border-border-secondary bg-surface-lighter text-sm font-lfg-normal text-text-secondary hover:bg-button-outlined-secondary_hover" data-sentry-component="IncreaseQuantityButton" data-sentry-source-file="QuantitySelectorAddToCart.tsx">
      <span className="sr-only">More</span>
      <svg className="h-5 w-5" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-source-file="QuantitySelectorAddToCart.tsx">
        <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="QuantitySelectorAddToCart.tsx" />
      </svg>
    </button>;
  return <div className="flex justify-center items-center" data-sentry-component="QuantitySelector" data-sentry-source-file="QuantitySelectorAddToCart.tsx">
      <DecreaseQuantityButton data-sentry-element="DecreaseQuantityButton" data-sentry-source-file="QuantitySelectorAddToCart.tsx" />
      <div className="w-14 h-14 justify-center inline-flex px-4 py-4 border-t border-b border-border-secondary bg-surface-lighter text-sm font-lfg-normal text-text-secondary">
        {quantity}
      </div>
      <IncreaseQuantityButton data-sentry-element="IncreaseQuantityButton" data-sentry-source-file="QuantitySelectorAddToCart.tsx" />
    </div>;
};
export const QuantitySelectorAddToCart = ({
  cartItem,
  cartItemDispatch,
  publicMenuItem
}: QuantityAndDescriptionProps) => {
  const decreaseQuantity = useCallback(() => {
    cartItemDispatch({
      type: UPDATE_ITEM_QUANTITY,
      quantity: cartItem.quantity - 1
    });
  }, [cartItem.quantity]);
  const increaseQuantity = useCallback(() => {
    cartItemDispatch({
      type: UPDATE_ITEM_QUANTITY,
      quantity: cartItem.quantity + 1
    });
  }, [cartItem.quantity]);
  return <div data-sentry-component="QuantitySelectorAddToCart" data-sentry-source-file="QuantitySelectorAddToCart.tsx">
      {publicMenuItem?.name !== "Condiments" ? <div className="w-full flex items-center justify-end">
          <QuantitySelector quantity={cartItem.quantity} increaseQuantity={increaseQuantity} decreaseQuantity={decreaseQuantity} />
        </div> : null}
    </div>;
};