import { faXmarkLarge } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@utils/classNames";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { BrandContent } from "../../../types";
import { ResponsiveImage } from "../../../ui/ResponsiveImage";
import { Select } from "../../../ui/Select";
interface BrandSelectorProps {
  brands: BrandContent[];
  selected: BrandContent[];
  setSelected: Dispatch<SetStateAction<BrandContent[]>>;
}
const BrandSelector = ({
  selected,
  setSelected,
  brands
}: BrandSelectorProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const handleRemoveSelected = useCallback((optionSelected: BrandContent) => {
    setSelected(selectedOptions => {
      return selectedOptions.filter(option => {
        if (option.brand_id) return option.brand_id !== optionSelected.brand_id;else return option.tag_id !== optionSelected.tag_id;
      });
    });
  }, []);
  const filteredOptions = useMemo(() => {
    return brands.filter(brand => {
      if (selected.length && selected.find(brandSelected => brandSelected.brand_id === brand.brand_id)) {
        return false;
      }
      return brand.name.toLowerCase?.()?.includes(searchValue.toLowerCase());
    });
  }, [brands, searchValue, selected]);
  return <Select multi placeholder="Search our brands..." emptyMessage="No brands found" debounceSearchMS={50} options={(filteredOptions.filter(brand => brand.brand_id ? true : false) as BrandContent[])} setSearchValue={setSearchValue} renderOption={(option: BrandContent) => <div key={option.brand_id} onClick={() => {
    setSelected(selectedOptions => {
      if (selectedOptions) {
        const selectedValues = new Set([...selectedOptions.map(o => o.brand_id), option.brand_id]);
        return brands.filter(o => selectedValues.has(o.brand_id));
      }
      return [option];
    });
  }} className={classNames("hover:bg-button-outlined-secondary_hover cursor-pointer flex items-center gap-2 p-2", selected?.find(opt => opt.brand_id === option.brand_id) && "bg-gray-300 hover:bg-gray-400")}>
          <ResponsiveImage imageUrl={option?.logo?.url} className="w-7 h-7 rounded-full object-contain bg-surface-lighter" />
          {option.name}
        </div>} value={selected} getTextLabel={(val: BrandContent) => val.name} onChange={(val?: BrandContent | BrandContent[]) => setSelected((val as BrandContent[]))} renderSelected={(optionSelected: BrandContent) => {
    return <span key={`opt-sel-${optionSelected.brand_id}`} className="px-2 pl-8 py-1 my-1 flex-grow-0 rounded-full relative text-text-secondary border border-border-secondary text-sm flex align-center items-center w-max cursor-pointer hover:bg-button-outlined-secondary_hover transition duration-300 ease">
            <ResponsiveImage imageUrl={optionSelected?.logo?.url} className="w-7 h-7 rounded-full object-contain left-0 top-0 absolute bg-surface-lighter" />
            {optionSelected.name}
            <FontAwesomeIcon icon={faXmarkLarge} className="w-4 h-4 ml-1 bg-transparent hover focus:outline-none" onClick={() => handleRemoveSelected(optionSelected)} />
          </span>;
  }} data-sentry-element="Select" data-sentry-component="BrandSelector" data-sentry-source-file="BrandSelector.tsx" />;
};
export default BrandSelector;