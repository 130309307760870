import { client } from "@appComponents/ApolloWrapper";
import { CART_QUERY } from "@features/Cart/hooks/useCartLoader";
import { useGroupOrderContext } from "@features/GroupOrder/context/GroupOrderContext";
import LeavePoolsideOrderModal, { LeavePoolsideOrderModalConfig } from "@features/LocationDiscovery/components/LeavePoolsideOrderModal";
import { PickupType } from "@graphql/types";
import React, { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import { useCartContext } from "../../app-components/hooks/useCartContext";
import { useUpdateCustomer } from "../../features/Account/hooks/useUpdateCustomer";
import { useAuth } from "../../features/Authentication/hooks/useAuth";
import { useCartMutations } from "../../features/Cart/hooks/useCartMutations";
import { LocationDiscoveryController } from "../../features/LocationDiscovery/LocationDiscoveryController";
import { useActiveBrands } from "../../features/LocationDiscovery/hooks/useActiveBrands";
import { OrderType } from "../../types";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { useTrackWithFlags } from "../hooks/useTrackWithFlags";
import { useLocationContext } from "./LocationContext";
export type ChangeOrderTypeFeature = "location-discovery-side-panel" | "navbar" | "checkout";
interface OrderTypeContextType {
  orderType: OrderType | undefined;
  handleChangeOrderTypeClicked: (orderType: OrderType, feature: ChangeOrderTypeFeature) => void;
  showChooseOrderType: boolean;
  setShowChooseOrderType: (isDelivery: boolean) => void;
  setLeavePoolsideOrderModalConfig: Dispatch<SetStateAction<LeavePoolsideOrderModalConfig>>;
}
export const OrderTypeContext = React.createContext<OrderTypeContextType>({
  orderType: undefined,
  showChooseOrderType: false,
  setShowChooseOrderType: () => {},
  handleChangeOrderTypeClicked: () => {},
  setLeavePoolsideOrderModalConfig: () => {}
});
export const OrderTypeContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [showChooseOrderType, setShowChooseOrderType] = useState(false);
  const {
    cart
  } = useCartContext();
  const {
    onUpdateIsDelivery
  } = useCartMutations();
  const [leavePoolsideOrderModalConfig, setLeavePoolsideOrderModalConfig] = useState<LeavePoolsideOrderModalConfig>({
    show: false
  });
  const {
    isGroupOrderCreator
  } = useGroupOrderContext();
  const {
    user,
    authFinished
  } = useAuth();
  const {
    activeBrandsIds
  } = useActiveBrands();
  const {
    setSearchedAddress,
    locations
  } = useLocationContext();
  const {
    track
  } = useTrackWithFlags();
  const {
    mutate: updateUser
  } = useUpdateCustomer();
  const updateOrderType = useCallback((orderType: OrderType) => {
    if (!cart.id) {
      return;
    }
    if (user && user.preferred_order_type !== orderType) {
      updateUser({
        preferred_order_type: orderType
      });
    }
    const isOrderTypeDelivery = orderType === OrderType.DELIVERY;
    onUpdateIsDelivery(cart.id, isOrderTypeDelivery).catch(() => {
      client.cache.updateQuery({
        query: CART_QUERY,
        variables: {
          id: cart.id
        }
      }, data => {
        if (!data?.cart) return null;
        return {
          cart: {
            ...data.cart,
            is_delivery: !isOrderTypeDelivery
          }
        };
      });
    });
    if (isOrderTypeDelivery && cart?.delivery_address) {
      setSearchedAddress(cart?.delivery_address);
    }
    client.cache.updateQuery({
      query: CART_QUERY,
      variables: {
        id: cart.id
      }
    }, data => {
      if (!data?.cart) return null;
      return {
        cart: {
          ...data.cart,
          is_delivery: orderType === OrderType.DELIVERY
        }
      };
    });
  }, [cart.id, cart?.delivery_address, user, updateUser, onUpdateIsDelivery, setSearchedAddress]);

  // Wrapper around updateOrderType that tracks the event
  const handleChangeOrderTypeClicked = (orderType: OrderType, feature: ChangeOrderTypeFeature) => {
    if (cart.pickup_type === PickupType.Poolside) {
      setLeavePoolsideOrderModalConfig(({
        onConfirm
      }) => ({
        show: true,
        onConfirm: () => {
          onConfirm?.();
          updateOrderType(orderType);
        }
      }));
    } else {
      updateOrderType(orderType);
    }
    track({
      event: "Changed Order Type",
      properties: {
        orderType,
        feature
      }
    });
  };
  useEffectOnce(() => {
    if (!user?.preferred_order_type || cart.is_group_order && !isGroupOrderCreator || cart.pickup_type !== PickupType.Regular) {
      return;
    }
    updateOrderType(user.preferred_order_type === OrderType.DELIVERY ? OrderType.DELIVERY : OrderType.PICKUP);
    if (user.preferred_order_type === OrderType.DELIVERY && cart?.delivery_address) {
      setSearchedAddress(cart?.delivery_address);
    }
  }, [user, updateOrderType, cart?.delivery_address, setSearchedAddress, cart.id], authFinished && !!cart.id);
  const orderType = cart?.is_delivery ? OrderType.DELIVERY : OrderType.PICKUP;
  return <OrderTypeContext.Provider value={{
    orderType,
    showChooseOrderType,
    setLeavePoolsideOrderModalConfig,
    setShowChooseOrderType: isDelivery => {
      if (cart.pickup_type === PickupType.Poolside && isDelivery) {
        setLeavePoolsideOrderModalConfig(({
          onConfirm
        }) => ({
          show: true,
          onConfirm: () => {
            onConfirm?.();
            setShowChooseOrderType(isDelivery);
          }
        }));
        return;
      }
      setShowChooseOrderType(isDelivery);
    },
    handleChangeOrderTypeClicked
  }} data-sentry-element="unknown" data-sentry-component="OrderTypeContextProvider" data-sentry-source-file="OrderTypeContext.tsx">
      {children}
      <LeavePoolsideOrderModal config={leavePoolsideOrderModalConfig} closeModal={() => {
      setLeavePoolsideOrderModalConfig({
        show: false
      });
    }} data-sentry-element="LeavePoolsideOrderModal" data-sentry-source-file="OrderTypeContext.tsx" />
      <LocationDiscoveryController show={showChooseOrderType} locations={locations} activeBrandsIds={activeBrandsIds} orderType={orderType} handleChangeOrderTypeClicked={handleChangeOrderTypeClicked} data-sentry-element="LocationDiscoveryController" data-sentry-source-file="OrderTypeContext.tsx" />
    </OrderTypeContext.Provider>;
};
export const useOrderTypeContext = () => {
  const context = useContext(OrderTypeContext);
  if (!context) {
    throw new Error("You may not use OrderTypeContext outside of OrderTypeContextProvider");
  } else {
    return context;
  }
};