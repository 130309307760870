import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertRawCartClientMetadataMutationVariables = Types.Exact<{
  raw_cart_id: Types.Scalars['String'];
  browser_user_agent?: Types.InputMaybe<Types.Scalars['String']>;
  browser_segment_user_id?: Types.InputMaybe<Types.Scalars['String']>;
  browser_segment_anonymous_id?: Types.InputMaybe<Types.Scalars['String']>;
  browser_facebook_pixel_cookie?: Types.InputMaybe<Types.Scalars['String']>;
  browser_facebook_click_id_cookie?: Types.InputMaybe<Types.Scalars['String']>;
  browser_checkout_url?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpsertRawCartClientMetadataMutation = { __typename?: 'Mutation', upsert_raw_cart_client_metadata?: string | undefined };


export const UpsertRawCartClientMetadataDocument = gql`
    mutation UpsertRawCartClientMetadata($raw_cart_id: String!, $browser_user_agent: String, $browser_segment_user_id: String, $browser_segment_anonymous_id: String, $browser_facebook_pixel_cookie: String, $browser_facebook_click_id_cookie: String, $browser_checkout_url: String) {
  upsert_raw_cart_client_metadata(
    raw_cart_id: $raw_cart_id
    browser_user_agent: $browser_user_agent
    browser_segment_user_id: $browser_segment_user_id
    browser_segment_anonymous_id: $browser_segment_anonymous_id
    browser_facebook_pixel_cookie: $browser_facebook_pixel_cookie
    browser_facebook_click_id_cookie: $browser_facebook_click_id_cookie
    browser_checkout_url: $browser_checkout_url
  )
}
    `;
export type UpsertRawCartClientMetadataMutationFn = Apollo.MutationFunction<UpsertRawCartClientMetadataMutation, UpsertRawCartClientMetadataMutationVariables>;

/**
 * __useUpsertRawCartClientMetadataMutation__
 *
 * To run a mutation, you first call `useUpsertRawCartClientMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRawCartClientMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRawCartClientMetadataMutation, { data, loading, error }] = useUpsertRawCartClientMetadataMutation({
 *   variables: {
 *      raw_cart_id: // value for 'raw_cart_id'
 *      browser_user_agent: // value for 'browser_user_agent'
 *      browser_segment_user_id: // value for 'browser_segment_user_id'
 *      browser_segment_anonymous_id: // value for 'browser_segment_anonymous_id'
 *      browser_facebook_pixel_cookie: // value for 'browser_facebook_pixel_cookie'
 *      browser_facebook_click_id_cookie: // value for 'browser_facebook_click_id_cookie'
 *      browser_checkout_url: // value for 'browser_checkout_url'
 *   },
 * });
 */
export function useUpsertRawCartClientMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRawCartClientMetadataMutation, UpsertRawCartClientMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRawCartClientMetadataMutation, UpsertRawCartClientMetadataMutationVariables>(UpsertRawCartClientMetadataDocument, options);
      }
export type UpsertRawCartClientMetadataMutationHookResult = ReturnType<typeof useUpsertRawCartClientMetadataMutation>;
export type UpsertRawCartClientMetadataMutationResult = Apollo.MutationResult<UpsertRawCartClientMetadataMutation>;
export type UpsertRawCartClientMetadataMutationOptions = Apollo.BaseMutationOptions<UpsertRawCartClientMetadataMutation, UpsertRawCartClientMetadataMutationVariables>;