import useIsomorphicLayoutEffect from "@utils/hooks/useIsomorphicLayoutEffect";
import { PropsWithChildren, useRef } from "react";
import { createPortal } from "react-dom";
type ReactPortalProps = PropsWithChildren<{
  wrapperId?: string;
}>;
export const ReactPortal = ({
  children
}: ReactPortalProps) => {
  const ref = useRef<Element | null>(null);
  useIsomorphicLayoutEffect(() => {
    ref.current = document.getElementById("portal");
  }, []);
  if (!ref.current) return null;
  return createPortal(children, ref.current);
};