import { faChevronDown, faChevronUp } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { faCar, faShoppingBag, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { Button } from "@ui/NewButton/Button";
import { classNames } from "@utils/classNames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { LocationWithDistance } from "../../../types";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { formatDisplayTime, getDayOfWeek, getIsOpenNow, getIsToday, getWeekDay, isClosingSoon } from "../../../utils/storeHours";
interface StoreLocationCardProps {
  location: LocationWithDistance;
  isSelected: boolean;
  idx: number;
  onLocationClick: (location: LocationWithDistance) => void;
  onLinkClick?: (location: LocationWithDistance) => void;
  className?: string;
  fee?: string | null;
  showCTA: boolean;
  nearest?: boolean;
  showMap: boolean;
}
const StoreLocationCard = React.forwardRef<HTMLDivElement, StoreLocationCardProps>((props, ref) => {
  const {
    idx,
    isSelected,
    location,
    className,
    fee,
    onLinkClick,
    showCTA,
    nearest,
    showMap
  } = props;
  const [showDisplayHours, setShowDisplayHours] = useState(false);
  const displayHoursRef = useRef<HTMLUListElement>(null);
  const {
    display_name,
    street,
    state,
    stores,
    display_hours
  } = location;
  const {
    track
  } = useTrackWithFlags();
  const statusMessage = useMemo(() => {
    const store = stores?.find(st => st.brand.id === 11);
    if (store?.store_hours) {
      const hours = store?.store_hours?.[getDayOfWeek()]?.hours?.[0];
      if (hours) {
        if (isClosingSoon(hours.end_time, hours.end_day)) {
          return <div className="text-sm">{`Open until ${formatDisplayTime(hours.end_time)}`}</div>;
        }
        if (!getIsOpenNow(store)) {
          return <div className="text-sm">{`Opens at ${formatDisplayTime(hours.start_time)}`}</div>;
        }
        return <div className="text-sm">Open now</div>;
      }
    }
    return null;
  }, [stores]);
  const toggleShowDisplayHours = (evt: any) => {
    evt.stopPropagation();
    track({
      event: "Click on Card Display hours",
      properties: {
        slug: location.slug,
        id: location.id,
        name: location.name
      }
    });
    setShowDisplayHours(show => !show);
  };
  useEffect(() => {
    if (showDisplayHours) {
      displayHoursRef.current?.focus?.();
    } else {
      displayHoursRef.current?.blur?.();
    }
  }, [showDisplayHours]);
  return <div ref={ref} className={`${className} border-solid border-border-secondary p-5 sm:p-6 mb-4 rounded-xl shadow-sm ${isSelected ? "border-border-primary border-2 bg-surface-light" : "border hover:border-border-primary"}`}>
      <div className="flex justify-between">
        <div className="font-semibold">{`${display_name}${nearest ? " (nearest)" : ""}`}</div>
        {showMap ? <div className="flex rounded-full bg-orange-700 w-5 h-5 items-center justify-center text-white text-xs font-bold">
            {idx + 1}
          </div> : null}
      </div>
      <div className="font-normal text-sm text-text-secondary">{`${street}, ${state}`}</div>
      <div className="flex items-center">
        {location.distance ? <div className="text-text-secondary text-sm">{`${Math.round(location.distance)} Miles away`}</div> : null}
        {location.distance && fee ? <div className="mx-1">{"\u2E31"}</div> : null}
        {fee && <div className="text-text-secondary text-sm">{fee}</div>}
      </div>
      <div className="flex flex-row justify-between">
        <div className={classNames("flex align-center text-text-secondary", statusMessage ? "justify-start" : "justify-end")}>
          {statusMessage}
        </div>
        <div onClick={toggleShowDisplayHours} className="text-text-secondary link text-sm cursor-pointer prose flex flex-row justify-center" role="presentation">
          Hours
          {showDisplayHours ? <FontAwesomeIcon icon={faChevronUp} className="h-5 w-5" /> : <FontAwesomeIcon icon={faChevronDown} className="h-5 w-5" />}
        </div>
      </div>
      <Transition show={showDisplayHours} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
        <ul className="focus text-text-secondary space-y-1 my-2 px-8" onBlur={toggleShowDisplayHours} ref={displayHoursRef}>
          {display_hours?.map((hour, idx) => {
          const isToday = getIsToday(hour.date);
          const weekDay = getWeekDay(hour.date);
          return <li key={`hours-${idx}`} className={`flex flex-row justify-between text-sm ${isToday ? "font-semibold" : ""}`}>
                <div>{isToday ? "Today*" : weekDay}</div>
                <div>{hour.hours}</div>
              </li>;
        })}
        </ul>
      </Transition>
      <div className={`flex items-end ${showCTA ? "justify-between" : "justify-center"}`}>
        <div className="flex flex-col justify-center text-text-secondary text-sm items-start mt-4 mr-2 space-y-1">
          {location?.is_dine_in_available ? <div className="flex flex-row items-center">
              <FontAwesomeIcon className="rounded-full text-lfg-primary mr-2" size="1x" icon={faUtensils} fixedWidth />
              Dine In
            </div> : null}
          <div className="flex flex-row items-center">
            <FontAwesomeIcon className="rounded-full text-lfg-primary mr-2" size="1x" fixedWidth icon={faShoppingBag} />
            Pickup
          </div>
          <div className="flex flex-row items-center">
            <FontAwesomeIcon className="rounded-full text-lfg-primary mr-2" size="1x" fixedWidth icon={faCar} />
            Delivery
          </div>
        </div>
        {showCTA ? <div className="flex flex-row justify-center items-center mt-4 shrink-0">
            <Button onClick={e => {
          e.stopPropagation();
          onLinkClick?.(location);
        }} label="Order Now" mode="primary" size="small" />
          </div> : null}
      </div>
    </div>;
});
export default StoreLocationCard;