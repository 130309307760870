import { useAddressDeliveryEstimatesLazyQuery } from "../../../graphql/addressDeliveryEstimates.query.generated";
import { DeliveryAddress, DeliveryEstimate } from "../../Cart/types";
export const useAddressDeliveryEstimates = () => {
  const [loadEstimates, {
    error,
    loading,
    data
  }] = useAddressDeliveryEstimatesLazyQuery();
  const onDeliveryEstimates = (deliveryAddress: DeliveryAddress, locationIds?: number[]) => {
    const {
      city,
      street,
      state,
      zipcode
    } = deliveryAddress;
    return loadEstimates({
      variables: {
        delivery_address: {
          city,
          street,
          state,
          zipcode
        },
        location_ids: locationIds
      },
      fetchPolicy: "network-only"
    });
  };
  return {
    deliveryEstimates: ((data?.address_delivery_estimates || []) as DeliveryEstimate[]),
    onDeliveryEstimates,
    error,
    loadingDeliveryEstimates: loading
  };
};