import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { ChangeEvent } from "react";

export const parsePhoneNumberNational = (number: string) => {
  return parsePhoneNumber(number).format("NATIONAL");
};

export const validatePhoneNumber = (number?: string | null) => {
  if (!number) {
    return "Phone number is required";
  }
  try {
    const parsedPhoneNumber = parsePhoneNumber(number, "US").format(
      "INTERNATIONAL",
    );
    if (!isValidPhoneNumber(parsedPhoneNumber, "US")) {
      return "Phone number is invalid";
    }
  } catch {
    return "Phone number is invalid";
  }
};

export const formatPhoneNumberForUI = (phoneNumber: string) => {
  if (!phoneNumber) {
    return "";
  }

  if (phoneNumber.length < 4) {
    return `(${phoneNumber}`;
  }

  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
};

export const parsePhoneNumberWithoutCountryCode = (number?: string) => {
  return (
    number
      ?.replace("+1", "")
      ?.replace(/[^0-9]/g, "")
      .slice(0, 10) ?? ""
  );
};

export const checkPhoneNumberValid = (number: string): boolean => {
  if (!number) {
    return false;
  }

  const parsed = parsePhoneNumberWithoutCountryCode(number);
  const missingLeadingOne = parsePhoneNumber(`+1${parsed}`);
  const isValid = Boolean(missingLeadingOne?.isValid());

  return isValid;
};

export const onPhoneNumberInputChange = (
  event: ChangeEvent<HTMLInputElement>,
  previousValue: string,
) => {
  const newValue = event.target.value;
  let cleanedNewNumber = parsePhoneNumberWithoutCountryCode(newValue);
  const cleanedPreviousNumber =
    parsePhoneNumberWithoutCountryCode(previousValue);

  // Positive delta: user is adding numbers
  // Negative delta; user is deleting numbers
  const inputDelta = newValue.length - previousValue.length;

  // Delta is 0, user's change was not recorded
  const phoneNumberDelta =
    cleanedNewNumber.length - cleanedPreviousNumber.length;

  if (newValue.length > 1 && inputDelta < phoneNumberDelta) {
    cleanedNewNumber = cleanedNewNumber.slice(0, -1);
  }

  if (inputDelta < 0) {
    // If the user is deleting a number, we need to adjust the caret position
    const caret = event.target.selectionStart;
    const element = event.target;

    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });
  }

  if (phoneNumberDelta === 0 && inputDelta < 0 && newValue.length > 0) {
    return previousValue;
  }

  const formattedPhoneNumber = formatPhoneNumberForUI(cleanedNewNumber);

  return formattedPhoneNumber;
};
