import { useEffect, useState } from "react";
import useIntersectionObserver from "../../../utils/hooks/useIntersectionObserver";
export const useShadows = (imageContainerRef: React.RefObject<HTMLDivElement>) => {
  const [bottomShadow, setBottomShadow] = useState(false);
  const isImageVisible = useIntersectionObserver(imageContainerRef, {
    threshold: 0.01
  })?.isIntersecting;
  useEffect(() => {
    const newBottomShadow = !isImageVisible;
    if (newBottomShadow !== bottomShadow) {
      setBottomShadow(!isImageVisible);
    }
  }, [bottomShadow, isImageVisible]);
  return {
    bottomShadow
  };
};