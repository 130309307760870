import { faXmarkLarge } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { LocationWithDistance } from "../../../../types";
import { useLocationContext } from "../../../../utils/contexts/LocationContext";
import { GoogleMapInstance } from "./GoogleMapInstance";
import { GoogleMapMarker } from "./GoogleMapMarker";
const NEXT_PUBLIC_GUEST_WEB_URL = process.env.NEXT_PUBLIC_GUEST_WEB_URL;
const deliveryAddressImage = {
  url: `${NEXT_PUBLIC_GUEST_WEB_URL}/guest_web_assets/marker-delivery-home.png`
};
const storeMarker = {
  url: `${NEXT_PUBLIC_GUEST_WEB_URL}/guest_web_assets/marker-store.png`
};
type GoogleMapWrapperProps = {
  bounds?: google.maps.LatLngBounds;
  locations: LocationWithDistance[];
  onLocationClick: (location: LocationWithDistance) => void;
  onLocationDoubleClick: (location: LocationWithDistance) => void;
  onClose?: () => void;
  shouldClose?: boolean;
};
export const GoogleMapWrapper = ({
  bounds,
  locations,
  onLocationClick,
  onLocationDoubleClick,
  onClose,
  shouldClose
}: GoogleMapWrapperProps) => {
  const {
    selectedLocation,
    searchedAddress
  } = useLocationContext();
  const [zoom, setZoom] = useState<number>(8);
  const [panTo, setPanTo] = useState<google.maps.LatLngLiteral>();
  useEffect(() => {
    if (selectedLocation) {
      setZoom(10);
      setPanTo({
        lat: selectedLocation.lat,
        lng: selectedLocation.lng
      });
    }
  }, [selectedLocation]);
  useEffect(() => {
    if (searchedAddress?.geocodes?.lat && searchedAddress?.geocodes?.lng) {
      setZoom(10);
      setPanTo({
        lat: searchedAddress.geocodes.lat,
        lng: searchedAddress.geocodes.lng
      });
    }
  }, [searchedAddress]);
  return <div style={{
    width: "100%",
    height: "100%"
  }} data-sentry-component="GoogleMapWrapper" data-sentry-source-file="GoogleMapWrapper.tsx">
      <GoogleMapInstance zoom={zoom} panTo={panTo} style={{
      width: "100%",
      height: "100%"
    }} bounds={bounds} fullscreenControl={false} mapTypeControl={false} streetViewControl={false} data-sentry-element="GoogleMapInstance" data-sentry-source-file="GoogleMapWrapper.tsx">
        {/* Child components, such as markers, info windows, etc. */}
        {searchedAddress?.geocodes?.lat && searchedAddress?.geocodes?.lng && <GoogleMapMarker key={searchedAddress.id} position={{
        lat: searchedAddress.geocodes.lat,
        lng: searchedAddress.geocodes.lng
      }} icon={deliveryAddressImage} />}
        {locations.map((location, idx) => {
        const isSelected = location.slug === selectedLocation?.slug;
        return <GoogleMapMarker key={location.id} position={{
          lat: location.lat,
          lng: location.lng
        }} label={{
          text: `${idx + 1}`,
          fontSize: "13px",
          fontWeight: "500",
          color: "white",
          className: "mb-4 z-30"
        }} icon={storeMarker} onClick={() => {
          if (isSelected) {
            onLocationDoubleClick(location);
          } else {
            onLocationClick(location);
          }
        }} />;
      })}
      </GoogleMapInstance>
      <div className="w-10 h-10 shadow-md rounded-full bg-surface-lighter hover:bg-opacity-80 absolute top-3 sm:top-5 left-3 sm:left-5 z-10 flex items-center justify-center cursor-pointer" onClick={onClose}>
        {shouldClose ? <FontAwesomeIcon icon={faXmarkLarge} className="h-6 w-6 text-lfg-secondary" /> : <ArrowLeftIcon className="h-6 w-6 text-lfg-secondary" />}
      </div>
    </div>;
};