import { ConfirmationModal } from "../../../ui/ConfirmationModal/ConfirmationModal";
interface ConfirmChangeLocationModalProps {
  showModal: boolean;
  closeModal: () => void;
  onConfirm: () => void;
}
const ConfirmChangeLocationModal = ({
  showModal,
  closeModal,
  onConfirm
}: ConfirmChangeLocationModalProps) => {
  return <ConfirmationModal show={showModal} closeModal={closeModal} header="Confirm location change" size="small" message="Changing location will clear your bag. Are you sure you want to continue?" confirmButton={{
    label: "Continue",
    onClick: onConfirm
  }} cancelButton={{
    label: "Cancel",
    onClick: closeModal
  }} data-sentry-element="ConfirmationModal" data-sentry-component="ConfirmChangeLocationModal" data-sentry-source-file="ConfirmChangeLocationModal.tsx" />;
};
export default ConfirmChangeLocationModal;