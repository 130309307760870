import { ApolloError, gql, useQuery } from "@apollo/client";
export const ACTIVE_BRANDS_QUERY = gql`
  query ActiveBrandsQuery {
    active_brands {
      id
    }
  }
`;
export interface ActiveBrands {
  id: number;
}
export const useActiveBrands = (): {
  activeBrandsIds?: ActiveBrands[];
  loading: boolean;
  error?: ApolloError;
} => {
  const {
    data,
    error,
    loading
  } = useQuery(ACTIVE_BRANDS_QUERY, {
    fetchPolicy: "cache-and-network"
  });
  return {
    activeBrandsIds: data?.active_brands,
    loading,
    error
  };
};