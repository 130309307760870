import { Header, Text } from "../../../ui/Typography";
interface MenuItemModalSectionTitleProps {
  title: string;
  subtitle: string;
  pillText?: string;
  className?: string;
}
const MenuItemModalSectionTitle = ({
  title,
  subtitle,
  pillText,
  className
}: MenuItemModalSectionTitleProps) => {
  return <div className={className} data-sentry-component="MenuItemModalSectionTitle" data-sentry-source-file="MenuItemModalSectionTitle.tsx">
      <div className="flex justify-between items-start">
        <div>
          <Header h3 data-sentry-element="Header" data-sentry-source-file="MenuItemModalSectionTitle.tsx">{title}</Header>
          <Text medium className="text-text-secondary" data-sentry-element="Text" data-sentry-source-file="MenuItemModalSectionTitle.tsx">
            {subtitle}
          </Text>
        </div>
        {pillText ? <Text medium className="px-3 py-2 bg-button-filled-primary_disabled text-text-secondary rounded-lg">
            {pillText}
          </Text> : null}
      </div>
    </div>;
};
export default MenuItemModalSectionTitle;