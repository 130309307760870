import { Transition } from "@headlessui/react";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { useLocationContext } from "../../../utils/contexts/LocationContext";
import { Address } from "../../ProfileDeliveryAddress/components/Address";
import { ADDRESS_STAGES } from "../types";
type ViewAddressProps = {
  show: boolean;
  setAddressStage: Dispatch<SetStateAction<number>>;
};
export const ViewAddress = ({
  show,
  setAddressStage
}: ViewAddressProps) => {
  const {
    searchedAddress
  } = useLocationContext();
  const {
    cart
  } = useCartContext();
  const address = useMemo(() => {
    if (searchedAddress) {
      return searchedAddress;
    }
    return cart?.delivery_address;
  }, [searchedAddress, cart]);
  const isValid = !(!address?.street || !address.zipcode);
  return <Transition show={show} enter="transition ease-out duration-200" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-150" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" data-sentry-element="Transition" data-sentry-component="ViewAddress" data-sentry-source-file="ViewAddress.tsx">
      {isValid && address ? <Address address={address} onClick={() => {
      setAddressStage(ADDRESS_STAGES.MANUAL_ENTRY);
    }} onEdit={() => {
      setAddressStage(ADDRESS_STAGES.MANUAL_ENTRY);
    }} /> : null}
    </Transition>;
};