import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import { Cart } from "../types";

export const CART_VALIDATION_QUERY = gql`
  query CartValidationQuery($id: String, $location_slug: String) {
    cart(id: $id, location_slug: $location_slug) {
      id
      status
      items
      first_name
      last_name
      email
      location
      phone_number
      is_delivery
      customer_id
      start_buffer_time
      delivery_address {
        id
        street
        city
        state
        zipcode
        unit
        customer_id
        instructions
        is_default
      }
      is_scheduled
      order_time
      stripe_payment_intent_id
      customer_delivery_fee
      tip_rate
      tip
      credits_used
      coupon {
        id
        name
        display_name
        description
        code
        discount_rate
        max_amount
        credits
        brand_attrs
      }
      validation {
        is_items_valid
        is_order_time_valid
        is_store_status_valid
        is_promo_valid
        invalid_items {
          id
          external_id
          is_active
          is_store_active
          name
          price
          quantity
          modifier_items {
            id
            is_active
            name
            price
            quantity
          }
        }
      }
    }
  }
`;

export const useCartValidation = (cart: Cart | undefined) => {
  const client = useApolloClient();
  const [loadCart, { loading, refetch }] = useLazyQuery(CART_VALIDATION_QUERY, {
    variables: {
      id: cart?.id,
      location_slug: cart?.location,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  return {
    fetchCart: () => {
      // do not fetch a cart with no location
      if (!cart?.location) return;

      return client.query({
        query: CART_VALIDATION_QUERY,
        variables: {
          id: cart?.id,
          location_slug: cart?.location,
        },
        fetchPolicy: "network-only",
      });
    },
    loadCart,
    refetchCart: refetch,
    loadingCart: loading,
  };
};
