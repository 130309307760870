import * as Types from "../../../graphql/types";

import * as Apollo from "@apollo/client";
import { gql } from "@apollo/client";
const defaultOptions = {} as const;
export type DeliveryAddressesQueryQueryVariables = Types.Exact<{
  auth0_user_id?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type DeliveryAddressesQueryQuery = {
  __typename?: "Query";
  delivery_addresses?:
    | Array<
        | {
            __typename?: "DeliveryAddress";
            id?: string | undefined;
            street?: string | undefined;
            city?: string | undefined;
            state?: string | undefined;
            zipcode?: string | undefined;
            unit?: string | undefined;
            customer_id?: string | undefined;
            instructions?: string | undefined;
            is_default?: boolean | undefined;
            geocodes?:
              | {
                  __typename?: "GeocodeDetails";
                  lat?: number | undefined;
                  lng?: number | undefined;
                }
              | undefined;
          }
        | undefined
      >
    | undefined;
};

export const DeliveryAddressesQueryDocument = gql`
  query DeliveryAddressesQuery {
    delivery_addresses {
      id
      street
      city
      state
      zipcode
      unit
      customer_id
      instructions
      is_default
      geocodes {
        lat
        lng
      }
    }
  }
`;

/**
 * __useDeliveryAddressesQueryQuery__
 *
 * To run a query within a React component, call `useDeliveryAddressesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryAddressesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryAddressesQueryQuery({
 *   variables: {
 *      auth0_user_id: // value for 'auth0_user_id'
 *   },
 * });
 */
export function useDeliveryAddressesQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DeliveryAddressesQueryQuery,
    DeliveryAddressesQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DeliveryAddressesQueryQuery,
    DeliveryAddressesQueryQueryVariables
  >(DeliveryAddressesQueryDocument, options);
}
export function useDeliveryAddressesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeliveryAddressesQueryQuery,
    DeliveryAddressesQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DeliveryAddressesQueryQuery,
    DeliveryAddressesQueryQueryVariables
  >(DeliveryAddressesQueryDocument, options);
}
export type DeliveryAddressesQueryQueryHookResult = ReturnType<
  typeof useDeliveryAddressesQueryQuery
>;
export type DeliveryAddressesQueryLazyQueryHookResult = ReturnType<
  typeof useDeliveryAddressesQueryLazyQuery
>;
export type DeliveryAddressesQueryQueryResult = Apollo.QueryResult<
  DeliveryAddressesQueryQuery,
  DeliveryAddressesQueryQueryVariables
>;
