import { faChevronDown, faChevronUp, faXmarkLarge } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
interface SelectProps<Option> {
  multi: boolean;
  options: Option[];
  setSearchValue: (value: string) => void;
  getTextLabel?: (option: Option) => string;
  value?: Option | Option[];
  onChange: (value?: Option | Option[]) => void;
  renderOption: (optData: Option, isMulti: boolean) => React.ReactNode;
  renderSelected: (optData: Option) => React.ReactNode;
  emptyMessage?: string;
  debounceSearchMS?: number;
  placeholder?: string;
}
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export const Select = <Option,>({
  multi,
  options,
  setSearchValue,
  value,
  onChange,
  renderOption,
  renderSelected,
  getTextLabel,
  debounceSearchMS = 300,
  emptyMessage,
  placeholder
}: SelectProps<Option>) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    searchRef?.current?.focus?.();
    setOpen(true);
  }, [searchRef]);
  const debouncedSetSearchQuery: (query: string) => void = useCallback(debounce(query => {
    setSearchValue(query);
  }, debounceSearchMS), [setSearchValue]);
  useEffect(() => {
    if (!multi && value && getTextLabel) {
      setInputValue(getTextLabel((value as Option)));
    }
    if (multi) {
      setInputValue("");
      setSearchValue("");
      searchRef?.current?.focus?.();
    }
  }, [value]);
  const handleResetInput = useCallback(() => {
    setInputValue("");
    debouncedSetSearchQuery("");
    if (!multi) {
      onChange(undefined);
    }
  }, []);
  const chips = useMemo(() => {
    if (multi) {
      if (Array.isArray(value) && value.length) {
        return value.map(option => renderSelected(option));
      }
      return null;
    }
    return renderSelected((value as Option));
  }, [multi, renderSelected, value]);
  return <div className="mt-5 relative" data-sentry-component="Select" data-sentry-source-file="Select.tsx">
      <div onFocus={() => {
      searchRef?.current?.focus?.();
    }} className={classNames(multi ? "flex items-center flex-wrap gap-x-2" : "flex items-center", "relative w-full py-1 bg-surface-lighter border border-border-secondary rounded-lg shadow-sm pl-3 pr-10 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm")}>
        {chips}
        <div className="sm:text-sm flex flex-grow" style={{
        minWidth: "10rem"
      }}>
          <input ref={searchRef} type="text" name="select_search" id="select_search" placeholder={placeholder || "Search..."} autoComplete="off" className="bg-surface-lighter sm:text-sm rounded-md flex-auto pt-2 leading-normal w-px px-0 flex-1 border-0 relative self-center text-xl focus:ring-0" value={inputValue} onChange={event => {
          setInputValue(event.target.value);
          debouncedSetSearchQuery(event.target.value);
          if (!open) {
            setOpen(true);
          }
        }} onBlur={() => {
          if (!multi && value && getTextLabel) {
            setInputValue(getTextLabel((value as Option)));
            debouncedSetSearchQuery(getTextLabel((value as Option)));
          }
        }} />
          {Boolean(inputValue.length) && <div className="flex cursor-pointer" onClick={() => handleResetInput()}>
              <span className="flex items-center leading-normal bg-surface-lighter rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-text-primary">
                <FontAwesomeIcon icon={faXmarkLarge} className="h-5 w-5" />
              </span>
            </div>}
        </div>

        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2">
          {open ? <FontAwesomeIcon icon={faChevronUp} className="h-5 w-5 text-text-secondary" aria-hidden="true" onClick={() => setOpen(false)} /> : <FontAwesomeIcon icon={faChevronDown} className="h-5 w-5 text-text-secondary" aria-hidden="true" onClick={() => setOpen(true)} />}
        </span>
      </div>

      <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="Transition" data-sentry-source-file="Select.tsx">
        <div className={`absolute border border-gray-100 z-30 mt-1 w-full bg-surface-lighter shadow-md max-h-80 md:max-h-40 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm`}>
          {Boolean(options.length) && options.map(option => renderOption(option, multi))}
          {Boolean(!options.length) && <div className="text-sm mx-3 text-text-secondary py-2">
              {emptyMessage || "No items"}
            </div>}
        </div>
      </Transition>
    </div>;
};