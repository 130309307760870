import { faChevronDown, faChevronRight } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { LoadingSpinner } from "../../ui/Loading/LoadingSpinner";
import { ResponsiveImage } from "../../ui/ResponsiveImage";
import { Header, Text } from "../../ui/Typography";
import { usdFormat } from "../../utils/usdFormatter";
import { PublicMenuItem } from "./types";
type Props = {
  pairingPublicMenuItems?: PublicMenuItem[];
  parentMenuItem?: PublicMenuItem;
  recommendedPairingsItems: PublicMenuItem[];
  setRecommendedPairingsItems: Dispatch<SetStateAction<PublicMenuItem[]>>;
};
export const RecommendedPairings = ({
  pairingPublicMenuItems,
  parentMenuItem,
  recommendedPairingsItems,
  setRecommendedPairingsItems
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleExpanded = useCallback(() => setIsExpanded(prev => !prev), []);
  const findRecommendedPairingItem = useCallback((currentPairingItem: PublicMenuItem) => {
    return recommendedPairingsItems.find((pairing: PublicMenuItem) => pairing.id === currentPairingItem.id) ? true : false;
  }, [recommendedPairingsItems]);
  const handleCheckBoxToggle = (currentPairingItem: PublicMenuItem) => {
    if (findRecommendedPairingItem(currentPairingItem)) {
      const updatedItems = recommendedPairingsItems.filter((pairing: PublicMenuItem) => pairing.id !== currentPairingItem.id);
      setRecommendedPairingsItems(updatedItems);
    } else {
      setRecommendedPairingsItems([...recommendedPairingsItems, currentPairingItem]);
    }
  };
  const content = useMemo(() => {
    if (!pairingPublicMenuItems) {
      return <div className="w-full flex justify-center">
          <LoadingSpinner size={6} />
        </div>;
    }
    return <>
        <Text className="mb-2 text-text-secondary" medium>
          Complete your meal with items that go well with {parentMenuItem?.name}
        </Text>

        {pairingPublicMenuItems.length > 0 && <div className="mt-4">
            {pairingPublicMenuItems.map((pairingItem, i) => <div className="flex mt-3 cursor-pointer" key={pairingItem?.id ?? i} onClick={() => handleCheckBoxToggle(pairingItem)}>
                <button className={`cursor-pointer mt-2 ${findRecommendedPairingItem(pairingItem) ? "border-lfg-primary" : "border-lfg-secondary"} bg-surface-lighter border-2 rounded-md 
                             w-6 h-6 shrink-0 focus-within:border-lfg-secondary`}>
                  <div className={`${findRecommendedPairingItem(pairingItem) ? "bg-lfg-primary" : ""} w-5 h-5 justify-center items-center flex`}>
                    <svg className={`fill-current w-3 h-3 text-lfg-primary pointer-events-none block`} version="1.1" viewBox="0 0 17 12" xmlns="https://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <g transform="translate(-9 -11)" fill="#ffffff" fillRule="nonzero">
                          <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                        </g>
                      </g>
                    </svg>
                  </div>
                </button>
                <div className="flex-1 mt-2 ml-3">
                  <Header className="mr-4" h5>
                    {pairingItem?.name}
                  </Header>
                  <Text className="mr-4 text-text-secondary" medium>
                    {usdFormat(pairingItem?.price)}
                  </Text>
                </div>
                <div className="ml-auto">
                  <ResponsiveImage imageUrl={pairingItem?.image_url} className="w-24 h-[72px] rounded-lg mr-2 transition-transform duration-300 ease-out border" loading="lazy" />
                </div>
              </div>)}
          </div>}
      </>;
  }, [pairingPublicMenuItems, parentMenuItem?.name, findRecommendedPairingItem]);
  if (!parentMenuItem) {
    return null;
  }
  return <div data-sentry-component="RecommendedPairings" data-sentry-source-file="RecommendedPairings.tsx">
      <hr className="w-full block border-0 bg-black opacity-10 h-px mb-4" />
      <div className="mx-4 flex flex-row justify-between cursor-pointer" onClick={toggleExpanded}>
        <Header className={`${isExpanded ? "mb-1" : "mb-2"}`} h3 data-sentry-element="Header" data-sentry-source-file="RecommendedPairings.tsx">
          Recommended Pairings
        </Header>
        {isExpanded ? <FontAwesomeIcon icon={faChevronDown} className="h-5 w-5" /> : <FontAwesomeIcon icon={faChevronRight} className="h-5 w-5" />}
      </div>
      {isExpanded && <div className="mb-4 mx-4">{content}</div>}
    </div>;
};