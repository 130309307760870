import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { Alert } from "../Alert/Alert";
export type ToastProps = {
  description?: string;
  seconds?: number;
  variant: "success" | "info" | "error" | "warning";
  onClose?: () => void;
  onClick?: () => void;
  show?: string;
};
export const Toast = ({
  variant,
  description,
  seconds,
  onClose,
  onClick,
  show
}: ToastProps) => {
  const timeoutSeconds = seconds ?? 4;
  const [isDisplaying, setIsDisplaying] = useState(false);
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    setIsDisplaying(false);
  };
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setIsDisplaying(false);
  };
  useEffect(() => {
    setIsDisplaying(Boolean(show));
  }, [show]);
  useEffect(() => {
    if (show) {
      const interval = setInterval(() => {
        setIsDisplaying(false);
      }, timeoutSeconds * 1000);
      return () => clearInterval(interval);
    }
  }, [show]);
  return <Transition show={isDisplaying} enter="transition ease-in-out duration-150" enterFrom="-translate-y-1/4 opacity-0" enterTo="translate-y-0 opacity-100" leave="transition ease-in-out duration-75" leaveFrom="translate-y-0 opacity-100" leaveTo="-translate-y-3.5 opacity-0" className="fixed top-0 sm:top-16 right-0 p-3 z-60" onClick={handleClose} data-sentry-element="Transition" data-sentry-component="Toast" data-sentry-source-file="Toast.tsx">
      <div className="flex justify-center sm:justify-end max-w-sm w-full" onClick={handleClick}>
        <Alert variant={variant} description={description} onClose={handleClose} data-sentry-element="Alert" data-sentry-source-file="Toast.tsx" />
      </div>
    </Transition>;
};