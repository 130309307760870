import { useContext } from "react";
import { NewModal } from "../../../ui/NewModal/Modal";
import { GroupOrderContext } from "../context/GroupOrderContext";
const GroupOrderMessageModal = () => {
  const {
    showMessageModal,
    setShowMessageModal,
    message
  } = useContext(GroupOrderContext);
  return <NewModal header="Group order" mobileHeader="Oops, you can't add this!" body={<div>
          <p className="text-left text-gray-550 font-normal text-md font-light">
            {message}
          </p>
        </div>} show={showMessageModal} confirmMessage="Got it" onConfirm={() => {
    setShowMessageModal(false);
  }} onCloseComplete={() => {
    setShowMessageModal(false);
  }} onClose={() => {
    setShowMessageModal(false);
  }} onBlur={() => {
    setShowMessageModal(false);
  }} hasConfirm hasClose data-sentry-element="NewModal" data-sentry-component="GroupOrderMessageModal" data-sentry-source-file="GroupOrderMessageModal.tsx" />;
};
export default GroupOrderMessageModal;