export const CUSTOM_TIP_INDEX = -1;

export const CUSTOM_TIP_RATES = [
  {
    value: 0,
    displayValue: "No tip",
  },
  {
    value: 1000,
    displayValue: "10%",
  },
  {
    value: 1500,
    displayValue: "15%",
  },
  {
    value: 2000,
    displayValue: "20%",
  },
  {
    value: CUSTOM_TIP_INDEX,
    displayValue: "Custom",
  },
];

export const formatPriceFromCents = (priceInCents: number) =>
  (priceInCents / 100).toFixed(2);

export const getCustomTipDisplay = (tip: number) => {
  return `$${String(formatPriceFromCents(tip))}`;
};

export const getPercentTipDisplay = (value: number, subtotal: number) => {
  return `$${String(((value * subtotal) / 1000000).toFixed(2))}`;
};
