import { useMutation } from "@tanstack/react-query";
import { useToast } from "../../../app-components/hooks/useToast";
import { ShopBackendClient } from "../../../utils/shopBackend/apiClient";
import { PasscodeLoginMethod } from "../types";
import { useAuth } from "../hooks/useAuth";

// TODO: Replace with shopapi package hook
const useRequestPasscode = (
  onSuccess?: (method: PasscodeLoginMethod, identifier: string) => void,
  onConflict?: () => void,
) => {
  const { showToast } = useToast();
  const { isAuthenticated } = useAuth();

  return useMutation({
    mutationFn: isAuthenticated
      ? ShopBackendClient.requestUpdateIdentifierPasscode
      : ShopBackendClient.requestPasscode,
    onSettled: (data, error, variables) => {
      if (data?.response.status === 409 && onConflict) {
        onConflict();
        return;
      }

      if (error || !data?.response.ok) {
        showToast({
          variant: "error",
          description: "Unexpected error occured. Please try again.",
        });
        throw new Error(error?.message || "Unknown error");
      }

      onSuccess?.(variables.method, variables.identifier);
    },
  });
};

export default useRequestPasscode;
