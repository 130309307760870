import { faExclamationCircle, faShoppingBag } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction } from "react";
import { Button } from "../../NewButton/Button";
import { Text } from "../../Typography";
interface MobileFloatingCartButtonProps {
  cartQuantity: number;
  isCartValid: boolean;
  label: string;
  setShowCart: Dispatch<SetStateAction<boolean>>;
}
const MobileFloatingCartButton = ({
  cartQuantity,
  isCartValid,
  label,
  setShowCart
}: MobileFloatingCartButtonProps) => {
  return <div className="sm:hidden fixed bottom-0 mb-safe z-10 flex w-full justify-center" data-sentry-component="MobileFloatingCartButton" data-sentry-source-file="MobileFloatingCartButton.tsx">
      <Button mode="primary" size="large" className="w-full justify-between mx-4 !px-4" leadingIcon={{
      icon: faShoppingBag
    }} trailingIcon={!isCartValid ? <FontAwesomeIcon icon={faExclamationCircle} className="h-7 w-7 rounded-full text-new-error" /> : cartQuantity ? <Text large className="flex justify-center items-center text-text-white bg-icon-primary rounded-full h-7 w-7">
              {cartQuantity}
            </Text> : <div className="w-7" />} label={label} onPress={() => setShowCart(true)} data-sentry-element="Button" data-sentry-source-file="MobileFloatingCartButton.tsx" />
    </div>;
};
export default MobileFloatingCartButton;