import * as Types from '../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublicMenuItemReordersQueryVariables = Types.Exact<{
  locationId?: Types.InputMaybe<Types.Scalars['Int']>;
  isDelivery?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type PublicMenuItemReordersQuery = { __typename?: 'Query', public_menu_item_reorders: Array<{ __typename?: 'PublicMenuItemReorder', store_menu_item_id: number, reorders: Array<{ __typename?: 'PlacedOrderItemReorder', id: string, date: string, modifier_items: Array<{ __typename?: 'PlacedOrderModifierItem', id: number, quantity: number, name: string, price: number }> }> }> };


export const PublicMenuItemReordersDocument = gql`
    query PublicMenuItemReorders($locationId: Int, $isDelivery: Boolean) {
  public_menu_item_reorders(location_id: $locationId, is_delivery: $isDelivery) {
    store_menu_item_id
    reorders {
      id
      date
      modifier_items {
        id
        quantity
        name
        price
      }
    }
  }
}
    `;

/**
 * __usePublicMenuItemReordersQuery__
 *
 * To run a query within a React component, call `usePublicMenuItemReordersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicMenuItemReordersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicMenuItemReordersQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      isDelivery: // value for 'isDelivery'
 *   },
 * });
 */
export function usePublicMenuItemReordersQuery(baseOptions?: Apollo.QueryHookOptions<PublicMenuItemReordersQuery, PublicMenuItemReordersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicMenuItemReordersQuery, PublicMenuItemReordersQueryVariables>(PublicMenuItemReordersDocument, options);
      }
export function usePublicMenuItemReordersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicMenuItemReordersQuery, PublicMenuItemReordersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicMenuItemReordersQuery, PublicMenuItemReordersQueryVariables>(PublicMenuItemReordersDocument, options);
        }
export type PublicMenuItemReordersQueryHookResult = ReturnType<typeof usePublicMenuItemReordersQuery>;
export type PublicMenuItemReordersLazyQueryHookResult = ReturnType<typeof usePublicMenuItemReordersLazyQuery>;
export type PublicMenuItemReordersQueryResult = Apollo.QueryResult<PublicMenuItemReordersQuery, PublicMenuItemReordersQueryVariables>;