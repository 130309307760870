import Link from "next/link";
import { ResponsiveImage } from "../../../ui/ResponsiveImage";
import { useRouterQueryParam } from "../../../utils/hooks/useRouterQueryParam";
type BrandNameProps = {
  brandSlug: string;
  brandName: string;
  brandLogoUrl?: string;
  onClick?: () => void;
};
export const BrandName = ({
  brandSlug,
  brandName,
  brandLogoUrl,
  onClick
}: BrandNameProps) => {
  const locationSlug = useRouterQueryParam("locationSlug");
  return <Link onClick={onClick} className={`flex items-center gap-2 justify-start h-max`} href={`/order/store/${locationSlug}/${brandSlug}`} data-sentry-element="Link" data-sentry-component="BrandName" data-sentry-source-file="BrandName.tsx">
      {brandLogoUrl && <div className="justify-self-start">
          <ResponsiveImage className="h-6 w-6 mr-0.5 object-contain rounded-full drop-shadow-[0px_4px_12px_rgba(0,0,0,0.1)] border" imageUrl={brandLogoUrl} style={{
        borderColor: "rgba(0, 0, 0, 0.08)"
      }} />
        </div>}
      <div className="lfg-book text-text-secondary text-sm">
        {`${brandName}`}
      </div>
    </Link>;
};