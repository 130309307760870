import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ShopBackendClient } from "../../../utils/shopBackend/apiClient";
import { User } from "../../Authentication/types";

export const useUpdateCustomer = (
  onMutationCompleted?: (data: User) => void,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ShopBackendClient.updateMe,
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["me"], { data });
      onMutationCompleted?.(data);
    },
  });
};
