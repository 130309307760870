import { faChevronLeft, faChevronRight } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Children, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { Button } from "./NewButton/Button";
import { Text } from "./Typography";
const SLIDER_SETTINGS: Settings = {
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  infinite: true,
  swipeToSlide: true,
  draggable: true
};
interface MediaCarouselProps {
  children: React.ReactNode;
  showArrows?: boolean;
  showSlidesCounter?: boolean;
}
const MediaCarousel = ({
  showArrows = true,
  showSlidesCounter,
  children
}: MediaCarouselProps) => {
  const sliderRef = useRef<Slider>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  return <div className="relative" data-sentry-component="MediaCarousel" data-sentry-source-file="MediaCarousel.tsx">
      {showArrows && <Button mode="secondary" size="small" icon={<FontAwesomeIcon icon={faChevronLeft} className={`h-5 w-5`} />} iconOnly onPress={() => {
      sliderRef.current?.slickPrev();
    }} className="hidden md:flex absolute top-[50%] left-4 transition-opacity opacity-50 hover:opacity-100 translate-y-[-50%] z-10" />}
      {showArrows && <Button mode="secondary" size="small" icon={<FontAwesomeIcon icon={faChevronRight} className={`h-5 w-5`} />} iconOnly onPress={() => {
      sliderRef.current?.slickNext();
    }} className="hidden md:flex absolute top-[50%] right-4 transition-opacity opacity-50 hover:opacity-100 translate-y-[-50%] z-10" />}
      {showSlidesCounter && <div className="absolute bottom-4 right-4 z-10 px-2 py-1 text-xs text-white bg-black bg-opacity-60 rounded">
          <Text small>{`${currentSlide} / ${Children.count(children)}`}</Text>
        </div>}
      <Slider ref={sliderRef} {...SLIDER_SETTINGS} beforeChange={(_, nextSlide) => {
      setCurrentSlide(nextSlide + 1);
    }} data-sentry-element="Slider" data-sentry-source-file="MediaCarousel.tsx">
        {children}
      </Slider>
    </div>;
};
export default MediaCarousel;