import { DateTime } from "luxon";
import { AvailableOrderTime, DisplayOrderTimes } from "../features/Cart/types";
import { PublicStore } from "../features/Stores/types";
const DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const formatTime = (dateTime: DateTime) => {
  if (!dateTime) return "Closed";
  return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
};
function yyyymmddToLocalDate(isoString: string) {
  const [year, month, day] = isoString.split("-").map(x => parseInt(x, 10));
  return DateTime.fromObject({
    year,
    month,
    day,
    zone: "America/Los_Angeles" // TODO: Pull from API instead
  });
}

const formatDateString = (isToday: boolean, date: string) => {
  const dateTime = yyyymmddToLocalDate(date);
  const fmt: {
    [key: string]: "long" | "numeric";
  } = {
    month: "long",
    day: "numeric"
  };
  return `${isToday ? "Today" : DAYS_OF_WEEK[dateTime.weekday % 7]}, ${dateTime.setLocale("en-US").toLocaleString(fmt)}`;
};
export const getWeekDay = (date: string) => {
  const dateTime = yyyymmddToLocalDate(date);
  return DAYS_OF_WEEK[dateTime.weekday % 7];
};
const unixTimestampToLocalTime = (timestamp: number) => {
  return DateTime.fromSeconds(timestamp);
};
const isTimestampAfterDatetime = (timestamp: number, datetime: DateTime) => {
  return DateTime.fromSeconds(timestamp) > datetime;
};
export const getIsToday = (orderTime: string) => {
  const dateTime = yyyymmddToLocalDate(orderTime);
  const now = new Date();
  return dateTime.weekday === now.getDay();
};
export const formatPickupTime = (timestamp: number) => {
  return DateTime.fromSeconds(timestamp).setZone("America/Los_Angeles").toString();
};
export const getDisplayOrderTimeByTimestamp = (allDisplayOrderTimes: DisplayOrderTimes[], timestamp: number) => {
  return allDisplayOrderTimes.find(displayOrderTime => {
    const times = [...displayOrderTime.yesterdayLateNight, ...displayOrderTime.today];
    return times.find(t => t.time === timestamp);
  });
};
export const localTimeToUnixTimestamp = (localTime: string) => {
  const jsDateTime = new Date(localTime);
  return DateTime.fromJSDate(jsDateTime).toSeconds();
};
export const formatAvailableTimesData = (data: AvailableOrderTime[], startBufferTime: number | undefined): DisplayOrderTimes[] => {
  const now = DateTime.now();
  const nowWithBuffer = now.plus({
    minutes: startBufferTime
  });
  return data.slice(1).map((availableOrderTimes, idx) => {
    // NB: "yesterday", "today", and "tomorrow" mean the following:
    // yeserday: times that fall on selected day but are part of the previous day's late night hours
    // today: times that fall on selected day and are part of the selected day's regular hours
    // tomorrow: times that fall on day following selected but are part of selected day's late night hours
    const isToday = idx === 0;
    const todayDisplayDate = formatDateString(isToday, availableOrderTimes.date);
    const yesterday = data[idx];
    const yesterdayLateNightTimes = yesterday.times.length === 2 ? yesterday.times[1] : [];
    const yesterdayDisplayDate = formatDateString(isToday, yesterday.date);
    const todayTimes = [...yesterdayLateNightTimes, ...(availableOrderTimes.times[0] || [])];

    // add the buffer time to the start of the day
    const todayStart = availableOrderTimes.times[0]?.length ? availableOrderTimes.times[0][0] : 0;
    let tomorrowTimes: number[] = [];
    let tomorrowDate = "";
    if (idx < data.length - 2) {
      tomorrowTimes = availableOrderTimes.times.length === 2 ? [...availableOrderTimes.times[1]] : [];
      tomorrowDate = data[idx + 2].date;
    }
    return {
      yesterdayLateNight: yesterdayLateNightTimes.filter(time => isTimestampAfterDatetime(time, nowWithBuffer)).map(unixTime => ({
        time: unixTime,
        displayTime: formatTime(unixTimestampToLocalTime(unixTime))
      })),
      yesterdayLateNightDate: availableOrderTimes.date,
      yesterdayLateNightDisplayDate: yesterdayDisplayDate,
      today: todayTimes.filter(time => isTimestampAfterDatetime(time, nowWithBuffer) && time >= todayStart).map(unixTime => ({
        time: unixTime,
        displayTime: formatTime(unixTimestampToLocalTime(unixTime))
      })),
      todayDate: availableOrderTimes.date,
      todayDisplayDate,
      tomorrow: tomorrowTimes.filter(time => isTimestampAfterDatetime(time, nowWithBuffer)).map(unixTime => ({
        time: unixTime,
        displayTime: formatTime(unixTimestampToLocalTime(unixTime))
      })),
      tomorrowDate,
      tomorrowDisplayDate: tomorrowDate ? formatDateString(false, tomorrowDate) : ""
    };
  });
};
export const getPickupTimeRangeStr = (initialTime: number, range: number): string => {
  return `${initialTime}-${initialTime + range}`;
};
export const getIsOpenNow = (store: PublicStore) => {
  return store.is_open && store.is_within_hours;
};
export const getDayOfWeek = (daysFromNow?: number) => {
  const date = new Date();
  date.setDate(date.getDate() + (daysFromNow || 0));
  return date.getDay();
};
export const formatDisplayTime = (standardTime: string) => {
  const time = DateTime.fromFormat(standardTime, "HH:mm").toFormat("hh:mm a").replace("12:00 am", "Midnight").replace(" ", "");
  return time.charAt(0) === "0" ? time.slice(1) : time;
};
export const isClosingSoon = (standardTime: string, days: number) => {
  const closeTime = DateTime.fromFormat(standardTime, "HH:mm");
  const closeTimeWithBuffer = closeTime.plus({
    days
  });
  const now = DateTime.now();
  const nowWithBuffer = now.plus({
    minutes: 30
  });
  return now < closeTimeWithBuffer && nowWithBuffer > closeTimeWithBuffer;
};
export const isClosingSoonLateNight = (store: PublicStore) => {
  if (store?.is_open && store?.store_hours) {
    const yesterdayHours = store.store_hours[getDayOfWeek(-1)].hours[0];
    if (yesterdayHours?.end_day === 1) {
      return isClosingSoon(yesterdayHours?.end_time, 0);
    }
    return false;
  }
  return false;
};