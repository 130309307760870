import { SyntheticEvent } from "react";
import Modal from "../../../../ui/Modal/NewModal";
import { Button } from "../../../../ui/NewButton/Button";
import { Text } from "../../../../ui/Typography";
import { useLocationContext } from "../../../../utils/contexts/LocationContext";
import { useTrackWithFlags } from "../../../../utils/hooks/useTrackWithFlags";
type LargeOrderModalProps = {
  copy: string;
  show: boolean;
  closeModal: () => void;
};
export const LargeOrderModal = ({
  copy,
  show,
  closeModal
}: LargeOrderModalProps) => {
  return <Modal size="medium" title="Large Orders" showModal={show} closeModal={closeModal} data-sentry-element="Modal" data-sentry-component="LargeOrderModal" data-sentry-source-file="LargeOrderModal.tsx">
      <div className="mt-6">
        <Text large component="p" data-sentry-element="Text" data-sentry-source-file="LargeOrderModal.tsx">
          Orders this large must be scheduled at least {copy} to give our
          kitchen enough time to prepare your meal.
        </Text>
        <ul className="list-disc mx-6 mt-6">
          <li>
            <Text large component="p" data-sentry-element="Text" data-sentry-source-file="LargeOrderModal.tsx">
              Greater than $250: 60 minutes
            </Text>
          </li>
          <li>
            <Text large component="p" data-sentry-element="Text" data-sentry-source-file="LargeOrderModal.tsx">
              Greater than $500: 90 minutes
            </Text>
          </li>
          <li>
            <Text large component="p" data-sentry-element="Text" data-sentry-source-file="LargeOrderModal.tsx">
              Greater than $750: one day
            </Text>
          </li>
        </ul>
        <Text large component="p" className="mt-6" data-sentry-element="Text" data-sentry-source-file="LargeOrderModal.tsx">
          You can schedule this order, adjust your cart to order sooner, or{" "}
          <ContactUs copy={copy} data-sentry-element="ContactUs" data-sentry-source-file="LargeOrderModal.tsx" /> us to see how quickly we can prepare your
          order.
        </Text>

        <Button label="Close" size="large" mode="primary" onPress={closeModal} className="w-full mt-6" data-sentry-element="Button" data-sentry-source-file="LargeOrderModal.tsx" />
      </div>
    </Modal>;
};
type ContactUsProps = {
  copy: string;
};
export const ContactUs = ({
  copy
}: ContactUsProps) => {
  const {
    storeLocation
  } = useLocationContext();
  const {
    track
  } = useTrackWithFlags();
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    track({
      event: "Clicked Large Order Contact link",
      properties: {
        copy
      }
    });
  };
  return <>
      <a onClick={handleClick} className="font-semibold hidden md:inline-block" href={"mailto:guest@localkitchens.com"}>
        contact us
      </a>
      <a onClick={handleClick} className="font-semibold md:hidden" href={storeLocation?.phone_number ? `sms:+1${storeLocation?.phone_number}` : "mailto:guest@localkitchens.com"}>
        contact us
      </a>
    </>;
};