import { Dispatch, SetStateAction } from "react";
import { classNames } from "../../../utils/classNames";
import ReceiptCartItem from "../../Checkout/components/ReceiptCartItem";
import useCartReceiptLines from "../../Checkout/hooks/useCartReceiptLines";
import { PublicStore } from "../../Stores/types";
import { Cart, CartMenuItem, CartReceipt } from "../types";
import { cartHorizontalPaddingClassName } from "../utils";
interface CartItemListProps {
  cart: Cart;
  cartReceipt?: CartReceipt;
  stores: Record<number, PublicStore>;
  setItemInFocus: Dispatch<SetStateAction<CartMenuItem | undefined>>;
  setItemToRemove: Dispatch<SetStateAction<CartMenuItem | undefined>>;
  onUpdateItemQuantity: (item: CartMenuItem | undefined, quantity: number) => void;
  removeItem: (cart: Cart, item: CartMenuItem) => void;
}
const CartItemList = ({
  cart,
  cartReceipt,
  stores,
  setItemToRemove,
  setItemInFocus,
  onUpdateItemQuantity,
  removeItem
}: CartItemListProps) => {
  const {
    menuLineItems
  } = useCartReceiptLines(cartReceipt);
  return <ul className={classNames("space-y-6 mt-6", cartHorizontalPaddingClassName)} data-sentry-component="CartItemList" data-sentry-source-file="CartItemList.tsx">
      {menuLineItems.map(lineItem => {
      const cartItem = cart.items.find(item => item.external_id === lineItem.external_id);
      if (!cartItem) return null;
      return <ReceiptCartItem key={`${lineItem.external_id}-cart-item-key`} item={lineItem} cartItem={cartItem} stores={stores} removeItem={() => removeItem(cart, cartItem)} onUpdateQuantity={onUpdateItemQuantity} onModifyClick={item => setItemInFocus(item)} onRemoveClick={item => setItemToRemove(item)} />;
    })}
    </ul>;
};
export default CartItemList;