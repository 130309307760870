import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderCountQueryVariables = Types.Exact<{
  phoneNumber?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type OrderCountQuery = { __typename?: 'Query', order_count?: number | undefined };


export const OrderCountDocument = gql`
    query OrderCount($phoneNumber: String) {
  order_count(phone_number: $phoneNumber)
}
    `;

/**
 * __useOrderCountQuery__
 *
 * To run a query within a React component, call `useOrderCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderCountQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useOrderCountQuery(baseOptions?: Apollo.QueryHookOptions<OrderCountQuery, OrderCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderCountQuery, OrderCountQueryVariables>(OrderCountDocument, options);
      }
export function useOrderCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderCountQuery, OrderCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderCountQuery, OrderCountQueryVariables>(OrderCountDocument, options);
        }
export type OrderCountQueryHookResult = ReturnType<typeof useOrderCountQuery>;
export type OrderCountLazyQueryHookResult = ReturnType<typeof useOrderCountLazyQuery>;
export type OrderCountQueryResult = Apollo.QueryResult<OrderCountQuery, OrderCountQueryVariables>;