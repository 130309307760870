import { ApolloError, gql, useQuery } from "@apollo/client";
import { OrderType } from "../../../types";
import { PublicStore } from "../types";

export const STORES_MENUS_QUERY = gql`
  query StoresMenusQuery(
    $location_slug: String
    $filter_active_modifiers: Boolean
    $is_delivery: Boolean
  ) {
    public_stores(location_slug: $location_slug) {
      id
      is_open
      is_within_hours
      display_hours {
        hours
      }
      store_hours {
        day_of_week
        hours {
          start_day
          end_day
          start_time
          end_time
        }
      }
      ordinal
      brand {
        id
        name
        slug
        logo {
          url
        }
      }
      location {
        id
        name
        street
        display_name
      }
      most_popular {
        id
        count
      }
      most_recently_added(is_delivery: $is_delivery) {
        id
        menu_item_id
        name
        description
        brand_name
        brand_slug
        price
        is_active
        blurhash
        image_url
        store_id
        inserted_at
        tags {
          id
          name
          is_v2
          is_active
          slug
        }
        modifier_lists {
          id
          name
          min
          max
          is_single_select
          modifier_items(
            filter_active_modifiers: $filter_active_modifiers
            is_delivery: $is_delivery
          ) {
            id
            name
            price
            is_active
          }
        }
      }
      menus {
        id
        menu_categories {
          id
          name
          ordinal
          menu_items(is_delivery: $is_delivery) {
            id
            menu_item_id
            name
            description
            brand_name
            brand_slug
            price
            is_active
            blurhash
            image_url
            store_id
            inserted_at
            tags {
              id
              name
              is_v2
              is_active
              slug
            }
            modifier_lists {
              id
              name
              min
              max
              is_single_select
              modifier_items(
                filter_active_modifiers: $filter_active_modifiers
                is_delivery: $is_delivery
              ) {
                id
                name
                price
                is_active
              }
            }
          }
        }
      }
    }
  }
`;

const useStoresMenus = (
  locationSlug?: string,
  orderType?: OrderType,
): { publicStores?: PublicStore[]; loading: boolean; error?: ApolloError } => {
  const { data, loading, error, previousData } = useQuery(STORES_MENUS_QUERY, {
    skip: !locationSlug || !orderType,
    variables: {
      location_slug: locationSlug,
      filter_active_modifiers: false,
      is_delivery: orderType === OrderType.DELIVERY,
    },
    pollInterval: locationSlug ? 60 * 1000 : 0,
    fetchPolicy: "network-only",
  });

  return {
    publicStores: data?.public_stores ?? previousData?.public_stores,
    loading,
    error,
  };
};

export { useStoresMenus };
