import { gql } from "@apollo/client";
import { DeliveryAddress } from "../../../graphql/types";
import { CART_QUERY } from "@features/Cart/hooks/useCartLoader";
import { CART_DELIVERY_DETAILS_QUERY } from "@features/ChoosePickupDelivery/hooks/useCartDeliveryDetails";
import { DeliveryAddressesQueryDocument } from "@features/Profile/hooks/useDeliveryAddresses.generated";
import { CreateOrUpdateDeliveryAddressMutationVariables, useCreateOrUpdateDeliveryAddressMutation } from "./useDeliveryAddressMutations.generated";
import { useCallback } from "react";
import { useTrackWithFlags } from "@utils/hooks/useTrackWithFlags";
import { findMatchingAddressInList } from "@features/DeliveryAddresses/utils";
import { client } from "../../../app-components/ApolloWrapper";
const ONLY_SET_IS_DEFAULT_INPUT = ["id", "is_default"];
gql`
  mutation createOrUpdateDeliveryAddress(
    $input: DeliveryAddressInput
    $cart_id: String
  ) {
    create_or_update_delivery_address(input: $input, cart_id: $cart_id) {
      id
      street
      city
      state
      zipcode
      unit
      customer_id
      instructions
      geocodes {
        lat
        lng
      }
    }
  }
`;
export const useDeliveryAddressMutations = () => {
  const {
    track
  } = useTrackWithFlags();
  const [upsertDeliveryAddressMutation, {
    loading
  }] = useCreateOrUpdateDeliveryAddressMutation();
  const onUpdateDeliveryAddress = useCallback(async ({
    input,
    cart_id
  }: CreateOrUpdateDeliveryAddressMutationVariables): Promise<DeliveryAddress | undefined> => {
    if (!input) return;
    if (!(input.id || input.customer_id)) return;
    if (!input.id) {
      //TODO move this validation to the server
      // Validate for creating a new address
      // If the address exists doesn't create a new one
      // If the address exists and is_default is true, set it as default
      const deliveryAddresses: DeliveryAddress[] = await client.query({
        query: DeliveryAddressesQueryDocument,
        fetchPolicy: "cache-first"
      }).then(result => result.data?.delivery_addresses ?? []);
      const existingAddress = findMatchingAddressInList(input, deliveryAddresses);
      if (existingAddress) {
        if (input.is_default) {
          await onUpdateDeliveryAddress({
            input: {
              id: existingAddress.id,
              is_default: true
            },
            cart_id: cart_id
          });
        }
        return existingAddress;
      }
    }
    const {
      data
    } = await upsertDeliveryAddressMutation({
      variables: {
        input: {
          id: input.id,
          unit: input.unit,
          customer_id: input.customer_id,
          street: input.street,
          city: input.city,
          state: input.state,
          zipcode: input.zipcode,
          instructions: input.instructions,
          is_default: input.is_default
        },
        cart_id: cart_id
      },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: DeliveryAddressesQueryDocument
      }, {
        query: CART_QUERY,
        variables: {
          id: cart_id
        }
      }, {
        query: CART_DELIVERY_DETAILS_QUERY,
        variables: {
          id: cart_id
        }
      }]
    }).then(result => {
      const {
        id,
        customer_id
      } = result.data?.create_or_update_delivery_address ?? {};
      const addressWasUpdateOrCreate = Object.keys(input).some(key => !ONLY_SET_IS_DEFAULT_INPUT.includes(key));
      const addressWasSetDefault = input.is_default;
      if (addressWasUpdateOrCreate) {
        track({
          event: `${input?.id ? "Update" : "Create"} Delivery Address`,
          properties: {
            addressId: id,
            customerId: customer_id
          }
        });
      }
      if (addressWasSetDefault) {
        track({
          event: "Set Default Delivery Address",
          properties: {
            addressId: id
          }
        });
      }
      return result;
    });
    return data?.create_or_update_delivery_address;
  }, [track, upsertDeliveryAddressMutation]);
  return {
    onUpdateDeliveryAddress,
    loading
  };
};