import { OrderType } from "../../types";
import { Modal } from "../../ui/Modal/Modal";
import { useTrackWithFlags } from "../../utils/hooks/useTrackWithFlags";
type LocationDiscoveryModalProps = {
  show: boolean;
  closeModal: () => void;
  handleChangeOrderType: (orderType: OrderType) => void;
};
const LocationDiscoveryModal = ({
  show,
  closeModal,
  handleChangeOrderType
}: LocationDiscoveryModalProps) => {
  const {
    track
  } = useTrackWithFlags();
  return <>
      <Modal show={show} onCloseComplete={closeModal} header={<div className="flex flex-col space-y-4">
            <img className="h-8 sm:h-10 w-auto" src="https://lfg-landing.s3.us-west-2.amazonaws.com/local-kitchens-logolockup-master.svg" alt="Local Kitchens" />
            <div>Welcome! Select an order method to get started.</div>
          </div>} body={<div className="w-full relative z-0 flex flex-col items-center justify-center rounded-md space-y-4 mb-4">
            <button type="button" onClick={() => {
        track({
          event: "Changed Order Type",
          properties: {
            orderType: OrderType.PICKUP,
            feature: "location-discovery-modal"
          }
        });
        handleChangeOrderType(OrderType.PICKUP);
        closeModal();
      }} className={`text-white mt-4 hover:opacity-80 over rounded-lg text-center w-4/5 sm:w-64 relative px-4 py-2 bg-lfg-primary text-sm focus:outline-none focus:border-2 focus:border-lfg-primary focus:bg-lfg-primary focus:text-white focus:font-semibold`}>
              PICKUP
            </button>
            <button type="button" onClick={() => {
        track({
          event: "Changed Order Type",
          properties: {
            orderType: OrderType.DELIVERY,
            feature: "location-discovery-modal"
          }
        });
        handleChangeOrderType(OrderType.DELIVERY);
        closeModal();
      }} className={`
                text-white hover:opacity-80 rounded-lg text-center w-4/5 sm:w-64 relative px-4 py-2 bg-lfg-primary text-sm focus:outline-none focus:border-2 focus:border-lfg-primary focus:bg-lfg-primary focus:text-white focus:font-semibold`}>
              DELIVERY
            </button>
          </div>} hasCancel={false} data-sentry-element="Modal" data-sentry-source-file="LocationDiscoveryModal.tsx" />
    </>;
};
export default LocationDiscoveryModal;