import { createContext, useCallback, useContext, useState } from "react";
import { Toast, ToastProps } from "../../ui/Toast/Toast";
type ToastContextType = {
  showToast: (props: ToastProps) => void;
  clearToast: () => void;
};
export const ToastContext = createContext<ToastContextType>({
  showToast: () => {},
  clearToast: () => {}
});
export const ToastContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [toastProps, setToastProps] = useState<ToastProps>({
    variant: "info",
    show: undefined
  });
  const showToast = useCallback((props: ToastProps) => {
    setToastProps({
      ...props,
      show: new Date().toISOString()
    });
  }, []);
  const clearToast = useCallback(() => {
    setToastProps({
      variant: "info",
      show: undefined
    });
  }, []);
  return <ToastContext.Provider value={{
    showToast,
    clearToast
  }} data-sentry-element="unknown" data-sentry-component="ToastContextProvider" data-sentry-source-file="useToast.tsx">
      {children}
      <Toast {...toastProps} data-sentry-element="Toast" data-sentry-source-file="useToast.tsx" />
    </ToastContext.Provider>;
};
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within ToastContextProvider");
  }
  return context;
};