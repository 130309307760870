import { Children, cloneElement, isValidElement, useEffect, useRef, useState } from "react";
interface GoogleMapInstance extends google.maps.MapOptions {
  style: {
    [key: string]: string;
  };
  children?: React.ReactNode;
  bounds?: google.maps.LatLngBounds;
  zoom: number;
  panTo?: google.maps.LatLngLiteral;
}
export const GoogleMapInstance: React.FC<GoogleMapInstance> = ({
  children,
  style,
  bounds,
  zoom,
  panTo,
  ...options
}) => {
  // Create ref for the wrapper div that contains the map
  const ref = useRef<HTMLDivElement>(null);
  // Create state for the map instance itself
  const [map, setMap] = useState<google.maps.Map>();

  // Initialize the Google Map instance.
  // google.maps.Map takes as a constructor parameter the containing DOM element.
  // Note: requires the Google Maps API to be loaded.
  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        ...options
      }));
    }
  }, [ref, map, setMap, options]);
  useEffect(() => {
    if (options) {
      map?.setOptions(options);
    }
  }, [map, options]);
  useEffect(() => {
    if (bounds) {
      map?.fitBounds(bounds);
    }
  }, [map, bounds]);
  useEffect(() => {
    if (zoom) {
      map?.setZoom(zoom);
    }
  }, [map, zoom]);
  useEffect(() => {
    if (panTo) {
      map?.panTo(panTo);
    }
  }, [map, panTo]);

  /**
   * Render the map container and pass the Google Map instance to children
   * Code from: https://developers.google.com/maps/documentation/javascript/react-map
   */

  return <>
      <div ref={ref} style={style} />
      {Children.map(children, child => {
      if (isValidElement(child)) {
        // @ts-ignore
        return cloneElement(child, {
          map
        });
      }
    })}
    </>;
};