import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { animated, useSpring } from "@react-spring/web";
import { Link as StyledLink } from "@ui/Typography";
import Link from "next/link";
import { Fragment } from "react";
import { useAuth } from "../../features/Authentication/hooks/useAuth";
import { classNames } from "../../utils/classNames";
import { useLoyaltyContext } from "../../utils/contexts/LoyaltyContext";
import { useNavToCashRewardsWithTrack } from "../../utils/hooks/useNavToCashRewardsWithTrack";
import { useTrackWithFlags } from "../../utils/hooks/useTrackWithFlags";
import { usdFormat } from "../../utils/usdFormatter";
import { UserNavigation } from "../Navbar/NavbarController";
import Profile from "./Profile";
type ProfileDropdownProps = {
  userNavigation: UserNavigation;
  onLogin?: () => void;
  className?: string;
  isAuthPage: boolean;
};
export const ProfileDropdown = ({
  userNavigation,
  onLogin,
  className,
  isAuthPage
}: ProfileDropdownProps) => {
  const {
    track
  } = useTrackWithFlags();
  const navToCashRewards = useNavToCashRewardsWithTrack();
  const {
    cashRewardLoyalty
  } = useLoyaltyContext();
  const progress = 100 - (cashRewardLoyalty?.cash_reward_progress ?? 0) / 150 * 100;
  const {
    isAuthenticated
  } = useAuth();
  const styles = useSpring({
    to: async next => {
      await next({
        backgroundImage: `conic-gradient(#c6ebda ${progress}%, #01a55c ${progress}%, #01a55c)`
      });
    },
    from: {
      backgroundImage: `conic-gradient(#c6ebda 100%, #01a55c 100%, #01a55c)`
    }
  });
  return <Menu as="div" className={classNames("relative flex-shrink-0", className)} data-sentry-element="Menu" data-sentry-component="ProfileDropdown" data-sentry-source-file="ProfileDropdown.tsx">
      {({
      open,
      close
    }) => {
      return <>
            <div className="flex items-center justify-center">
              {isAuthenticated ? <Menu.Button className="bg-surface-lighter rounded-sm flex text-sm focus:outline-none">
                  <div>
                    <span className="sr-only">Open user menu</span>
                    <div className="flex flex-col items-center justify-center">
                      <animated.div className="relative pie h-10 w-10 flex justify-center items-center rounded-full" style={styles}>
                        <Profile />
                      </animated.div>
                    </div>
                  </div>
                </Menu.Button> : !isAuthPage && <>
                    <StyledLink underLine="solid" className="hidden md:block !font-semibold" onClick={() => {
              onLogin?.();
            }}>
                      Log In/Join
                    </StyledLink>
                    <Menu.Button className="rounded-sm">
                      <Bars3Icon className="md:hidden cursor-pointer h-7 w-7 text-lfg-primary" />
                    </Menu.Button>
                  </>}
            </div>
            <Transition show={open} as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
              <Menu.Items static className="origin-top-left md:origin-top-right absolute left-0 md:right-0 md:left-auto mt-2 w-48 rounded-md shadow-lg py-1 bg-surface-lighter ring-1 ring-black ring-opacity-5 focus:outline-none">
                {isAuthenticated && (cashRewardLoyalty?.credits ?? 0) >= 500 && <Menu.Item key="loyalty">
                    <div className="cursor-pointer" onClick={() => {
                close();
                navToCashRewards("Profile dropdown");
              }}>
                      <div className="flex flex-col items-center pt-2 pb-3 mb-1 mx-auto w-full border-b border-border-secondary">
                        <div className="text-green font-semibold">
                          Credits: {usdFormat(cashRewardLoyalty?.credits)}
                        </div>
                      </div>
                    </div>
                  </Menu.Item>}
                {userNavigation.map(item => {
              if (!item) return null;
              return <Menu.Item key={item.name}>
                      {({
                  active
                }) => <Link onClick={e => {
                  track({
                    event: "Menu Item Clicked",
                    properties: {
                      name: item.name
                    }
                  });
                  close();
                  item?.onClick?.(e);
                }} href={item.href || "#"} className={classNames(active && "bg-button-outlined-secondary_hover", "flex px-4 py-2 text-sm text-lfg-secondary space-x-2 items-center")}>
                          <div>{item.icon}</div>
                          <div>{item.name}</div>
                          {(item.notifBadgeIndicator ?? 0) > 0 && <div className="relative inline-block">
                              <FontAwesomeIcon icon={faCircle} className="h-5 w-5" />
                              <span className={classNames("absolute inset-0 flex items-center justify-center text-xs font-semibold leading-none", active ? "text-text-notif_hover" : "text-text-notif")}>
                                {item.notifBadgeIndicator}
                              </span>
                            </div>}
                        </Link>}
                    </Menu.Item>;
            })}
              </Menu.Items>
            </Transition>
          </>;
    }}
    </Menu>;
};