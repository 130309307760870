import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { BrandContent } from "../../../types";
export const BRANDS_CONTENT_QUERY = gql`
  query BrandsContentQuery($location_slug: String) {
    public_brands(include_localkitchens: true) {
      brand_id
      slug
      name
      about
      about_media {
        ... on Image {
          url
          blurhash
        }
        ... on Video {
          videoUrl: url
          thumbnail {
            url
            blurhash
          }
        }
      }
      locations {
        id
        slug
      }
      cuisine
      short_cuisine
      logo {
        url
        blurhash
      }
      hero_image: food_hero_image {
        url
        blurhash
      }
      is_exclusive
      exclusive_description
      tagline
      gallery_media {
        ... on Image {
          url
          blurhash
        }
        ... on Video {
          videoUrl: url
          thumbnail {
            url
            blurhash
          }
        }
      }
      cta {
        text
        url
      }
      feature_section {
        header
        body
        image {
          url
          blurhash
        }
        cta {
          text
          url
        }
      }
      coming_soon(location_slug: $location_slug) {
        text
        date
        notifyDescriptionWeb
        notifyMeHeaderWeb
        notifyMeTitleWeb
        notifyMeBodyWeb
        notifyMePlaceholderWeb
      }
    }
  }
`;
export const useBrandsContent = (): BrandContent[] => {
  const {
    data
  } = useQuery(BRANDS_CONTENT_QUERY, {
    fetchPolicy: "cache-and-network"
  });
  const content = useMemo(() => {
    if (!data) {
      return [];
    }
    return (data.public_brands as BrandContent[]);
  }, [data]);
  return content;
};