import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const MAKE_GROUP_ORDER_CART = gql`
  mutation MakeGroupOrder($input: MakeGroupOrderInput) {
    make_cart_group_order(input: $input) {
      id
      is_group_order
      participants {
        id
      }
      spend_limit
    }
  }
`;

export type onUpdateCartProps = {
  id: string;
  isGroupOrder: boolean;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  spendLimit?: number;
};

export type UseMakeGroupOrderProps = {
  onUpdateComplete?: (data: any) => void;
};

export const useMakeGroupOrder = ({
  onUpdateComplete,
}: UseMakeGroupOrderProps) => {
  const [updateCart, { loading }] = useMutation(MAKE_GROUP_ORDER_CART, {
    onCompleted: (data) => {
      onUpdateComplete?.(data);
    },
  });

  const onMakeGroupOrderCart = useCallback(
    async ({
      id,
      isGroupOrder,
      firstName,
      lastName,
      phoneNumber,
      spendLimit,
    }: onUpdateCartProps) => {
      return updateCart({
        variables: {
          input: {
            id,
            is_group_order: isGroupOrder,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            spend_limit: spendLimit,
          },
        },
      });
    },
    [updateCart],
  );

  return {
    onMakeGroupOrderCart,
    makingGroupOrderCart: loading,
  };
};
