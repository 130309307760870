import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from "@apollo/client";
import React from "react";
type ApolloWrapperProps = {
  children: React.ReactNode;
};
const uri = `${process.env.NEXT_PUBLIC_API_URL}/graphql`;
const httpLink = createHttpLink({
  uri,
  credentials: "include"
});
export const client = new ApolloClient({
  link: from([httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Customer: {
        merge: true
      },
      PlacedOrderModifierItem: {
        keyFields: ["id", "price"]
      }
    }
  }),
  connectToDevTools: true,
  name: "guest-web"
});
export const ApolloWrapper = ({
  children
}: ApolloWrapperProps) => {
  return <ApolloProvider client={client} data-sentry-element="ApolloProvider" data-sentry-component="ApolloWrapper" data-sentry-source-file="ApolloWrapper.tsx">{children}</ApolloProvider>;
};