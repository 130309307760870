import { RefObject, useEffect, useState } from "react";
const useElementSize = (elementRef: RefObject<Element>, initialValue = {
  width: 0,
  height: 0
}) => {
  const [size, setSize] = useState(initialValue);
  useEffect(() => {
    if (!elementRef.current) return;
    const resizeObserver = new ResizeObserver(entries => {
      const {
        height,
        width
      } = entries[0].contentRect;
      setSize({
        width,
        height
      });
    });
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect();
  }, [elementRef]);
  return size;
};
export default useElementSize;