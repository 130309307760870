import { NextComponentType } from "next";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { CartMenuItem } from "../../features/Cart/types";
import { useSegmentContext } from "../segmentUtils";

export type TrackingEvent = {
  event: string;
  properties?: object;
};

export type TrackState = {
  track: TrackingHandler;
};

export type TrackingHandler = (trackingEvent: TrackingEvent) => void;

export const useTrackWithFlags = () => {
  const context = useSegmentContext();

  const track = useCallback(
    ({ event, properties }: TrackingEvent): Promise<any> => {
      return analytics.track(
        event,
        {
          platform: "guest-web",
          ...(properties || {}),
        },
        { context },
      ) as unknown as Promise<any>;
    },
    [context],
  );

  const trackRemoveItem = useCallback(
    (item: CartMenuItem, location?: string) => {
      // follows Segment Ecommerce spec
      const properties = {
        product_id: item.id,
        sku: item.id,
        name: item.name,
        price: item.price / 100,
        brand: item.brand_name,
        location,
        quantity: item.quantity,
        currency: "USD",
      };
      track({
        event: "Product Removed",
        properties,
      });
    },
    [track],
  );

  const trackUpdateItem = useCallback(
    (item: CartMenuItem, location?: string) => {
      // follows Segment Ecommerce spec
      const properties = {
        product_id: item.id,
        sku: item.id,
        name: item.name,
        price: item.price / 100,
        brand: item.brand_name,
        location,
        quantity: item.quantity,
        currency: "USD",
      };
      track({
        event: "Product Updated",
        properties,
      });
    },
    [track],
  );

  // You shouldn't need to call page() directly. Instead, use the usePageViewTracking hook below.
  const page = useCallback(
    ({
      name,
      properties,
    }: {
      name?: string;
      properties?: { [key: string]: any };
    }): Promise<any> => {
      return analytics.page(
        undefined, // Category
        name,
        {
          ...properties,
        },
        { context },
      ) as unknown as Promise<any>;
    },
    [context],
  );

  return { track, trackRemoveItem, trackUpdateItem, page };
};

/**
 * This code is responsible for handling page view analytics in our Next.js app.
 * It uses two useEffect hooks to accomplish this task.
 * The first useEffect hook is triggered on the initial page load, while the second useEffect hook
 * handles the SPA navigation events by attaching an event listener to the router.
 **/
export const usePageViewTracking = (Component: NextComponentType) => {
  const router = useRouter();
  const { page } = useTrackWithFlags();

  useEffect(() => {
    page({ name: Component.displayName });
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      page({ name: Component.displayName });
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, page, Component]);
};

export default usePageViewTracking;
