import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";

export const CART_DELIVERY_DETAILS_QUERY = gql`
  query CartDeliveryDetailsQuery($id: String) {
    cart(id: $id) {
      id
      is_delivery
      is_scheduled
      order_time
      start_buffer_time
      delivery_address {
        id
        street
        city
        state
        zipcode
        unit
        customer_id
        instructions
        is_default
        geocodes {
          lat
          lng
        }
      }
    }
  }
`;

export const useCartDeliveryDetails = () => {
  const { cart } = useCartContext();
  const { data, loading, refetch } = useQuery(CART_DELIVERY_DETAILS_QUERY, {
    variables: { id: cart?.id },
    fetchPolicy: "network-only",
    skip: !cart?.id,
  });

  useEffect(() => {
    if (cart?.id && cart?.delivery_address?.id) {
      refetch({
        id: cart.id,
      });
    }
  }, [cart?.delivery_address]);

  return { data: data?.cart?.delivery_address, loading };
};
