import { gql, useQuery } from "@apollo/client";

export const LOCATIONS_QUERY = gql`
  query Locations {
    locations {
      id
      name
      display_name
      state
      zipcode
      slug
      street
      timezone
      share_link
      is_active
      lat
      lng
      stores {
        id
        ordinal
        brand {
          id
          slug
          name
          logo {
            url
            blurhash
          }
          food_hero_image {
            url
            blurhash
          }
        }
        is_open
        is_within_hours
        store_hours {
          day_of_week
          hours {
            start_day
            end_day
            start_time
            end_time
          }
        }
      }
      display_hours {
        date
        hours
      }
      is_dine_in_available
    }
  }
`;

export const useLocations = () => {
  const { data, error, loading, refetch } = useQuery(LOCATIONS_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  return { data, error, loading, refetch };
};
