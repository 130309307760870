import { faXmarkLarge } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@utils/classNames";
import { useLoyaltyContext } from "@utils/contexts/LoyaltyContext";
import { useContext, useState } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { ToastContext } from "../../../app-components/hooks/useToast";
import TagIcon from "../../../ui/Icons/Tagicon";
import { LoadingSpinner } from "../../../ui/Loading/LoadingSpinner";
import SectionModule from "../../../ui/SectionModule/SectionModule";
import { useLoginContext } from "../../Authentication/contexts/LoginContext";
import { useAuth } from "../../Authentication/hooks/useAuth";
import useRequestPasscode from "../../Authentication/hooks/useRequestPasscode";
import { LoginState, PasscodeLoginMethod } from "../../Authentication/types";
import { useRemovePromoCode } from "../hooks/useRemovePromoCode";
import PromoCodeModal from "./modals/PromoCodeModal";
interface PromoCodeProps {
  loadingApplyCoupon: boolean;
  onApplyPromoCode: (code: string) => Promise<void>;
}
const PromoCode = ({
  loadingApplyCoupon,
  onApplyPromoCode
}: PromoCodeProps) => {
  const {
    showVerifyCodeModal,
    setLoginState
  } = useLoginContext();
  const {
    isVerifiedForPromo,
    user
  } = useAuth();
  const {
    cart
  } = useCartContext();
  const {
    showToast
  } = useContext(ToastContext);
  const [promoCodeError, setPromoCodeError] = useState<string>();
  const [promoCode, setPromoCode] = useState("");
  const [showPromoCodeModal, setShowPromoCodeModal] = useState(false);
  const {
    onRemovePromoCode
  } = useRemovePromoCode();
  const {
    isCashRewardRedeemEnabled
  } = useLoyaltyContext();
  const {
    isPending: isRequestCodePending,
    mutate: requestPasscode
  } = useRequestPasscode((method, identifier) => {
    setLoginState((prevState: LoginState) => ({
      ...prevState,
      // Avoid redirect after verification
      redirectUrl: "",
      method,
      identifier,
      allowSignUp: false
    }));
    showVerifyCodeModal();
  });
  const onApply = async () => {
    if (!onApplyPromoCode) {
      return;
    }
    try {
      await onApplyPromoCode(promoCode);
      setPromoCodeError(undefined);
      showToast({
        description: `Nice! Promo code ${promoCode} applied successfully.`,
        variant: "success",
        onClose: () => {}
      });
      setPromoCode("");
      setShowPromoCodeModal(false);
    } catch (error) {
      if (error instanceof Error) {
        setPromoCodeError(error.message);
      }
    }
  };
  const onChange = (value: string) => {
    setPromoCode(value.toUpperCase());
    setPromoCodeError(undefined);
  };
  if (cart?.coupon) {
    return <SectionModule title={cart.coupon.code} subtitle={cart.coupon.display_name} icon={<TagIcon className="text-new-green-base" />} rightComponent={<FontAwesomeIcon icon={faXmarkLarge} className="text-text-secondary" width={24} height={24} />} onClick={onRemovePromoCode} />;
  }
  if (!isVerifiedForPromo) {
    if (isRequestCodePending) {
      return <div className="absolute inset-0 flex items-center justify-center bg-surface-lighter">
          <LoadingSpinner />
        </div>;
    }
    return <SectionModule title="Add promo code" icon={<TagIcon width={24} height={24} />} onClick={() => requestPasscode({
      method: PasscodeLoginMethod.Sms,
      identifier: user?.phone_number || ""
    })} />;
  }
  return <div className={classNames(isCashRewardRedeemEnabled ? "opacity-50" : "opacity-100")} data-sentry-component="PromoCode" data-sentry-source-file="PromoCode.tsx">
      <SectionModule title="Add promo code" icon={<TagIcon width={24} height={24} />} onClick={() => setShowPromoCodeModal(true)} disabled={isCashRewardRedeemEnabled} data-sentry-element="SectionModule" data-sentry-source-file="PromoCode.tsx" />
      <PromoCodeModal showModal={showPromoCodeModal} closeModal={() => setShowPromoCodeModal(false)} onChangePromoCode={onChange} onApplyPromoCode={onApply} promoCode={promoCode} promoCodeError={promoCodeError} loadingApplyCoupon={loadingApplyCoupon} data-sentry-element="PromoCodeModal" data-sentry-source-file="PromoCode.tsx" />
    </div>;
};
export default PromoCode;