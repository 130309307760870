import { useMemo } from "react";
import { CartReceipt, CartReceiptLineItem } from "../../Cart/types";

const useCartReceiptLines = (cartReceipt?: CartReceipt) => {
  const result = useMemo(() => {
    if (!cartReceipt?.line_items?.length)
      return {
        menuLineItems: [],
        additionalLineItems: [],
        totalLineItem: undefined,
      };

    const menuLineItems: CartReceiptLineItem[] = [];
    const additionalLineItems: CartReceiptLineItem[] = [];
    let totalLineItem: CartReceiptLineItem | undefined;

    cartReceipt.line_items?.forEach((lineItem) => {
      if (lineItem.line_item_type === "MenuItem") {
        menuLineItems.push(lineItem);
      } else if (lineItem.line_item_type === "Total") {
        totalLineItem = lineItem;
      } else {
        additionalLineItems.push(lineItem);
      }
    });

    return {
      menuLineItems,
      additionalLineItems,
      totalLineItem,
    };
  }, [cartReceipt?.line_items]);

  return result;
};

export default useCartReceiptLines;
