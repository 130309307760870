/* eslint-disable react-hooks/exhaustive-deps */
import { Transition } from "@headlessui/react";
import { LocationWithDistance, OrderType } from "@types";
import { useRouter } from "next/router";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { ConfirmationModal } from "../../../ui/ConfirmationModal/ConfirmationModal";
import { LoadingSpinner } from "../../../ui/Loading/LoadingSpinner";
import { notEmpty } from "../../../utils/array";
import { classNames } from "../../../utils/classNames";
import { useLocationContext } from "../../../utils/contexts/LocationContext";
import { useOrderTypeContext } from "../../../utils/contexts/OrderTypeContext";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { usdFormat } from "../../../utils/usdFormatter";
import useDiscoveryQueryParams from "../hooks/useDiscoveryQueryParams";
import ConfirmChangeLocationModal from "./ConfirmChangeLocationModal";
import StoreLocationCard from "./StoreLocationCard";
type LocationsProps = {
  orderType: string;
  setShowClose?: Dispatch<SetStateAction<boolean>>;
  showClose?: boolean;
  locations: LocationWithDistance[];
  loading: boolean;
  showMap: boolean;
};
export const Locations = ({
  orderType,
  setShowClose,
  showClose,
  locations,
  loading,
  showMap
}: LocationsProps) => {
  const [confirmLocationChange, setConfirmLocationChange] = useState<LocationWithDistance | null>(null);
  const router = useRouter();
  const {
    track
  } = useTrackWithFlags();
  const {
    cart
  } = useCartContext();
  const {
    selectedLocation,
    onLocationClick,
    searchedAddress,
    storeLocation: currentLocation
  } = useLocationContext();
  const {
    setShowChooseOrderType
  } = useOrderTypeContext();
  const [showUndeliverableNotification, setShowUndeliverableNotification] = useState(false);
  const queryParams = useDiscoveryQueryParams(orderType, searchedAddress);
  useEffect(() => {
    if (typeof setShowClose === "function") {
      const isCurrentLocationAvailable = currentLocation && locations.find(location => location.id === currentLocation?.id);
      if (!isCurrentLocationAvailable) {
        setShowClose(false);
      } else {
        setShowClose(true);
      }
    }
  }, [locations, currentLocation]);
  const deliverableLocationsRefs = useMemo(() => locations.reduce((acc: {
    [key: string]: React.RefObject<HTMLDivElement>;
  }, value) => {
    acc[value.id] = React.createRef<HTMLDivElement>();
    return acc;
  }, {}), [locations]);
  const handleLocationClick = useCallback((location: LocationWithDistance) => {
    track({
      event: "Location Clicked on Sidebar",
      properties: {
        slug: location.slug
      }
    });
    onLocationClick(location);
  }, [deliverableLocationsRefs]);
  const handleLocationLinkClick = (location: LocationWithDistance) => {
    const isOrderPage = router.pathname === "/order";
    track({
      event: "Location Link Clicked on Sidebar",
      properties: {
        slug: location.slug
      }
    });
    const estimate = notEmpty(location.fee);
    if (orderType === OrderType.DELIVERY && !estimate) {
      setShowUndeliverableNotification(true);
      return;
    }
    if (!isOrderPage && currentLocation?.slug === location.slug) {
      setShowChooseOrderType(false);
      return;
    }
    if (cart?.items?.length) {
      setConfirmLocationChange(location);
      return;
    }
    router.push(`/order/store/${location.slug}/popular${queryParams}`, `/order/store/${location.slug}/popular`);
    setShowChooseOrderType(false);
  };
  useEffect(() => {
    if (selectedLocation?.id) {
      deliverableLocationsRefs[selectedLocation.id]?.current?.scrollIntoView?.({
        behavior: "smooth",
        block: "center"
      });
    }
  }, [selectedLocation]);
  if (loading) {
    return <div className="flex justify-center items-center mt-10">
        <LoadingSpinner size={10} />
      </div>;
  }
  return <Transition show enter="transition ease-out duration-300" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-150" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" className="w-full" data-sentry-element="Transition" data-sentry-component="Locations" data-sentry-source-file="Locations.tsx">
      {orderType === OrderType.DELIVERY && locations.length > 0 && !showClose && <div className="text w-full flex font-lfg-normal items-start justify-start rounded-lg p- text-white bg-orange mt-2 mb-4 py-2 px-4">
            Select a store below, update your address, or choose pickup
          </div>}
      <ul className="w-full">
        {locations.map((location: LocationWithDistance, idx: number) => {
        const {
          fee
        } = location;
        if (orderType === OrderType.DELIVERY) {
          return <StoreLocationCard key={`location-${idx}`} showMap={showMap} className={classNames(loading ? "opacity-40 cursor-not-allowed" : "cursor-pointer")} ref={deliverableLocationsRefs[location.id]} location={location} fee={fee && fee > 0 ? `${usdFormat(fee / 2)} delivery fee` : null} idx={idx} onLinkClick={handleLocationLinkClick} onLocationClick={handleLocationClick} isSelected={selectedLocation?.id === location.id} showCTA nearest={Boolean(searchedAddress?.geocodes) && idx === 0} />;
        }
        return <StoreLocationCard key={`location-pickup-${idx}`} showMap={showMap} ref={deliverableLocationsRefs[location.id]} location={location} idx={idx} onLocationClick={handleLocationClick} isSelected={selectedLocation?.id === location.id} onLinkClick={handleLocationLinkClick} showCTA nearest={Boolean(searchedAddress?.geocodes) && idx === 0} />;
      })}
        {orderType === OrderType.DELIVERY && locations.length === 0 && <div className="text w-full flex justify-center text-text-secondary mt-2 px-8 text-center">
            {`We're not in your area just yet! Change your address or select pickup.`}
          </div>}
      </ul>
      {/* This shows up when a store is within a given radius from the delivery address, but DoorDash estimate fails */}
      <ConfirmationModal show={showUndeliverableNotification} closeModal={() => setShowUndeliverableNotification(false)} header="Oops!" message="Your address is outside the store's delivery range. Please select a different location, or choose 'Pickup' instead." confirmButton={{
      label: "Select another store",
      onClick: () => setShowUndeliverableNotification(false)
    }} data-sentry-element="ConfirmationModal" data-sentry-source-file="Locations.tsx" />
      <ConfirmChangeLocationModal showModal={!!confirmLocationChange} closeModal={() => setConfirmLocationChange(null)} onConfirm={() => {
      if (!confirmLocationChange) {
        return;
      }
      const currentPath = router.asPath;
      const targetPath = `/order/store/${confirmLocationChange.slug}/popular${queryParams}`;
      if (currentPath !== targetPath) {
        router.push(targetPath, `/order/store/${confirmLocationChange.slug}/popular`);
      }
      setShowChooseOrderType(false);
    }} data-sentry-element="ConfirmChangeLocationModal" data-sentry-source-file="Locations.tsx" />
    </Transition>;
};