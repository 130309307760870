import { ExclamationCircleIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { InputHTMLAttributes, forwardRef } from "react";
import { classNames } from "@utils/classNames";
import { headerStyles } from "@ui/Typography/Header";
import { Text, textStyles } from "@ui/Typography/Text";
export type TextInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onKeyUp"> & {
  inputClassName?: string;
  description?: string;
  error?: string;
  endIcon?: React.ReactNode;
  label?: string;
  startIcon?: React.ReactNode;
  pressEnterFunction?: () => void;
};
const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({
  inputClassName,
  description,
  error,
  label,
  startIcon,
  className,
  pressEnterFunction,
  ...props
}, ref) => {
  const {
    disabled
  } = props;
  const onKeyUpHandler = (event: {
    key: string;
  }) => {
    if (event.key === "Enter") {
      pressEnterFunction?.();
    }
  };
  const endIcon = (() => {
    if (error) {
      return <ExclamationCircleIcon className="text-new-error" />;
    }
    if (props.disabled) {
      return <LockClosedIcon className="text-text-secondary" />;
    }
    return props.endIcon;
  })();
  return <div className={classNames(error && "Error", className)}>
        <label className={classNames(headerStyles.h4, "w-full")}>
          {label}
          <div className={classNames("relative", label && "mt-2")}>
            {startIcon ? <div className="absolute w-6 h-6 left-4 top-4">{startIcon}</div> : null}
            {endIcon ? <div className="absolute w-6 h-6 right-4 top-4">{endIcon}</div> : null}
            <input ref={ref} onKeyUp={onKeyUpHandler} className={classNames("text-input", textStyles.large, startIcon ? "pl-12" : "pl-4", endIcon ? "pr-12" : "pr-4", error && "!ring-2 !ring-border-error !focus:ring-border-error", !disabled && "bg-surface-lighter focus:ring-border-primary sm:hover:ring-border-primary active:bg-grey-1-base active:ring-border-primary focus:ring-2", disabled && " bg-button-filled-primary_disabled border-border-disabled", "h-14 rounded-lg focus:outline-none ring-1 ring-border-secondary border-0 p-4 w-full placeholder:text-text-placeholder", inputClassName)} {...props} />
          </div>
        </label>
        {error || description ? <Text small component="p" className={classNames(error && "!text-new-error", disabled && "text-new-grey-3-lighten-3", !disabled && "text-text-secondary", "mt-2")}>
            {error || description}
          </Text> : null}
      </div>;
});
export default TextInput;