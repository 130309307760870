import { useContext } from "react";
import { NewModal } from "../../../ui/NewModal/Modal";
import { hasMobileShareAPI } from "../../../utils/userAgent";
import { GroupOrderContext } from "../context/GroupOrderContext";
import ShareGroupOrder from "./ShareGroupOrder";
interface ShareGroupOrderModalProps {
  handleCopy: () => void;
  handleShare: () => void;
}
const ShareGroupOrderModal = ({
  handleCopy,
  handleShare
}: ShareGroupOrderModalProps) => {
  const {
    showShareGroupOrderModal,
    setShowShareGroupOrderModal
  } = useContext(GroupOrderContext);
  return <NewModal overlayClassName="backdrop-blur" header="Group order" hasClose onClose={() => setShowShareGroupOrderModal(false)} onCloseComplete={() => setShowShareGroupOrderModal(false)} body={<ShareGroupOrder handleCopy={handleCopy} />} hasConfirm={hasMobileShareAPI()} confirmMessage="Share" onConfirm={handleShare} show={showShareGroupOrderModal} onBlur={() => setShowShareGroupOrderModal(false)} data-sentry-element="NewModal" data-sentry-component="ShareGroupOrderModal" data-sentry-source-file="ShareGroupOrderModal.tsx" />;
};
export default ShareGroupOrderModal;