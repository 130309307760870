import { faXmarkLarge } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { BrandContent, LocationWithDistance, OrderType } from "@types";
import { Text } from "@ui/Typography";
import { classNames } from "@utils/classNames";
import { useLocationContext } from "@utils/contexts/LocationContext";
import { useOrderTypeContext } from "@utils/contexts/OrderTypeContext";
import { useTrackWithFlags } from "@utils/hooks/useTrackWithFlags";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useGate } from "statsig-react";
import { useCartMutations } from "../Cart/hooks/useCartMutations";
import { Cart, DeliveryAddress } from "../Cart/types";
import { useHideBodyOverflow } from "../MenuItemModal/hooks/useHideBodyOverflow";
import LocationDiscoveryModal from "./LocationDiscoveryModal";
import BrandSelector from "./components/BrandSelector";
import BrandSelectorMobile from "./components/BrandSelectorMobile";
import { EnterAddressController } from "./components/EnterAddressController";
import { LocationsController } from "./components/LocationsController";
import { OrderMethodSelector } from "./components/OrderMethodSelector";
import SearchAddress from "./components/SearchAddress";
import { LocalKitchensMap } from "./components/map/LocalKitchensMap";
import useDiscoveryQueryParams from "./hooks/useDiscoveryQueryParams";
import { ADDRESS_STAGES } from "./types";
const returnTo = process.env.NEXT_PUBLIC_LANDING_URL;
type LocationDiscoveryProps = {
  show: boolean;
  orderType: OrderType | undefined;
  handleChangeOrderType: (orderType: OrderType) => void;
  loadingDeliveryAddresses: boolean;
  deliveryAddresses?: DeliveryAddress[];
  onSelectDeliveryAddress: (address?: DeliveryAddress) => void;
  onUpdateDeliveryAddress: (newAddress: DeliveryAddress) => Promise<DeliveryAddress | undefined>;
  showOrderTypeModal: boolean;
  handleCloseOrderTypeModal: () => void;
  brands: BrandContent[];
  cart: Cart;
  locations: LocationWithDistance[];
  isLoadingAddressMutation: boolean;
};
export const LocationDiscovery = ({
  show,
  orderType,
  handleChangeOrderType,
  loadingDeliveryAddresses,
  deliveryAddresses,
  onSelectDeliveryAddress,
  onUpdateDeliveryAddress,
  showOrderTypeModal,
  handleCloseOrderTypeModal,
  cart,
  brands,
  locations,
  isLoadingAddressMutation
}: LocationDiscoveryProps) => {
  const router = useRouter();
  const [showClose, setShowClose] = useState(true);
  const [brandSelector, setBrandSelector] = useState(false);
  const {
    selectedLocation,
    searchedAddress,
    deliverableLocations,
    loadingDeliveryEstimates,
    brandSelected,
    setBrandSelected,
    storeLocation: currentLocation
  } = useLocationContext();
  const {
    setShowChooseOrderType
  } = useOrderTypeContext();
  const [addressStage, setAddressStage] = useState(ADDRESS_STAGES.VIEW);
  const [pickupAddress, setPickupAddress] = useState<DeliveryAddress>();
  const {
    isLoading: isLoadingCartMutation
  } = useCartMutations();
  const {
    track
  } = useTrackWithFlags();
  const {
    value: allowDDPricing
  } = useGate("doordash_delivery_price_change");
  const discoveryRoute = router.pathname === "/order";
  const [showMap, setShowMap] = useState(discoveryRoute);
  const isClosable = router.pathname !== "/order";
  const queryParams = useDiscoveryQueryParams(orderType, searchedAddress);
  const onClose = () => {
    if (discoveryRoute && !selectedLocation) {
      router.replace(returnTo || "");
    } else if (selectedLocation && currentLocation?.slug !== selectedLocation?.slug) {
      track({
        event: "Sidebar close icon clicked with location selected",
        properties: {
          slug: selectedLocation.slug
        }
      });
      const currentPath = router.asPath;
      const targetPath = `/order/store/${selectedLocation.slug}/popular${queryParams}`;
      if (currentPath !== targetPath) {
        router.push(targetPath, `/order/store/${selectedLocation.slug}/popular`);
      }
    } else {
      setShowChooseOrderType?.(false);
      // If no address is selected, default back to pickup
      if (cart?.id && !cart?.delivery_address?.id) {
        handleChangeOrderType(OrderType.PICKUP);
      }
    }
  };
  useEffect(() => {
    setShowMap(Boolean(discoveryRoute));
  }, [discoveryRoute]);
  const toggleBrandSelector = () => {
    setBrandSelector(status => !status);
  };
  const handleToggleShowMap = () => {
    setShowMap(show => !show);
  };
  useHideBodyOverflow(show);
  return <Transition show={show} data-sentry-element="Transition" data-sentry-component="LocationDiscovery" data-sentry-source-file="LocationDiscovery.tsx">
      <section className="fixed z-50 inset-0 overflow-hidden flex flex-col md:flex-row" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
        <div className={classNames("sm:discovery-map w-screen flex relative", showMap ? "h-1/3 md:h-full bg-new-brown-base" : "h-0 md:h-full bg-gray-800 bg-opacity-75")} onClick={() => {
        if (!discoveryRoute && !showMap) {
          setShowChooseOrderType?.(false);

          // If no address is selected, default back to pickup
          if (!cart?.delivery_address?.id) {
            handleChangeOrderType(OrderType.PICKUP);
          }
        }
      }}>
          {Boolean(showMap) && <LocalKitchensMap orderType={orderType} />}
        </div>

        <div className={classNames("w-screen md:max-w-md md:h-full shadow-lg bg-surface-lighter pt-6", showMap ? "h-2/3" : "h-full")}>
          <div className="flex flex-col h-full" onClick={() => setBrandSelector(false)}>
            <div id="map-close-button" className={classNames("sm:flex justify-between items-center p-3 px-5 pb-0", showMap ? "hidden" : "flex")}>
              <h2 className="text-lg font-semibold">Order Method</h2>
              {isClosable && <FontAwesomeIcon icon={faXmarkLarge} className="h-6 w-6 cursor-pointer font-semibold hover:opacity-80 " onClick={onClose} />}
            </div>
            <OrderMethodSelector orderType={orderType} handleChangeOrderType={handleChangeOrderType} showMap={showMap} isDiscoveryRoute={discoveryRoute} isLoadingCartMutation={isLoadingCartMutation} isLoadingAddressMutation={isLoadingAddressMutation} handleToggleShowMap={handleToggleShowMap} data-sentry-element="OrderMethodSelector" data-sentry-source-file="LocationDiscovery.tsx" />
            {orderType === OrderType.PICKUP && <div className="flex flex-col m-5 mt-0">
                <div className="flex h-10 items-center gap-2">
                  <SearchAddress address={pickupAddress} setAddress={setPickupAddress} className="flex-grow" />
                  <div role="presentation" onClick={e => {
                toggleBrandSelector();
                e.stopPropagation();
              }} className={classNames("flex flex-grow", Boolean(brandSelected.length) || brandSelector ? "border-lfg-secondary" : "border-border-secondary", Boolean(brandSelected.length) || brandSelector ? "text-lfg-secondary" : "text-text-tertiary", `relative md:hover:text-lfg-secondary border
                      items-center justify-center rounded-lg w-10 h-full
                      cursor-pointer md:hover:border-lfg-secondary`)}>
                    <FontAwesomeIcon size="1x" icon={faSlidersH} />
                    {Boolean(brandSelected.length) && <span className="absolute -top-2 -right-2 inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-red-100 bg-red-600 rounded-full">
                        {brandSelected.length}
                      </span>}
                  </div>
                </div>
                <div onClick={e => e.stopPropagation()}>
                  <Transition show={brandSelector} enter="transition ease-out duration-300" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-150" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" className="hidden sm:block">
                    <BrandSelector brands={brands} selected={brandSelected} setSelected={setBrandSelected} />
                  </Transition>
                </div>

                <BrandSelectorMobile isActive={brandSelector} toggleActive={toggleBrandSelector} brands={brands} selected={brandSelected} setSelected={setBrandSelected} />
              </div>}
            {orderType === OrderType.PICKUP && <div className="flex flex-col align-center discovery-sidebar overflow-y-auto px-4 sm:px-5">
                {Boolean(brandSelected.length && !locations.length) && <div className="text w-full h-full flex items-start justify-center text-text-secondary mt-2 px-8 text-center">
                    No locations match selected brands.
                  </div>}
                {Boolean(brandSelected.length && locations.length) && <div className="text w-full flex items-start justify-center text-text-secondary mb-5 px-8 text-center">
                    {`${locations.length} location${locations.length > 1 ? "s" : ""} match${locations.length > 1 ? "" : "es"} selected brands.`}
                  </div>}
                <LocationsController locations={locations} showClose={showClose} setShowClose={setShowClose} orderType={orderType} showMap={showMap} />
              </div>}

            {orderType === OrderType.DELIVERY && <div className={classNames("flex flex-col overflow-y-auto", addressStage === ADDRESS_STAGES.MANUAL_ENTRY && showMap && "discovery-address-detail", addressStage === ADDRESS_STAGES.MANUAL_ENTRY && !showMap && "discovery-address-detail-no-map")}>
                {allowDDPricing ? <div className="w-full py-4 border-t border-b border-new-grey-2-lighten-3 px-8">
                    <Text large className="text-new-grey-1-darken-3">
                      Prices are different for delivery, with no delivery fees
                    </Text>
                  </div> : null}
                {!loadingDeliveryAddresses && <div className={classNames("m-5", addressStage === ADDRESS_STAGES.MANUAL_ENTRY && "h-full")}>
                    <EnterAddressController setShowClose={setShowClose} deliveryAddresses={deliveryAddresses} onSelectDeliveryAddress={onSelectDeliveryAddress} onUpdateDeliveryAddress={onUpdateDeliveryAddress} addressStage={addressStage} setAddressStage={setAddressStage} isLoadingAddressMutation={isLoadingAddressMutation} />
                    {!loadingDeliveryEstimates && !deliverableLocations?.length && !loadingDeliveryAddresses && deliveryAddresses?.length && cart?.is_delivery && !cart?.delivery_address?.id ? <div className="w-full text-center mt-2">
                        <span>
                          Pick one of your saved addresses, create a new one, or
                          <span onClick={() => {
                    track({
                      event: "Changed Order Type",
                      properties: {
                        orderType: OrderType.PICKUP,
                        feature: "location-discovery-side-panel"
                      }
                    });
                    handleChangeOrderType(OrderType.PICKUP);
                  }} className="ml-1 cursor pointer text-text-link underline">
                            switch&nbsp;to&nbsp;pickup
                          </span>
                          .
                        </span>
                      </div> : null}
                  </div>}

                {addressStage === ADDRESS_STAGES.VIEW && !loadingDeliveryAddresses && <div className={`flex flex-col align-center ${showMap ? "discovery-delivery-sidebar" : "discovery-delivery-sidebar-no-map"} overflow-y-scroll px-5`}>
                      <LocationsController locations={locations} showClose={showClose} setShowClose={setShowClose} orderType={orderType} showMap={showMap} />
                    </div>}
              </div>}
          </div>
        </div>
      </section>
      <LocationDiscoveryModal show={showOrderTypeModal} closeModal={handleCloseOrderTypeModal} handleChangeOrderType={handleChangeOrderType} data-sentry-element="LocationDiscoveryModal" data-sentry-source-file="LocationDiscovery.tsx" />
    </Transition>;
};