import { VIDEO_CONFIG } from "@constants";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useCartContext } from "../../app-components/hooks/useCartContext";
import { PlacedOrderItemReorder, PublicMenuItemReorder } from "../../graphql/types";
import { getUnavailableModifierItems } from "../../utils/cart";
import { useRouterQueryParam } from "../../utils/hooks/useRouterQueryParam";
import { useTrackWithFlags } from "../../utils/hooks/useTrackWithFlags";
import { MediaInfo } from "../BrandPage/types";
import { useCartMutations } from "../Cart/hooks/useCartMutations";
import { CartMenuItem } from "../Cart/types";
import { PublicStore } from "../Stores/types";
import { useVideoManager } from "../VideoManager/VideoManagerContext";
import { useHideBodyOverflow } from "./hooks/useHideBodyOverflow";
import { usePublicMenuItem } from "./hooks/usePublicMenuItem";
import { MenuItemModal } from "./MenuItemModal";
import { cartItemReducer, CLEAR_STATE, initialState, SET_REORDER_ITEM, UPDATE_ITEM_ACTION } from "./reducers/itemReducer";
import { PublicMenuItem } from "./types";
type MenuItemModalControllerProps = {
  itemInFocus?: CartMenuItem;
  setItemInFocus: Dispatch<SetStateAction<CartMenuItem | undefined>>;
  publicMenuItem?: PublicMenuItem;
  setPublicMenuItem: Dispatch<SetStateAction<PublicMenuItem | undefined>>;
  store?: PublicStore;
  mediaInfo?: MediaInfo;
  setMediaInfo?: Dispatch<SetStateAction<MediaInfo | undefined>>;
  disableVideos?: boolean;
  menuItemReorders?: PublicMenuItemReorder[];
};
export const MenuItemModalController = ({
  itemInFocus,
  setItemInFocus,
  publicMenuItem,
  setPublicMenuItem,
  store,
  mediaInfo,
  setMediaInfo,
  disableVideos,
  menuItemReorders
}: MenuItemModalControllerProps) => {
  const {
    cart
  } = useCartContext();
  const locationSlug = useRouterQueryParam("locationSlug");
  const videoManager = useVideoManager();
  const [yourUsualsItem, setYourUsualsItem] = useState<PlacedOrderItemReorder>();
  const [show, setShow] = useState(Boolean(itemInFocus || publicMenuItem));
  const {
    track
  } = useTrackWithFlags();
  const [{
    cartItem
  }, cartItemDispatch] = useReducer(cartItemReducer, initialState);
  const {
    onAddItem,
    onRemoveItem,
    onUpdateItem
  } = useCartMutations();
  const {
    data
  } = usePublicMenuItem(itemInFocus?.id, cart.is_delivery);
  const yourUsualsItems = useMemo(() => {
    if (!publicMenuItem) {
      return [];
    }
    const dataItem = menuItemReorders?.find(item => item.store_menu_item_id === publicMenuItem.id);
    if (!dataItem) return [];
    return dataItem.reorders;
  }, [publicMenuItem, menuItemReorders]);

  // The item is in shape expected by the order pipeline; the publicMenuItem
  // will have all modifier list min / max and other info needed to present
  // item modal
  useEffect(() => {
    const menu = store?.menus[0];
    if (!menu) return;
    const items = menu.menu_categories.map(category => category.menu_items).flat();
    const item = items?.find(item => item.id === itemInFocus?.id);
    if (item) {
      setPublicMenuItem({
        ...item,
        is_one_click_add: itemInFocus?.is_one_click_add || publicMenuItem?.is_one_click_add
      });
    }
  }, [itemInFocus, store]);
  useEffect(() => {
    if (data?.public_menu_item) {
      setPublicMenuItem({
        ...data.public_menu_item,
        is_one_click_add: itemInFocus?.is_one_click_add || publicMenuItem?.is_one_click_add
      });
    } else {
      setPublicMenuItem(undefined);
    }
  }, [data]);
  useEffect(() => {
    videoManager.setAllowVideos(!show);
  }, [show]);
  useEffect(() => {
    if (cartItem.reorder_item_id && yourUsualsItems.length) {
      const selectedYourUsualsItem = yourUsualsItems.find(item => item.id === cartItem.reorder_item_id);
      setYourUsualsItem(selectedYourUsualsItem);
    }
  }, [cartItem.reorder_item_id, yourUsualsItems]);
  useEffect(() => {
    if (!show && cartItem && publicMenuItem) {
      setShow(true);
      cartItemDispatch({
        type: UPDATE_ITEM_ACTION,
        cartItem: itemInFocus || cartItem,
        publicMenuItem
      });
    }
  }, [show, itemInFocus, publicMenuItem, cartItem]);
  useEffect(() => {
    if (!show) {
      cartItemDispatch({
        type: CLEAR_STATE
      });
    }
  }, [show]);
  const trackAction = useCallback((eventName: string, properties?: object) => {
    if (publicMenuItem) {
      const fullProperties = {
        ...properties,
        product_id: publicMenuItem.id,
        sku: publicMenuItem.id,
        name: publicMenuItem.name,
        price: publicMenuItem.price / 100,
        brand: publicMenuItem.brand_name,
        location: locationSlug,
        is_one_click_add: Boolean(publicMenuItem?.is_one_click_add),
        currency: "USD"
      };
      // follows Segment Ecommerce spec
      track({
        event: eventName,
        properties: fullProperties
      });
    }
  }, [publicMenuItem, locationSlug, track]);
  useEffect(() => {
    if (publicMenuItem) {
      const unavailableMods = getUnavailableModifierItems(publicMenuItem);
      if (unavailableMods.length > 0) {
        trackAction("Product Viewed", {
          unavailableMods,
          media_info: mediaInfo
        });
      } else {
        trackAction("Product Viewed", {
          media_info: mediaInfo
        });
      }
    }
  }, [publicMenuItem]);
  useHideBodyOverflow(show);
  const menuItemVideoConfig = VIDEO_CONFIG["menuItemsVideoUrls"].find(config => config.name === (itemInFocus || publicMenuItem)?.name);
  const imageId = `${(itemInFocus || publicMenuItem)?.name}_image`;
  const videoId = `${(itemInFocus || publicMenuItem)?.name}_video`;
  const defaultMediaInfo = {
    media_ids: menuItemVideoConfig ? [imageId, videoId] : [imageId],
    media_seen: [imageId],
    number_of_media: menuItemVideoConfig ? 2 : 1,
    number_of_media_seen: 1,
    currently_visible_media: imageId,
    has_video: false,
    did_start_video: false,
    did_finish_video: false
  };
  const onYourUsualsClick = (item: PlacedOrderItemReorder) => {
    setYourUsualsItem(item);
    cartItemDispatch({
      type: SET_REORDER_ITEM,
      reorderItem: item
    });
    trackAction("Your Usuals Item Selected", {
      your_usuals_item: item
    });
  };
  return <MenuItemModal setMediaInfo={setMediaInfo} mediaInfo={mediaInfo ?? defaultMediaInfo} setItemInFocus={setItemInFocus} publicMenuItem={publicMenuItem} setPublicMenuItem={setPublicMenuItem} cartItemId={itemInFocus?.external_id} onAddItem={onAddItem} onRemoveItem={onRemoveItem} onUpdateItem={onUpdateItem} store={store} videoConfig={menuItemVideoConfig} disableVideos={disableVideos} onYourUsualsClick={onYourUsualsClick} setYourUsualsItem={setYourUsualsItem} yourUsualsItem={yourUsualsItem} yourUsualsItems={yourUsualsItems} trackAction={trackAction} cartItemDispatch={cartItemDispatch} show={show} setShow={setShow} cartItem={cartItem} data-sentry-element="MenuItemModal" data-sentry-component="MenuItemModalController" data-sentry-source-file="MenuItemModalController.tsx" />;
};