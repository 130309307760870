import { useRouter } from "next/router";
import { useMemo } from "react";
import {
  UpsertRawCartClientMetadataMutationVariables,
  useUpsertRawCartClientMetadataMutation,
} from "../../../graphql/upsertRawCartClientMetadata.mutation.generated";
import { useCookie } from "../../../utils/hooks/useCookie";

export const useCartClientMetadataMutation = () => {
  const [upsertRawCartClientMetadata, mutationResults] =
    useUpsertRawCartClientMetadataMutation();

  const cartClientMetadata = useGatherCartMetadata();

  const upsert = async (raw_cart_id: string) => {
    if (!raw_cart_id || !cartClientMetadata) {
      return;
    }
    return await upsertRawCartClientMetadata({
      variables: {
        raw_cart_id,
        ...cartClientMetadata,
      },
    });
  };

  return {
    upsert,
    ...mutationResults,
  };
};

type ClientMetadata = Pick<
  UpsertRawCartClientMetadataMutationVariables,
  | "browser_segment_user_id"
  | "browser_segment_anonymous_id"
  | "browser_user_agent"
  | "browser_facebook_pixel_cookie"
  | "browser_facebook_click_id_cookie"
  | "browser_checkout_url"
>;

export const useGatherCartMetadata = (): ClientMetadata => {
  const router = useRouter();
  const userAgent = useMemo(
    () => (typeof navigator !== "undefined" ? navigator.userAgent : undefined),
    [],
  );

  const _analytics = typeof analytics !== "undefined" ? analytics : undefined;
  const segmentAnonymousId = _analytics?.user?.().anonymousId() ?? undefined;
  const segmentUserId = _analytics?.user?.().id() ?? undefined;

  const facebookPixelCookie = useCookie("_fbp", "")?.cookie;
  const facebookClickIdCookie = useCookie("_fbc", "")?.cookie;

  const checkoutURL = useMemo(() => {
    if (typeof window === "undefined") {
      return undefined;
    }
    if (!router.isReady) {
      return undefined;
    }
    const isCheckoutPage = router.asPath.endsWith("/checkout");
    const checkoutURL = window.location.href;
    return isCheckoutPage ? checkoutURL : undefined;
  }, [router.asPath, router.isReady]);

  return useMemo(
    () => ({
      browser_user_agent: userAgent,
      browser_segment_user_id: segmentUserId,
      browser_segment_anonymous_id: segmentAnonymousId,
      browser_facebook_pixel_cookie: facebookPixelCookie,
      browser_facebook_click_id_cookie: facebookClickIdCookie,
      browser_checkout_url: checkoutURL,
    }),
    [
      userAgent,
      segmentUserId,
      segmentAnonymousId,
      facebookPixelCookie,
      facebookClickIdCookie,
      checkoutURL,
    ],
  );
};
