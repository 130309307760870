import { useRouter } from "next/router";
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "../../features/Authentication/hooks/useAuth";
import { useBrandsContent } from "../../features/BrandPage/hooks/useBrandsContent";
import { DeliveryAddress, DeliveryEstimate } from "../../features/Cart/types";
import { useAddressDeliveryEstimates } from "../../features/LocationDiscovery/hooks/useAddressDeliveryEstimates";
import { useLocations } from "../../features/Locations/hooks/useLocations";
import { useUpdateCustomer } from "../../features/Account/hooks/useUpdateCustomer";
import { StoreLocationQuery, useStoreLocationQuery } from "../../graphql/storeLocation.query.generated";
import { BrandContent, LocationWithDistance } from "../../types";
import { useRouterQueryParam } from "../hooks/useRouterQueryParam";
import { addDistanceToLocation } from "../location";
type LocationContextType = {
  locations: LocationWithDistance[];
  storeLocation: StoreLocationQuery["store_location"] | undefined;
  brandSelected: BrandContent[];
  setBrandSelected: Dispatch<SetStateAction<BrandContent[]>>;
  deliverableLocations: LocationWithDistance[] | null;
  brandsContentMap: {
    [key: number]: BrandContent;
  };
  loading: boolean;
  setLocations: Dispatch<SetStateAction<LocationWithDistance[]>>;
  setDeliverableLocations: Dispatch<SetStateAction<LocationWithDistance[] | null>>;
  onLocationClick: (location: LocationWithDistance) => void;
  selectedLocation: LocationWithDistance | null;
  searchedAddress: DeliveryAddress | undefined;
  setSearchedAddress: Dispatch<SetStateAction<DeliveryAddress | undefined>>;
  deliveryEstimates: DeliveryEstimate[];
  onDeliveryEstimates: (deliveryAddress: DeliveryAddress, locationIds?: number[] | undefined) => Promise<any>;
  loadingDeliveryEstimates: boolean;
  userPreferredLocation?: LocationWithDistance;
  goToStorePopularPage: () => void;
};
export const LocationContext = createContext<LocationContextType>({
  locations: [],
  storeLocation: undefined,
  brandSelected: [],
  setBrandSelected: () => {},
  brandsContentMap: {},
  deliverableLocations: [],
  loading: false,
  setLocations: () => {},
  setSearchedAddress: () => {},
  onLocationClick: () => {},
  setDeliverableLocations: () => {},
  selectedLocation: null,
  searchedAddress: undefined,
  deliveryEstimates: [],
  onDeliveryEstimates: () => Promise.resolve({}),
  loadingDeliveryEstimates: false,
  goToStorePopularPage: () => {}
});
export function LocationContextProvider({
  children
}: PropsWithChildren) {
  const {
    user
  } = useAuth();
  const router = useRouter();
  const locationSlug = useRouterQueryParam("locationSlug");
  const [brandSelected, setBrandSelected] = useState<BrandContent[]>([]);
  const {
    data,
    loading
  } = useLocations();
  const brandsContent = useBrandsContent();
  const [locations, setLocations] = useState<LocationWithDistance[]>([]);
  const [deliverableLocations, setDeliverableLocations] = useState<LocationWithDistance[] | null>(null);
  const [searchedAddress, setSearchedAddress] = useState<DeliveryAddress>();
  const [selectedLocation, setSelectedLocation] = useState<LocationWithDistance | null>(null);
  const {
    data: storeLocation,
    previousData
  } = useStoreLocationQuery({
    skip: !locationSlug,
    variables: {
      location_slug: locationSlug
    },
    fetchPolicy: "cache-and-network"
  });
  const {
    deliveryEstimates,
    onDeliveryEstimates,
    loadingDeliveryEstimates
  } = useAddressDeliveryEstimates();
  const handleLocationClick = useCallback((location: LocationWithDistance) => {
    setSelectedLocation(location);
  }, []);
  const {
    mutate: updateUser
  } = useUpdateCustomer();
  const userPreferredLocation = useMemo(() => {
    if (!user?.preferred_location_id) return;
    return locations.find(location => location.id === user?.preferred_location_id);
  }, [locations, user?.preferred_location_id]);
  const brandsContentMap = useMemo(() => {
    return brandsContent.reduce<{
      [key: number]: BrandContent;
    }>((acum, brand) => {
      if (brand.brand_id !== null && brand.brand_id !== undefined && !acum[brand.brand_id]) {
        acum[brand.brand_id] = brand;
      }
      return acum;
    }, {});
  }, [brandsContent]);
  useEffect(() => {
    if (router.query.brand_id) {
      const brandId = parseInt((router.query.brand_id as string), 10);
      const brand = brandsContentMap[brandId];
      if (brand) {
        setBrandSelected([brand]);
      }
    }
  }, [brandsContentMap, router.query.brand_id]);
  useEffect(() => {
    if (locationSlug && locations) {
      const newLocation = locations.find(location => location.slug === locationSlug);
      if (newLocation) {
        setSelectedLocation(newLocation);
      }
    }
  }, [locationSlug, locations]);
  useEffect(() => {
    if (data?.locations) {
      const selectedBrandIds = brandSelected.map(brand => brand.brand_id);
      const filteredLocations = selectedBrandIds.length ? data?.locations.filter((loc: LocationWithDistance) => loc.is_active).filter((loc: LocationWithDistance) => {
        return selectedBrandIds.every(id => loc.stores?.find(store => store.brand.id === id));
      }) : data?.locations.filter((loc: LocationWithDistance) => loc.is_active);
      const newLocations = addDistanceToLocation(filteredLocations, searchedAddress?.geocodes).slice().sort((location1, location2) => (location1.distance || 0) - (location2.distance || 0));
      setLocations(newLocations);
    }
  }, [data, searchedAddress, brandSelected.length, brandSelected]);

  // Keep the user's preferred location up to date
  useEffect(() => {
    if (!storeLocation?.store_location || !user || storeLocation.store_location.id === user.preferred_location_id) {
      return;
    }
    updateUser({
      preferred_location_id: storeLocation.store_location?.id
    });
  }, [user, storeLocation, updateUser]);

  // We want to remember the last seen store location
  const knownStoreLocation = storeLocation?.store_location || previousData?.store_location || userPreferredLocation;
  const goToStorePopularPage = useCallback(() => {
    if (knownStoreLocation?.slug) {
      router.push(`/order/store/${knownStoreLocation?.slug}/popular`);
    } else {
      router.push("/order");
    }
  }, [knownStoreLocation?.slug, router]);
  return <LocationContext.Provider value={{
    locations,
    storeLocation: knownStoreLocation,
    deliverableLocations,
    setDeliverableLocations,
    brandsContentMap,
    setLocations,
    loading,
    selectedLocation,
    onLocationClick: handleLocationClick,
    searchedAddress,
    setSearchedAddress,
    brandSelected,
    setBrandSelected,
    deliveryEstimates,
    onDeliveryEstimates,
    loadingDeliveryEstimates,
    userPreferredLocation,
    goToStorePopularPage
  }} data-sentry-element="unknown" data-sentry-component="LocationContextProvider" data-sentry-source-file="LocationContext.tsx">
      {children}
    </LocationContext.Provider>;
}
export const useLocationContext = () => {
  const locationContext = useContext(LocationContext);
  if (!locationContext) {
    throw new Error("You may not user LocationContext outside of LocationContextProvider");
  } else {
    return locationContext;
  }
};