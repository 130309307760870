type Props = {
  className?: string;
};
export const UserGroupOrderIcon = (props: Props) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="UserGroupOrderIcon" data-sentry-source-file="UserGroupOrderIcon.tsx">
      <path d="M16 21V19.3333C16 18.4493 15.6758 17.6014 15.0988 16.9763C14.5218 16.3512 13.7391 16 12.9231 16H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="UserGroupOrderIcon.tsx" />
      <path d="M4 13V19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="UserGroupOrderIcon.tsx" />
      <path d="M7 16H1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="UserGroupOrderIcon.tsx" />
      <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="UserGroupOrderIcon.tsx" />
      <path d="M22 20.9999V18.9999C21.9993 18.1136 21.7044 17.2527 21.1614 16.5522C20.6184 15.8517 19.8581 15.3515 19 15.1299" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="UserGroupOrderIcon.tsx" />
      <path d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="UserGroupOrderIcon.tsx" />
    </svg>;
};