import { useRouter } from "next/router";
import { useCallback } from "react";
import { useLocationContext } from "../contexts/LocationContext";
import { useTrackWithFlags } from "./useTrackWithFlags";

export const useNavToCashRewardsWithTrack = () => {
  const router = useRouter();
  const { track } = useTrackWithFlags();
  const { storeLocation } = useLocationContext();
  const doNavToRewards = useCallback(
    async (origin: string, properties: { [key: string]: any } = {}) => {
      let path = "/order/rewards";

      if (storeLocation?.slug) {
        path = `/order/store/${storeLocation?.slug}/rewards`;
      }

      await track({
        event: "navigate_to_cash_rewards_page",
        properties: {
          origin,
          ...properties,
        },
      });

      router?.push(path);
    },
    [router, track, storeLocation],
  );

  return doNavToRewards;
};
