import { useCallback } from "react";
import { useCalculateTipAmountLazyQuery } from "../../../graphql/calculateTipAmount.query.generated";

export const useCalculateTipAmount = () => {
  const [_fetchCustomTipAmount, { data, loading }] =
    useCalculateTipAmountLazyQuery();

  const fetchCustomTipAmount = useCallback(
    (tipRate: number, subtotal: number) => {
      return _fetchCustomTipAmount({
        fetchPolicy: "network-only",
        variables: {
          subtotal,
          tipRate,
        },
      });
    },
    [_fetchCustomTipAmount],
  );

  return {
    fetchCustomTipAmount,
    data: data?.calculate_tip_amount,
    loading,
  };
};
