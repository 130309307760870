import { v4 as uuidv4 } from "uuid";
import { CartMenuItem } from "../features/Cart/types";
import { PublicMenuItem } from "../features/MenuItemModal/types";
import { PublicStore } from "../features/Stores/types";

export const handleAddingRecommendedPairingsToCart = (
  cartItem: CartMenuItem,
  recommendedPairingsItems: PublicMenuItem[],
  store?: PublicStore,
  participantId?: string,
  isOneClickAdd?: boolean,
) => {
  // Need to associate an external_id to the parent item in the PDP
  const quantity = recommendedPairingsItems.length;
  const allCartItems = recommendedPairingsItems.map(
    (pairing: PublicMenuItem) => {
      return {
        id: pairing?.id,
        external_id: uuidv4(),
        name: pairing.name,
        blurhash: pairing.blurhash,
        image_url: pairing.image_url,
        brand_name: pairing.brand_name,
        store_id: store?.id || cartItem?.store_id,
        quantity: pairing.quantity ?? 1,
        price: pairing.price,
        modifier_items: [],
        group_order_participant_id: participantId,
        is_one_click_add: isOneClickAdd,
      };
    },
  );
  const price = allCartItems.reduce((total, item) => total + item.price, 0);
  return {
    allCartItems,
    price,
    quantity,
  };
};
