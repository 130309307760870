import { useCallback, useState } from "react";

export const getItem = (key: string) => {
  if (typeof document === "undefined") return "";

  return document.cookie.split("; ").reduce((total, currentCookie) => {
    const item = currentCookie.split("=");
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, "");
};

const setItem = (key: string, value: string, numberOfDays = 180) => {
  const now = new Date();
  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);

  document.cookie = `${key}=${value}; domain=${
    process.env.NEXT_PUBLIC_LFG_COOKIE_DOMAIN
  }; expires=${now.toUTCString()}; path=/`;
};

export const useCookie = (key: string, defaultValue: string) => {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = useCallback(
    (value: string, numberOfDays = 180) => {
      setCookie(value);
      setItem(key, value, numberOfDays);
    },
    [key],
  );

  return { cookie, updateCookie };
};
