import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoreLocationQueryVariables = Types.Exact<{
  location_slug: Types.Scalars['String'];
}>;


export type StoreLocationQuery = { __typename?: 'Query', store_location?: { __typename?: 'LocationV2', id?: number | undefined, slug?: string | undefined, street?: string | undefined, name?: string | undefined, display_name?: string | undefined, state?: string | undefined, zipcode?: string | undefined, tax_rate?: number | undefined, share_link?: string | undefined, yelp_location_id?: string | undefined, phone_number?: string | undefined, is_open?: boolean | undefined, lat?: number | undefined, lng?: number | undefined } | undefined };


export const StoreLocationDocument = gql`
    query storeLocation($location_slug: String!) {
  store_location(location_slug: $location_slug) {
    id
    slug
    street
    name
    display_name
    state
    zipcode
    tax_rate
    share_link
    yelp_location_id
    share_link
    phone_number
    is_open
    lat
    lng
  }
}
    `;

/**
 * __useStoreLocationQuery__
 *
 * To run a query within a React component, call `useStoreLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreLocationQuery({
 *   variables: {
 *      location_slug: // value for 'location_slug'
 *   },
 * });
 */
export function useStoreLocationQuery(baseOptions: Apollo.QueryHookOptions<StoreLocationQuery, StoreLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreLocationQuery, StoreLocationQueryVariables>(StoreLocationDocument, options);
      }
export function useStoreLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreLocationQuery, StoreLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreLocationQuery, StoreLocationQueryVariables>(StoreLocationDocument, options);
        }
export type StoreLocationQueryHookResult = ReturnType<typeof useStoreLocationQuery>;
export type StoreLocationLazyQueryHookResult = ReturnType<typeof useStoreLocationLazyQuery>;
export type StoreLocationQueryResult = Apollo.QueryResult<StoreLocationQuery, StoreLocationQueryVariables>;