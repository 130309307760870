import { gql, useQuery } from "@apollo/client";
import { useLocationContext } from "../../../utils/contexts/LocationContext";
import { PublicMenuItem } from "../../MenuItemModal/types";

export const STORE_MENU_ITEM = gql`
  query StoreMenuItem(
    $menu_item_id: Int
    $location_id: Int
    $filter_active_modifiers: Boolean
  ) {
    store_menu_item(location_id: $location_id, menu_item_id: $menu_item_id) {
      id
      store_id
      name
      description
      brand_name
      price
      is_active
      blurhash
      image_url
      tags {
        id
        name
        is_v2
        is_active
        slug
      }
      modifier_lists {
        id
        name
        min
        max
        is_single_select
        modifier_items(filter_active_modifiers: $filter_active_modifiers) {
          id
          name
          price
          is_active
        }
      }
    }
  }
`;

export const useCondiments = (): PublicMenuItem | null => {
  const { storeLocation: location } = useLocationContext();
  const { data } = useQuery(STORE_MENU_ITEM, {
    variables: {
      location_id: location?.id,
      menu_item_id: 450,
      filter_active_modifiers: false,
    },
    skip: Boolean(!location?.id),
    fetchPolicy: "network-only",
  });

  if (!data?.store_menu_item) return null;

  return data?.store_menu_item as PublicMenuItem;
};
