import { DeliveryAddress } from "../../graphql/types";

const isEmpty = (value: string | undefined): boolean => {
  return value === "" || value === null || value === undefined;
};

const areEquivalent = (
  val1: string | undefined,
  val2: string | undefined,
): boolean => {
  return val1 === val2 || (isEmpty(val1) && isEmpty(val2));
};

export function findMatchingAddressInList(
  deliveryAddressInput: DeliveryAddress,
  existingAddresses: DeliveryAddress[],
) {
  return existingAddresses.find((existingAddress) => {
    const { city, state, street, unit } = existingAddress;
    const {
      city: inputCity,
      state: inputState,
      street: inputStreet,
      unit: inputUnit,
    } = deliveryAddressInput;

    return (
      areEquivalent(city, inputCity) &&
      areEquivalent(state, inputState) &&
      areEquivalent(street, inputStreet) &&
      areEquivalent(unit, inputUnit)
    );
  });
}
