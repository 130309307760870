import { PropsWithChildren } from "react";
import Divider from "../../../ui/Divider";
import { classNames } from "../../../utils/classNames";
import { cartHorizontalPaddingClassName } from "../utils";
interface CartSectionProps extends PropsWithChildren {
  className?: string;
  dividerClassName?: string;
}
const CartSection = ({
  children,
  className,
  dividerClassName
}: CartSectionProps) => {
  return <section className={classNames(cartHorizontalPaddingClassName, className)} data-sentry-component="CartSection" data-sentry-source-file="CartSection.tsx">
      <Divider className={dividerClassName} data-sentry-element="Divider" data-sentry-source-file="CartSection.tsx" />
      {children}
    </section>;
};
export default CartSection;