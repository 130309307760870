import React from "react";
import { BrandContent } from "../../../types";
import { classNames } from "../../../utils/classNames";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { ResponsiveImage } from "../../ResponsiveImage";
import { Header } from "../../Typography";
type Props = {
  brand: BrandContent;
  isSelected?: boolean;
  goToBrandPage: (brandSlug: string) => void;
  position: number;
};
export const BrandPageMobileNavbarPill = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    brand,
    isSelected,
    goToBrandPage,
    position
  } = props;
  const {
    track
  } = useTrackWithFlags();
  const onClick = () => {
    if (isSelected) return;
    goToBrandPage(brand?.slug);
    track({
      event: "Brand Navbar Clicked",
      properties: {
        brand,
        position
      }
    });
  };
  return <div ref={ref} className={classNames("flex min-w-max h-10 px-[12px] justify-center items-center rounded-full cursor-pointer select-none border border-border-primary", isSelected ? "bg-surface-light text-text-primary border-2" : "text-text-secondary ")} onClick={onClick}>
      {/* Brand Logo */}
      <ResponsiveImage className="aspect-square h-6 w-6 rounded-full bg-surface-lighter object-cover" imageUrl={brand?.logo?.url} />
      {/* Brand Name  */}
      <Header h5 className="text-center ml-1">
        {brand?.name}
      </Header>
    </div>;
});