import { ConfirmationModal } from "../../../../ui/ConfirmationModal/ConfirmationModal";
interface ConfirmRemoveModalProps {
  showModal: boolean;
  isLastItem?: boolean;
  isLoading: boolean;
  closeModal: () => void;
  onConfirm: () => Promise<void>;
}
const ConfirmRemoveModal = ({
  showModal,
  isLastItem,
  isLoading,
  closeModal,
  onConfirm
}: ConfirmRemoveModalProps) => {
  return <ConfirmationModal show={showModal} closeModal={closeModal} size="small" header="Confirm remove" message={isLastItem ? "Are you sure you want to remove this item? You will get redirected back to home page." : "Are you sure you want to remove this item?"} confirmButton={{
    label: "Remove it",
    onPress: onConfirm,
    loading: isLoading
  }} cancelButton={{
    label: "Cancel",
    onPress: closeModal
  }} data-sentry-element="ConfirmationModal" data-sentry-component="ConfirmRemoveModal" data-sentry-source-file="ConfirmRemoveModal.tsx" />;
};
export default ConfirmRemoveModal;