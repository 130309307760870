import { Dispatch, SetStateAction } from "react";
import Divider from "../../../ui/Divider";
import { Header, Text } from "../../../ui/Typography";
import { hasExceededSpendLimit } from "../../../utils/cart";
import { classNames } from "../../../utils/classNames";
import { getDisplayName } from "../../../utils/getDisplayName";
import ReceiptCartItem from "../../Checkout/components/ReceiptCartItem";
import useCartReceiptLines from "../../Checkout/hooks/useCartReceiptLines";
import { useGroupOrderContext } from "../../GroupOrder/context/GroupOrderContext";
import { PublicStore } from "../../Stores/types";
import { Cart, CartMenuItem, CartReceipt } from "../types";
import { cartHorizontalPaddingClassName } from "../utils";
interface GroupOrderParticipantListProps {
  cart: Cart;
  cartReceipt?: CartReceipt;
  stores: Record<number, PublicStore>;
  removeItem: (cart: Cart, item: CartMenuItem) => void;
  onUpdateItemQuantity: (item: CartMenuItem | undefined, quantity: number) => void;
  setItemInFocus: Dispatch<SetStateAction<CartMenuItem | undefined>>;
  setItemToRemove: Dispatch<SetStateAction<CartMenuItem | undefined>>;
}
const GroupOrderParticipantList = ({
  cart,
  cartReceipt,
  stores,
  removeItem,
  setItemInFocus,
  setItemToRemove,
  onUpdateItemQuantity
}: GroupOrderParticipantListProps) => {
  const {
    participantId,
    isGroupOrderCreator
  } = useGroupOrderContext();
  const {
    menuLineItems
  } = useCartReceiptLines(cartReceipt);
  return <ul className={classNames("mt-4", cartHorizontalPaddingClassName)} data-sentry-component="GroupOrderParticipantList" data-sentry-source-file="GroupOrderParticipantList.tsx">
      {cart?.participants?.map((participant, index) => {
      const participantItems = participant.items?.filter(cartItem => cartItem.name !== "Utensils") || [];
      const itemQuantity = participantItems.reduce((qty, item) => {
        qty += item.quantity;
        return qty;
      }, 0);
      return <li key={`${participant.id}-participant`}>
            {index > 0 && <Divider />}
            <Header h5>
              {`${getDisplayName(participant.first_name, participant.last_name)} ${participantId === participant.id ? "(You)" : ""}`}
            </Header>
            <Text medium className={"text-text-secondary"}>
              {itemQuantity > 0 ? <span>
                  {`${itemQuantity} items: `}
                  {!participant.is_creator && hasExceededSpendLimit(cart, cart.items, participant.id) && <span className="text-new-error">
                        over ${cart.spend_limit} spend limit
                      </span>}
                </span> : "No items yet"}
            </Text>
            {menuLineItems?.length ? <ul className="space-y-6 mt-4">
                {menuLineItems.map((lineItem, idx) => {
            const participantItem = participantItems.find(item => item.external_id === lineItem.external_id);
            if (!participantItem) {
              return null;
            }
            return <ReceiptCartItem key={`${participantId}-cart-item-${lineItem.external_id}-${idx}`} stores={stores} item={lineItem} cartItem={participantItem} canMutateItem={Boolean(isGroupOrderCreator || participantId === participant.id)} removeItem={() => removeItem(cart, participantItem)} onModifyClick={setItemInFocus} onRemoveClick={setItemToRemove} onUpdateQuantity={onUpdateItemQuantity} />;
          })}
              </ul> : null}
          </li>;
    })}
    </ul>;
};
export default GroupOrderParticipantList;