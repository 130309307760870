import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuestWebPairingsQueryVariables = Types.Exact<{
  menu_item_id?: Types.InputMaybe<Types.Scalars['Int']>;
  location_slug?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GuestWebPairingsQuery = { __typename?: 'Query', client_menu_item_pairings?: Array<{ __typename?: 'PublicMenuItem', id?: number | undefined } | undefined> | undefined };


export const GuestWebPairingsDocument = gql`
    query GuestWebPairings($menu_item_id: Int, $location_slug: String) {
  client_menu_item_pairings(
    menu_item_id: $menu_item_id
    location_slug: $location_slug
  ) {
    id
  }
}
    `;

/**
 * __useGuestWebPairingsQuery__
 *
 * To run a query within a React component, call `useGuestWebPairingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestWebPairingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestWebPairingsQuery({
 *   variables: {
 *      menu_item_id: // value for 'menu_item_id'
 *      location_slug: // value for 'location_slug'
 *   },
 * });
 */
export function useGuestWebPairingsQuery(baseOptions?: Apollo.QueryHookOptions<GuestWebPairingsQuery, GuestWebPairingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuestWebPairingsQuery, GuestWebPairingsQueryVariables>(GuestWebPairingsDocument, options);
      }
export function useGuestWebPairingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuestWebPairingsQuery, GuestWebPairingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuestWebPairingsQuery, GuestWebPairingsQueryVariables>(GuestWebPairingsDocument, options);
        }
export type GuestWebPairingsQueryHookResult = ReturnType<typeof useGuestWebPairingsQuery>;
export type GuestWebPairingsLazyQueryHookResult = ReturnType<typeof useGuestWebPairingsLazyQuery>;
export type GuestWebPairingsQueryResult = Apollo.QueryResult<GuestWebPairingsQuery, GuestWebPairingsQueryVariables>;