import { useStoresContext } from "@utils/contexts/StoresContext";
import { getDisplayName } from "@utils/getDisplayName";
import { getPathname } from "@utils/router";
import { useRouter } from "next/router";
import { Dispatch, MouseEvent, ReactElement, SetStateAction, useEffect } from "react";
import { Cart } from "@features/Cart/types";
import { useGroupOrderContext } from "@features/GroupOrder/context/GroupOrderContext";
import { PickupType } from "@graphql/types";
import { useCartContext } from "../../app-components/hooks/useCartContext";
import { calculateCartQuanity, getIsCartItemsValid } from "../../utils/cart";
import { Navbar } from "./Navbar";
import MobileFloatingCartButton from "./components/MobileFloatingCartButton";
type NavbarProps = {
  setShowCart: Dispatch<SetStateAction<boolean>>;
  pageDisplayName?: string;
};
export type UserNavigationMenuItem = {
  icon: ReactElement;
  name: string;
  href: string;
  onClick?: (event?: MouseEvent<HTMLAnchorElement>) => void;
  notifBadgeIndicator?: number;
};
export type UserNavigation = (UserNavigationMenuItem | undefined)[];
const getSpecialOrderButtonLabel = (cart: Cart, isGroupOrderCreator: boolean, name: string) => {
  if (cart.pickup_type === PickupType.Poolside) {
    return "Your poolside order";
  }
  if (cart.is_group_order && !isGroupOrderCreator) {
    return `${name}'s Group Order`;
  }
  if (cart.is_group_order) {
    return "Your group order";
  }
  return "View bag";
};
export const NavbarController = ({
  setShowCart,
  pageDisplayName
}: NavbarProps) => {
  const router = useRouter();
  const {
    asPath: routeLocation,
    query
  } = router;
  const openInviteModal: boolean = query?.open_invite_modal === "true";
  const {
    isGroupOrderCreator
  } = useGroupOrderContext();
  const {
    cart
  } = useCartContext();
  const {
    publicStores: stores
  } = useStoresContext();
  useEffect(() => {
    if (openInviteModal) {
      router.replace(getPathname(router));
    }
  }, [openInviteModal, router]);
  const cartQuantity = calculateCartQuanity(cart);
  const isCartValid = getIsCartItemsValid(cart);
  const isCheckout = routeLocation.includes("/checkout");
  const isCashRewards = routeLocation.includes("/rewards");
  const isAccount = routeLocation.includes("/account");
  const isBrands = routeLocation.includes("/brands");
  const orderDetailRegex = new RegExp(".*/order/store/.*/order/.*");
  const isOrderDetail = orderDetailRegex.test(routeLocation);
  const isDeliveryAddress = routeLocation.includes("/delivery-addresses");
  const isCareers = routeLocation.includes("/careers");
  const isApp = routeLocation.includes("/app");
  const isSavedPaymentMethods = routeLocation.includes("/saved-payment-methods");
  const isStorePage = routeLocation.includes("/store/");
  const isOrderHistory = routeLocation.includes("/order-history");
  const isMenuBrowsing = isStorePage && !isOrderDetail && !isCheckout && !isCashRewards && !isAccount;
  const isAuthPage = routeLocation.includes("/login") || routeLocation.includes("/signup");
  const showNavHeaderDesktop = isStorePage || isCheckout || isAuthPage || isAccount || isOrderHistory || isDeliveryAddress || isBrands || isSavedPaymentMethods || isCareers || isApp;
  const showNavHeaderMobile = isStorePage && !isCashRewards || isCheckout || isBrands || isCareers;
  const showLocationPicker = isMenuBrowsing;
  const showCart = isMenuBrowsing;
  const showCartButton = (cart?.is_group_order || cart.pickup_type === PickupType.Poolside || cartQuantity > 0) && showCart;
  const displayName = getDisplayName(cart.first_name, cart.last_name);
  const showGroupOrder = !(isAccount || isOrderHistory || isDeliveryAddress || isCashRewards || isSavedPaymentMethods);
  return <>
      <Navbar setShowCart={setShowCart} showNavHeaderDesktop={showNavHeaderDesktop} showNavHeaderMobile={showNavHeaderMobile} showLocationPicker={showLocationPicker} showCart={showCart} isCartValid={isCartValid} cartQuantity={cartQuantity} stores={stores} pageDisplayName={pageDisplayName} showGroupOrder={showGroupOrder} data-sentry-element="Navbar" data-sentry-source-file="NavbarController.tsx" />
      {showCartButton && <MobileFloatingCartButton label={getSpecialOrderButtonLabel(cart, isGroupOrderCreator, displayName)} setShowCart={setShowCart} cartQuantity={cartQuantity} isCartValid={isCartValid} />}
    </>;
};