import { Dispatch, SetStateAction } from "react";
import { useLocationContext } from "../../../utils/contexts/LocationContext";
import { DeliveryAddress } from "../../Cart/types";
import { EnterAddress } from "./EnterAddress";
type EnterAddressControllerProps = {
  setShowClose: Dispatch<SetStateAction<boolean>>;
  deliveryAddresses?: DeliveryAddress[];
  onSelectDeliveryAddress: (address?: DeliveryAddress) => void;
  onUpdateDeliveryAddress: (newAddress: DeliveryAddress) => Promise<DeliveryAddress | undefined>;
  addressStage: number;
  setAddressStage: Dispatch<SetStateAction<number>>;
  isLoadingAddressMutation?: boolean;
};
export const EnterAddressController = ({
  setShowClose,
  deliveryAddresses,
  onSelectDeliveryAddress,
  onUpdateDeliveryAddress,
  addressStage,
  setAddressStage,
  isLoadingAddressMutation
}: EnterAddressControllerProps) => {
  const {
    searchedAddress
  } = useLocationContext();
  return <EnterAddress deliveryAddress={searchedAddress} onSelectDeliveryAddress={onSelectDeliveryAddress} deliveryAddresses={deliveryAddresses || []} setShowClose={setShowClose} onUpdateDeliveryAddress={onUpdateDeliveryAddress} addressStage={addressStage} setAddressStage={setAddressStage} isLoadingAddressMutation={isLoadingAddressMutation} data-sentry-element="EnterAddress" data-sentry-component="EnterAddressController" data-sentry-source-file="EnterAddressController.tsx" />;
};