import { useContext } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { Button } from "@ui/NewButton/Button";
import { GroupOrderContext } from "../context/GroupOrderContext";
import useInviteToGroupOrder from "../hooks/useInviteToGroupOrder";
interface ShareGroupOrderProps {
  handleCopy: () => void;
}
const ShareGroupOrder = ({
  handleCopy
}: ShareGroupOrderProps) => {
  const {
    cart
  } = useCartContext();
  const {
    setShowShareGroupOrderModal
  } = useContext(GroupOrderContext);
  const {
    url
  } = useInviteToGroupOrder(cart);
  return <div className="flex flex-col w-screen px-8 sm:px-0 sm:w-124" data-sentry-component="ShareGroupOrder" data-sentry-source-file="ShareGroupOrder.tsx">
      <h3 className="font-semibold text-2.5xl mb-1 text-left">
        {"You're all set! Invite others to join this group order."}
      </h3>
      <div className="mt-6 flex border rounded border-gray-350 bg-surface-lighter h-14 items-center px-4">
        <p className="truncate pr-12 text-gray-550 font-normal text-md font-light">
          {url}
        </p>
        <button className="text-md text-gray-550 font-medium py-4 px-2 text-center font-normal" onClick={handleCopy}>
          Copy
        </button>
      </div>
      <Button onPress={() => setShowShareGroupOrderModal(false)} mode="primary" label="View menu" className="h-14 mt-6 mb-12" size="small" data-sentry-element="Button" data-sentry-source-file="ShareGroupOrder.tsx" />
    </div>;
};
export default ShareGroupOrder;