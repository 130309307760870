import TagIcon from "../../../ui/Icons/Tagicon";
import { Link, Text } from "../../../ui/Typography";
import { CartReceiptLineItem } from "../../Cart/types";
interface ReceiptLineItemProps {
  item: CartReceiptLineItem;
  setTooltipItem: (item: CartReceiptLineItem) => void;
}
const ReceiptLineItem = ({
  item,
  setTooltipItem
}: ReceiptLineItemProps) => {
  if (item.line_item_type === "PromotionalLineItem") {
    return <li className="w-full flex flex-row justify-between items-center">
        <div className="flex flex-row items-center">
          <Text medium className="text-text-primary">
            Discount
          </Text>
          <TagIcon width={16} height={16} className="text-new-green-base mr-1" />
          <Text className="!line-1" eyebrow>
            {item.label}
          </Text>
        </div>
        <div>
          {item.slashed_amount && <Text className="text-text-secondary line-through mr-2" large>
              {item.slashed_amount}
            </Text>}
          <Text large className="text-new-green-base">
            {item.display_amount}
          </Text>
        </div>
      </li>;
  }
  return <li className="w-full flex flex-row justify-between items-center text-text-primary" data-sentry-component="ReceiptLineItem" data-sentry-source-file="ReceiptLineItem.tsx">
      {item.tooltip_description ? <Link onClick={() => setTooltipItem(item)} underLine="dashed">
          <Text medium className="text-text-primary">
            {item.label}
          </Text>
        </Link> : <Text medium>{item.label}</Text>}
      <div className="flex">
        {item.slashed_amount && <Text className="text-text-secondary line-through mr-2" large>
            {item.slashed_amount}
          </Text>}
        <Text medium data-sentry-element="Text" data-sentry-source-file="ReceiptLineItem.tsx">{item.display_amount}</Text>
      </div>
    </li>;
};
export default ReceiptLineItem;