import { PlacedOrderItemReorder } from "../../../graphql/types";
import RadioButtonIcon from "../../../ui/Icons/RadioButtonIcon";
import { Header, Text } from "../../../ui/Typography";
import { getTotalItemPrice } from "../../../utils/cart";
import { classNames } from "../../../utils/classNames";
import { usdFormat } from "../../../utils/usdFormatter";
import MenuItemModalDivider from "./MenuItemModalDivider";
import MenuItemModalSectionTitle from "./MenuItemModalSectionTitle";
interface YourUsualsProps {
  itemPrice: number;
  items?: PlacedOrderItemReorder[];
  selectedItemId?: PlacedOrderItemReorder["id"];
  onItemClick: (item: PlacedOrderItemReorder) => void;
}
interface YourUsualsItemProps {
  itemPrice: number;
  item: PlacedOrderItemReorder;
  isSelected: boolean;
  onClick: () => void;
}
const YourUsualsItem = ({
  item,
  itemPrice,
  onClick,
  isSelected
}: YourUsualsItemProps) => {
  const modifiers = item.modifier_items.map(modifier => modifier.name).join(", ");
  return <button className={classNames("flex flex-col items-start relative p-3 border rounded-lg flex-1 min-w-[300px] mb-4", isSelected ? "border-new-black-base" : "border-new-grey-2-lighten-3")} onClick={onClick} data-sentry-component="YourUsualsItem" data-sentry-source-file="YourUsuals.tsx">
      {isSelected ? <RadioButtonIcon className="absolute right-3" /> : null}
      <Header h5 className="text-new-green-base" data-sentry-element="Header" data-sentry-source-file="YourUsuals.tsx">
        Ordered on {item.date}
      </Header>
      <Text small className="text-text-secondary text-left my-1 pr-10" data-sentry-element="Text" data-sentry-source-file="YourUsuals.tsx">
        {modifiers}
      </Text>
      <Text small className="text-text-secondary" data-sentry-element="Text" data-sentry-source-file="YourUsuals.tsx">
        {usdFormat(getTotalItemPrice({
        price: itemPrice,
        modifier_items: item.modifier_items
      }))}
      </Text>
    </button>;
};
const YourUsuals = ({
  items,
  selectedItemId,
  itemPrice,
  onItemClick
}: YourUsualsProps) => {
  if (!items?.length) return null;
  return <div className="w-full" data-sentry-component="YourUsuals" data-sentry-source-file="YourUsuals.tsx">
      <MenuItemModalDivider data-sentry-element="MenuItemModalDivider" data-sentry-source-file="YourUsuals.tsx" />
      <MenuItemModalSectionTitle title="Your Usuals" subtitle="Quickly reorder with previously selected options" className="px-4 my-4" data-sentry-element="MenuItemModalSectionTitle" data-sentry-source-file="YourUsuals.tsx" />
      <div className="flex flex-row w-full overflow-x-scroll gap-2 px-4 no-scrollbar">
        {items.map(item => <YourUsualsItem key={`your-usuals-item-${item.id}`} item={item} isSelected={item.id === selectedItemId} itemPrice={itemPrice} onClick={() => onItemClick(item)} />)}
      </div>
    </div>;
};
export default YourUsuals;