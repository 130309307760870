import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { DeliveryAddress } from "../../Cart/types";

const ADDRESS_DETAILS_QUERY = gql`
  query AddressDetails($place_id: String) {
    address_details(place_id: $place_id) {
      id
      zipcode
      city
      street
      state
      geocodes {
        lat
        lng
      }
    }
  }
`;

export const usePlaceDetails = (
  place_id?: string,
): { place?: DeliveryAddress; loading: boolean } => {
  const { data, loading, refetch } = useQuery(ADDRESS_DETAILS_QUERY, {
    variables: { place_id: place_id },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (place_id) {
      refetch({
        place_id,
      });
    }
  }, [place_id, refetch]);

  return {
    place: data?.address_details,
    loading,
  };
};
