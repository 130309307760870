import { Dispatch, SetStateAction, useMemo } from "react";
import { LocationWithDistance, OrderType } from "@types";
import { useLocationContext } from "@utils/contexts/LocationContext";
import { Locations } from "./Locations";
type LocationsControllerProps = {
  locations: LocationWithDistance[];
  orderType: string;
  setShowClose?: Dispatch<SetStateAction<boolean>>;
  showClose?: boolean;
  showMap: boolean;
};
export const LocationsController = ({
  locations,
  orderType,
  setShowClose,
  showClose,
  showMap
}: LocationsControllerProps) => {
  const {
    deliverableLocations,
    loadingDeliveryEstimates
  } = useLocationContext();
  const shownLocations = useMemo(() => {
    return orderType === OrderType.DELIVERY ? deliverableLocations ?? [] : locations;
  }, [orderType, locations, deliverableLocations]);
  return <Locations showClose={showClose} setShowClose={setShowClose} orderType={orderType} locations={shownLocations?.filter(loc => loc.is_active) || []} loading={loadingDeliveryEstimates} showMap={showMap} data-sentry-element="Locations" data-sentry-component="LocationsController" data-sentry-source-file="LocationsController.tsx" />;
};