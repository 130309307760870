import React, { useContext, useEffect, useRef, useState } from "react";
import { useTrackWithFlags } from "../../utils/hooks/useTrackWithFlags";
import { getRandomItem } from "../../utils/random";
type VideoType = {
  type: "MenuItem";
  videoRef: React.RefObject<HTMLVideoElement>;
  additionalTrackData?: Record<string, any>;
};
interface VideoManagerContext {
  currentPlayingVideo?: VideoType;
  visibleRefsSet: Set<VideoType>;
  allowVideos: boolean;
  setCurrentPlayingVideo: React.Dispatch<React.SetStateAction<VideoType | undefined>>;
  setAllowVideos: React.Dispatch<React.SetStateAction<boolean>>;
  pickNewRandomVideo: () => void;
  addVisibleItem: (item: VideoType) => void;
  removeVisibleItem: (item: VideoType) => void;
  clearVideo: () => void;
}
const VideoManagerContext = React.createContext<VideoManagerContext>({
  visibleRefsSet: new Set(),
  allowVideos: false,
  setCurrentPlayingVideo: () => {},
  pickNewRandomVideo: () => {},
  addVisibleItem: () => {},
  removeVisibleItem: () => {},
  setAllowVideos: () => {},
  clearVideo: () => {}
});
const VideoManagerContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [allowVideos, setAllowVideos] = useState(true);
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState<VideoType>();
  const {
    track
  } = useTrackWithFlags();
  const [visibleRefsSet, setVisibleRefsSet] = useState(() => new Set<VideoType>());
  const madeVisibleTimeMapRef = useRef(new Map<React.RefObject<HTMLVideoElement>, Date>());
  const clearVideo = () => {
    setCurrentPlayingVideo(undefined);
  };
  const pickNewRandomVideo = () => {
    if (visibleRefsSet.size > 0) {
      const picked = getRandomItem(visibleRefsSet);
      setCurrentPlayingVideo(picked);
    }
  };
  const addVisibleItem = (item: VideoType) => {
    if (!visibleRefsSet.has(item)) {
      madeVisibleTimeMapRef.current.set(item.videoRef, new Date());
      setVisibleRefsSet(prev => {
        const next = new Set(prev);
        next.add(item);
        return next;
      });
    }
  };
  const removeVisibleItem = (item: VideoType) => {
    madeVisibleTimeMapRef.current.delete(item.videoRef);
    setVisibleRefsSet(prev => {
      const next = new Set(prev);
      next.delete(item);
      return next;
    });
  };
  useEffect(() => {
    const madeVisibleTimeMapRefCopy = madeVisibleTimeMapRef.current;
    const ref = currentPlayingVideo?.videoRef;
    ref?.current?.play()?.catch(() => {});
    return () => {
      if (currentPlayingVideo?.type === "MenuItem" && ref?.current) {
        track({
          event: "Media Viewed",
          properties: {
            media_url: ref.current?.src,
            media_type: "video",
            media_source: "localkitchens",
            video_info: {
              video_length_in_seconds: Math.round(ref.current.duration)
            },
            dwell_time_in_seconds: Math.round((new Date().getTime() - (madeVisibleTimeMapRefCopy.get(ref)?.getTime() ?? 0)) / 1000),
            ...(currentPlayingVideo.additionalTrackData ?? {})
          }
        });
      }
      ref?.current?.pause();
    };
  }, [currentPlayingVideo]);
  useEffect(() => {
    if (!currentPlayingVideo && visibleRefsSet.size > 0 && allowVideos) {
      const timeout = setTimeout(pickNewRandomVideo, 1000);
      return () => clearTimeout(timeout);
    }
  }, [currentPlayingVideo, visibleRefsSet.size, allowVideos]);
  useEffect(() => {
    if (!allowVideos) {
      clearVideo();
    }
  }, [allowVideos]);
  return <VideoManagerContext.Provider value={{
    allowVideos,
    currentPlayingVideo,
    visibleRefsSet,
    setAllowVideos,
    setCurrentPlayingVideo,
    pickNewRandomVideo,
    addVisibleItem,
    removeVisibleItem,
    clearVideo
  }} data-sentry-element="unknown" data-sentry-component="VideoManagerContextProvider" data-sentry-source-file="VideoManagerContext.tsx">
      {children}
    </VideoManagerContext.Provider>;
};
export const useVideoManager = () => {
  const videoManager = useContext(VideoManagerContext);
  if (!videoManager) {
    throw new Error("You may not user CurrentUserContext outside of CurrentUserContextProvider");
  } else {
    return videoManager;
  }
};
export default VideoManagerContextProvider;