import { z } from "zod";
import { PasscodeLoginMethod } from "../../features/Authentication/types";
import { OrderType } from "../../types";

export const passcodeRequestParamsSchema = z.object({
  identifier: z.string(),
  method: z.nativeEnum(PasscodeLoginMethod),
});

export type PasscodeRequestParams = z.infer<typeof passcodeRequestParamsSchema>;

export const passcodeLoginParamsSchema = z.object({
  identifier: z.string(),
  passcode: z.string(),
  method: z.nativeEnum(PasscodeLoginMethod),
});

export type PasscodeLoginParams = z.infer<typeof passcodeLoginParamsSchema>;

export const signUpParamsSchema = z.object({
  email: z.string().email(),
  phone_number: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  marketing_consent: z.boolean().nullish(),
  signup_provider: z.number(),
});

export const postPasscodeSignupData = z.object({
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  email: z.string().email().nullish(),
  phone_number: z.string().nullish(),
  marketing_consent: z.boolean().nullish(),
});

export type PostPasscodeSignupData = z.infer<typeof postPasscodeSignupData>;

export type SignUpParams = z.infer<typeof signUpParamsSchema>;

export const googleLoginParamsSchema = z.object({
  credential: z.string(),
});

export const appleLoginParamsSchema = z.object({
  id_token: z.string(),
  state: z.string(),
  code: z.string(),
});

export type GoogleLoginParams = z.infer<typeof googleLoginParamsSchema>;
export type AppleLoginParams = z.infer<typeof appleLoginParamsSchema>;

export const socialLoginProviderDataSchema = z.object({
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  email: z.string().email(),
  identifier: z.string(),
  phone_number: z.string().nullish(),
  google_external_id: z.string().nullish(),
  apple_external_id: z.string().nullish(),
  marketing_consent: z.boolean().nullish(),
});

export type SocialLoginProviderData = z.infer<
  typeof socialLoginProviderDataSchema
>;

export const updateMeSchema = z.object({
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  preferred_location_id: z.number().nullish(),
  preferred_order_type: z.nativeEnum(OrderType).nullish(),
});

export type UpdateMeParams = z.infer<typeof updateMeSchema>;

export const loyaltySchema = z.object({
  credits: z.number(),
  cash_reward_progress: z.number(),
  cash_rewards_available: z.number(),
  total_cash_rewards_received_count: z.number(),
  total_cash_rewards_used_count: z.number(),
  total_signup_rewards_received_count: z.number(),
});

export type Loyalty = z.infer<typeof loyaltySchema>;

export const toggleCashRewardRequestSchema = z.object({
  cart_id: z.string(),
});

export type ToggleCashRewardRequest = z.infer<
  typeof toggleCashRewardRequestSchema
>;

export const loyaltyCashRewardStateSchema = z.object({
  is_applied: z.boolean(),
  cash_rewards_available: z.number(),
  cart_id: z.string(),
  is_cash_reward_intent_set: z.boolean(),
});

export const currentRewardMultiplierSchema = z.object({
  multiplier: z.number(),
  start_at: z.string().nullable(),
  expire_at: z.string().nullable(),
  is_active: z.boolean(),
});

export type LoyaltyCashRewardState = z.infer<
  typeof loyaltyCashRewardStateSchema
>;
