import { faXmarkLarge } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { Link } from "@ui/Typography";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "../../../ui/NewButton/Button";
import { DeliveryAddress } from "../../Cart/types";
type AddressDetailFormProps = {
  show: boolean;
  address: DeliveryAddress | undefined;
  handleAddressUpdate: (input: any) => void;
  onStreetAddressInput: (street: string) => void;
  handlePredictionSelection: (prediction: google.maps.places.AutocompletePrediction) => void;
  predictions: google.maps.places.AutocompletePrediction[];
  isLoadingAddressMutation: boolean;
};
export const AddressDetailForm = ({
  address,
  show,
  handleAddressUpdate,
  onStreetAddressInput,
  handlePredictionSelection,
  predictions,
  isLoadingAddressMutation
}: AddressDetailFormProps) => {
  const [deliveryAddressInput, setDeliveryAddress] = useState<DeliveryAddress | undefined>(address);
  const [street, setStreet] = useState("");
  const [state, setState] = useState("CA");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [unit, setUnit] = useState("");
  const [instructions, setInstructions] = useState("");
  useEffect(() => {
    setDeliveryAddress(address);
  }, [address]);
  useEffect(() => {
    setStreet(deliveryAddressInput?.street || "");
    setZipcode(deliveryAddressInput?.zipcode || "");
    setCity(deliveryAddressInput?.city || "");
    setUnit(deliveryAddressInput?.unit || "");
    setInstructions(deliveryAddressInput?.instructions || "");
  }, [deliveryAddressInput]);
  const updateAddress = useCallback(() => {
    handleAddressUpdate({
      ...deliveryAddressInput,
      street,
      state,
      zipcode,
      city,
      unit,
      instructions
    });
  }, [handleAddressUpdate, deliveryAddressInput, street, state, zipcode, city, unit, instructions]);
  const isAddressValid = useMemo(() => {
    return Boolean(street && zipcode && city);
  }, [street, zipcode, city]);
  const hasData = useMemo(() => {
    return Boolean(street || zipcode || city || unit || instructions);
  }, [street, zipcode, city, unit, instructions]);
  const resetForm = () => {
    setStreet("");
    setZipcode("");
    setCity("");
    setUnit("");
    setInstructions("");
  };
  return <>
      <Transition show={show} enter="transition ease-out duration-200" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-150" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" data-sentry-element="Transition" data-sentry-source-file="AddressDetailForm.tsx">
        <div className="p-4 mb-2 border rounded-lg relative">
          <div className="w-full flex justify-end">
            {address?.street && <FontAwesomeIcon icon={faXmarkLarge} className="absolute h-5 w-5 cursor-pointer text-gray-400 hover:opacity-80" />}
          </div>
          <div className="mt-3 grid grid-cols-2 gap-y-2 gap-x-4 sm:grid-cols-6">
            <div className="relative col-span-2 sm:col-span-6">
              <label htmlFor="street_address" className="block text-sm font-lfg-normal text-text-primary">
                Street address
              </label>
              <div className="mt-1">
                <input type="text" name="street_address" id="street_address" autoComplete="street-address" className="shadow-sm focus:ring-lfg-primary focus:border-lfg-primary block w-full sm:text-sm border-border-secondary bg-surface-lighter rounded-md" value={street} onChange={event => {
                onStreetAddressInput(event.target.value);
                setStreet(event.target.value);
              }} />
              </div>
              <Transition show={Boolean(predictions && predictions.length)} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" data-sentry-element="Transition" data-sentry-source-file="AddressDetailForm.tsx">
                <div className="z-10 mt-1 absolute w-full rounded-md shadow-lg bg-surface-lighter ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <div className="py-1" role="none">
                    {predictions?.map((prediction, idx) => <div key={`prediction-${idx}`} className="cursor-pointer block px-4 py-2 text-sm text-text-primary hover:bg-button-outlined-secondary_hover hover:text-gray-900" role="menuitem" tabIndex={0} onClick={() => handlePredictionSelection(prediction)}>
                        {prediction.description}
                      </div>)}
                  </div>
                </div>
              </Transition>
            </div>

            <div className="col-span-2">
              <label htmlFor="street_address" className="block text-sm font-lfg-normal text-text-primary">
                Apt / Unit
              </label>
              <div className="mt-1">
                <input type="text" name="unit_number" id="unit_number" autoComplete="unit-number" className="shadow-sm focus:ring-lfg-primary focus:border-lfg-primary block w-full sm:text-sm border-border-secondary bg-surface-lighter rounded-md" value={unit} onChange={event => {
                setUnit(event.target.value);
              }} />
              </div>
            </div>

            <div className="col-span-2 sm:col-span-3">
              <label htmlFor="city" className="block text-sm font-lfg-normal text-text-primary">
                City
              </label>
              <div className="mt-1">
                <input type="text" name="city" id="city" className="shadow-sm focus:ring-lfg-primary focus:border-lfg-primary block w-full sm:text-sm border-border-secondary bg-surface-lighter rounded-md" value={city} onChange={event => {
                setCity(event.target.value);
              }} />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label htmlFor="state" className="block text-sm font-lfg-normal text-text-primary">
                State
              </label>
              <div className="mt-1">
                <input disabled type="text" name="state" id="state" className="shadow-sm bg-text-disabled focus:ring-lfg-primary focus:border-lfg-primary block w-full sm:text-sm border-border-secondary bg-surface-lighter rounded-md" value={state} onChange={event => {
                setState(event.target.value);
              }} />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="zip" className="block text-sm font-lfg-normal text-text-primary">
                ZIP / Postal
              </label>
              <div className="mt-1">
                <input type="text" name="zip" id="zip" autoComplete="postal-code" className="shadow-sm focus:ring-lfg-primary focus:border-lfg-primary block w-full sm:text-sm border-border-secondary bg-surface-lighter rounded-md" value={zipcode} onChange={event => {
                setZipcode(event.target.value);
              }} />
              </div>
            </div>

            <div className="col-span-2 sm:col-span-6">
              <label htmlFor="dropoff_instructions" className="block text-sm font-lfg-normal text-text-primary">
                Dropoff Instructions
              </label>
              <div className="mt-1">
                <input type="text" name="dropoff_instructions" id="dropoff_instructions" autoComplete="dropoff_instructions" placeholder="e.g. 'Leave it on my doorstep'" className="shadow-sm focus:ring-lfg-primary focus:border-lfg-primary block w-full sm:text-sm border-border-secondary bg-surface-lighter rounded-md" value={instructions} onChange={event => {
                setInstructions(event.target.value);
              }} />
              </div>
            </div>
            {hasData && <Link underLine="solid" onClick={resetForm} className="mt-2 mb-4 col-span-full flex flex-row justify-center hover:opacity-80 text-center text-text-secondary">
                Clear Form
              </Link>}
          </div>
          <div className="hidden md:block border-t sm:relative sm:w-auto sm:bg-transparent sm:pt-4">
            <Button disabled={!isAddressValid} onPress={updateAddress} className="w-full" size="small" mode="primary" loading={isLoadingAddressMutation} label="Use this address" data-sentry-element="Button" data-sentry-source-file="AddressDetailForm.tsx" />
          </div>
        </div>
      </Transition>
      {show && <div className="border-t fixed md:hidden left-0 bottom-0 w-screen bg-surface-lighter p-4">
          <Button disabled={!isAddressValid} onPress={updateAddress} size="small" mode="primary" label="Use this address" className="w-full" loading={isLoadingAddressMutation} />
        </div>}
    </>;
};