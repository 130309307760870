import { useEffect, useState } from "react";
import SectionModule from "../../../ui/SectionModule/SectionModule";
import Switch from "../../../ui/Switch";
import useOnUpdateUseBag from "../../Checkout/hooks/useOnUpdateUseBag";
import { Cart } from "../types";
import CartSection from "./CartSection";
interface UseBagProps {
  cart: Cart;
}
const UseBag = ({
  cart
}: UseBagProps) => {
  const [useBag, setUseBag] = useState(!!cart.use_bag);
  const updateUseBag = useOnUpdateUseBag(setUseBag);
  useEffect(() => {
    if (cart.use_bag !== useBag) {
      updateUseBag(useBag);
    }
  }, [useBag]);
  return <CartSection data-sentry-element="CartSection" data-sentry-component="UseBag" data-sentry-source-file="UseBag.tsx">
      <SectionModule title="Save a tree, skip the bag" onClick={() => {
      setUseBag(prev => !prev);
    }} rightComponent={<Switch checked={!useBag} toggle={() => {
      setUseBag(prev => !prev);
    }} />} data-sentry-element="SectionModule" data-sentry-source-file="UseBag.tsx" />
    </CartSection>;
};
export default UseBag;