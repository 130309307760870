import Divider from "@ui/Divider";
import { useContext, useState } from "react";
import { ToastContext } from "../../../app-components/hooks/useToast";
import { MandatoryAdvancedScheduling } from "../../../graphql/types";
import { Header, Text } from "../../../ui/Typography";
import { classNames } from "../../../utils/classNames";
import { useLoyaltyContext } from "../../../utils/contexts/LoyaltyContext";
import { useMenuItemModal } from "../../../utils/contexts/MenuItemModalContext";
import { useStoresContext } from "../../../utils/contexts/StoresContext";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { LargeOrders } from "../../Cart/components/LargeOrders";
import { useCartMutations } from "../../Cart/hooks/useCartMutations";
import { Cart, CartMenuItem, CartReceipt, CartReceiptLineItem } from "../../Cart/types";
import { CashRewardRedeemToggle } from "../../CashRewards/components/CashRewardRedeemToggle";
import { useApplyPromoCode } from "../hooks/useApplyPromoCode";
import useCartReceiptLines from "../hooks/useCartReceiptLines";
import CheckoutDivider from "./CheckoutDivider";
import PromoCode from "./PromoCode";
import ReceiptCartItem from "./ReceiptCartItem";
import ReceiptLineItem from "./ReceiptLineItem";
import Tip from "./Tip";
import ConfirmRemoveModal from "./modals/ConfirmRemoveModal";
interface ReceiptProps {
  cart: Cart;
  cartReceipt?: CartReceipt;
  className?: string;
  mandatoryAdvancedScheduling?: MandatoryAdvancedScheduling;
  showCartLineItems?: boolean;
  canMutateCart?: boolean;
  onTipChange?: (tipRate: number, tipValue?: number) => void;
  displayCustomTipModal?: () => void;
  setShowLargeOrderModal?: (show: boolean) => void;
  setTooltipModalItem: (item?: CartReceiptLineItem) => void;
  isCheckout: boolean;
}
const Receipt = ({
  cart,
  cartReceipt,
  mandatoryAdvancedScheduling,
  showCartLineItems = true,
  canMutateCart = true,
  className,
  onTipChange,
  displayCustomTipModal,
  setShowLargeOrderModal,
  setTooltipModalItem,
  isCheckout
}: ReceiptProps) => {
  // Modal states
  const {
    setItemInFocus
  } = useMenuItemModal();
  const [itemToRemove, setItemToRemove] = useState<CartMenuItem>();
  const {
    storesMap
  } = useStoresContext();
  const {
    onApplyPromoCode,
    loading: loadingApplyCoupon
  } = useApplyPromoCode();
  const {
    trackRemoveItem,
    trackUpdateItem
  } = useTrackWithFlags();
  const {
    showToast
  } = useContext(ToastContext);
  const {
    onRemoveItem,
    onUpdateItem,
    isLoadingRemoveItem
  } = useCartMutations();
  const {
    menuLineItems,
    additionalLineItems,
    totalLineItem
  } = useCartReceiptLines(cartReceipt);
  const {
    cashRewardLoyalty
  } = useLoyaltyContext();
  const onUpdateItemQuantity = (item: CartMenuItem | undefined, quantity: number) => {
    if (!cart.id || !item) return;
    if (quantity === 0) {
      setItemToRemove(item);
      return;
    }
    onUpdateItem(cart.id, item.external_id, {
      ...item,
      quantity
    }).then(() => {
      trackUpdateItem(item, cart.location);
      showToast({
        variant: "success",
        description: "Item updated"
      });
    });
  };
  const removeItem = async (cart: Cart, item: CartMenuItem) => {
    try {
      if (!cart.id) return Promise.reject();
      await onRemoveItem(cart.id, item.external_id);
      trackRemoveItem(item, cart.location);
      showToast({
        variant: "success",
        description: "Item removed"
      });
    } catch {
      showToast({
        variant: "error",
        description: "Error removing item"
      });
    }
  };
  const isLastCartItem = cart.items.length === 1;
  return <>
      <div className={classNames("p-6", className)}>
        <Header className="text-text-secondary mb-6 sm:hidden" h4 data-sentry-element="Header" data-sentry-source-file="Receipt.tsx">
          Order summary
        </Header>
        {showCartLineItems ? <ul className="flex flex-col gap-6 mb-6">
            {menuLineItems.length ? menuLineItems.map(lineItem => {
          const cartItem = cart.items.find(item => item.external_id === lineItem.external_id);
          if (!cartItem) return null;
          return <ReceiptCartItem key={`receipt-cart-item-${lineItem.external_id}`} item={lineItem} cartItem={cartItem} stores={storesMap} removeItem={() => removeItem(cart, cartItem)} canMutateItem={canMutateCart} onUpdateQuantity={onUpdateItemQuantity} onRemoveClick={item => setItemToRemove(item)} onModifyClick={item => setItemInFocus(item)} />;
        }) : <Text large className="w-full text-center">
                No items found in cart
              </Text>}
          </ul> : null}

        {canMutateCart && isCheckout && <div className="rounded-xl border border-border-secondary gap-8 p-4 mb-6">
            <PromoCode onApplyPromoCode={onApplyPromoCode} loadingApplyCoupon={loadingApplyCoupon} />
            {(cashRewardLoyalty?.cash_rewards_available ?? 0) > 0 && <>
                <div className="mb-6" />
                <CashRewardRedeemToggle />
              </>}
          </div>}

        <ul className="flex flex-col gap-2">
          {additionalLineItems.map(lineItem => <ReceiptLineItem item={lineItem} setTooltipItem={setTooltipModalItem} key={`receipt-line-item-${lineItem.label}`} />)}
        </ul>
        {onTipChange && <div className="hidden lg:block">
            <Divider />
            <Tip receipt cart={cart} onTipChange={onTipChange} initialTipRate={cart.tip_rate ?? 0} displayCustomTipModal={displayCustomTipModal} />
          </div>}
        {!!mandatoryAdvancedScheduling?.copy && setShowLargeOrderModal && <>
            <Divider />
            <LargeOrders copy={mandatoryAdvancedScheduling.copy} minutes={mandatoryAdvancedScheduling.minutes} setShowLargeOrderModal={setShowLargeOrderModal} />
          </>}
        {totalLineItem && <>
            <CheckoutDivider bold />
            <div className="w-full flex flex-row justify-between">
              <Header h3>{totalLineItem.label}</Header>
              <Header h3>{totalLineItem.display_amount}</Header>
            </div>
          </>}
      </div>
      {/* Modals */}
      <ConfirmRemoveModal showModal={!!itemToRemove} isLastItem={isLastCartItem} isLoading={isLoadingRemoveItem} closeModal={() => {
      setItemToRemove(undefined);
    }} onConfirm={() => {
      if (!itemToRemove?.external_id || !cart.id) return Promise.reject();
      return onRemoveItem(cart.id, itemToRemove.external_id).then(() => {
        trackRemoveItem(itemToRemove, cart.location);
        setItemToRemove(undefined);
        showToast({
          variant: "success",
          description: "Item removed"
        });
      }).catch(() => {
        showToast({
          variant: "error",
          description: "Error removing item"
        });
      });
    }} data-sentry-element="ConfirmRemoveModal" data-sentry-source-file="Receipt.tsx" />
    </>;
};
export default Receipt;