import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { Text } from "../../../ui/Typography";
import { classNames } from "../../../utils/classNames";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { ContactUs } from "../../Checkout/components/modals/LargeOrderModal";
type LargeOrdersProps = {
  copy?: string;
  minutes?: number;
  className?: string;
  setShowLargeOrderModal: (show: boolean) => void;
};
export function LargeOrders({
  copy,
  minutes,
  setShowLargeOrderModal,
  className
}: LargeOrdersProps) {
  const showSchedulingNotice = !!copy && !!minutes;
  const {
    track
  } = useTrackWithFlags();
  useEffect(() => {
    if (showSchedulingNotice) {
      track({
        event: "Viewed Large Order Notice",
        properties: {
          source: "cart",
          copy,
          minutes
        }
      });
    }
  }, [copy, minutes]);
  return <section id="large-orders" data-sentry-component="LargeOrders" data-sentry-source-file="LargeOrders.tsx">
      {showSchedulingNotice ? <button type="button" onClick={() => setShowLargeOrderModal(true)} className={classNames("cursor-pointer border py-2 px-4 text-sm rounded-lg flex flex-row items-center justify-between gap-x-4 bg-new-yellow-lighten-3 border-new-warning", className)}>
          <Text large component="p" className="text-left">
            {`That's an epic order! Orders this large must be scheduled at least ${copy}. If you'd like to receive your order sooner, please `}
            <ContactUs copy={copy} />.
          </Text>
          <QuestionMarkCircleIcon className="h-20 w-20 rounded-full text-black" />
        </button> : null}
    </section>;
}