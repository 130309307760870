import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { CART_RECEIPT } from "./useCartReceipt";

const APPLY_PROMO_CODE = gql`
  mutation applyPromoCode($apply_promo_code_input: ApplyPromoCodeInput) {
    apply_promo_code(apply_promo_code_input: $apply_promo_code_input) {
      id
      coupon {
        id
        name
        code
        discount_rate
        max_amount
        credits
      }
    }
  }
`;

export const useApplyPromoCode = () => {
  const { cart } = useCartContext();
  const { track } = useTrackWithFlags();

  const [applyPromoCode, { loading, error }] = useMutation(APPLY_PROMO_CODE, {
    refetchQueries: cart?.id
      ? [
          {
            query: CART_RECEIPT,
            variables: {
              id: cart?.id,
            },
          },
        ]
      : undefined,
  });

  const onApplyPromoCode = useCallback(
    async (code: string) => {
      track({
        event: "Promo Code Applied",
        properties: {
          code,
          coupon_id: cart?.coupon?.id,
        },
      });
      await applyPromoCode({
        variables: {
          apply_promo_code_input: {
            code,
            cart_id: cart.id,
          },
        },
      });
    },
    [cart?.id, applyPromoCode, cart?.coupon?.id, track],
  );

  return { onApplyPromoCode, loading, error };
};
