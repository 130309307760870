import { gql, useMutation } from "@apollo/client";
import { PickupType } from "@graphql/types";
import { useCallback, useContext } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { useUpdateCartMutation } from "../../../graphql/updateCart.mutation.generated";
import { checkLimit } from "../../../utils/cart";
import { useLocalStorage } from "../../../utils/hooks/useLocalStorage";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { parsePhoneNumberWithoutCountryCode } from "../../../utils/phoneNumber";
import {
  GroupOrderContext,
  useGroupOrderContext,
} from "../../GroupOrder/context/GroupOrderContext";
import { CART_RECEIPT } from "../../OrderDetail/hooks/useCartReceipt";
import { CartMenuItem } from "../types";
import { AVAILABLE_ORDER_TIMES_QUERY } from "./useAvailableOrderTimes";

const ADD_ITEM = gql`
  mutation addItem($input: AddItemInput) {
    add_item(input: $input) {
      id
      location
      items
      participants {
        id
        first_name
        last_name
        phone_number
        items
        customer_id
        is_creator
        items
      }
      coupon {
        id
        name
        code
        discount_rate
        max_amount
        credits
        brand_attrs
      }
      validation {
        is_items_valid
        is_order_time_valid
        is_store_status_valid
        is_promo_valid
        invalid_items {
          id
          external_id
          is_active
          is_store_active
          name
          price
          quantity
          modifier_items {
            id
            is_active
            name
            price
            quantity
          }
        }
      }
    }
  }
`;

const REMOVE_ITEM = gql`
  mutation removeItem($input: RemoveItemInput) {
    remove_item(input: $input) {
      id
      items
      participants {
        id
        first_name
        last_name
        phone_number
        items
        customer_id
        is_creator
        items
      }
      coupon {
        id
        name
        code
        discount_rate
        max_amount
        credits
        brand_attrs
      }
      validation {
        is_items_valid
        is_order_time_valid
        is_store_status_valid
        is_promo_valid
        invalid_items {
          id
          external_id
          is_active
          is_store_active
          name
          price
          quantity
          modifier_items {
            id
            is_active
            name
            price
            quantity
          }
        }
      }
    }
  }
`;

const UPDATE_ITEM = gql`
  mutation updateItem($input: UpdateItemInput) {
    update_item(input: $input) {
      id
      items
      participants {
        id
        first_name
        last_name
        phone_number
        items
        customer_id
        is_creator
        items
      }
      coupon {
        id
        name
        code
        discount_rate
        max_amount
        credits
        brand_attrs
      }
      validation {
        is_items_valid
        is_order_time_valid
        is_store_status_valid
        is_promo_valid
        invalid_items {
          id
          external_id
          is_active
          is_store_active
          name
          price
          quantity
          modifier_items {
            id
            is_active
            name
            price
            quantity
          }
        }
      }
    }
  }
`;

export type onUpdateCartProps = {
  id: string;
  phoneNumber?: string;
  orderTime?: string | null;
  firstName?: string;
  lastName?: string;
  location?: string;
  isScheduled?: boolean;
  isDelivery?: boolean;
  deliveryAddressId?: string;
  email?: string;
  status?: string;
  tipRate?: number;
  tip?: number;
  useBag?: boolean;
  pickupType?: PickupType;
};

export type CreateCartProps = {
  phone_number?: string;
  order_time?: string;
  firstName?: string;
  lastName?: string;
  location?: string;
  is_scheduled?: boolean;
  is_delivery?: boolean;
  delivery_address_id?: string;
  stripe_payment_intent_id?: string;
};

export const useCartMutations = () => {
  const { cart } = useCartContext();
  const { setMessage, setShowMessageModal } = useContext(GroupOrderContext);
  const { getGroupOrderTrackProperties } = useGroupOrderContext();
  const [participantId] = useLocalStorage("lfg_participant_id", "");
  const { track } = useTrackWithFlags();
  const [addItem, { loading: isLoadingAddItem }] = useMutation(ADD_ITEM);
  const [removeItem, { loading: isLoadingRemoveItem }] =
    useMutation(REMOVE_ITEM);
  const [updateItem, { loading: isLoadingUpdateItem }] =
    useMutation(UPDATE_ITEM);

  const [updateCart, { loading: isLoadingUpdateCart }] = useUpdateCartMutation({
    refetchQueries: cart?.id
      ? [
          {
            query: CART_RECEIPT,
            variables: {
              id: cart?.id,
            },
          },
        ]
      : [],
  });

  const onAddItem = async (
    cartId: string,
    location_slug: string,
    item: CartMenuItem | CartMenuItem[],
    groupOrderParticipantId?: string,
  ) => {
    if (checkLimit(item, cart, participantId, setMessage)) {
      setShowMessageModal(true);
      return { spendLimitExceeded: true };
    }
    return addItem({
      variables: {
        input: {
          cart_id: cartId,
          item: JSON.stringify(item),
          location_slug,
          group_order_participant_id: groupOrderParticipantId,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CART_RECEIPT,
          variables: {
            id: cartId,
          },
        },
        {
          query: AVAILABLE_ORDER_TIMES_QUERY,
          variables: {
            id: cartId,
          },
        },
      ],
    });
  };

  const onRemoveItem = async (cartId: string, externalId: string) => {
    return removeItem({
      variables: {
        input: {
          cart_id: cartId,
          external_id: externalId,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CART_RECEIPT,
          variables: {
            id: cartId,
          },
        },
        {
          query: AVAILABLE_ORDER_TIMES_QUERY,
          variables: {
            id: cartId,
          },
        },
      ],
    });
  };

  const onUpdateItem = async (
    cartId: string,
    externalId: string,
    item: CartMenuItem,
  ) => {
    if (checkLimit(item, cart, participantId, setMessage)) {
      setShowMessageModal(true);
      return { spendLimitExceeded: true };
    } else {
      return updateItem({
        variables: {
          input: {
            cart_id: cartId,
            external_id: externalId,
            item: JSON.stringify(item),
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: CART_RECEIPT,
            variables: {
              id: cartId,
            },
          },
          {
            query: AVAILABLE_ORDER_TIMES_QUERY,
            variables: {
              id: cartId,
            },
          },
        ],
      });
    }
  };

  const onUpdateIsDelivery = async (id: string, is_delivery: boolean) => {
    track({
      event: "Cart Delivery Updated",
      properties: {
        id,
        is_delivery,
        ...getGroupOrderTrackProperties(),
      },
    });
    return updateCart({
      variables: {
        input: {
          id,
          is_delivery,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CART_RECEIPT,
          variables: {
            id,
          },
        },
        {
          query: AVAILABLE_ORDER_TIMES_QUERY,
          variables: {
            id,
          },
        },
      ],
    });
  };

  const onUpdateCartDeliveryDetails = async (
    id: string,
    isDelivery: boolean,
    deliveryAddressId?: string | null,
  ) => {
    track({
      event: "Cart Delivery Details Updated",
      properties: {
        id,
        is_delivery: isDelivery,
        delivery_address_id: deliveryAddressId,
        ...getGroupOrderTrackProperties(),
      },
    });
    return updateCart({
      variables: {
        input: {
          id,
          is_delivery: isDelivery,
          delivery_address_id: deliveryAddressId ?? "",
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CART_RECEIPT,
          variables: {
            id,
          },
        },
        {
          query: AVAILABLE_ORDER_TIMES_QUERY,
          variables: {
            id,
          },
        },
      ],
    });
  };

  // TODO clean this up so we can reuse for any subset of params
  const onUpdateCart = useCallback(
    async ({
      id,
      phoneNumber,
      orderTime,
      isScheduled,
      isDelivery,
      firstName,
      lastName,
      location,
      deliveryAddressId,
      email,
      status,
      tipRate,
      tip,
      useBag,
      pickupType,
    }: onUpdateCartProps) => {
      const phone = phoneNumber
        ? parsePhoneNumberWithoutCountryCode(phoneNumber)
        : undefined;
      track({
        event: "Cart Updated",
        properties: {
          id,
          phone,
          order_time: orderTime,
          firstName,
          lastName,
          location,
          is_scheduled: isScheduled,
          is_delivery: isDelivery,
          delivery_address_id: deliveryAddressId,
          email,
          status,
          tip_rate: tipRate,
          pickupType,
          tip,
          use_bag: useBag,
          ...getGroupOrderTrackProperties(),
        },
      });
      return await updateCart({
        variables: {
          input: {
            id,
            phone_number: phone,
            // Null value is required to remove the order time
            // But since we typed the code by hand, we didn't include null as a possible value
            // TODO: Once we migrate to fully generated types, remove this
            // @ts-ignore
            order_time: orderTime,
            first_name: firstName,
            last_name: lastName,
            location,
            is_scheduled: isScheduled,
            is_delivery: isDelivery,
            delivery_address_id: deliveryAddressId,
            email,
            status,
            tip_rate: tipRate,
            tip,
            use_bag: useBag,
            pickup_type: pickupType,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: CART_RECEIPT,
            variables: {
              id,
            },
          },
          {
            query: AVAILABLE_ORDER_TIMES_QUERY,
            variables: {
              id,
            },
          },
        ],
      });
    },
    [getGroupOrderTrackProperties, updateCart],
  );

  const isLoading =
    isLoadingAddItem ||
    isLoadingRemoveItem ||
    isLoadingUpdateItem ||
    isLoadingUpdateCart;

  return {
    onAddItem,
    onRemoveItem,
    onUpdateItem,
    onUpdateCart,
    onUpdateIsDelivery,
    onUpdateCartDeliveryDetails,
    isLoadingAddItem,
    isLoadingRemoveItem,
    isLoadingUpdateItem,
    isLoadingUpdateCart,
    isLoading,
  };
};
