import { gql, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import useDebounce from "../../../utils/hooks/useDebounce";
import { Cart, CartReceipt, CartReceiptLineItem } from "../../Cart/types";

export const WEB_PROVIDER_ID = 6;

export const CART_RECEIPT = gql`
  query CART_RECEIPT($id: String!) {
    cart_receipt(id: $id) {
      id
      coupon {
        id
      }
      line_items {
        line_item_type
        label
        amount
        display_amount
        ordinal
        external_id
        quantity
        slashed_amount
        modifier_text
        tooltip_description
        include_on_cart_receipt
        is_free
      }
    }
  }
`;

type CartReceiptData = {
  cartReceipt: CartReceipt | undefined;
  loading: boolean;
  total: number;
  subtotal: number;
  tax: number;
  onRefetch: () => void;
};

export const useCartReceipt = (cart: Cart): CartReceiptData => {
  const { data, refetch, loading } = useQuery(CART_RECEIPT, {
    skip: !cart?.id,
    variables: {
      id: cart?.id,
      provider_id: WEB_PROVIDER_ID,
    },
    fetchPolicy: "network-only",
  });

  const total = useMemo(() => {
    return data?.cart_receipt?.line_items?.find(
      (item: CartReceiptLineItem) => item.label === "Total",
    )?.amount;
  }, [data]);

  const subtotal = useMemo(() => {
    return data?.cart_receipt?.line_items?.find(
      (item: CartReceiptLineItem) => item.label === "Subtotal",
    )?.amount;
  }, [data]);

  const tax = useMemo(() => {
    return data?.cart_receipt?.line_items?.find(
      (item: CartReceiptLineItem) => item.label === "Tax",
    )?.amount;
  }, [data]);

  const onRefetch = useCallback(() => {
    if (cart?.id && typeof refetch === "function") {
      refetch({
        id: cart.id,
        provider_id: WEB_PROVIDER_ID,
      });
    }
  }, [cart, refetch]);

  // We refetch cart every few seconds so we get updates when group order practicipants modify their items
  // but cart_receipt query is only triggered when group order owner modify his items.
  // It leads to inconsistencies between Cart and CartReceipt data.
  // To fix this we need to refetch cart_receipt query when items in cart change.
  // 1. Calculate unique key for items in cart
  // 2. Use debounce to call onRefetch when items in cart change
  const itemsReceiptKey = useMemo(() => {
    const itemIds =
      cart?.items
        ?.map(
          (item) =>
            `${item.quantity} ${item.id} - ${item.modifier_items
              ?.map((modifier) => modifier.id)
              .sort()
              .join(",")}`,
        )
        .sort() || [];
    const participantItemIds =
      cart?.participants
        ?.map(
          (participant) =>
            participant?.items
              ?.map(
                (item) =>
                  `${participant.id} ${item.quantity} ${
                    item.id
                  } - ${item.modifier_items
                    ?.map((modifier) => modifier.id)
                    .sort()
                    .join(",")}`,
              )
              .sort() || [],
        )
        .sort() || [];
    return JSON.stringify([...itemIds, ...participantItemIds]);
  }, [cart?.items, cart?.participants]);

  useDebounce(
    () => {
      if (cart?.is_group_order) {
        onRefetch();
      }
    },
    500,
    [itemsReceiptKey, onRefetch],
  );

  return {
    cartReceipt: data?.cart_receipt,
    total,
    subtotal,
    tax,
    loading,
    onRefetch,
  };
};
