import { isArray } from "lodash";
import { DateTime } from "luxon";
import { Cart, CartMenuItem } from "../features/Cart/types";
import {
  PublicMenuItem,
  PublicModifierItem,
  PublicModifierList,
} from "../features/MenuItemModal/types";
import { PublicStore } from "../features/Stores/types";
import {
  formatDisplayTime,
  getDayOfWeek,
  getIsOpenNow,
  isClosingSoon,
  isClosingSoonLateNight,
} from "./storeHours";
import { usdFormat } from "./usdFormatter";

export const calculateCartQuanity = (cart: Cart): number => {
  let count = 0;

  if (!cart?.items) {
    return count;
  }

  cart.items.forEach((cartItem) => {
    if (cartItem.name !== "Utensils") {
      count += cartItem.quantity;
    }
  });

  return count;
};

export const getTotalItemPrice = <
  T extends {
    price: number;
    modifier_items: { quantity?: number; price: number }[];
  },
>(
  item?: T,
): number => {
  if (!item) return 0;

  return (
    item.price +
    item.modifier_items
      .map((modfier) =>
        modfier.quantity ? modfier.quantity * modfier.price : 0,
      )
      .reduce((sum, price) => sum + price, 0)
  );
};

export const checkItemValid = (
  menuItem?: PublicMenuItem,
  cartItem?: CartMenuItem,
): boolean => {
  if (!menuItem || !cartItem) {
    return false;
  }

  if (!cartItem.quantity) {
    return false;
  }

  const isInvalid = menuItem.modifier_lists.find((list) => {
    if (list.min === 0) {
      return false;
    }

    return (
      list.modifier_items.reduce((sum, modifier) => {
        const cartModifierItem = cartItem.modifier_items.find(
          (cartModifier) => cartModifier.id === modifier.id,
        );
        const cartModifierItemQuantity = cartModifierItem?.quantity;
        return sum + (cartModifierItemQuantity || 0);
      }, 0) < list.min
    );
  });

  return !isInvalid;
};
export const getInvalidModList = (
  menuItem?: PublicMenuItem,
  cartItem?: CartMenuItem,
): PublicModifierList | undefined => {
  if (!menuItem || !cartItem) {
    return undefined;
  }

  if (!cartItem.quantity) {
    return undefined;
  }

  const invalidModList = menuItem.modifier_lists.find((list) => {
    if (list.min === 0) {
      return false;
    }

    return (
      list.modifier_items.reduce((sum, modifier) => {
        const cartModifierItem = cartItem.modifier_items.find(
          (cartModifier) => cartModifier.id === modifier.id,
        );
        const cartModifierItemQuantity = cartModifierItem?.quantity;
        return sum + (cartModifierItemQuantity || 0);
      }, 0) < list.min
    );
  });

  return invalidModList;
};

export const getIsItemAvailable = (cartItem: CartMenuItem, cart: Cart) => {
  if (cart.validation) {
    const invalidItem = cart.validation.invalid_items?.find(
      (item) => item.id === cartItem.id,
    );
    return invalidItem
      ? invalidItem.is_active && invalidItem.is_store_active
      : true;
  }
  return true;
};

export const getModifierValidationText = (
  cartItem: CartMenuItem,
  cart: Cart,
) => {
  if (cart.validation) {
    const invalidItem = cart.validation.invalid_items?.find(
      (item) => item.id === cartItem.id,
    );
    if (invalidItem?.is_active) {
      const message = invalidItem.modifier_items
        .filter((modifier) => !modifier.is_active)
        .map((modifier: any) => modifier.name)
        .join(", ");
      return message ? `${message} (unavailable)` : "";
    }
  }
  return "";
};

export const getStatusMessageWithoutBrandName = (store: PublicStore | null) => {
  if (store?.is_open && store?.store_hours) {
    if (isClosingSoonLateNight(store)) {
      const yesterdayHours = store.store_hours[getDayOfWeek(-1)].hours[0];
      if (yesterdayHours)
        return `Open until ${formatDisplayTime(yesterdayHours.end_time)}`;
    }

    const hours = store.store_hours[getDayOfWeek()].hours[0];
    if (hours) {
      if (!getIsOpenNow(store)) {
        if (
          hours.start_day === 0 &&
          hours.start_time <= DateTime.now().toFormat("hh:mm")
        ) {
          const tomorrowHours = store.store_hours[getDayOfWeek(1)].hours[0];
          if (tomorrowHours) {
            return `Opens at ${formatDisplayTime(tomorrowHours.start_time)}`;
          }
        }
        return `Opens at ${formatDisplayTime(hours.start_time)}`;
      }
      return `Open until ${formatDisplayTime(hours.end_time)}`;
    }
  }
  return null;
};

export const getStatusMessageWithBrandName = (store: PublicStore | null) => {
  if (store?.is_open && store?.store_hours) {
    if (isClosingSoonLateNight(store)) {
      const yesterdayHours = store.store_hours[getDayOfWeek(-1)].hours[0];
      if (yesterdayHours) {
        return `${store.brand.name} open until ${formatDisplayTime(
          yesterdayHours.end_time,
        )}`;
      }
    }

    const hours = store.store_hours[getDayOfWeek()].hours[0];
    if (hours) {
      if (!getIsOpenNow(store)) {
        if (
          hours.start_day === 0 &&
          hours.start_time <= DateTime.now().toFormat("hh:mm")
        ) {
          const tomorrowHours = store.store_hours[getDayOfWeek(1)].hours[0];
          if (tomorrowHours) {
            return `${store.brand.name} opens at ${formatDisplayTime(
              tomorrowHours.start_time,
            )}`;
          }
        }
        return `${store.brand.name} opens at ${formatDisplayTime(
          hours.start_time,
        )}`;
      }
      if (isClosingSoon(hours.end_time, hours.end_day)) {
        return `${store.brand.name} open until ${formatDisplayTime(
          hours.end_time,
        )}`;
      }
    }
  }
  return null;
};

export const getOpenStatusMessage = (store?: PublicStore | null) => {
  if (store?.is_open && store?.store_hours) {
    const hours = store.store_hours[getDayOfWeek()].hours[0];
    if (!getIsOpenNow(store) && hours) {
      if (
        hours.start_day === 0 &&
        hours.start_time <= DateTime.now().toFormat("hh:mm")
      ) {
        const tomorrowHours = store.store_hours[getDayOfWeek(1)].hours[0];
        if (tomorrowHours) {
          return `Available at ${formatDisplayTime(tomorrowHours.start_time)}`;
        }
      }
      return `Available at ${formatDisplayTime(hours.start_time)}`;
    }
  }
  return null;
};

export const getIsCartItemsValid = (cart: Cart) => {
  return cart.validation ? cart.validation.is_items_valid : true;
};

export const getIsCartPromoValid = (cart: Cart) => {
  return cart.validation ? cart.validation.is_promo_valid : true;
};

export const getIsStoreStatusValid = (cart: Cart) => {
  return cart.validation ? cart.validation.is_store_status_valid : true;
};

export const getUnavailableModifierItems = (menuItem: PublicMenuItem) => {
  return menuItem.modifier_lists
    .reduce(
      (prevVal: PublicModifierItem[], modList: PublicModifierList) => [
        ...prevVal,
        ...modList.modifier_items,
      ],
      [],
    )
    .filter((modifierItem: PublicModifierItem) => !modifierItem.is_active);
};

export const goToCartDetails = (locationSlug: string, id: string) => {
  window.location.href = `${process.env.NEXT_PUBLIC_GUEST_WEB_URL}/order/store/${locationSlug}/order/${id}`;
};

export const getCartDescription = (cart: Cart) =>
  cart.items
    .map((item) => {
      return `${item.quantity} ${item.name}`;
    })
    .join(", ");

const calculatePartcipantItemsExpense = (
  items: CartMenuItem[],
  participantId: string,
  excludeItemId?: string,
) => {
  return (
    items
      .filter((item) => item.name !== "Utensils")
      // exclude a current item that a guest is currently modifying
      .filter((item) =>
        excludeItemId ? item.external_id !== excludeItemId : true,
      )
      .filter((item) => item.group_order_participant_id === participantId)
      .reduce((price, item) => {
        price += getTotalItemPrice(item) * item.quantity;
        return price;
      }, 0)
  );
};

const findPartcipants = (cart: Cart, participantId?: string) => {
  return cart.participants?.find(
    (participant) =>
      !participant.is_creator && participant.id === participantId,
  );
};

export const hasExceededSpendLimit = (
  cart: Cart,
  items: CartMenuItem[],
  participantId: string,
) => {
  if (
    findPartcipants(cart, participantId) &&
    cart &&
    cart.is_group_order &&
    cart.spend_limit
  ) {
    const participantItemsExpense = calculatePartcipantItemsExpense(
      items,
      participantId,
    );
    if (participantItemsExpense / 100 > cart.spend_limit) {
      return true;
    }
  }
  return false;
};

export const checkLimit = (
  item: CartMenuItem | CartMenuItem[],
  cart?: Cart,
  participantId?: string,
  setMessage?: (value: string) => void,
) => {
  if (cart && cart.is_group_order && participantId && cart.spend_limit) {
    const participant = findPartcipants(cart, participantId);

    if (participant && participant.items) {
      const participantItemsExpense = calculatePartcipantItemsExpense(
        participant.items,
        participantId,
        // exclude a current item that a guest is currently modifying
        isArray(item) ? undefined : item.external_id,
      );

      let newItemPrice;
      if (isArray(item)) {
        newItemPrice = item.reduce((total, i) => {
          return total + getTotalItemPrice(i) * i.quantity;
        }, 0);
      } else {
        newItemPrice = getTotalItemPrice(item) * item.quantity;
      }

      if ((participantItemsExpense + newItemPrice) / 100 > cart.spend_limit) {
        const remaining = cart.spend_limit * 100 - participantItemsExpense;
        setMessage?.(
          `You've exceeded the ${usdFormat(
            cart.spend_limit * 100,
          )} limit that is set on this group order. You've only ${usdFormat(
            remaining,
          )} left to spend.`,
        );
        return true;
      }
    }
  }
  return false;
};
