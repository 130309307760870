import { textStyles } from "../../../ui/Typography/Text";
import { classNames } from "../../../utils/classNames";
import { DeliveryAddress } from "../../Cart/types";
type DeliveryAddressDropdownProps = {
  selectedAddress?: DeliveryAddress;
  deliveryAddresses: DeliveryAddress[];
  onSelectAddress: (deliveryAddress: DeliveryAddress) => void;
};
export const DeliveryAddressDropdown = ({
  selectedAddress,
  deliveryAddresses: _deliveryAddresses,
  onSelectAddress
}: DeliveryAddressDropdownProps) => {
  // If selectedAddress is not in deliveryAddresses, then add it to the list (happens in logged out cases)
  const deliveryAddresses = _deliveryAddresses.filter(address => address.id !== selectedAddress?.id).concat(selectedAddress || []);
  return <div className="w-full cursor-pointer" data-sentry-component="DeliveryAddressDropdown" data-sentry-source-file="DeliveryAddressDropdown.tsx">
      <label className="sr-only">Choose delivery address</label>
      <select defaultValue="Select an address" id="delivery-addresses" name="delivery-addresses" className={classNames("border-0 border-b border-border-secondary cursor-pointer bg-transparent block w-full text-text-primary focus:ring-0 focus:border-0 focus:border-border-secondary", textStyles.large)} onChange={e => {
      const selected = deliveryAddresses.find(address => address.id === e.target.value);
      if (selected?.id) {
        onSelectAddress(selected);
      }
    }} value={selectedAddress?.id}>
        <option value={"none"} disabled={Boolean(selectedAddress?.id || selectedAddress?.id === null)} selected={Boolean(!selectedAddress?.id)}>
          Click to select an address...
        </option>
        {deliveryAddresses.map((address: DeliveryAddress, idx: number) => <option className="cursor-pointer" key={`delivery-address-method-${idx}`} value={address.id}>
            {`${address.street}${address.unit ? ` ${address.unit}` : ""}, ${address.city}, ${address.state} `}
          </option>)}
      </select>
    </div>;
};