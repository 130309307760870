import { gql, useMutation } from "@apollo/client";
import { useContext } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { ToastContext } from "../../../app-components/hooks/useToast";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { CART_QUERY } from "../../Cart/hooks/useCartLoader";
import { CART_RECEIPT } from "./useCartReceipt";

const REMOVE_PROMO_CODE = gql`
  mutation removePromoCode($cart_id: String) {
    remove_promo_code(cart_id: $cart_id) {
      id
      coupon {
        id
      }
    }
  }
`;

export const useRemovePromoCode = () => {
  const [removePromoCode, { loading, error }] = useMutation(REMOVE_PROMO_CODE);
  const { track } = useTrackWithFlags();
  const { showToast } = useContext(ToastContext);
  const { cart } = useCartContext();

  const onRemovePromoCode = async () => {
    track({
      event: "Promo Code Removed",
      properties: {
        code: cart?.coupon?.code,
        coupon_id: cart?.coupon?.id,
      },
    });
    return removePromoCode({
      variables: {
        cart_id: cart?.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CART_QUERY,
          variables: { id: cart?.id },
        },
        {
          query: CART_RECEIPT,
          variables: {
            id: cart?.id,
          },
        },
      ],
    })
      .then((result) => {
        showToast({
          description: "Promo code removed.",
          variant: "success",
        });
        return result;
      })
      .catch(() => {
        showToast({
          variant: "error",
          description: "Something went wrong. Please try again.",
        });
      });
  };

  return { onRemovePromoCode, loading, error };
};
