import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { client } from "../../../app-components/ApolloWrapper";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { useToast } from "../../../app-components/hooks/useToast";
import { ShopBackendClient } from "../../../utils/shopBackend/apiClient";
import { useAuth } from "../../Authentication/hooks/useAuth";
import { CART_RECEIPT } from "../../Checkout/hooks/useCartReceipt";
export const useCashRewardStateToggle = () => {
  const {
    cart
  } = useCartContext();
  const {
    isAuthenticated,
    user
  } = useAuth();
  const {
    showToast
  } = useToast();
  const {
    data,
    isPending
  } = useQuery({
    queryKey: ["loyaltyCashRewardState", cart.id, user?.id],
    queryFn: () => ShopBackendClient.loyaltyCashRewardState({
      cart_id: cart.id || ""
    }),
    enabled: Boolean(isAuthenticated && cart?.id),
    retry: 3,
    refetchOnWindowFocus: true
  });
  const [isCashRewardRedeemEnabled, setCashRewardRedeemState] = useState(data?.data?.is_applied || false);
  useEffect(() => {
    setCashRewardRedeemState(data?.data?.is_applied || false);
  }, [data?.data?.is_applied, setCashRewardRedeemState]);
  const refetchCartReceipt = () => {
    client.refetchQueries({
      include: [CART_RECEIPT]
    });
  };
  const {
    mutate: applyCashReward,
    isPending: isApplyCashRewardPending
  } = useMutation({
    mutationFn: ShopBackendClient.loyaltyApplyCashReward,
    onSettled: (data, error) => {
      if (data?.response.ok && data?.data) {
        setCashRewardRedeemState(data.data.is_applied);
        refetchCartReceipt();
        return;
      }
      showToast({
        variant: "error",
        description: "Unexpected error occurred. Please try again."
      });
      throw new Error(error?.message || "Unknown error");
    }
  });
  const {
    mutate: removeCashReward,
    isPending: isRemoveCashRewardPending
  } = useMutation({
    mutationFn: ShopBackendClient.loyaltyRemoveCashReward,
    onSettled: (data, error) => {
      if (data?.response.ok && data?.data) {
        setCashRewardRedeemState(data.data.is_applied);
        refetchCartReceipt();
        return;
      }
      showToast({
        variant: "error",
        description: "Unexpected error occurred. Please try again."
      });
      throw new Error(error?.message || "Unknown error");
    }
  });
  const toggleCashRewardRedeem = useCallback(() => {
    if (isCashRewardRedeemEnabled) {
      removeCashReward({
        cart_id: cart.id || ""
      });
    } else {
      applyCashReward({
        cart_id: cart.id || ""
      });
    }
  }, [isCashRewardRedeemEnabled, removeCashReward, applyCashReward, cart.id]);
  return {
    isCashRewardRedeemEnabled,
    toggleCashRewardRedeem,
    loading: isPending || isRemoveCashRewardPending || isApplyCashRewardPending
  };
};