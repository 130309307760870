import { PlusIcon } from "@heroicons/react/24/outline";
import { Link } from "@ui/Typography";
type Props = {
  onPress: () => void;
  disabled?: boolean;
};
export function NewAddressButton({
  onPress,
  disabled
}: Props) {
  return <Link onClick={onPress} size="small" className="hover:opacity-80 !font-semibold" underLine="solid" disabled={disabled} data-sentry-element="Link" data-sentry-component="NewAddressButton" data-sentry-source-file="NewAddressButton.tsx">
      <div className="flex items-center">
        <PlusIcon className="text-lfg-primary h-6 w-6 mr-1" data-sentry-element="PlusIcon" data-sentry-source-file="NewAddressButton.tsx" />
        New address
      </div>
    </Link>;
}