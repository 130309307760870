import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoredPaymentMethodsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StoredPaymentMethodsQuery = { __typename?: 'Query', stored_payment_methods?: Array<{ __typename?: 'StoredPaymentMethod', id?: number | undefined, stripe_payment_method_id?: string | undefined, payment_type?: string | undefined, last4?: string | undefined, name?: string | undefined, brand?: string | undefined, is_default?: boolean | undefined } | undefined> | undefined };


export const StoredPaymentMethodsDocument = gql`
    query StoredPaymentMethods {
  stored_payment_methods {
    id
    stripe_payment_method_id
    payment_type
    last4
    name
    brand
    is_default
  }
}
    `;

/**
 * __useStoredPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useStoredPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoredPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoredPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoredPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<StoredPaymentMethodsQuery, StoredPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoredPaymentMethodsQuery, StoredPaymentMethodsQueryVariables>(StoredPaymentMethodsDocument, options);
      }
export function useStoredPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoredPaymentMethodsQuery, StoredPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoredPaymentMethodsQuery, StoredPaymentMethodsQueryVariables>(StoredPaymentMethodsDocument, options);
        }
export type StoredPaymentMethodsQueryHookResult = ReturnType<typeof useStoredPaymentMethodsQuery>;
export type StoredPaymentMethodsLazyQueryHookResult = ReturnType<typeof useStoredPaymentMethodsLazyQuery>;
export type StoredPaymentMethodsQueryResult = Apollo.QueryResult<StoredPaymentMethodsQuery, StoredPaymentMethodsQueryVariables>;