import { useCallback, useContext } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { useCartMutations } from "../../Cart/hooks/useCartMutations";
import { ToastContext } from "./../../../app-components/hooks/useToast";

const useOnUpdateUseBag = (
  setClientSideBagIndicator: (useBag: boolean) => void,
) => {
  const { cart } = useCartContext();
  const { onUpdateCart } = useCartMutations();
  const { showToast } = useContext(ToastContext);

  return useCallback(
    async (useBag: boolean) => {
      if (cart?.id) {
        try {
          await onUpdateCart({ id: cart.id, useBag });
        } catch (err) {
          showToast({
            variant: "error",
            description: "Failed to update cart",
          });
          setClientSideBagIndicator(!useBag);
        }
      }
    },
    [cart.id, onUpdateCart, showToast, setClientSideBagIndicator],
  );
};

export default useOnUpdateUseBag;
