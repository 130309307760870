import { CheckIcon, ChevronRightIcon, CreditCardIcon } from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { NewModal } from "../../../ui/NewModal/Modal";
import { GroupOrderContext } from "../context/GroupOrderContext";
import { classNames } from "@utils/classNames";
interface ConfirmationModalBodyProps {
  setTempLimit: Dispatch<SetStateAction<number | undefined>>;
  setCurrentSection: Dispatch<SetStateAction<string>>;
  tempLimit?: number;
  currentSection: string;
}
interface ConfirmGroupOrderModalProps {
  handleConfirm: (limit?: number) => Promise<any>;
  loading: boolean;
}
const sections = {
  CUSTOMER_INFORMATION: "CUSTOMER_INFORMATION",
  START_GROUP_ORDER: "START_GROUP_ORDER",
  SPENDING_LIMIT: "SPENDING_LIMIT"
};
const ConfirmationModalBody = ({
  tempLimit,
  setTempLimit,
  setCurrentSection,
  currentSection
}: ConfirmationModalBodyProps) => {
  const [showInputValue, setShowInputValue] = useState(false);
  const [otherInputValue, setOtherInputValue] = useState("");
  const handleLimitChange = (newLimit?: number) => () => {
    setTempLimit(newLimit);
    setShowInputValue(false);
  };
  const spendLimitVisible = useMemo(() => {
    return currentSection === sections.SPENDING_LIMIT;
  }, [currentSection]);
  const transitionClass = useMemo(() => {
    return currentSection === sections.SPENDING_LIMIT ? "-translate-x-full" : "translate-x-0";
  }, [currentSection]);
  const goToSpendLimit = () => {
    setCurrentSection(sections.SPENDING_LIMIT);
  };
  const NonDigits = new RegExp("[a-zA-Z].*");
  return <div className="position-relative flex w-screen sm:w-124 overflow-hidden" data-sentry-component="ConfirmationModalBody" data-sentry-source-file="ConfirmGroupOrderModal.tsx">
      <div className={classNames(`position-absolute inset-0 flex items-start justify-start
          transition-all ease-in-out duration-1000 transform slide`, transitionClass)}>
        <div className={`w-screen px-8 sm:px-0 sm:w-124 flex-1 justify-start
           transition-all ease-in-out duration-500 ${spendLimitVisible ? "opacity-0" : "opacity-100"}`}>
          <h3 className="font-semibold text-2.5xl mb-1 text-left">
            Start your group order
          </h3>
          <p className="text-base text-text-secondary font-light text-left">
            {`Easily share your order link with friends & family. \nThey'll pick, you'll pay, and everyone's meal will be in one easy`}
            order!
          </p>
          <div className="my-8">
            <div className="border-t border-border-secondary opacity-50 w-full" />
            <button className={`flex justify-between items-center cursor-pointer h-20 w-full`} onClick={goToSpendLimit}>
              <CreditCardIcon className="h-6 w-6" aria-hidden="true" data-sentry-element="CreditCardIcon" data-sentry-source-file="ConfirmGroupOrderModal.tsx" />
              <div className="mx-4 flex flex-1 flex-col items-start">
                {tempLimit ? <>
                    <p className="text-md text-left">{`$${tempLimit} per person spending limit`}</p>
                  </> : <>
                    <p className="text-md text-left">
                      Set a per person spending limit
                    </p>
                    <p className="text-sm text-text-secondary font-light text-left">
                      No limit set
                    </p>
                  </>}
              </div>

              <ChevronRightIcon className="h-6 w-6" aria-hidden="true" data-sentry-element="ChevronRightIcon" data-sentry-source-file="ConfirmGroupOrderModal.tsx" />
            </button>
            <div className="border-t border-border-secondary opacity-50 w-full" />
            <div className="flex py-6">
              <CheckIcon className="h-6 w-6" aria-hidden="true" data-sentry-element="CheckIcon" data-sentry-source-file="ConfirmGroupOrderModal.tsx" />
              <p className="ml-4 text-md flex-2">
                {"You'll pay for the order"}
              </p>
            </div>
            <div className="border-t border-border-secondary opacity-50 w-full" />
          </div>
        </div>
      </div>
      <div className={classNames(`position-absolute inset-0 flex items-center justify-start 
        transition-all ease-in-out duration-1000 transform slide`, transitionClass)}>
        <div className={classNames(`w-screen px-8 sm:px-0 sm:w-124 flex-1 h-full
            transition-all ease-in-out duration-500`, spendLimitVisible ? "opacity-100" : "opacity-0")}>
          <h3 className="font-semibold text-2.5xl mb-1 mt-4 text-left">
            Set your per person spending limit
          </h3>
          <div className="my-8">
            <div className="flex rounded-md shadow-sm mt-2 h-14 w-full" role="group">
              <button type="button" className={classNames(`flex flex-2 items-center py-2 px-4 text-sm font-medium rounded-l-lg border
                 border-border-secondary hover:bg-button-outlined-secondary_hover
                  focus:bg-black focus:text-white`, typeof tempLimit === "undefined" && !showInputValue ? "bg-lfg-primary text-white" : "text-gray-550 bg-transparent")} onClick={handleLimitChange(undefined)}>
                No Limit
              </button>
              <button type="button" className={classNames(`flex flex-1 items-center justify-center py-2 px-4 text-sm
                  font-medium border-t border-b border-r border-border-secondary
                hover:bg-button-outlined-secondary_hover focus:bg-lfg-primary focus:text-white`, tempLimit === 15 && !showInputValue ? "bg-lfg-primary text-white" : "text-gray-550 bg-transparent")} onClick={handleLimitChange(15)}>
                $15
              </button>
              <button type="button" className={classNames(`flex flex-1 items-center justify-center py-2 px-4 text-sm font-medium border-t
                  border-b border-r border-border-secondary hover:bg-button-outlined-secondary_hover
                  focus:bg-lfg-primary focus:text-white`, tempLimit === 25 && !showInputValue ? "bg-lfg-primary text-white" : "text-gray-550 bg-transparent")} onClick={handleLimitChange(25)}>
                $25
              </button>
              <button type="button" className={classNames(`flex flex-1 items-center justify-center py-2 px-4 text-sm font-medium
                  border-t border-b border-border-secondary hover:bg-button-outlined-secondary_hover
                  focus:bg-lfg-primary focus:text-white`, tempLimit === 50 && !showInputValue ? "bg-lfg-primary text-white" : "text-gray-550 bg-transparent")} onClick={handleLimitChange(50)}>
                $50
              </button>
              <button type="button" className={classNames(`flex flex-1 items-center justify-center py-2 px-4 text-sm font-medium rounded-r-md
                  border border-border-secondary hover:bg-button-outlined-secondary_hover
                  focus:bg-lfg-primary focus:text-white`, showInputValue ? "bg-lfg-primary text-white" : "text-gray-550 bg-transparent")} onClick={() => {
              setTempLimit(undefined);
              setOtherInputValue("");
              setShowInputValue(true);
            }}>
                Other
              </button>
            </div>

            {showInputValue && <div className="mt-4">
                <div className="flex justify-center sm:justify-start border border-border-secondary rounded-md overflow-hidden focus:ring">
                  <span className="inline-flex items-center pl-4 pr-2 text-md text-text-secondary">
                    $
                  </span>
                  <input type="text" value={otherInputValue} id="limit" className="bg-surface-lighter text-text-secondary text-sm border-0 focus:ring-0 block p-2 h-14 flex-1 placeholder-text-placeholder placeholder-opacity-50" placeholder="Enter amount" onChange={e => {
                if (e.target.value && !NonDigits.test(e.target.value)) {
                  setOtherInputValue(e.target.value);
                  setTempLimit(Math.round(Math.ceil(parseFloat(e.target.value))));
                } else {
                  setOtherInputValue("");
                }
              }} />
                  <span className="font-normal text-text-secondary  self-center pr-4">
                    per person
                  </span>
                </div>
              </div>}
          </div>
        </div>
      </div>
    </div>;
};
interface SectionProps {
  cancelMessage: string;
  successMessage: string;
  showBack?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  onCloseComplete?: () => void;
}
const ConfirmGroupOrderModal = ({
  handleConfirm,
  loading
}: ConfirmGroupOrderModalProps) => {
  const {
    showConfirmationModal,
    setShowConfirmationModal
  } = useContext(GroupOrderContext);
  const [tempLimit, setTempLimit] = useState<number | undefined>();
  const sectionProps = useMemo(() => {
    return {
      [sections.CUSTOMER_INFORMATION]: {
        cancelMessage: "Nevermind",
        successMessage: "Invite Guests",
        onConfirm: () => {},
        onCancel: () => {},
        onClose: () => {
          setShowConfirmationModal(false);
        },
        onCloseComplete: () => {
          setShowConfirmationModal(false);
        }
      },
      [sections.START_GROUP_ORDER]: {
        cancelMessage: "Nevermind",
        successMessage: "Invite Guests",
        onConfirm: async () => {
          await handleConfirm(tempLimit).catch((err: any) => console.log("ERROR:", err));
        },
        onCancel: () => {},
        onClose: () => {
          setShowConfirmationModal(false);
        },
        onCloseComplete: () => {
          setTempLimit(undefined);
          setShowConfirmationModal(false);
        }
      },
      [sections.SPENDING_LIMIT]: {
        cancelMessage: "Cancel",
        successMessage: "Save",
        onConfirm: () => {
          setCurrentSection(sections.START_GROUP_ORDER);
        },
        onCancel: () => {
          setTempLimit(undefined);
          setCurrentSection(sections.START_GROUP_ORDER);
        },
        onClose: () => {
          setTempLimit(undefined);
          setCurrentSection(sections.START_GROUP_ORDER);
          setShowConfirmationModal(false);
        },
        onCloseComplete: () => {},
        showBack: true
      }
    };
  }, [tempLimit, handleConfirm]);
  const [currentSection, setCurrentSection] = useState<string>(sections.START_GROUP_ORDER);
  const [currentSectionProps, setCurrentSectionProps] = useState<SectionProps>(sectionProps[currentSection]);
  useEffect(() => {
    setCurrentSectionProps(sectionProps[currentSection]);
  }, [currentSection, sectionProps]);
  return <NewModal overlayClassName="backdrop-blur" header="Group order" body={<ConfirmationModalBody tempLimit={tempLimit} setTempLimit={setTempLimit} setCurrentSection={setCurrentSection} currentSection={currentSection} />} show={showConfirmationModal} cancelMessage={currentSectionProps.cancelMessage} confirmMessage={currentSectionProps.successMessage} onConfirm={currentSectionProps.onConfirm} isConfirmLoading={loading} onCancel={currentSectionProps.onCancel} onClose={currentSectionProps.onClose} onBlur={currentSectionProps.onClose} onCloseComplete={currentSectionProps.onCloseComplete} hasConfirm hasCancel hasClose hasBack={currentSectionProps.showBack} data-sentry-element="NewModal" data-sentry-component="ConfirmGroupOrderModal" data-sentry-source-file="ConfirmGroupOrderModal.tsx" />;
};
export default ConfirmGroupOrderModal;