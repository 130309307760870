import { useAuth } from "../../features/Authentication/hooks/useAuth";
import { classNames } from "../../utils/classNames";
interface ProfileProps {
  className?: string;
}
const Profile = ({
  className
}: ProfileProps) => {
  const {
    user
  } = useAuth();
  if (!user) {
    return null;
  }
  const initials = user.first_name || user.last_name ? `${user.first_name.slice(0, 1)}${user.last_name.slice(0, 1)}` : user.email?.slice(0, 2);
  return <div className={classNames("flex justify-center items-center bg-surface-lighter ont-lfg-semibold rounded-full h-8 w-8 relative", className)} data-sentry-component="Profile" data-sentry-source-file="Profile.tsx">
      <h2 className="p-3 text-base text-center uppercase">{initials}</h2>
    </div>;
};
export default Profile;