import { ApolloQueryResult } from "@apollo/client";
import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { PaymentMethodType } from "../../features/Checkout/components/PaymentMethodAccordion";
import { useStoredPaymentMethods } from "../../features/Checkout/hooks/useStoredPaymentMethods";
import { useAuth } from "../../features/Authentication/hooks/useAuth";
import { useUpdatePaymentMethodMutation } from "../../features/ProfilePaymentMethods/hooks/useUpdatePaymentMethodMutation";
import { StoredPaymentMethodsQuery } from "../../graphql/storedPaymentMethods.query.generated";
import { StoredPaymentMethod } from "../../graphql/types";
type PaymentMethodContextType = {
  paymentMethod?: StoredPaymentMethod;
  paymentMethodType: PaymentMethodType;
  setPaymentMethod: (method?: StoredPaymentMethod) => void;
  refetchPaymentMethods: () => Promise<ApolloQueryResult<StoredPaymentMethodsQuery>>;
  setPaymentMethodType: (paymentType: PaymentMethodType) => void;
  storedPaymentMethods: StoredPaymentMethod[];
};
export const PaymentMethodContext = createContext<PaymentMethodContextType>({
  paymentMethodType: "card",
  setPaymentMethod: () => {},
  refetchPaymentMethods: () => Promise.reject({}),
  setPaymentMethodType: () => {},
  storedPaymentMethods: []
});
export function PaymentMethodContextProvider({
  children
}: PropsWithChildren) {
  const {
    user
  } = useAuth();
  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType>("card");
  const [paymentMethod, setPaymentMethod] = useState<StoredPaymentMethod>();
  const {
    storedPaymentMethods,
    refetch: refetchPaymentMethods
  } = useStoredPaymentMethods(user);
  const {
    onUpdatePaymentMethod
  } = useUpdatePaymentMethodMutation();

  // Ensures the state of paymentMethodType is always in sync with the paymentMethod
  useEffect(() => {
    if (paymentMethodType !== "card") {
      setPaymentMethod(undefined);
    }
  }, [paymentMethodType]);
  useEffect(() => {
    if (paymentMethod) {
      setPaymentMethodType("card");
    }
  }, [paymentMethod]);
  useEffect(() => {
    // set initial payment method
    if (!paymentMethod && storedPaymentMethods.length) {
      const defaultPaymentMethod = storedPaymentMethods.find(method => method?.is_default);
      setPaymentMethod(defaultPaymentMethod || storedPaymentMethods[0]);
    }

    // in case selected method was deleted
    if (!storedPaymentMethods?.find(method => method?.id === paymentMethod?.id)) {
      const defaultPaymentMethod = storedPaymentMethods.find(method => method?.is_default);
      setPaymentMethod(defaultPaymentMethod || storedPaymentMethods[0]);
    }
  }, [paymentMethod, storedPaymentMethods]);
  useEffect(() => {
    // set default payment method whenever payment method changes
    if (paymentMethod?.id && !paymentMethod.is_default) {
      onUpdatePaymentMethod(paymentMethod.id, paymentMethod.name, true);
    }
  }, [onUpdatePaymentMethod, paymentMethod]);
  return <PaymentMethodContext.Provider value={{
    paymentMethodType,
    setPaymentMethodType,
    storedPaymentMethods,
    paymentMethod,
    setPaymentMethod,
    refetchPaymentMethods
  }} data-sentry-element="unknown" data-sentry-component="PaymentMethodContextProvider" data-sentry-source-file="PaymentMethodContext.tsx">
      {children}
    </PaymentMethodContext.Provider>;
}