import dynamic from "next/dynamic";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useCookie } from "../../utils/hooks/useCookie";
import { useCartLoader } from "../Cart/hooks/useCartLoader";
import { Cart } from "../Cart/types";
import { OrderType } from "@types";
type CartLoaderProps = {
  setCart: Dispatch<SetStateAction<Cart>>;
};
const CartLoader = ({
  setCart
}: CartLoaderProps) => {
  const {
    data
  } = useCartLoader();
  const {
    updateCookie
  } = useCookie("lfg_defaults", "");
  useEffect(() => {
    if (data?.cart?.location) {
      const customerPreferences = {
        locationSlug: data?.cart?.location,
        orderType: data?.cart.is_delivery ? OrderType.DELIVERY : OrderType.PICKUP
      };
      updateCookie(JSON.stringify(customerPreferences));
    }
  }, [updateCookie, data?.cart?.location, data?.cart?.is_delivery]);
  useEffect(() => {
    if (data?.cart) {
      setCart({
        ...data.cart,
        items: data.cart.items ? data.cart.items.map((item: string) => JSON.parse(item)) : [],
        participants: data.cart.participants ? data.cart.participants.map((participant: any) => ({
          ...participant,
          items: participant.items ? participant.items.map((item: string) => JSON.parse(item)) : []
        })) : []
      });
    }
  }, [data]);
  return null;
};
export default dynamic(() => Promise.resolve(CartLoader), {
  ssr: false
});