import { StoreLocation } from "../features/ChoosePickupDelivery/types";
import { GeocodeDetails } from "../graphql/types";
import { Location, LocationWithDistance } from "../types";

export type Coordinates = { lat: number; lng: number };

const EARTH_RADIUS_IN_MILES = 3958.8;

const getAnglelikeValueFromCoordinates = (
  // function that computes angle between two points on the Earth, to get distance multiply by 2 * R, based on haversine calculation https://en.wikipedia.org/wiki/Haversine_formula
  place1: Coordinates,
  place2: Coordinates,
) => {
  const radLatitude1 = (place1.lat * Math.PI) / 180;
  const radLatitude2 = (place2.lat * Math.PI) / 180;

  const radDeltaLatitude = ((place2.lat - place1.lat) * Math.PI) / 180;
  const radDeltaLongitude = ((place2.lng - place1.lng) * Math.PI) / 180;

  const haversine =
    Math.sin(radDeltaLatitude / 2) * Math.sin(radDeltaLatitude / 2) +
    Math.sin(radDeltaLongitude / 2) *
      Math.sin(radDeltaLongitude / 2) *
      Math.cos(radLatitude1) *
      Math.cos(radLatitude2);

  return Math.atan2(Math.sqrt(haversine), Math.sqrt(1 - haversine));
};

export const sortLocationByDistance = (
  locations: Location[],
  targetCoordinates: Coordinates,
) => {
  if (!targetCoordinates) return locations;

  return locations
    .slice()
    .sort(
      (location1, location2) =>
        getAnglelikeValueFromCoordinates(location1, targetCoordinates) -
        getAnglelikeValueFromCoordinates(location2, targetCoordinates),
    );
};
export const addDistanceToLocation = (
  locations: LocationWithDistance[],
  targetGeocode?: Pick<GeocodeDetails, "lat" | "lng">,
) => {
  if (!targetGeocode) return locations;

  return locations.slice().map((location1) => ({
    ...location1,
    distance: getDistanceFromLocation(location1, targetGeocode),
  }));
};

export const getDistanceFromLocation = (
  coords: Coordinates,
  geocodes: Pick<GeocodeDetails, "lat" | "lng">,
) => {
  if (geocodes?.lat && geocodes?.lng) {
    return Math.ceil(
      Math.sqrt(
        (coords.lat - geocodes.lat) ** 2 + (coords.lng - geocodes.lng) ** 2,
      ) * 69,
    );
  }

  return -1;
};

export const getDistanceBetweenLocations = (
  firstLocation: StoreLocation,
  secondLocation: StoreLocation,
): number => {
  if (
    !(
      firstLocation.lat &&
      firstLocation.lng &&
      secondLocation.lat &&
      secondLocation.lng
    )
  ) {
    return Number.MAX_SAFE_INTEGER;
  }

  const firstCoordinates = {
    lat: firstLocation.lat,
    lng: firstLocation.lng,
  };

  const secondCoordinates = {
    lat: secondLocation.lat,
    lng: secondLocation.lng,
  };

  return (
    getAnglelikeValueFromCoordinates(firstCoordinates, secondCoordinates) *
    2 *
    EARTH_RADIUS_IN_MILES
  );
};
