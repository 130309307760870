import { BrandContent } from "../../../types";
import { getIsOpenNow } from "../../../utils/storeHours";
import {
  getMostPopularItems,
  MOST_POPULAR_TAG,
} from "../../BrandPage/utils/items";
import { PublicMenuCategory, PublicMenuItem } from "../../MenuItemModal/types";
import { PublicStore } from "../types";

export const sortMostPopular = (
  items: PublicMenuItem[],
  stores: PublicStore[],
): PublicMenuItem[] => {
  const brandsRepresented: { [key: string]: boolean } = {};
  const topN: PublicMenuItem[] = [];
  const theRest: PublicMenuItem[] = [];

  items.forEach((item) => {
    if (brandsRepresented[item.brand_slug]) {
      theRest.push(item);
    } else {
      brandsRepresented[item.brand_slug] = true;
      topN.push(item);
    }
  });

  const sorted = [...topN, ...theRest];
  const openNow: PublicMenuItem[] = [];
  const closedNow: PublicMenuItem[] = [];
  const inactive: PublicMenuItem[] = [];

  sorted.forEach((item) => {
    const store = stores.find((s) => s.id === item.store_id);
    if (store && getIsOpenNow(store) && item.is_active) {
      openNow.push(item);
    } else if (item.is_active) {
      brandsRepresented[item.brand_slug] = true;
      closedNow.push(item);
    } else {
      inactive.push(item);
    }
  });

  return [...openNow, ...closedNow, ...inactive];
};

export const enhanceStoresWithPopularCategory = (
  stores: PublicStore[],
  brandsContent: BrandContent[],
) => {
  return stores.map((store) => {
    if (!store) {
      return store;
    }

    const brandContent = brandsContent.find(
      (content) => content.brand_id === store.brand.id,
    );

    let mostPopularCategory: PublicMenuCategory | undefined;

    if (store.is_collection && store.collection_items) {
      mostPopularCategory = {
        name: brandContent ? brandContent.name : "",
        id: brandContent && brandContent.brand_id ? brandContent.brand_id : 0,
        menu_items: sortMostPopular(store.collection_items, stores),
        ordinal: 0,
      };
    } else if (store.most_popular) {
      if (store.brand.id === 0) {
        mostPopularCategory = {
          name: brandContent ? brandContent.name : "",
          id: brandContent && brandContent.brand_id ? brandContent.brand_id : 0,
          menu_items: sortMostPopular(store.most_popular, stores),
          ordinal: 0,
        };
      } else {
        const mostPopularIds = store.most_popular.map((item) => item.id);
        const mostPopular =
          store.menus[0]?.menu_categories
            .map((category) => category.menu_items)
            .flat()
            .filter((item) => mostPopularIds.includes(item.id))
            .sort((itemA, itemB) => {
              const indexA = mostPopularIds.indexOf(itemA.id);
              const indexB = mostPopularIds.indexOf(itemB.id);
              return indexA - indexB;
            }) || [];
        mostPopularCategory = {
          name: "Most Popular",
          id: 0,
          menu_items: sortMostPopular(mostPopular, stores),
          ordinal: 0,
        };
      }
    }

    // Add Most popular category to store
    const newStore = {
      ...store,
      menus: [
        {
          ...(store.menus[0] || {}),
          menu_categories: mostPopularCategory?.menu_items.length
            ? [
                mostPopularCategory,
                ...(store.menus[0]?.menu_categories || []),
              ].filter((category) => category.menu_items.length)
            : store.menus[0]?.menu_categories.filter(
                (category) => category.menu_items.length,
              ),
        },
      ],
    };

    return newStore;
  });
};

export const getStores = (publicStores: PublicStore[]) => {
  if (publicStores) {
    const mostPopularItems = getMostPopularItems({
      public_stores: publicStores,
    });

    const mostPopular: PublicStore = {
      id: 0,
      brand: {
        id: 0,
        name: "All",
        slug: "popular",
      },
      location: publicStores[0]?.location,
      menus: [],
      most_popular: mostPopularItems,
      ordinal: 0,
    };

    return [mostPopular].concat(publicStores);
  }

  return [];
};

export const getAllMenuItems = (publicStores?: PublicStore[]) => {
  const items =
    publicStores?.flatMap((store) =>
      store.menus.flatMap((menu) =>
        menu.menu_categories
          .flatMap((category) => category.menu_items)
          .map((item) => {
            const count =
              store.most_popular?.find((_item) => _item?.id === item.id)
                ?.count || 0;
            const isPopular = Boolean(
              store.most_popular?.find(
                (mostPopularItem) => mostPopularItem.id === item.id,
              ),
            );
            return {
              ...item,
              count,
              tags: isPopular
                ? [...(item.tags || []), MOST_POPULAR_TAG]
                : item.tags,
            };
          }),
      ),
    ) || [];
  return items.sort((a, b) => (b.count || 0) - (a.count || 0));
};
