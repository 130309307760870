import Link from "next/link";
import React from "react";
import { useLocationContext } from "../../utils/contexts/LocationContext";
import { getHomePath } from "../../utils/getHomePath";
import { LocalKitchensLogo } from "../Icons/LocalKitchensLogo";
import { classNames } from "@utils/classNames";
type LKLogoProps = {
  locationSlug?: string;
  className?: string;
  color?: "white" | "black";
  style?: React.CSSProperties;
};
export const LKLogo = ({
  locationSlug,
  className,
  style,
  color
}: LKLogoProps) => {
  const {
    userPreferredLocation
  } = useLocationContext();
  const location = locationSlug || userPreferredLocation?.slug;
  return <Link href={getHomePath(location)} style={style} data-sentry-element="Link" data-sentry-component="LKLogo" data-sentry-source-file="LKLogo.tsx">
      <LocalKitchensLogo className={classNames(className)} color={color} data-sentry-element="LocalKitchensLogo" data-sentry-source-file="LKLogo.tsx" />
    </Link>;
};