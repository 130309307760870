import { Dispatch, SetStateAction } from "react";
import { useStoresContext } from "../../utils/contexts/StoresContext";
import { User } from "../Authentication/types";
import { Cart } from "./Cart";
import { useCartMutations } from "./hooks/useCartMutations";
type CartControllerProps = {
  show: boolean;
  setShowCart: Dispatch<SetStateAction<boolean>>;
  user?: User;
};
export const CartController = ({
  show,
  setShowCart,
  user
}: CartControllerProps) => {
  const {
    onRemoveItem,
    onUpdateItem,
    isLoadingRemoveItem,
    isLoadingUpdateItem
  } = useCartMutations();
  const {
    storesMap
  } = useStoresContext();
  return <Cart onRemoveItem={onRemoveItem} onUpdateItem={onUpdateItem} show={show} user={user} stores={storesMap} setShowCart={setShowCart} isLoadingRemoveItem={isLoadingRemoveItem} isLoadingUpdateItem={isLoadingUpdateItem} data-sentry-element="Cart" data-sentry-component="CartController" data-sentry-source-file="CartController.tsx" />;
};