import { faExclamationCircle, faShoppingBag } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@ui/Icon";
import React from "react";
import { classNames } from "../../../utils/classNames";
type Props = {
  isCartValid: boolean;
  cartQuantity: number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
};
export function ShoppingCartInNavForLargerScreens({
  isCartValid,
  cartQuantity,
  onClick,
  className
}: Props) {
  return <button onClick={onClick} className={classNames("hidden sm:block relative text-lfg-primary hover:opacity-80 cursor-pointer p-2", className)} data-test-class={`view-cart-button-desktop`} data-sentry-component="ShoppingCartInNavForLargerScreens" data-sentry-source-file="ShoppingCart.tsx">
      <Icon icon={faShoppingBag} data-sentry-element="Icon" data-sentry-source-file="ShoppingCart.tsx" />
      {isCartValid && cartQuantity ? <span data-test-class="cart-quantity-desktop" className="absolute -top-1 left-5 inline-flex items-center justify-center w-6 h-6 text-xs font-semibold text-text-white bg-icon-primary rounded-full">
          {cartQuantity}
        </span> : null}
      {!isCartValid && <span className="absolute top-0 left-6 bg-surface-lighter h-4 w-4 rounded-full">
          <FontAwesomeIcon icon={faExclamationCircle} className="relative -top-1 -left-1 h-7 w-7 rounded-full text-red-600" />
        </span>}
    </button>;
}