type Props = {
  className?: string;
  color?: string;
};
export const LocalKitchensLogo = ({
  className,
  color = "#FDFDFC"
}: Props) => {
  return <svg className={className} width="192" height="24" viewBox="0 0 192 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="LocalKitchensLogo" data-sentry-source-file="LocalKitchensLogo.tsx">
      <path d="M94.3043 1.4825L88.2872 11.9751L94.9905 23.1142H88.685L83.7918 15.3467V23.1142H77.8443V1.4825H83.7818V8.60356L87.9192 1.4825H94.3043Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M106.13 12.5619H103.444V7.77808H106.149V3.03402H111.719V7.75818H114.772V12.5719H111.749V16.4208C111.739 17.3756 112.266 17.9325 113.241 17.9624H115.14V23.1242H111.102C107.91 23.1142 106.14 20.6576 106.14 17.296C106.12 15.3865 106.13 12.5719 106.13 12.5719V12.5619Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M101.873 23.1142H96.2536V7.79797H101.873V23.1142Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M0 23.1142V1.4825H5.94747V17.3855H12.8895V23.1142H0Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M68.7938 1.4825H63.0452V23.1142H68.7938V1.4825Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M162.064 18.2607C163.337 15.5058 163.227 12.184 161.437 9.42905C158.374 4.70488 152.456 4.45624 148.876 7.48965C146.728 9.3097 145.037 12.8901 146.111 16.8883C146.986 20.1604 149.443 22.8059 153.123 23.2932C155.042 23.5518 156.842 23.2336 158.503 22.229C158.632 22.1495 158.752 22.0699 158.871 21.9904L153.988 18.2806L162.064 18.2607ZM152.178 12.7509C152.178 11.4381 153.242 10.3739 154.555 10.3739C155.868 10.3739 156.932 11.4381 156.932 12.7509C156.932 14.0637 155.868 15.1279 154.555 15.1279C153.242 15.1279 152.178 14.0637 152.178 12.7509Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M191.115 5.83868V10.6524H186.411C185.704 10.6524 185.326 10.9308 185.326 11.2988C185.326 11.6668 185.834 11.8856 185.973 11.9353C186.828 12.2536 187.922 12.552 188.688 12.8802C189.812 13.3576 192 14.4814 192 17.4154C192 19.7625 191.035 21.5329 188.887 22.5871C188.141 22.9551 187.326 23.1142 186.5 23.1242H180.125V18.3403H185.744C186.142 18.3304 186.619 18.0121 186.619 17.5745C186.619 17.1369 186.172 16.938 186.043 16.8783C185.147 16.4805 184.083 16.023 183.188 15.6053C182.572 15.3168 181.945 15.0185 181.388 14.6306C180.195 13.8051 179.747 13.0095 179.657 11.6071C179.498 9.15057 180.712 7.19128 182.999 6.28623C183.487 6.08732 184.213 5.84863 185.416 5.84863H191.135L191.115 5.83868Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M40.9859 10.8314H44.2281V5.83868H40.1803C35.287 5.83868 31.816 9.986 31.7862 14.4615C31.7862 14.4615 31.7862 14.4715 31.7862 14.4814C31.7862 14.4914 31.7862 14.4914 31.7862 14.5013C31.8061 18.9768 35.287 23.1242 40.1803 23.1242H44.2281V18.1315H40.9859C38.9768 18.1315 37.2662 16.5004 37.2662 14.4914C37.2662 12.4823 38.9768 10.8513 40.9859 10.8513V10.8314Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M125.086 10.8314H128.328V5.83868H124.28C119.387 5.83868 115.916 9.986 115.886 14.4615C115.886 14.4615 115.886 14.4715 115.886 14.4814C115.886 14.4914 115.886 14.4914 115.886 14.5013C115.906 18.9768 119.387 23.1242 124.28 23.1242H128.328V18.1315H125.086C123.077 18.1315 121.366 16.5004 121.366 14.4914C121.366 12.4823 123.077 10.8513 125.086 10.8513V10.8314Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M22.2284 5.54031C17.5142 5.54031 13.6951 9.53845 13.6951 14.4814C13.6951 19.4244 17.5142 23.4225 22.2284 23.4225C26.9427 23.4225 30.7618 19.4244 30.7618 14.4814C30.7618 9.53845 26.9427 5.54031 22.2284 5.54031ZM22.2284 17.9127C20.4183 17.9127 18.9464 16.381 18.9464 14.4814C18.9464 12.5818 20.4183 11.0502 22.2284 11.0502C24.0385 11.0502 25.5105 12.5818 25.5105 14.4814C25.5105 16.381 24.0385 17.9127 22.2284 17.9127Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M138.184 5.86852H136.016V0.577454H130.069V23.1043H136.016V11.3485H136.603C138.164 11.3485 138.761 12.5022 138.761 13.6161V23.1142H144.579V14.4317C144.579 8.58367 142.64 5.86852 138.184 5.86852Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M98.9588 6.87302C100.552 6.87302 101.843 5.58171 101.843 3.9888C101.843 2.39588 100.552 1.10457 98.9588 1.10457C97.3659 1.10457 96.0746 2.39588 96.0746 3.9888C96.0746 5.58171 97.3659 6.87302 98.9588 6.87302Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M172.238 5.86852H164.122V23.1043H170.07V11.3485H170.657C172.218 11.3485 172.815 12.5022 172.815 13.6161V23.1142H178.633V14.4317C178.633 8.58367 176.694 5.86852 172.238 5.86852Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
      <path d="M53.6068 5.53037C48.8926 5.53037 45.0735 9.5285 45.0735 14.4715C45.0735 19.673 49.3004 23.4126 53.1096 23.4126C53.1991 23.4126 53.2886 23.4126 53.3781 23.4026L56.4513 20.6278V23.1043H61.1655V13.8946C61.1655 8.54389 58.3211 5.53037 53.6168 5.53037H53.6068ZM53.1096 17.5049C51.5481 17.5049 50.2751 16.1423 50.2751 14.4715C50.2751 12.8006 51.5481 11.4381 53.1096 11.4381C54.671 11.4381 55.9441 12.8006 55.9441 14.4715C55.9441 16.1423 54.671 17.5049 53.1096 17.5049Z" fill={color} data-sentry-element="path" data-sentry-source-file="LocalKitchensLogo.tsx" />
    </svg>;
};