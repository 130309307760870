import { faChevronLeft } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { Button } from "@ui/NewButton/Button";
import React from "react";
type NewModalProps = {
  header?: string | React.ReactNode;
  mobileHeader?: string | React.ReactNode;
  body: React.ReactNode;
  show: boolean;
  onBlur?: () => void;
  onClose?: () => void;
  onCloseComplete?: () => void;
  hasCancel?: boolean;
  hasConfirm?: boolean;
  cancelMessage?: string;
  confirmMessage?: string;
  isConfirmDisabled?: boolean;
  isConfirmLoading?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  hasClose?: boolean;
  hasBack?: boolean;
  overlayClassName?: string;
  buttonClassName?: string;
  cancelStyle?: string;
  confirmStyle?: string;
};

/**
 *
 * @deprecated Use `Modal` component instead
 *
 */
export const NewModal = ({
  header,
  mobileHeader,
  body,
  show,
  onBlur,
  onCloseComplete,
  hasCancel,
  hasConfirm,
  cancelMessage,
  confirmMessage,
  onClose,
  onCancel,
  onConfirm,
  hasClose,
  hasBack,
  overlayClassName,
  isConfirmDisabled,
  isConfirmLoading,
  buttonClassName,
  confirmStyle,
  cancelStyle
}: NewModalProps) => {
  const shouldShowHeader = !!header || !!mobileHeader;
  return <Transition show={show} data-sentry-element="Transition" data-sentry-component="NewModal" data-sentry-source-file="Modal.tsx">
      <div className="fixed z-60 bottom-0 left-0 right-0 sm:inset-0 bg-fixed" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-center justify-center h-full w-full">
          <Transition.Child className={`fixed inset-0 bg-gray-800 bg-opacity-75 transition-all ${overlayClassName || ""}`} enter="transition ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-1" leave="transition ease-in duration-300" leaveFrom="opacity-1" leaveTo="opacity-0" aria-hidden="true" onClick={onBlur} data-sentry-element="unknown" data-sentry-source-file="Modal.tsx" />
          <Transition.Child enter="transition ease-out duration-300" enterFrom="transform translate-y-full opacity-0" enterTo="transform translate-y-0 opacity-100" leave="transition ease-in duration-300" leaveFrom="transform translate-y-0 opacity-100" leaveTo="transform translate-y-full opacity-0" className="w-full sm:w-auto" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
            <div className="bg-surface-lighter flex flex-col justify-center items-center rounded-lg overflow-hidden shadow-xl transform transition-all">
              <div className="absolute top-0 left-0 pt-5 sm:pt-3 pl-5">
                {hasBack && <button type="button" onClick={() => {
                onCancel?.();
              }} className="bg-surface-lighter flex items-center justify-center rounded-md text-text-primary hover:text-text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lfg-primary transition-all focus:opacity-25">
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon icon={faChevronLeft} className="w-7 h-7" />
                  </button>}
              </div>
              <div className="absolute top-0 right-0 pt-6 sm:pt-4 pr-5">
                {hasClose && <button type="button" onClick={() => {
                onClose?.();
              }} className={buttonClassName || "bg-surface-lighter rounded-md text-text-primary hover:text-text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lfg-primary"}>
                    <span className="sr-only">Close</span>
                    {/* <!-- Heroicon name: outline/x --> */}
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>}
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className={`${shouldShowHeader && "mt-3"} text-center sm:mt-0 sm:text-left`}>
                  {shouldShowHeader && <div className={`flex flex-row items-end sm:items-center sm:justify-center sm:border-b h-16 mt-2 sm:mt-0 sm:h-14 ${!mobileHeader ? "h-4" : ""}`}>
                      <h4 className="text-2.5xl sm:text-sm px-8 sm:px-4 leading-6 font-medium sm:font-medium text-text-primary hidden sm:flex" id="modal-title">
                        {header}
                      </h4>
                      {mobileHeader && <h4 className="text-2.5xl px-8 font-medium text-text-primary flex sm:hidden" id="modal-title">
                          {mobileHeader}
                        </h4>}
                    </div>}

                  <div className={`${shouldShowHeader && "mt-1 sm:mt-10 mx-8 sm:mx-16 "}position-relative`}>
                    {body}
                  </div>
                </div>
              </div>
              {Boolean(hasConfirm || hasCancel) && <div className="px-8 sm:px-16 w-full flex flex-col gap-4 mt-6 mb-12">
                  {hasConfirm && <div className="flex flex-col justify-center">
                      <Button onPress={async () => {
                  await onConfirm?.();
                  onCloseComplete?.();
                }} mode="primary" label={confirmMessage || "Confirm"} className={`h-14 ${confirmStyle ?? ""}`} disabled={isConfirmDisabled} loading={isConfirmLoading} size="small" />
                    </div>}
                  {hasCancel && <div className="flex flex-col justify-center">
                      <Button onPress={() => {
                  if (typeof onCancel === "function") {
                    onCancel();
                  }
                  if (typeof onCloseComplete === "function") {
                    onCloseComplete();
                  }
                }} mode="secondary" label={cancelMessage || "Cancel"} className={`h-14 ${cancelStyle ?? ""}`} size="small" />
                    </div>}
                </div>}
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>;
};