import { useOrderTypeContext } from "./../../../utils/contexts/OrderTypeContext";
/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from "@apollo/client";
import { useCartContext } from "@appComponents/hooks/useCartContext";
import { useAuth } from "@features/Authentication/hooks/useAuth";
import { PickupType } from "@graphql/types";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect } from "react";
import { useLocationContext } from "../../../utils/contexts/LocationContext";
import { useLocalStorage } from "../../../utils/hooks/useLocalStorage";
import {
  useQueryParamsRemover,
  useRouterQueryParam,
} from "../../../utils/hooks/useRouterQueryParam";
import {
  GroupOrderContext,
  useGroupOrderContext,
} from "../../GroupOrder/context/GroupOrderContext";
import { Participant } from "../types";
import { useCartMutations } from "./useCartMutations";

export const CART_QUERY = gql`
  query CartQuery(
    $id: String
    $location_slug: String
    $force_new: Boolean
    $order_type: GuestOrderType
    $pickup_type: PickupType
  ) {
    cart(
      id: $id
      location_slug: $location_slug
      force_new: $force_new
      order_type: $order_type
      pickup_type: $pickup_type
    ) {
      id
      status
      items
      first_name
      last_name
      email
      location
      store_location {
        name
        display_name
      }
      phone_number
      is_delivery
      customer_id
      is_group_order
      spend_limit
      participants {
        id
        first_name
        last_name
        phone_number
        items
        customer_id
        is_creator
      }
      start_buffer_time
      delivery_address {
        id
        street
        city
        state
        zipcode
        unit
        customer_id
        instructions
        is_default
        geocodes {
          lat
          lng
        }
      }
      is_scheduled
      order_time
      stripe_payment_intent_id
      customer_delivery_fee
      tip_rate
      tip
      credits_used
      coupon {
        id
        name
        display_name
        description
        code
        discount_rate
        max_amount
        credits
        brand_attrs
      }
      validation {
        is_items_valid
        is_order_time_valid
        is_store_status_valid
        is_promo_valid
        invalid_items {
          id
          external_id
          is_active
          is_store_active
          name
          price
          quantity
          modifier_items {
            id
            is_active
            name
            price
            quantity
          }
        }
      }
      use_bag
      pickup_type
    }
  }
`;

export const useClearCart = () => {
  const [, setCartIds] = useLocalStorage(`lfg_cart_id`, {});
  const { setNextCartPickupType } = useCartContext();

  const clearCart = useCallback(
    (locationSlug: string, pickupType?: PickupType) => {
      if (pickupType) {
        setNextCartPickupType(pickupType);
      }
      setCartIds((curent: any) => ({
        ...curent,
        [locationSlug]: undefined,
      }));
    },
    [setCartIds],
  );

  return clearCart;
};

// Helper function to decode delivery address id
const decodeDeliveryAddressId = (
  deliveryAddressParam: string | string[],
): string => {
  return atob(
    Array.isArray(deliveryAddressParam)
      ? deliveryAddressParam[0]
      : deliveryAddressParam,
  );
};

export const useCartLoader = () => {
  const router = useRouter();
  const { user } = useAuth();
  const { storeLocation } = useLocationContext();
  const deliveryParam = useRouterQueryParam("is-delivery");
  const deliveryAddressParam = useRouterQueryParam("address-id");
  const clearCart = useClearCart();
  const removeQueryParams = useQueryParamsRemover();
  const {
    onUpdateCartDeliveryDetails,
    onUpdateIsDelivery,
    isLoading: loadingCartMutations,
  } = useCartMutations();
  const [cartIds, setCartIds, idsLoaded] = useLocalStorage(`lfg_cart_id`, {});
  const { setParticipantId, participantId } = useContext(GroupOrderContext);
  const { isGroupOrder } = useGroupOrderContext();
  const { nextCartPickupType, setNextCartPickupType } = useCartContext();

  const locationSlug = storeLocation?.slug ?? "";

  const [loadCartQuery, { loading, data, called }] = useLazyQuery(CART_QUERY, {
    variables: {
      id: cartIds[locationSlug],
      location_slug: locationSlug,
      order_type: user?.preferred_order_type,
      force_new: false,
      pickup_type: nextCartPickupType,
    },
    pollInterval: isGroupOrder ? 10000 : 0,
    fetchPolicy: "network-only",
  });

  const loadCart = (forceNew = false) => {
    return loadCartQuery({
      variables: {
        id: cartIds[locationSlug],
        location_slug: locationSlug,
        order_type: user?.preferred_order_type,
        force_new: forceNew,
        pickup_type: nextCartPickupType,
      },
    });
  };

  const isCheckout = router.pathname.includes("checkout");
  const { setShowChooseOrderType } = useOrderTypeContext();

  // Fetch cart if it hasn't been fetched already
  useEffect(() => {
    if (!locationSlug || !idsLoaded) return;

    if (!cartIds[locationSlug] && called) {
      loadCart(true);
      setNextCartPickupType(PickupType.Regular);
    } else {
      loadCart().then(({ data }) => {
        // As a safety measure: If the cart location doesn't match the current location
        // reset the cart to bring correct state to the app
        if (data?.cart?.location !== locationSlug) {
          clearCart(locationSlug);
        }
      });
    }
  }, [cartIds[locationSlug], locationSlug, idsLoaded]);

  // Handle delivery parameters and fetch new cart as needed
  useEffect(() => {
    const cartId = locationSlug ? cartIds?.[locationSlug] : null;

    if (
      router.isReady &&
      !loading &&
      locationSlug &&
      cartId &&
      deliveryParam &&
      !loadingCartMutations
    ) {
      if (deliveryAddressParam) {
        const deliveryAddressId = decodeDeliveryAddressId(deliveryAddressParam);
        onUpdateCartDeliveryDetails(
          cartId,
          deliveryParam === "true",
          deliveryAddressId,
        );
      } else {
        onUpdateIsDelivery(cartId, deliveryParam === "true");
        if (deliveryParam === "true") {
          setShowChooseOrderType(true);
        }
      }

      // Remove the delivery and address params from the URL
      removeQueryParams(["is-delivery", "address-id"]);
    }
  }, [
    router,
    locationSlug,
    cartIds,
    loading,
    deliveryParam,
    deliveryAddressParam,
  ]);

  // Update participant Id and cart Ids as per new data
  useEffect(() => {
    // Ensure that correct cart is loaded for location
    if (data?.cart && locationSlug && data.cart.location === locationSlug) {
      const groupOrderParticipant = data.cart.participants?.find(
        (participant: Participant) =>
          participant.customer_id === user?.id?.toString() ||
          participant.id === participantId,
      );

      if (cartIds?.[locationSlug] !== data.cart.id) {
        if (data.cart.is_group_order && groupOrderParticipant) {
          setParticipantId(groupOrderParticipant.id);
        } else {
          setParticipantId("");
        }
      } else if (!data.cart.is_group_order) {
        setParticipantId("");
      } else if (groupOrderParticipant) {
        setParticipantId(groupOrderParticipant.id);
      } else if (participantId && !groupOrderParticipant) {
        setParticipantId("");
      }
      setCartIds((current: any) => ({
        ...current,
        [locationSlug]: data.cart.id,
      }));
    }
  }, [data, locationSlug]);

  // Load a new cart if the cart status is "PAID" or "PAYMENT_PENDING"
  useEffect(() => {
    // Don't load a new cart if we're on the checkout page
    // as payment can fail and we don't want to clear the cart
    if (
      !loading &&
      locationSlug &&
      !isCheckout &&
      ["PAID", "PAYMENT_PENDING"].includes(data?.cart?.status)
    ) {
      clearCart(locationSlug);
    }
  }, [locationSlug, data?.cart?.status, loading, isCheckout]);

  return { data, loading };
};
