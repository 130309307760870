import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { StoredPaymentMethodsDocument } from "../../../graphql/storedPaymentMethods.query.generated";

const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod($id: Int, $name: String, $is_default: Boolean) {
    update_payment_method(id: $id, name: $name, is_default: $is_default) {
      id
      name
      is_default
    }
  }
`;

export const useUpdatePaymentMethodMutation = () => {
  const [updatePaymentMethod] = useMutation(UPDATE_PAYMENT_METHOD, {
    refetchQueries: [
      {
        query: StoredPaymentMethodsDocument,
      },
    ],
  });

  const onUpdatePaymentMethod = useCallback(
    (id: number, name: string | null = null, isDefault = false) => {
      return updatePaymentMethod({
        variables: {
          id,
          name,
          is_default: isDefault,
        },
      });
    },
    [updatePaymentMethod],
  );

  return { onUpdatePaymentMethod };
};
