import { PickupType } from "@graphql/types";
import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { Cart, CartReceipt } from "../../features/Cart/types";

export type CartContextType = {
  cart: Cart;
  setCart: Dispatch<SetStateAction<Cart>>;
  setShowCart: (showCart: boolean) => void;
  selectedTipValue: number;
  setSelectedTipValue: Dispatch<SetStateAction<number>>;
  setShowCustomTipInput: Dispatch<SetStateAction<boolean>>;
  showCustomTipInput: boolean;
  cartReceiptLoading: boolean;
  cartReceipt?: CartReceipt;
  total: number;
  subtotal: number;
  tax: number;
  nextCartPickupType: PickupType;
  setNextCartPickupType: Dispatch<SetStateAction<PickupType>>;
};

export const CartContext = createContext<CartContextType>({
  cart: { items: [] },
  setCart: () => {},
  setShowCart: () => {},
  selectedTipValue: 0,
  setSelectedTipValue: () => {},
  showCustomTipInput: false,
  setShowCustomTipInput: () => {},
  cartReceiptLoading: false,
  total: 0,
  subtotal: 0,
  tax: 0,
  nextCartPickupType: PickupType.Regular,
  setNextCartPickupType: () => {},
});

export const CartProvider = CartContext.Provider;
export function useCartContext() {
  return useContext(CartContext);
}
