import { DateTime } from "luxon";
import { getPickupTimeRangeStr } from "../../utils/storeHours";
import { formatAvailableTimesData, getClosestAvailableTime, parseTime } from "../Checkout/utils/orderTime";
import { AvailableOrderTime, Cart } from "./types";
export const cartHorizontalPaddingClassName = "px-6";
export const getClosestOrderTimeText = (cart: Cart, availableOrderTimes: AvailableOrderTime[]) => {
  if (!availableOrderTimes.length) return "";
  const parsedTimes = formatAvailableTimesData(availableOrderTimes, cart.start_buffer_time);
  const closestAvailableTime = getClosestAvailableTime(parsedTimes);

  // If order is scheduled and time is saved in bag, show that time unless it's not available
  if (cart.order_time && cart.is_scheduled) {
    const cartTime = DateTime.fromISO(cart.order_time);
    const visibleTime = closestAvailableTime && cartTime < closestAvailableTime ? closestAvailableTime : cartTime;
    return cart.is_delivery && cart.delivery_address ? `Delivered on ${parseTime(visibleTime.toISO())} to` : `Ready on ${parseTime(visibleTime.toISO())} at`;
  }

  // If time is not saved in cart, show closest available time
  const asapTime = availableOrderTimes.find(time => time.is_asap_available);
  if (asapTime) {
    const asapTimeRange = getPickupTimeRangeStr(cart.start_buffer_time || 0, 10);
    return cart.is_delivery && cart.delivery_address ? `Delivered in ${asapTimeRange} min to` : `Ready in ${asapTimeRange} min at`;
  }
  if (!closestAvailableTime) return "";
  return cart.is_delivery && cart.delivery_address ? `Delivered on ${parseTime(closestAvailableTime.toISO())} to` : `Ready on ${parseTime(closestAvailableTime.toISO())} at`;
};