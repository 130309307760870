import { PickupType } from "@graphql/types";
import * as Sentry from "@sentry/nextjs";
import usePageViewTracking from "@utils/hooks/useTrackWithFlags";
import { AppProps } from "next/app";
import { useEffect, useState } from "react";
import { useLoginContext } from "../features/Authentication/contexts/LoginContext";
import { useAuth } from "../features/Authentication/hooks/useAuth";
import { CartController } from "../features/Cart";
import { useCartClientMetadataMutation } from "../features/Cart/hooks/useCartClientMetadata";
import { Cart } from "../features/Cart/types";
import CartLoader from "../features/CartLoader/CartLoader";
import { useCartReceipt } from "../features/Checkout/hooks/useCartReceipt";
import { GroupOrderController } from "../features/GroupOrder";
import { GroupOrderContextProvider } from "../features/GroupOrder/context/GroupOrderContext";
import VideoManagerContextProvider from "../features/VideoManager/VideoManagerContext";
import { NavbarContextProvider } from "../ui/Navbar/NavbarContext";
import { NavbarController } from "../ui/Navbar/NavbarController";
import { AnalyticsHelperContextProvider } from "../utils/contexts/AnalyticsHelperContext";
import { LoyaltyProvider } from "../utils/contexts/LoyaltyContext";
import { MenuItemModalProvider } from "../utils/contexts/MenuItemModalContext";
import { OrderTypeContextProvider } from "../utils/contexts/OrderTypeContext";
import { PaymentMethodContextProvider } from "../utils/contexts/PaymentMethodContext";
import { StoresContextProvider } from "../utils/contexts/StoresContext";
import useSegment from "../utils/hooks/useSegment";
import { useSegmentAddress } from "../utils/segmentUtils";
import { CartProvider } from "./hooks/useCartContext";
import { ToastContextProvider } from "./hooks/useToast";
interface AppContextsProps extends AppProps {
  ip: string;
}
const AppContexts = ({
  ip,
  Component,
  pageProps
}: AppContextsProps) => {
  const {
    loginWithTrack
  } = useLoginContext();
  const [cart, setCart] = useState<Cart>({
    items: []
  });
  const [showCart, setShowCart] = useState(false);
  const {
    user
  } = useAuth();
  const {
    cartReceipt,
    loading: cartReceiptLoading,
    subtotal,
    total,
    tax
  } = useCartReceipt(cart);
  const {
    identify
  } = useSegment();
  const {
    upsert: upsertCartClientMetadata
  } = useCartClientMetadataMutation();
  const [nextCartPickupType, setNextCartPickupType] = useState<PickupType>(PickupType.Regular);
  const [selectedTipValue, setSelectedTipValue] = useState(0);
  const [showCustomTipInput, setShowCustomTipInput] = useState(false);

  // TODO: pull address from the customer object and add it to the useEffect state
  const segmentAddress = useSegmentAddress();

  // This is a custom hook that will track page views on every route change
  usePageViewTracking(Component);
  useEffect(() => {
    if (user) {
      const username = `${user.first_name} ${user.last_name}`;
      identify({
        user,
        email: user.email,
        traits: {
          name: username,
          nickname: "",
          email: user.email,
          email_verified: "",
          sub: user.id,
          ...(Object.keys(segmentAddress).length > 0 && {
            address: segmentAddress
          })
        }
      });
      Sentry.setUser({
        id: user.id,
        email: user.email ?? undefined,
        username: username
      });
    }
  }, [user, segmentAddress, ip, identify]);
  useEffect(() => {
    if (cart?.id) {
      upsertCartClientMetadata(cart.id).catch((error: Error) => {
        console.error(error);
      });
    }
  }, [cart?.id]);
  return <ToastContextProvider data-sentry-element="ToastContextProvider" data-sentry-component="AppContexts" data-sentry-source-file="AppContexts.tsx">
      <CartProvider value={{
      cart,
      cartReceipt,
      setCart,
      setShowCart,
      cartReceiptLoading,
      selectedTipValue,
      setSelectedTipValue,
      showCustomTipInput,
      setShowCustomTipInput,
      total,
      subtotal,
      tax,
      nextCartPickupType,
      setNextCartPickupType
    }} data-sentry-element="CartProvider" data-sentry-source-file="AppContexts.tsx">
        <GroupOrderContextProvider data-sentry-element="GroupOrderContextProvider" data-sentry-source-file="AppContexts.tsx">
          <OrderTypeContextProvider data-sentry-element="OrderTypeContextProvider" data-sentry-source-file="AppContexts.tsx">
            <PaymentMethodContextProvider data-sentry-element="PaymentMethodContextProvider" data-sentry-source-file="AppContexts.tsx">
              <LoyaltyProvider data-sentry-element="LoyaltyProvider" data-sentry-source-file="AppContexts.tsx">
                <NavbarContextProvider data-sentry-element="NavbarContextProvider" data-sentry-source-file="AppContexts.tsx">
                  <VideoManagerContextProvider data-sentry-element="VideoManagerContextProvider" data-sentry-source-file="AppContexts.tsx">
                    <AnalyticsHelperContextProvider data-sentry-element="AnalyticsHelperContextProvider" data-sentry-source-file="AppContexts.tsx">
                      <StoresContextProvider data-sentry-element="StoresContextProvider" data-sentry-source-file="AppContexts.tsx">
                        <MenuItemModalProvider data-sentry-element="MenuItemModalProvider" data-sentry-source-file="AppContexts.tsx">
                          <CartLoader setCart={setCart} data-sentry-element="CartLoader" data-sentry-source-file="AppContexts.tsx" />
                          <NavbarController setShowCart={setShowCart} pageDisplayName={Component.displayName} data-sentry-element="NavbarController" data-sentry-source-file="AppContexts.tsx" />
                          <CartController show={showCart} setShowCart={setShowCart} user={user} data-sentry-element="CartController" data-sentry-source-file="AppContexts.tsx" />
                          <GroupOrderController user={user} data-sentry-element="GroupOrderController" data-sentry-source-file="AppContexts.tsx" />
                          <Component className="bg-brand-yellow" {...pageProps} setShowCart={setShowCart} showCart={showCart} loginWithTrack={loginWithTrack} data-sentry-element="Component" data-sentry-source-file="AppContexts.tsx" />
                        </MenuItemModalProvider>
                      </StoresContextProvider>
                    </AnalyticsHelperContextProvider>
                  </VideoManagerContextProvider>
                </NavbarContextProvider>
              </LoyaltyProvider>
            </PaymentMethodContextProvider>
          </OrderTypeContextProvider>
        </GroupOrderContextProvider>
      </CartProvider>
    </ToastContextProvider>;
};
export default AppContexts;