import { z } from "zod";
import { OrderType } from "../../types";
import { PostPasscodeSignupData, SocialLoginProviderData } from "../../utils/shopBackend/types";
export enum PasscodeLoginMethod {
  Email = "email",
  Sms = "sms",
}
export enum SocialLoginMethod {
  Apple = "apple",
  Google = "google",
}
export const LoginMethod = {
  ...PasscodeLoginMethod,
  ...SocialLoginMethod
};
export type LoginMethod = PasscodeLoginMethod | SocialLoginMethod;
type CommonLoginState = {
  redirectUrl: string;
  allowSignUp: boolean;
  isPromoVerification?: boolean;
};
export type PasscodeLoginState = {
  method: PasscodeLoginMethod;
  identifier?: string;
  serverData?: PostPasscodeSignupData;
} & CommonLoginState;
export type SocialLoginState = {
  method: SocialLoginMethod;
  serverData?: SocialLoginProviderData;
} & CommonLoginState;
export type LoginState = PasscodeLoginState | SocialLoginState;
export const userSchema = z.object({
  id: z.number(),
  email: z.string().email().nullable(),
  phone_number: z.string().nullable(),
  first_name: z.string(),
  last_name: z.string(),
  google_external_id: z.string().nullish(),
  apple_external_id: z.string().nullish(),
  is_email_verified: z.boolean().nullish(),
  is_phone_number_verified: z.boolean().nullish(),
  is_verified_for_promo: z.boolean().nullish(),
  preferred_location_id: z.number().nullish(),
  preferred_order_type: z.nativeEnum(OrderType).nullish()
});
export type User = z.infer<typeof userSchema>;