import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useLocationContext } from "../../../utils/contexts/LocationContext";
import { DeliveryAddress } from "../../Cart/types";
import { DeliveryAddressDropdown } from "../../ChoosePickupDelivery/components/DeliveryAddressDropdown";
import useAddressPredictions from "../../ChoosePickupDelivery/hooks/useAddressPredictions";
import { usePlaceDetails } from "../../ChoosePickupDelivery/hooks/usePlaceDetails";
import { EditAddressButton } from "../../EnterAddress/EditAddressButton";
import { NewAddressButton } from "../../EnterAddress/NewAddressButton";
import { ADDRESS_STAGES } from "../types";
import { AddressDetailForm } from "./AddressDetailForm";
import { ViewAddress } from "./ViewAddress";
import { useAuth } from "@features/Authentication/hooks/useAuth";
import { Link } from "@ui/Typography";
type EnterAddressProps = {
  deliveryAddress?: DeliveryAddress;
  onSelectDeliveryAddress: (address?: DeliveryAddress) => void;
  deliveryAddresses: DeliveryAddress[];
  setShowClose: Dispatch<SetStateAction<boolean>>;
  onUpdateDeliveryAddress: (newAddress: DeliveryAddress) => Promise<DeliveryAddress | undefined>;
  addressStage: number;
  setAddressStage: Dispatch<SetStateAction<number>>;
  isLoadingAddressMutation?: boolean;
};
export const EnterAddress = ({
  setShowClose,
  deliveryAddresses,
  deliveryAddress,
  onSelectDeliveryAddress,
  onUpdateDeliveryAddress,
  addressStage,
  setAddressStage,
  isLoadingAddressMutation
}: EnterAddressProps) => {
  const {
    setSearchedAddress
  } = useLocationContext();
  const {
    isAuthenticated
  } = useAuth();
  const [userAddressInput, setUserAddressInput] = useState("");
  const [prediction, setPrediction] = useState<google.maps.places.AutocompletePrediction>();
  const {
    predictions,
    setPredictions
  } = useAddressPredictions(userAddressInput);
  const [placeAddress, setPlaceAddress] = useState<DeliveryAddress | undefined>(undefined);
  const {
    place
  } = usePlaceDetails(prediction?.place_id);
  useEffect(() => {
    setPlaceAddress(place);
  }, [place]);
  const handlePredictionSelection = useCallback((prediction: google.maps.places.AutocompletePrediction) => {
    if (prediction) {
      setAddressStage(ADDRESS_STAGES.MANUAL_ENTRY);
      setUserAddressInput("");
      setPrediction(prediction);
      setPredictions([]);
    }
    if (setShowClose && prediction) {
      setShowClose(false);
    }
  }, []);
  useEffect(() => {
    if (!userAddressInput && !prediction) {
      setPrediction(undefined);
      setPredictions([]);
    }
  }, [userAddressInput, prediction, setPredictions]);
  useEffect(() => {
    if (deliveryAddress && deliveryAddress.street && deliveryAddress.zipcode) {
      setAddressStage(ADDRESS_STAGES.VIEW);
    } else {
      setAddressStage(ADDRESS_STAGES.MANUAL_ENTRY);
    }
    return () => {
      setPrediction(undefined);
      setPredictions([]);
    };
  }, []);
  const handleAddressUpdate = useCallback(async (newAddress: DeliveryAddress) => {
    const updatedAddress = isAuthenticated ? await onUpdateDeliveryAddress(newAddress) : newAddress;
    setAddressStage(ADDRESS_STAGES.VIEW);
    setSearchedAddress(updatedAddress);
    setShowClose(true);
    setPlaceAddress(updatedAddress);
    onSelectDeliveryAddress(updatedAddress);
  }, [isAuthenticated, onSelectDeliveryAddress, onUpdateDeliveryAddress, setAddressStage, setSearchedAddress, setShowClose]);
  const showDeliveryAddressDropdown = useMemo(() => Boolean(deliveryAddresses.length), [deliveryAddresses]);
  useEffect(() => {
    if (deliveryAddress) {
      onSelectDeliveryAddress(deliveryAddress);
    }
  }, []);
  return <div className="sm:mt-0" data-sentry-component="EnterAddress" data-sentry-source-file="EnterAddress.tsx">
      {addressStage === ADDRESS_STAGES.VIEW ? <div>
          <DeliveryAddressDropdown selectedAddress={deliveryAddress} deliveryAddresses={deliveryAddresses} onSelectAddress={address => {
        setAddressStage(ADDRESS_STAGES.VIEW);
        onSelectDeliveryAddress(address);
      }} />
          <div className="my-4 flex space-x-2 w-full justify-between">
            <EditAddressButton onPress={() => {
          setPlaceAddress(undefined);
          setAddressStage(ADDRESS_STAGES.MANUAL_ENTRY);
        }} />
            <NewAddressButton onPress={() => {
          setPlaceAddress(undefined);
          setSearchedAddress(undefined);
          setAddressStage(ADDRESS_STAGES.MANUAL_ENTRY);
        }} />
          </div>
        </div> : <div className="mb-32 sm:mb-2">
          <AddressDetailForm show={addressStage === ADDRESS_STAGES.MANUAL_ENTRY} address={placeAddress?.street ? placeAddress : deliveryAddress} handleAddressUpdate={handleAddressUpdate} onStreetAddressInput={(street: string) => {
        setUserAddressInput(street);
      }} handlePredictionSelection={handlePredictionSelection} predictions={predictions} isLoadingAddressMutation={Boolean(isLoadingAddressMutation)} />
          <ViewAddress show={addressStage === ADDRESS_STAGES.VIEW} setAddressStage={setAddressStage} />
          {showDeliveryAddressDropdown ? <Link underLine="solid" onClick={() => setAddressStage(ADDRESS_STAGES.VIEW)} className="mb-4 w-full text-right">
              Select from saved addresses
            </Link> : null}
        </div>}
    </div>;
};