import { client } from "@appComponents/ApolloWrapper";
import { useDeliveryAddressMutations } from "@features/LocationDiscovery/hooks/useDeliveryAddressMutations";
import { useQueryClient } from "@tanstack/react-query";
import { useLocationContext } from "@utils/contexts/LocationContext";
import { useLocalStorage } from "@utils/hooks/useLocalStorage";
import { useRouter } from "next/router";
import React, { Dispatch, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect } from "react";
import { useRouterQueryState } from "../../../utils/hooks/useRouterQueryState";
import { useSessionStorage } from "../../../utils/hooks/useSessionStorage";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { PostPasscodeSignupData, SocialLoginProviderData } from "../../../utils/shopBackend/types";
import { useClearCart } from "../../Cart/hooks/useCartLoader";
import VerifyCodeModal from "../components/VerifyCodeModal";
import useLogout from "../hooks/useLogout";
import { LoginState, PasscodeLoginMethod, SocialLoginMethod, User } from "../types";
import { isPasscodeLoginState, isSocialLoginState } from "../utils";
interface LoginContext {
  loginState: LoginState;
  setLoginState: Dispatch<SetStateAction<LoginState>>;
  changeLoginMethod: (loginMethod: PasscodeLoginMethod) => void;
  setLoginMethodAndIdentifier: (method: PasscodeLoginMethod, identifier?: string) => void;
  beginPromoVerification: () => void;
  showVerifyCodeModal: () => void;
  resetLoginState: () => void;
  onLoginSuccess: (user: User, isSignUp?: boolean) => void;
  onConflictLogin: (loginMethod: PasscodeLoginMethod, identifier: string) => void;
  onRedirectToSignup: (data: PostPasscodeSignupData) => void;
  onSocialSignUp: (method: SocialLoginMethod, serverData: SocialLoginProviderData) => void;
  login: (to?: string) => void;
  loginWithTrack: (to?: string) => void;
  logout: (locationSlug?: string) => void;
}
const initialLoginState = {
  redirectUrl: "/order",
  method: PasscodeLoginMethod.Email,
  allowSignUp: false,
  isPromoVerification: false
};
const LoginContext = React.createContext<LoginContext>({
  loginState: initialLoginState,
  setLoginState: () => {},
  changeLoginMethod: () => {},
  setLoginMethodAndIdentifier: () => {},
  beginPromoVerification: () => {},
  resetLoginState: () => {},
  onLoginSuccess: () => {},
  onConflictLogin: () => {},
  onRedirectToSignup: () => {},
  onSocialSignUp: () => {},
  login: () => {},
  loginWithTrack: () => {},
  logout: () => {},
  showVerifyCodeModal: () => {}
});
export const LoginContextProvider = ({
  children
}: PropsWithChildren) => {
  const [isShowVerifyCodeModal, setIsShowVerifyCodeModal] = useRouterQueryState("verify-code", undefined);
  const {
    storeLocation,
    searchedAddress,
    setSearchedAddress
  } = useLocationContext();
  const [cartIds] = useLocalStorage("lfg_cart_id", {});
  const cartId = storeLocation?.slug ? cartIds[storeLocation.slug] : undefined;
  const {
    onUpdateDeliveryAddress
  } = useDeliveryAddressMutations();
  const router = useRouter();
  const {
    mutateAsync: logoutRequest
  } = useLogout();
  const clearCart = useClearCart();
  const [loginState, setLoginState] = useSessionStorage<LoginState>("lfg-login", initialLoginState);
  const queryClient = useQueryClient();
  const {
    track
  } = useTrackWithFlags();
  const changeLoginMethod = useCallback((loginMethod: PasscodeLoginMethod) => {
    setLoginState(prevState => ({
      method: loginMethod,
      redirectUrl: prevState.redirectUrl,
      allowSignUp: false
    }));
  }, [setLoginState]);
  const setLoginMethodAndIdentifier = useCallback((method: PasscodeLoginMethod, identifier?: string) => {
    setLoginState(prevState => ({
      ...prevState,
      method,
      identifier,
      allowSignUp: false
    }));
  }, [setLoginState]);
  const setRedirect = useCallback((redirectUrl: string) => {
    setLoginState(prevState => ({
      ...prevState,
      redirectUrl,
      allowSignUp: false
    }));
  }, [setLoginState]);
  const onConflictLogin = useCallback((loginMethod: PasscodeLoginMethod, identifier: string) => {
    setLoginState(prevState => ({
      ...prevState,
      method: loginMethod,
      identifier,
      allowSignUp: false
    }));
    router.push({
      pathname: "/order/login"
    });
  }, [router, setLoginState]);
  const resetLoginState = useCallback(() => {
    setLoginState(initialLoginState);
  }, [setLoginState]);
  const onCodeCorrectWithoutUser = useCallback((serverData: PostPasscodeSignupData) => {
    setLoginState(prevState => {
      // Shouldn't happen, but just in case
      if (isSocialLoginState(prevState)) {
        return prevState;
      }
      return {
        ...prevState,
        serverData,
        allowSignUp: true
      };
    });
    router.push("/order/signup");
  }, [router, setLoginState]);
  const onLoginSuccess = useCallback(async (user: User, isSignUp?: boolean) => {
    if (searchedAddress) {
      const {
        id,
        ...newAddress
      } = searchedAddress;
      const address = await onUpdateDeliveryAddress({
        input: {
          ...newAddress,
          customer_id: user.id,
          is_default: false
        },
        cart_id: cartId
      });
      setSearchedAddress(address);
    }
    queryClient.setQueryData(["me"], {
      data: user
    });
    client.resetStore();
    if (isSignUp) {
      setRedirect("/order/signupreward");
    } else if (loginState.redirectUrl !== "") {
      router.push(loginState.redirectUrl);
    }
  }, [cartId, loginState.redirectUrl, onUpdateDeliveryAddress, queryClient, router, searchedAddress, setSearchedAddress]);
  const onSocialSignUp = useCallback((method: SocialLoginMethod, serverData: SocialLoginProviderData) => {
    setLoginState(prevState => ({
      method,
      redirectUrl: prevState.redirectUrl,
      serverData,
      allowSignUp: true
    }));
    router.push("/order/signup");
  }, [router, setLoginState]);
  const login = useCallback((to?: string) => {
    setRedirect(to || router.asPath);
    router.push("/order/login");
  }, [router, setRedirect]);
  const loginWithTrack = useCallback((to?: string) => {
    track({
      event: "Clicked Login CTA"
    });
    return login(to);
  }, [track, login]);
  const logout = useCallback(async (locationSlug?: string) => {
    await logoutRequest();
    if (locationSlug) {
      clearCart(locationSlug);
    }
    window.location.href = process.env.NEXT_PUBLIC_LANDING_URL || "/order";
  }, [clearCart, logoutRequest]);
  const showVerifyCodeModal = useCallback(() => {
    setIsShowVerifyCodeModal("true");
  }, [setIsShowVerifyCodeModal]);
  const beginPromoVerification = useCallback(() => {
    loginWithTrack();
    // change state after navigation to /login to avoid useEffect & resetLoginState below
    setLoginState(prevState => ({
      ...prevState,
      isPromoVerification: true
    }));
  }, [setLoginState, loginWithTrack]);
  useEffect(() => {
    if (["/order/login", "/order/signup"].includes(router.pathname)) {
      return;
    }
    resetLoginState();
  }, [router.pathname, resetLoginState]);
  const identifier = isPasscodeLoginState(loginState) ? loginState.identifier : null;
  const method = isPasscodeLoginState(loginState) ? loginState.method : null;
  return <LoginContext.Provider value={{
    loginState,
    setLoginState,
    changeLoginMethod,
    showVerifyCodeModal,
    setLoginMethodAndIdentifier,
    beginPromoVerification,
    resetLoginState,
    onLoginSuccess,
    onConflictLogin,
    onRedirectToSignup: onCodeCorrectWithoutUser,
    onSocialSignUp,
    login,
    loginWithTrack,
    logout
  }} data-sentry-element="unknown" data-sentry-component="LoginContextProvider" data-sentry-source-file="LoginContext.tsx">
      {children}
      <VerifyCodeModal identifier={identifier} method={method} showModal={!!isShowVerifyCodeModal && isPasscodeLoginState(loginState)} onNotFound={onCodeCorrectWithoutUser} onSuccess={onLoginSuccess}
    // Undefined is used to close the modal and clear query state
    closeModal={() => setIsShowVerifyCodeModal(undefined)} data-sentry-element="VerifyCodeModal" data-sentry-source-file="LoginContext.tsx" />
    </LoginContext.Provider>;
};
export const useLoginContext = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error("useLoginContext must be used within an LoginContextProvider");
  }
  return context;
};