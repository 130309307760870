import { useCallback } from "react";
import { User } from "../../features/Authentication/types";
import { useCartClientMetadataMutation } from "../../features/Cart/hooks/useCartClientMetadata";
import { Cart, CartMenuItem } from "../../features/Cart/types";
import { WEB_PROVIDER_ID } from "../../features/OrderDetail/hooks/useCartReceipt";
import { StoredPaymentMethod } from "../../graphql/types";
import { useAnalyticsHelperContext } from "../contexts/AnalyticsHelperContext";
import { getBrandCount } from "../getBrandCount";
import { sha256 } from "../hash";
import { useTrackWithFlags } from "./useTrackWithFlags";

type TrackOrderCompletedArgs = {
  cart: Cart;
  checkoutId: any;
  totalInCents: number;
  subtotalInCents: number;
  taxInCents: number;
  orderCount?: number;
  walletName?: string;
  paymentMethod?: StoredPaymentMethod;
  cashRewardDiscount?: number;
  isMenuV2?: boolean;
};

type IdentifyArgs = {
  email?: string | null;
  traits: Record<string, any>;
  user?: User;
};

const identify = ({ email, traits, user }: IdentifyArgs) => {
  if (email) {
    // Some destinations don't permit userIDs that have PII, so we want the option of using a hashed email
    // TODO: update this once we move to UUIDs
    sha256(email).then((emailHashed) => {
      // Always send this information if it exists
      const defaultTraits = user
        ? {
            phoneNumber: user.phone_number,
            phone: user.phone_number,
            firstName: user.first_name,
            lastName: user.last_name,
            email,
            emailHashed,
          }
        : { email, emailHashed };
      analytics.identify(email, {
        ...defaultTraits,
        ...traits,
      });
    });
  } else {
    analytics.identify(traits);
  }
};

export default function useSegment() {
  const { track } = useTrackWithFlags();
  const { upsert: upsertCartClientMetadata } = useCartClientMetadataMutation();
  const { orderCount } = useAnalyticsHelperContext();

  // Order Completed spec: https://segment.com/docs/connections/spec/ecommerce/v2/#order-completed
  const trackOrderCompleted = useCallback(
    async ({
      cart,
      checkoutId,
      totalInCents,
      subtotalInCents,
      taxInCents,
      walletName,
      paymentMethod,
      cashRewardDiscount,
      isMenuV2,
    }: TrackOrderCompletedArgs) => {
      const total = totalInCents / 100;
      const subtotal = subtotalInCents / 100;
      const tip = cart.tip;
      const tipRate = cart.tip_rate;
      const tax = taxInCents / 100;
      const products = cart.items.map((item: CartMenuItem) => ({
        product_id: item.id,
        sku: item.id,
        name: item.name,
        brand: item.brand_name,
        brand_name: item.brand_name,
        price: item.price / 100,
        quantity: item.quantity,
        category: "Food & Drink", // Some destinations require a category field
      }));

      const properties = {
        order_id: cart.id,
        orderId: cart.id,
        orderCount: (orderCount || 0) + 1, // nth order for given cart.phone_number
        checkoutId,
        checkout_id: checkoutId,
        products,
        brandCount: getBrandCount(cart?.items),
        subtotal,
        tax,
        total,
        revenue: total - tax,
        value: total, // see https://segment.com/docs/connections/spec/ecommerce/v2/
        location: cart.location,
        walletName,
        isDelivery: Boolean(cart.is_delivery),
        isStoredPaymentMethod: Boolean(paymentMethod),
        providerId: WEB_PROVIDER_ID,
        isGroupOrder: cart.is_group_order,
        currency: "USD",
        repeat: Boolean(orderCount), // see https://segment.com/docs/connections/destinations/catalog/shareasale/
        didUseCashReward: (cashRewardDiscount ?? 0) > 0,
        cashRewardDiscount,
        isMenuV2,
        tip,
        tipRate,
      };
      await track({
        // follows Segment Ecommerce Spec
        event: "Order Completed",
        properties,
      });

      if (orderCount !== undefined && orderCount + 1 === 1) {
        // for customer conversion events in Google Ads integrations
        await track({
          event: "New Order Completed",
          properties,
        });
      }

      if (cart.id) {
        upsertCartClientMetadata(cart.id).catch((error: any) => {
          console.error(error);
        });
      }
    },
    [track, orderCount],
  );

  return {
    identify,
    trackOrderCompleted,
  };
}
