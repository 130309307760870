import { gql, useQuery } from "@apollo/client";
import { MandatoryAdvancedScheduling } from "../../../graphql/types";
import { AvailableOrderTime, Cart } from "../types";

export const AVAILABLE_ORDER_TIMES_QUERY = gql`
  query AvailableOrderTimes($id: String) {
    cart(id: $id) {
      id
      mandatory_advanced_scheduling {
        minutes
        copy
      }
      available_order_times {
        is_asap_available
        date
        times
      }
    }
  }
`;

export const useAvailableOrderTimes = (cart?: Cart, shouldPoll?: boolean) => {
  const { data, loading, refetch } = useQuery(AVAILABLE_ORDER_TIMES_QUERY, {
    skip: !cart?.id,
    variables: { id: cart?.id },
    fetchPolicy: "network-only",
    pollInterval: shouldPoll && cart?.id ? 1000 * 15 : 0,
  });

  return {
    data: data?.cart?.available_order_times as AvailableOrderTime[] | undefined,
    mandatoryAdvancedScheduling: data?.cart?.mandatory_advanced_scheduling as
      | MandatoryAdvancedScheduling
      | undefined,
    refetch,
    loading,
  };
};
