import { useMemo } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { TipRateType } from "../../../types";
import { Header, Text } from "../../../ui/Typography";
import { classNames } from "../../../utils/classNames";
import { isCustomTip } from "../utils/customTipUtils";
import { CUSTOM_TIP_INDEX, getCustomTipDisplay, getPercentTipDisplay } from "../utils/tipDisplay";
interface TipButtonProps {
  isSelected: boolean;
  onClick: () => void;
  tipRate: TipRateType;
  subtotal: number;
}
const TipButton = ({
  isSelected,
  onClick,
  tipRate,
  subtotal
}: TipButtonProps) => {
  const {
    cart
  } = useCartContext();
  const displayedPrice = useMemo(() => {
    if (tipRate.value === 0) return undefined;
    if (tipRate.value === CUSTOM_TIP_INDEX) {
      return isCustomTip(cart.tip_rate ?? 0) && !!cart.tip ? getCustomTipDisplay(cart.tip || 0) : undefined;
    }
    return getPercentTipDisplay(tipRate.value, subtotal);
  }, [cart.tip, cart.tip_rate, subtotal, tipRate.value]);
  return <div className="flex flex-col items-center grow basis-0" data-sentry-component="TipButton" data-sentry-source-file="TipButton.tsx">
      <button type="button" onClick={onClick} className={classNames("flex items-center justify-center", "w-full px-2 h-[52px] sm:h-14 bg-surface-lighter rounded-lg focus:outline-none ring-border-secondary", "sm:hover:bg-button-outlined-secondary_hover active:ring-border-primary focus:ring-2", isSelected ? "ring-2 ring-border-primary bg-button-outlined-secondary_hover" : "ring-1 ring-border-secondary")}>
        {isSelected ? <Header className="font-semibold text-text-primary" h4>
            {tipRate.displayValue}
          </Header> : <Text large component="p" className="text-text-secondary">
            {tipRate.displayValue}
          </Text>}
      </button>
      {displayedPrice ? <Text component="p" medium className="mt-2 text-text-secondary">
          {displayedPrice}
        </Text> : null}
    </div>;
};
export default TipButton;