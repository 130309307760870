import { useCallback } from "react";
import { User } from "../../Authentication/types";
import { useCartMutations } from "../../Cart/hooks/useCartMutations";
import { Cart } from "../../Cart/types";

const useUpdateCartWithUser = () => {
  const { onUpdateCart } = useCartMutations();

  return useCallback(
    async (cart: Cart, user?: User) => {
      if (!cart.id) {
        return;
      }

      await onUpdateCart({
        id: cart.id,
        firstName: user?.first_name || cart.first_name,
        lastName: user?.last_name || cart.last_name,
        phoneNumber: user?.phone_number || cart.phone_number,
        email: user?.email || cart.email,
      });
    },
    [onUpdateCart],
  );
};

export default useUpdateCartWithUser;
