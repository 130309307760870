import { gql, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { Cart, CartReceipt, CartReceiptLineItem } from "../../Cart/types";

export const WEB_PROVIDER_ID = 6;

export const CART_RECEIPT = gql`
  query CART_RECEIPT($id: String!) {
    cart_receipt(id: $id) {
      id
      coupon {
        id
      }
      line_items {
        line_item_type
        label
        amount
        display_amount
        ordinal
        external_id
        quantity
        slashed_amount
        modifier_text
        tooltip_description
        include_on_cart_receipt
        is_free
      }
    }
  }
`;

type CartReceiptData = {
  cartReceipt: CartReceipt | undefined;
  loading: boolean;
  total: number;
  subtotal: number;
  tax: number;
  onRefetch: () => void;
};

export const useCartReceipt = (cart: Cart): CartReceiptData => {
  const { data, refetch, loading } = useQuery(CART_RECEIPT, {
    skip: !cart?.id,
    variables: {
      id: cart?.id,
      provider_id: WEB_PROVIDER_ID,
    },
    fetchPolicy: "network-only",
  });

  const total = useMemo(() => {
    return data?.cart_receipt?.line_items?.find(
      (item: CartReceiptLineItem) => item.label === "Total",
    )?.amount;
  }, [data]);

  const subtotal = useMemo(() => {
    return data?.cart_receipt?.line_items?.find(
      (item: CartReceiptLineItem) => item.label === "Subtotal",
    )?.amount;
  }, [data]);

  const tax = useMemo(() => {
    return data?.cart_receipt?.line_items?.find(
      (item: CartReceiptLineItem) => item.label === "Tax",
    )?.amount;
  }, [data]);

  const onRefetch = useCallback(() => {
    if (cart?.id && typeof refetch === "function") {
      refetch({
        id: cart.id,
        provider_id: WEB_PROVIDER_ID,
      });
    }
  }, [cart, refetch]);

  return {
    cartReceipt: data?.cart_receipt,
    total,
    subtotal,
    tax,
    loading,
    onRefetch,
  };
};
