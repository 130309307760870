import * as Types from '../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrUpdateDeliveryAddressMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.DeliveryAddressInput>;
  cart_id?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateOrUpdateDeliveryAddressMutation = { __typename?: 'Mutation', create_or_update_delivery_address?: { __typename?: 'DeliveryAddress', id?: string | undefined, street?: string | undefined, city?: string | undefined, state?: string | undefined, zipcode?: string | undefined, unit?: string | undefined, customer_id?: number | undefined, instructions?: string | undefined, geocodes?: { __typename?: 'GeocodeDetails', lat?: number | undefined, lng?: number | undefined } | undefined } | undefined };


export const CreateOrUpdateDeliveryAddressDocument = gql`
    mutation createOrUpdateDeliveryAddress($input: DeliveryAddressInput, $cart_id: String) {
  create_or_update_delivery_address(input: $input, cart_id: $cart_id) {
    id
    street
    city
    state
    zipcode
    unit
    customer_id
    instructions
    geocodes {
      lat
      lng
    }
  }
}
    `;
export type CreateOrUpdateDeliveryAddressMutationFn = Apollo.MutationFunction<CreateOrUpdateDeliveryAddressMutation, CreateOrUpdateDeliveryAddressMutationVariables>;

/**
 * __useCreateOrUpdateDeliveryAddressMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateDeliveryAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateDeliveryAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateDeliveryAddressMutation, { data, loading, error }] = useCreateOrUpdateDeliveryAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *      cart_id: // value for 'cart_id'
 *   },
 * });
 */
export function useCreateOrUpdateDeliveryAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateDeliveryAddressMutation, CreateOrUpdateDeliveryAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateDeliveryAddressMutation, CreateOrUpdateDeliveryAddressMutationVariables>(CreateOrUpdateDeliveryAddressDocument, options);
      }
export type CreateOrUpdateDeliveryAddressMutationHookResult = ReturnType<typeof useCreateOrUpdateDeliveryAddressMutation>;
export type CreateOrUpdateDeliveryAddressMutationResult = Apollo.MutationResult<CreateOrUpdateDeliveryAddressMutation>;
export type CreateOrUpdateDeliveryAddressMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateDeliveryAddressMutation, CreateOrUpdateDeliveryAddressMutationVariables>;