import { NextRouter } from "next/router";

// TODO: Should move to a hook within useRouterQueryParam.ts
export const getPathname = (router: NextRouter) => {
  return router.asPath.split("?")[0];
};

export const getURLSearchParams = (router: NextRouter): URLSearchParams => {
  const searchString = router.asPath.split("?")[1];

  return new URLSearchParams(searchString);
};

export const slugify = (text: string) => {
  if (!text) return "";
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};
