import orderBy from "lodash/orderBy";
import React from "react";
import { usdFormat } from "../../utils/usdFormatter";
import { CartMenuItem, CartModifierItem } from "../Cart/types";
import MenuItemModalDivider from "./components/MenuItemModalDivider";
import MenuItemModalSectionTitle from "./components/MenuItemModalSectionTitle";
import { CartItemAction, DECREASE_MODIFIER_ITEM_QTY, DESELECT_MODIFIER_ITEM, INCREASE_MODIFIER_ITEM_QTY, SELECT_MODIFIER_ITEM } from "./reducers/itemReducer";
import { PublicMenuItem, PublicModifierItem, PublicModifierList } from "./types";
import { classNames } from "@utils/classNames";
type ModifiersProps = {
  cartItem: CartMenuItem;
  cartItemDispatch: React.Dispatch<CartItemAction>;
  publicMenuItem?: PublicMenuItem;
  trackAction: (eventName: string, properties: object) => void;
  nextInvalidModList?: PublicModifierList;
  nextInvalidModRef: React.MutableRefObject<any>;
};
export const Modifiers = ({
  cartItem,
  cartItemDispatch,
  publicMenuItem,
  trackAction,
  nextInvalidModList,
  nextInvalidModRef
}: ModifiersProps) => {
  const trackModifierUpdate = (modifierName: string, modifierId: number, modifierValue: number, modifierPrice: number) => {
    trackAction("Modifier Updated", {
      modifier_name: modifierName,
      modifier_id: modifierId,
      modifier_value: modifierValue,
      modifier_price: modifierPrice,
      currency: "USD"
    });
  };
  return <div className="grow pt-2" data-sentry-component="Modifiers" data-sentry-source-file="Modifiers.tsx">
      {/* orderBy ensures we show required options first */}
      {orderBy(publicMenuItem?.modifier_lists, ["min"], ["desc"]).map((modifierList: PublicModifierList) => {
      let helpText = "";
      let isOptional = true;
      const modifierItemCount = modifierList.modifier_items.reduce((sum, mod) => {
        const modifierItem = cartItem.modifier_items.find(mi => mi.id === mod.id);
        return sum + (modifierItem?.quantity || 0);
      }, 0);
      if (modifierList.min === 0 && modifierList.max === 0) {
        helpText = "Choose as many as you like";
      } else if (modifierList.min === 0 && modifierList.max > 0) {
        helpText = `Choose up to ${modifierList.max}`;
      } else if (modifierList.min === modifierList.max) {
        helpText = `Choose ${modifierList.min}`;
        isOptional = false;
      } else if (modifierList.max > modifierList.min) {
        helpText = `Choose between ${modifierList.min} and ${modifierList.max}`;
        isOptional = false;
      } else {
        helpText = `Choose at least ${modifierList.min}`;
        isOptional = false;
      }
      return <div key={`modifiers-${modifierList.id}`}>
              <MenuItemModalDivider />
              <div id={`list-${modifierList.id}`} key={`list-${modifierList.id}`} className="mb-4 relative px-4 mt-4">
                <div ref={nextInvalidModList?.id === modifierList.id ? nextInvalidModRef : null} className="absolute -top-24" />
                <fieldset name={`modifier-fieldset-${modifierList.id}`}>
                  <MenuItemModalSectionTitle title={modifierList.name} subtitle={helpText} pillText={isOptional ? "Optional" : "Required"} />
                  <div className="mt-4 space-y-4">
                    {modifierList.modifier_items.map((modifierItem: PublicModifierItem) => {
                const cartModifierItem = cartItem.modifier_items.find(mi => mi.id === modifierItem.id);
                const unavailableClass = modifierItem.is_active ? "cursor-pointer" : "cursor-not-allowed line-through opacity-60";
                return <div key={`list-${modifierList.id}-item-${modifierItem.id}`} className={`flex items-center ${unavailableClass}`}>
                            {modifierList.is_single_select ? <Checkbox modifierItem={modifierItem} cartModifierItem={cartModifierItem} trackModifierUpdate={trackModifierUpdate} cartItemDispatch={cartItemDispatch} modifierList={modifierList} unavailableClass={unavailableClass} /> : <NumberSelect modifierItem={modifierItem} cartModifierItem={cartModifierItem} modifierList={modifierList} trackModifierUpdate={trackModifierUpdate} cartItemDispatch={cartItemDispatch} unavailableClass={unavailableClass} modifierItemCount={modifierItemCount} />}
                            <label onClick={() => {
                    if (modifierItem.is_active) {
                      trackModifierUpdate(modifierItem.name, modifierItem.id, cartModifierItem?.quantity ? 0 : 1, modifierItem.price);
                      if (modifierList.is_single_select) {
                        cartItemDispatch({
                          type: cartModifierItem?.quantity ? DESELECT_MODIFIER_ITEM : SELECT_MODIFIER_ITEM,
                          modifierListId: modifierList.id,
                          modifierItemId: modifierItem.id
                        });
                      } else {
                        cartItemDispatch({
                          type: INCREASE_MODIFIER_ITEM_QTY,
                          modifierListId: modifierList.id,
                          modifierItemId: modifierItem.id
                        });
                      }
                    }
                  }} htmlFor={`modifier-item-${modifierItem.id}`} className={`${unavailableClass} select-none flex w-full justify-between`}>
                              <div className={`text-sm sm:text-base ${unavailableClass}`}>
                                {modifierItem.name}
                              </div>
                              <div className={classNames("ml-4", cartModifierItem?.quantity ? "text-text-primary" : "text-text-tertiary")}>
                                {modifierItem.price ? usdFormat((cartModifierItem?.quantity || 1) * modifierItem.price) : null}
                              </div>
                            </label>
                          </div>;
              })}
                  </div>
                </fieldset>
              </div>
            </div>;
    })}
    </div>;
};
type CheckboxProps = {
  modifierItem: PublicModifierItem;
  cartModifierItem?: CartModifierItem;
  modifierList: PublicModifierList;
  trackModifierUpdate: (modifierName: string, modifierId: number, modifierValue: number, modifierPrice: number) => void;
  cartItemDispatch: React.Dispatch<CartItemAction>;
  unavailableClass: string;
};
const Checkbox = ({
  modifierItem,
  cartModifierItem,
  modifierList,
  trackModifierUpdate,
  cartItemDispatch,
  unavailableClass
}: CheckboxProps) => <div onClick={() => {
  if (modifierItem.is_active) {
    trackModifierUpdate(modifierItem.name, modifierItem.id, cartModifierItem?.quantity ? 0 : 1, modifierItem.price);
    cartItemDispatch({
      type: cartModifierItem?.quantity ? DESELECT_MODIFIER_ITEM : SELECT_MODIFIER_ITEM,
      modifierListId: modifierList.id,
      modifierItemId: modifierItem.id
    });
  }
}} className={classNames(cartModifierItem?.quantity ? "border-lfg-primary bg-lfg-primary" : "border-lfg-secondary bg-surface-lighter", unavailableClass, "border-2 rounded-md w-6 h-6 flex shrink-0 justify-center items-center mr-2 focus-within:border-lfg-secondary")} data-sentry-component="Checkbox" data-sentry-source-file="Modifiers.tsx">
    <svg className={classNames("fill-current w-3 h-3 text-lfg-primary pointer-events-none", cartModifierItem?.quantity ? "block" : "hidden")} version="1.1" viewBox="0 0 17 12" xmlns="https://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Modifiers.tsx">
      <g fill="none" fillRule="evenodd" data-sentry-element="g" data-sentry-source-file="Modifiers.tsx">
        <g transform="translate(-9 -11)" fill="#ffffff" fillRule="nonzero" data-sentry-element="g" data-sentry-source-file="Modifiers.tsx">
          <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" data-sentry-element="path" data-sentry-source-file="Modifiers.tsx" />
        </g>
      </g>
    </svg>
  </div>;
type NumberSelectProps = {
  cartModifierItem?: CartModifierItem;
  modifierItem: PublicModifierItem;
  modifierList: PublicModifierList;
  trackModifierUpdate: (modifierName: string, modifierId: number, modifierValue: number, modifierPrice: number) => void;
  cartItemDispatch: React.Dispatch<CartItemAction>;
  unavailableClass: string;
  modifierItemCount: number;
};
const NumberSelect = ({
  cartModifierItem,
  modifierItem,
  modifierList,
  trackModifierUpdate,
  cartItemDispatch,
  unavailableClass,
  modifierItemCount
}: NumberSelectProps) => <div className="flex items-center" data-sentry-component="NumberSelect" data-sentry-source-file="Modifiers.tsx">
    <button type="button" disabled={!cartModifierItem?.quantity} onClick={() => {
    if (modifierItem.is_active) {
      trackModifierUpdate(modifierItem.name, modifierItem.id, cartModifierItem?.quantity ? cartModifierItem?.quantity - 1 : 0, modifierItem.price);
      cartItemDispatch({
        type: DECREASE_MODIFIER_ITEM_QTY,
        modifierListId: modifierList.id,
        modifierItemId: modifierItem.id
      });
    }
  }} className={classNames(!cartModifierItem?.quantity && "opacity-50 cursor-not-allowed", unavailableClass, `relative inline-flex items-center px-2 py-2 rounded-l-md border
        border-border-secondary bg-surface-lighter text-sm font-lfg-normal
        text-text-secondary hover:bg-button-outlined-secondary_hover focus:outline-none`)}>
      <span className="sr-only">Less</span>
      <svg className="h-5 w-5" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-source-file="Modifiers.tsx">
        <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="Modifiers.tsx" />
      </svg>
    </button>
    <div className="w-10 justify-center relative inline-flex items-center px-2 py-2
      border-t border-b border-border-secondary bg-surface-lighter text-sm
      font-lfg-normal text-text-secondary">
      {cartModifierItem?.quantity || 0}
    </div>
    <button type="button" disabled={modifierList.max > 0 && modifierItemCount >= modifierList.max} onClick={() => {
    if (modifierItem.is_active) {
      trackModifierUpdate(modifierItem.name, modifierItem.id, cartModifierItem?.quantity ? cartModifierItem?.quantity + 1 : 1, modifierItem.price);
      cartItemDispatch({
        type: INCREASE_MODIFIER_ITEM_QTY,
        modifierListId: modifierList.id,
        modifierItemId: modifierItem.id
      });
    }
  }} className={classNames(modifierList.max > 0 && modifierItemCount >= modifierList.max && "opacity-50 cursor-not-allowed", unavailableClass, `mr-2 relative inline-flex items-center px-2 py-2 rounded-r-md border
        border-border-secondary bg-surface-lighter text-sm font-lfg-normal text-text-secondary
        hover:bg-button-outlined-secondary_hover focus:outline-none`)}>
      <span className="sr-only">More</span>
      <svg className="h-5 w-5" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-source-file="Modifiers.tsx">
        <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="Modifiers.tsx" />
      </svg>
    </button>
  </div>;