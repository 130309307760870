globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"W2iEGJcyYARn3k7UXSIoK"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const IGNORE_LIST = [
  /load failed/i,
  /network error/i,
  /Failed to load Stripe/i,
  /a.adroll.push/i,
];

export const shouldIgnoreError = (error: unknown) => {
  if (!(error instanceof Error)) {
    return false;
  }
  if (IGNORE_LIST.some((ignore) => error?.message?.match(ignore))) {
    return true;
  }
  return false;
};

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  integrations: [
    Sentry.rewriteFramesIntegration(),
    Sentry.captureConsoleIntegration({ levels: ["error"] }),
    Sentry.extraErrorDataIntegration(),
    Sentry.contextLinesIntegration(),
    Sentry.httpClientIntegration(),
  ],
  tracesSampleRate: 1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event, hint) {
    const maybeError = hint?.originalException;
    if (shouldIgnoreError(maybeError)) {
      return null;
    }
    return event;
  },
});
