import { PostPasscodeSignupData } from "@utils/shopBackend/types";
import { useEffect, useState } from "react";
import { LoadingSpinner } from "../../../ui/Loading/LoadingSpinner";
import Modal from "../../../ui/Modal/NewModal";
import { Header, Link, Text } from "../../../ui/Typography";
import { classNames } from "../../../utils/classNames";
import { parsePhoneNumberNational } from "../../../utils/phoneNumber";
import usePostPasscode from "../hooks/usePostPasscode";
import useRequestPasscode from "../hooks/useRequestPasscode";
import { PasscodeLoginMethod, User } from "../types";
import CodeInput, { CODE_LENGTH } from "./CodeInput";
interface VerifyCodeModalProps {
  showModal: boolean;
  closeModal: () => void;
  identifier?: string | null;
  method?: PasscodeLoginMethod | null;
  onSuccess?: (data: User) => void;
  onNotFound?: (data: PostPasscodeSignupData) => void;
}
const VerifyCodeModal = ({
  showModal,
  closeModal,
  identifier,
  onSuccess,
  onNotFound,
  method
}: VerifyCodeModalProps) => {
  const isCheckout = (typeof window !== "undefined" ? window.location.href : "").includes("checkout");
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [codeError, setCodeError] = useState<string | null>(null);
  // This is to prevent the code input from focusing on the first render
  // causing UI to jump
  const [allowFocus, setAllowFocus] = useState(false);
  const {
    isPending: isPostCodePending,
    mutate: postPasscode
  } = usePostPasscode({
    onSuccess: data => {
      onSuccess?.(data);
      closeModal();
    },
    onNotFound: data => {
      onNotFound?.(data);
      closeModal();
    },
    onCodeError: () => setCodeError("Incorrect code. Please try again."),
    onCodeExpired: () => setCodeError("Your code has expired. Please request a new one.")
  });
  const {
    isPending: isRequestPasscodePending,
    mutate: requestPasscode
  } = useRequestPasscode();

  // Resets modal state
  useEffect(() => {
    setCode(["", "", "", "", "", ""]);
    setCodeError(null);
    setAllowFocus(false);
  }, [showModal]);
  useEffect(() => {
    if (!identifier || !method || code.join("").length !== CODE_LENGTH) {
      return;
    }
    postPasscode({
      identifier,
      passcode: code.join(""),
      method
    });
  }, [code, identifier, method, postPasscode]);
  const loginContactIdentifier = identifier === PasscodeLoginMethod.Sms ? parsePhoneNumberNational(identifier) : identifier;
  const isLoading = isPostCodePending || isRequestPasscodePending;
  return <Modal size="large" showModal={showModal} closeModal={closeModal} onAnimationComplete={definition => {
    if (definition === "exit") {
      setAllowFocus(false);
      return;
    }
    setAllowFocus(true);
  }} title="Code verification" data-sentry-element="Modal" data-sentry-component="VerifyCodeModal" data-sentry-source-file="VerifyCodeModal.tsx">
      <div className="mt-4 sm:mt-16 sm:mx-10 sm:mb-8 relative">
        {isLoading && <div className="absolute inset-0 flex items-center justify-center bg-surface-lighter">
            <LoadingSpinner />
          </div>}
        <div className={classNames(isLoading && "invisible")}>
          <Header h1 data-sentry-element="Header" data-sentry-source-file="VerifyCodeModal.tsx">{"What's the code?"}</Header>
          <Text large component="p" className="text-text-secondary" data-sentry-element="Text" data-sentry-source-file="VerifyCodeModal.tsx">
            {"Enter the 6 digit code sent to you at "}
            <span className="font-semibold whitespace-nowrap">
              {loginContactIdentifier}
            </span>
            {isCheckout ? " to use rewards and discounts." : ""}
          </Text>
          <CodeInput code={code} hasError={!!codeError} clearError={() => setCodeError(null)} setCode={setCode} className="mt-4" allowFocus={allowFocus} data-sentry-element="CodeInput" data-sentry-source-file="VerifyCodeModal.tsx" />
          {codeError && <Text medium className="text-new-error mt-2">
              {codeError}
            </Text>}
          <Header h4 className="text-text-secondary mt-6" data-sentry-element="Header" data-sentry-source-file="VerifyCodeModal.tsx">
            {"Didn't get it? "}
            <Link underLine="solid" variant="primary" onClick={() => {
            if (!identifier || !method) {
              return;
            }
            requestPasscode({
              identifier,
              method
            });
          }} className="text-new-black-base" data-sentry-element="Link" data-sentry-source-file="VerifyCodeModal.tsx">
              Resend Code
            </Link>
          </Header>
          <Header h4 className="mt-6 text-text-secondary" data-sentry-element="Header" data-sentry-source-file="VerifyCodeModal.tsx">
            {"Having trouble? "}
            <Link underLine="solid" variant="primary" to="mailto:guest@localkitchens.com" className="text-new-black-base" data-sentry-element="Link" data-sentry-source-file="VerifyCodeModal.tsx">
              guest@localkitchens.com
            </Link>
          </Header>
        </div>
      </div>
    </Modal>;
};
export default VerifyCodeModal;