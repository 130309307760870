import { useQuery } from "@tanstack/react-query";
import { ShopBackendClient } from "../../../utils/shopBackend/apiClient";
import { useAuth } from "../../Authentication/hooks/useAuth";
export const useCashRewardLoyalty = () => {
  const {
    isAuthenticated,
    user
  } = useAuth();
  const {
    data,
    isFetched,
    refetch
  } = useQuery({
    queryKey: ["cashRewardLoyalty", user?.id],
    queryFn: ShopBackendClient.loyalty,
    enabled: isAuthenticated,
    retry: 3,
    refetchOnWindowFocus: false
  });
  return {
    data: data?.data,
    loading: !isFetched,
    refetch
  };
};