import { classNames } from "../utils/classNames";
import CheckIcon from "./Icons/CheckIcon";
interface SwitchProps {
  disabled?: boolean;
  error?: boolean;
  checked: boolean;
  toggle: () => void;
}
const Switch = ({
  disabled,
  checked,
  error,
  toggle
}: SwitchProps) => {
  return <label className="relative inline-flex items-center cursor-pointer" data-sentry-component="Switch" data-sentry-source-file="Switch.tsx">
      <input type="checkbox" onChange={toggle} className="sr-only peer" checked={checked} disabled={disabled} />
      <div className={classNames("relative w-9 h-5 bg-border-secondary rounded-full peer peer-focus:bg-border-secondary peer-checked:peer-hover:bg-surface-dark peer-hover:bg-border-primary peer-checked:peer-focus:bg-new-black-base peer-checked:bg-surface-dark", error && "bg-new-error")}>
        <span className="absolute left-0 m-1">
          <CheckIcon className="w-3 h-3 text-new-white" data-sentry-element="CheckIcon" data-sentry-source-file="Switch.tsx" />
        </span>
        <span className={classNames("absolute w-4 h-4 left-0.5 top-0.5  border-white bg-surface-lighter rounded-full transition-all", checked && "translate-x-full")} />
      </div>
    </label>;
};
export default Switch;