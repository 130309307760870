import { useEffect, useState } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import NoticeBadge from "../../../ui/Notice/NoticeBadge";
import QuantitySelector from "../../../ui/QuantitySelector";
import { Header, Link, Text } from "../../../ui/Typography";
import { getIsItemAvailable, getModifierValidationText, getOpenStatusMessage } from "../../../utils/cart";
import useDebounce from "../../../utils/debounce";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { CartMenuItem, CartReceiptLineItem } from "../../Cart/types";
import { PublicStore } from "../../Stores/types";
interface ReceiptCartItemProps {
  item: CartReceiptLineItem;
  cartItem: CartMenuItem;
  stores: Record<number, PublicStore>;
  canMutateItem?: boolean;
  onUpdateQuantity: (item: CartMenuItem | undefined, quantity: number) => void;
  onRemoveClick: (item?: CartMenuItem) => void;
  onModifyClick: (item?: CartMenuItem) => void;
  removeItem: () => void;
}
const ReceiptCartItem = ({
  item,
  cartItem,
  stores,
  canMutateItem = true,
  onUpdateQuantity,
  onRemoveClick,
  onModifyClick,
  removeItem
}: ReceiptCartItemProps) => {
  const {
    cart
  } = useCartContext();
  const {
    track
  } = useTrackWithFlags();
  const [quantity, setQuantity] = useState(cartItem.quantity ?? 1);
  const debounceQuantity = useDebounce(quantity, 500);
  const store = cartItem.store_id !== undefined ? stores[cartItem.store_id] : undefined;
  const isItemAvailable = getIsItemAvailable(cartItem, cart) && !!store?.is_open;
  const storeStatusMessage = !isItemAvailable ? "Unavailable" : getOpenStatusMessage(store);
  const modifierValidationError = getModifierValidationText(cartItem, cart);
  const hasError = !isItemAvailable || !!modifierValidationError;
  const modifierText = (() => {
    if (!isItemAvailable) {
      return "Sold out. Remove item";
    }
    if (modifierValidationError) {
      return modifierValidationError;
    }
    return item.modifier_text;
  })();
  useEffect(() => {
    if (debounceQuantity === cartItem?.quantity) return;
    onUpdateQuantity(cartItem, debounceQuantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceQuantity]);
  useEffect(() => {
    if (cartItem?.quantity !== undefined) {
      setQuantity(cartItem.quantity);
    }
  }, [cartItem.quantity]);
  const minusDisabled = quantity <= 0 || !canMutateItem || hasError || item.is_free;
  const plusDisabled = !canMutateItem || quantity >= 99 || hasError || item.is_free;
  return <li data-sentry-component="ReceiptCartItem" data-sentry-source-file="ReceiptCartItem.tsx">
      <div className="flex justify-between gap-4">
        <div className="flex items-start">
          <QuantitySelector
        // We have a bug with the same quantity if we are using coupons in the cart for free items
        // To avoid this we are using the quantity from the line item as the temporary fix
        quantity={item.quantity ?? 1} minusDisabled={minusDisabled} plusDisabled={plusDisabled} onPlusClick={() => {
          track({
            event: "Clicked Quantity Selector",
            properties: {
              source: "checkout",
              action: "increment"
            }
          });
          setQuantity(previous => {
            if (previous < 99) {
              return previous + 1;
            }
            return previous;
          });
        }} onMinusClick={() => {
          track({
            event: "Clicked Quantity Selector",
            properties: {
              source: "checkout",
              action: "decrement"
            }
          });
          setQuantity(previous => {
            if (previous === 1) {
              onRemoveClick(cartItem);
              return previous;
            }
            return previous - 1;
          });
        }} className="mr-4" data-sentry-element="QuantitySelector" data-sentry-source-file="ReceiptCartItem.tsx" />
          <div>
            <Header h4 className={hasError ? "text-new-error" : ""} data-sentry-element="Header" data-sentry-source-file="ReceiptCartItem.tsx">
              {item.label}
            </Header>
            <Text className={hasError ? "text-new-error" : "text-text-secondary"} small data-sentry-element="Text" data-sentry-source-file="ReceiptCartItem.tsx">
              {modifierText}
            </Text>
            {storeStatusMessage && <NoticeBadge type={hasError ? "error" : "warning"} text={storeStatusMessage} className="mt-2" />}
            {canMutateItem && !item.is_free && <div className="flex flex-row gap-4">
                {isItemAvailable && <Link size="small" variant="secondary" underLine="solid" onClick={() => {
              track({
                event: "Clicked Modify Item",
                properties: {
                  source: "checkout"
                }
              });
              onModifyClick(cartItem);
            }}>
                    Modify
                  </Link>}
                <Link size="small" variant="secondary" underLine="solid" onClick={() => {
              track({
                event: "Clicked Remove Item",
                properties: {
                  source: "checkout"
                }
              });
              isItemAvailable ? onRemoveClick(cartItem) : removeItem();
            }}>
                  Remove
                </Link>
              </div>}
          </div>
        </div>
        {item.is_free ? <Text large>
            <span className="text-new-green-base mr-2">$0.00</span>
            <span className="line-through">{item.display_amount}</span>
          </Text> : <Text large>{item.display_amount}</Text>}
      </div>
    </li>;
};
export default ReceiptCartItem;