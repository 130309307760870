import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useState } from "react";

/**
 * Singleton to manage Google Maps API loading
 *
 * @typedef {Object} GoogleMapsService
 * @property {Object} google - The Google Maps API object.
 * @property {function} load - A function that loads the Google Maps API.
 */
const GoogleMapsService: {
  google: typeof google | undefined;
  load: () => Promise<typeof google>;
} = {
  google: undefined,

  /**
   * Loads the Google Maps API and returns a promise.
   * The API is only loaded if it hasn't already been loaded.
   *
   * @returns {Promise} A promise that resolves with the Google Maps API object.
   */

  load: () => {
    if (GoogleMapsService.google) {
      return Promise.resolve(GoogleMapsService.google);
    }
    return new Promise((resolve) => {
      new Loader({
        apiKey: process.env.NEXT_PUBLIC_GCC_PLACES_API_KEY as string,
        version: "weekly",
        libraries: ["places"],
        language: "en",
        region: "US",
      })
        .load()
        .then((googleInstance: typeof google) => {
          GoogleMapsService.google = googleInstance;
          resolve(googleInstance);
        })
        .catch((e: any) => {
          console.log(e);
        });
    });
  },
};

/**
 * Custom hook that returns a boolean indicating if Google Maps API is ready.
 * If the API is not already loaded, it will be loaded.
 *
 * @returns {boolean} Indicates if the Google Maps API is ready.
 */
export const useIsGoogleMapsLoaded = () => {
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(
    Boolean(GoogleMapsService.google),
  );

  useEffect(() => {
    if (!isGoogleLoaded) {
      GoogleMapsService.load().then(() => {
        setIsGoogleLoaded(true);
      });
    }
  }, []);

  return isGoogleLoaded;
};
