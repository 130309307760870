import { faPepperHot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "@ui/Typography";
import { useMemo } from "react";
import { PublicMenuItem } from "../MenuItemModal/types";
const DIETARY_SLUGS = ["vegetarian", "vegan", "glutenfree", "spicy"];
const DIETARY_ELEMENTS: {
  [key: string]: JSX.Element;
} = {
  vegetarian: <DietaryTag key="veg-dietary-icon" id="veg-dietary-icon" text="V" tooltipText="Depending on your chosen modifications, this item may be made vegetarian." />,
  vegan: <DietaryTag key="vegan-dietary-icon" id="vegan-dietary-icon" text="VG" tooltipText="Depending on your chosen modifications, this item may be made vegan." />,
  glutenfree: <DietaryTag key="gf-dietary-icon" id="gf-dietary-icon" text="GF-Option" tooltipText="Trace amounts of gluten are possible as the items are produced in a facility that contain gluten. Some modifiers may also contain gluten." />,
  spicy: <SpicyTag key="spicy-dietary-icon" tooltipText="This item is spicy." />
};
export const TagDescriptors = ({
  menuItem
}: {
  menuItem?: PublicMenuItem;
  className?: string;
}) => {
  const tags = useMemo(() => {
    return (menuItem?.tags ?? []).filter(tag => DIETARY_SLUGS.includes(tag.slug) && tag.is_v2);
  }, [menuItem]);
  return tags.length > 0 ? tags.map(tag => {
    return DIETARY_ELEMENTS[tag.slug];
  }) : null;
};
function DietaryTag({
  id,
  text,
  tooltipText
}: {
  id: string;
  text: string;
  tooltipText: string;
}) {
  return <div id={id} className="relative px-2 border border-1 border-border-secondary rounded h-5 " data-tooltip-id="tag-description" data-tooltip-content={tooltipText} data-sentry-component="DietaryTag" data-sentry-source-file="DietaryTooltips.tsx">
      <div className="flex items-center h-full peer">
        <Text className="peer text-text-secondary truncate" eyebrow data-sentry-element="Text" data-sentry-source-file="DietaryTooltips.tsx">
          {text}
        </Text>
      </div>
    </div>;
}
function SpicyTag({
  tooltipText
}: {
  tooltipText: string;
}) {
  return <div className="relative flex justify-center items-center w-5 h-5 bg-surface-lighter border border-1 border-border-secondary rounded peer" data-tooltip-id="tag-description" data-tooltip-content={tooltipText} data-sentry-component="SpicyTag" data-sentry-source-file="DietaryTooltips.tsx">
      <FontAwesomeIcon icon={faPepperHot} className="text-new-orange-base text-[10px]" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="DietaryTooltips.tsx" />
    </div>;
}