export const notEmpty = <TValue>(
  value: TValue | null | undefined,
): value is TValue => {
  return value !== null && value !== undefined;
};

export const ensureDefinedItems = <T>(
  array: Array<T | undefined>,
): Array<T> => {
  return array.filter(notEmpty);
};
