import RouterLink, { LinkProps } from "next/link";
import { ButtonHTMLAttributes } from "react";
import { UrlObject } from "url";
import { classNames } from "../../utils/classNames";
import { textStyles } from "./Text";
import { TypographyBaseProps } from "./types";
type LinkSize = "large" | "small";
type LinkVariant = "primary" | "secondary";
type LinkUnderLine = "solid" | "dashed";
type LinkTextBaseProps = {
  size?: LinkSize;
  variant?: LinkVariant;
  underLine?: LinkUnderLine;
} & Omit<TypographyBaseProps, "component">;
type AnchorProps = {
  to: string | UrlObject;
} & LinkTextBaseProps & Omit<LinkProps, "href">;
type ButtonProps = LinkTextBaseProps & ButtonHTMLAttributes<HTMLButtonElement>;
type SpanProps = LinkTextBaseProps & Omit<React.HTMLProps<HTMLSpanElement>, "size" | "onClick">;
type LinkTextProps = AnchorProps | ButtonProps | SpanProps;
const linkVariants: Record<LinkVariant, Record<LinkSize, string>> = {
  primary: {
    large: classNames(textStyles.linkLarge, "text-new-black-base"),
    small: textStyles.linkSmall
  },
  secondary: {
    large: classNames(textStyles.linkLarge, "text-text-secondary"),
    small: classNames(textStyles.linkSmall, "text-text-secondary")
  }
};
const underLineVariants: Record<LinkUnderLine, string> = {
  solid: "underline-offset-4 decoration-solid",
  dashed: "underline-offset-[6px] decoration-1 decoration-dashed"
};
export const Link = ({
  className,
  size = "large",
  variant = "primary",
  underLine,
  children,
  ...props
}: LinkTextProps) => {
  if ("to" in props) {
    return <RouterLink {...props} href={props.to} className={classNames(linkVariants[variant][size], underLine && underLineVariants[underLine], className)}>
        {children}
      </RouterLink>;
  }
  if ("onClick" in props) {
    return <button type="button" {...props} className={classNames(linkVariants[variant][size], underLine && underLineVariants[underLine], className)}>
        {children}
      </button>;
  }
  return <span {...props} className={classNames(linkVariants[variant][size], underLine && underLineVariants[underLine], className)} data-sentry-component="Link" data-sentry-source-file="Link.tsx">
      {children}
    </span>;
};