import { CheckIcon, ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
const bgColorMap = {
  success: "bg-black",
  info: "bg-lfg-secondary",
  error: "bg-red",
  warning: "bg-orange"
};
export type AlertProps = {
  description?: string;
  variant: "success" | "info" | "error" | "warning";
  onClose?: () => void;
};
export const Alert = ({
  variant,
  description,
  onClose
}: AlertProps) => {
  const bgColor = bgColorMap[variant] || "yellow";
  const textColor = "text-white";
  const xIconColor = `${textColor}-500`;
  const onClick = () => {
    onClose?.();
  };
  return <div className={`flex ${bgColor} ${textColor} px-4 py-3 rounded shadow-md`} onClick={onClick} data-sentry-component="Alert" data-sentry-source-file="Alert.tsx">
      {variant === "info" ? <InformationCircleIcon className={`flex-shrink-0 w-6 h-6 ${textColor}`} /> : null}
      {variant === "success" ? <CheckIcon className={`flex-shrink-0 w-6 h-6 ${textColor}`} /> : null}
      {variant === "error" ? <ExclamationCircleIcon className={`flex-shrink-0 w-6 h-6 ${textColor}`} /> : null}
      {variant === "warning" ? <ExclamationCircleIcon className={`flex-shrink-0 w-6 h-6 ${textColor}`} /> : null}
      {description && <div className="w-full pl-3 pr-10">
          <span className="block">{description}</span>
        </div>}
      {onClose && <span>
          <svg className={`fill-current h-6 w-6 ${xIconColor}`} role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>}
    </div>;
};