import { useRouter } from "next/router";
import { useCallback } from "react";

export const useRouterQueryParam = <T = string>(paramKey: string) => {
  const router = useRouter();
  const value = router.query[paramKey];

  return ((Array.isArray(value) ? value[0] : value) ?? "") as T;
};

// Custom hook to remove specified query parameters
export const useQueryParamsRemover = () => {
  const router = useRouter();

  const removeQueryParams = useCallback(
    (paramsToRemove: string[]) => {
      const newQueryParams = { ...router.query };
      paramsToRemove.forEach((param) => delete newQueryParams[param]);

      router.replace(
        {
          pathname: router.pathname,
          query: newQueryParams,
        },
        undefined,
        { shallow: true }, // Update path of the current page without rerunning
      );
    },
    [router],
  );

  return removeQueryParams;
};
