import { useEffect, useState } from "react";
import { useOrderCountQuery } from "../../../graphql/orderCount.query.generated";

/**
 * Many of our downstream analytics tools like Google and Facebook ads need to know whether this order is the first
 * or not. Additionally, it is helpful to know the rank of the given order in various downstream tools like Amplitude.
 * This hook returns the count of store_orders existing for the given phoneNumber, allowing us to index the `Order
 * Completed` event.
 *
 * @param phoneNumber
 * @returns number
 */
export const useOrderCount = (phoneNumber?: string) => {
  const [lastKnownOrderCount, setLastKnownOrderCount] = useState<number>();
  const { data, updateQuery } = useOrderCountQuery({
    variables: {
      phoneNumber,
    },
    skip: !phoneNumber,
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data?.order_count !== undefined) {
      setLastKnownOrderCount(data.order_count);
    }
  }, [data?.order_count]);

  return {
    orderCount: lastKnownOrderCount,
    updateOrderCount: updateQuery,
  };
};
