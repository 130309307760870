import { TypographyBaseProps } from "./types";
type ButtonTextProps = {
  size: keyof typeof textStyles;
} & TypographyBaseProps;
const textStyles = {
  large: "button-large text-[16px] leading-[19px] tracking-[0.016px] font-semibold",
  small: "button-small text-[14px] leading-[16px] tracking-[0.001px] font-semibold"
};
export const ButtonText = ({
  component,
  size,
  className,
  ...rest
}: ButtonTextProps) => {
  const Component = component || "span";
  return <Component className={`text-inherit ${textStyles[size]} ${className}`} {...rest} data-sentry-element="Component" data-sentry-component="ButtonText" data-sentry-source-file="ButtonText.tsx" />;
};