import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCartMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.UpdateCartInput>;
}>;


export type UpdateCartMutation = { __typename?: 'Mutation', update_cart?: { __typename?: 'Cart', id?: string | undefined, phone_number?: string | undefined, location?: string | undefined, first_name?: string | undefined, last_name?: string | undefined, order_time?: string | undefined, is_scheduled?: boolean | undefined, is_delivery?: boolean | undefined, tip_rate?: number | undefined, tip?: number | undefined, customer_id?: number | undefined, items?: Array<any | undefined> | undefined, stripe_payment_intent_id?: string | undefined, email?: string | undefined, start_buffer_time?: number | undefined, use_bag?: boolean | undefined, pickup_type?: Types.PickupType | undefined, coupon?: { __typename?: 'Coupon', id?: number | undefined, name?: string | undefined, code?: string | undefined, discount_rate?: number | undefined, max_amount?: number | undefined, credits?: number | undefined, brand_attrs?: any | undefined } | undefined, delivery_address?: { __typename?: 'DeliveryAddress', id?: string | undefined, street?: string | undefined, city?: string | undefined, state?: string | undefined, zipcode?: string | undefined, unit?: string | undefined, customer_id?: number | undefined, instructions?: string | undefined, is_default?: boolean | undefined } | undefined, participants?: Array<{ __typename?: 'Participant', id: string, first_name: string, last_name?: string | undefined, phone_number?: string | undefined, items?: Array<any | undefined> | undefined, customer_id?: string | undefined, is_creator?: boolean | undefined } | undefined> | undefined } | undefined };


export const UpdateCartDocument = gql`
    mutation updateCart($input: UpdateCartInput) {
  update_cart(input: $input) {
    id
    phone_number
    location
    first_name
    last_name
    order_time
    is_scheduled
    is_delivery
    tip_rate
    tip
    customer_id
    coupon {
      id
      name
      code
      discount_rate
      max_amount
      credits
      brand_attrs
    }
    delivery_address {
      id
      street
      city
      state
      zipcode
      unit
      customer_id
      instructions
      is_default
    }
    items
    participants {
      id
      first_name
      last_name
      phone_number
      items
      customer_id
      is_creator
      items
    }
    stripe_payment_intent_id
    email
    start_buffer_time
    use_bag
    pickup_type
  }
}
    `;
export type UpdateCartMutationFn = Apollo.MutationFunction<UpdateCartMutation, UpdateCartMutationVariables>;

/**
 * __useUpdateCartMutation__
 *
 * To run a mutation, you first call `useUpdateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartMutation, { data, loading, error }] = useUpdateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartMutation, UpdateCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCartMutation, UpdateCartMutationVariables>(UpdateCartDocument, options);
      }
export type UpdateCartMutationHookResult = ReturnType<typeof useUpdateCartMutation>;
export type UpdateCartMutationResult = Apollo.MutationResult<UpdateCartMutation>;
export type UpdateCartMutationOptions = Apollo.BaseMutationOptions<UpdateCartMutation, UpdateCartMutationVariables>;