import { useCartContext } from "@appComponents/hooks/useCartContext";
import { useToast } from "@appComponents/hooks/useToast";
import { useCartMutations } from "@features/Cart/hooks/useCartMutations";
import { PickupType } from "@graphql/types";
import { ConfirmationModal } from "@ui/ConfirmationModal/ConfirmationModal";
export type LeavePoolsideOrderModalConfig = {
  show: boolean;
  onConfirm?: () => void;
};
type LeavePoolsideOrderModalProps = {
  config: LeavePoolsideOrderModalConfig;
  closeModal: () => void;
};
const LeavePoolsideOrderModal = ({
  config: {
    show,
    onConfirm
  },
  closeModal
}: LeavePoolsideOrderModalProps) => {
  const {
    cart
  } = useCartContext();
  const {
    showToast
  } = useToast();
  const {
    onUpdateCart,
    isLoadingUpdateCart
  } = useCartMutations();
  const onConfirmLeavePoolsideOrder = async () => {
    if (!cart?.id) {
      return;
    }
    try {
      await onUpdateCart({
        id: cart.id,
        pickupType: PickupType.Regular
      });
      onConfirm?.();
      showToast({
        description: "Successfully left poolside order!",
        variant: "success"
      });
      closeModal();
    } catch {
      showToast({
        description: "Failed to leave poolside order!",
        variant: "error"
      });
    }
  };
  return <ConfirmationModal show={show} closeModal={closeModal} header="Leave poolside order?" message="You will not be able to pickup your order from the poolside if you change your order type." confirmButton={{
    label: "Leave poolside order",
    loading: isLoadingUpdateCart,
    onClick: onConfirmLeavePoolsideOrder
  }} cancelButton={{
    label: "Nevermind",
    onClick: closeModal
  }} data-sentry-element="ConfirmationModal" data-sentry-component="LeavePoolsideOrderModal" data-sentry-source-file="LeavePoolsideOrderModal.tsx" />;
};
export default LeavePoolsideOrderModal;