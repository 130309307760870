import { DateTime } from "luxon";
import { getPickupTimeRangeStr } from "../../../utils/storeHours";
import { AvailableOrderTime } from "../../Cart/types";
import { OrderDay, OrderDayScrollItem } from "./../types";

export const getAsapDescription = (
  isAvailable: boolean,
  startTime?: number,
) => {
  if (!isAvailable) {
    return "Not available";
  }

  return startTime ? `${getPickupTimeRangeStr(startTime, 10)} min` : "";
};

export const getScheduledDescription = (
  isSelected: boolean,
  orderTime?: string,
) => {
  if (!isSelected || !orderTime) {
    return "Pick a time";
  }

  return parseTime(orderTime);
};

const yyyymmddToLocalDate = (isoString: string) => {
  const [year, month, day] = isoString.split("-").map((x) => parseInt(x, 10));
  return DateTime.fromObject({
    year,
    month,
    day,
    zone: "America/Los_Angeles", // TODO: Pull from API instead
  });
};

export const unixTimestampToLocalTime = (timestamp: number) => {
  return DateTime.fromSeconds(timestamp);
};

export const parseTime = (isoTime: string) => {
  const dateTime = DateTime.fromISO(isoTime);

  return dateTime.toLocaleString({
    ...DateTime.DATETIME_MED_WITH_WEEKDAY,
    year: undefined,
    locale: "en-US",
  });
};

export const parseOrderDayTimes = (
  timestamps: number[],
  nowWithBuffer: DateTime,
  todayStart?: number,
) => {
  return timestamps
    .map((timestamp) => unixTimestampToLocalTime(timestamp))
    .filter(
      (time) =>
        time > nowWithBuffer &&
        (!todayStart || time >= unixTimestampToLocalTime(todayStart)),
    );
};

export const formatAvailableTimesData = (
  data: AvailableOrderTime[],
  startBufferTime: number | undefined,
): OrderDayScrollItem[] => {
  const now = DateTime.now();
  const nowWithBuffer = now.plus({
    minutes: startBufferTime,
  });
  return data.slice(1).map((availableOrderTimes, idx) => {
    // NB: "yesterday", "today", and "tomorrow" mean the following:
    // yeserday: times that fall on selected day but are part of the previous day's late night hours
    // today: times that fall on selected day and are part of the selected day's regular hours
    // tomorrow: times that fall on day following selected but are part of selected day's late night hours
    const yesterday = data[idx];
    const yesterdayLateNightTimes =
      yesterday.times.length === 2 ? yesterday.times[1] : [];

    const todayTimes = [
      ...yesterdayLateNightTimes,
      ...(availableOrderTimes.times[0] || []),
    ];

    // add the buffer time to the start of the day
    const todayStart = availableOrderTimes.times[0]?.length
      ? availableOrderTimes.times[0][0]
      : 0;

    let tomorrowTimes: number[] = [];
    let tomorrowDate = "";
    if (idx < data.length - 2) {
      tomorrowTimes =
        availableOrderTimes.times.length === 2
          ? [...availableOrderTimes.times[1]]
          : [];
      tomorrowDate = data[idx + 2].date;
    }

    return {
      key: `day-${availableOrderTimes.date.toLocaleString()}`,
      yesterdayLateNight: {
        times: parseOrderDayTimes(yesterdayLateNightTimes, nowWithBuffer),
        date: yyyymmddToLocalDate(yesterday.date),
      },
      today: {
        times: parseOrderDayTimes(todayTimes, nowWithBuffer, todayStart),
        date: yyyymmddToLocalDate(availableOrderTimes.date),
      },
      tomorrow: {
        times: parseOrderDayTimes(tomorrowTimes, nowWithBuffer),
        date: tomorrowDate ? yyyymmddToLocalDate(tomorrowDate) : undefined,
      },
    };
  });
};

export const getAllAvailableTimesInTheDay = <T extends OrderDay>(day: T) => {
  return [
    ...day.yesterdayLateNight.times,
    ...day.today.times,
    ...day.tomorrow.times,
  ];
};

export const getClosestAvailableTime = (parsedTimes: OrderDayScrollItem[]) => {
  const scheduledClosestDate = parsedTimes.find((dateTime) =>
    Object.values(dateTime).some((value) => {
      if (typeof value !== "object") return false;

      return value.times.length > 0;
    }),
  );

  if (!scheduledClosestDate) return undefined;

  return getAllAvailableTimesInTheDay(scheduledClosestDate)[0];
};

export const getOrderDateTimeByTimestamp = (
  allDisplayOrderTimes: OrderDayScrollItem[],
  timestamp: number,
) => {
  return allDisplayOrderTimes.find((displayOrderTime) => {
    const times = getAllAvailableTimesInTheDay(displayOrderTime);

    return times.find((t) => t.toSeconds() === timestamp);
  });
};

export const findSameTimeOfDay = <T extends OrderDay>(
  orderDay: T,
  timeToMatch?: DateTime,
) => {
  if (!timeToMatch) {
    return undefined;
  }

  const times = getAllAvailableTimesInTheDay(orderDay);

  const newTime = times.find(
    (time) =>
      timeToMatch?.toLocaleString(DateTime.TIME_SIMPLE) ===
      time.toLocaleString(DateTime.TIME_SIMPLE),
  );

  return newTime;
};
