import React, { createContext, useCallback, useContext } from "react";
import { useCartContext } from "../../app-components/hooks/useCartContext";
import { useOrderCount } from "../../features/Checkout/hooks/useOrderCount";
import { useAuth } from "../../features/Authentication/hooks/useAuth";
export type AnalyticsHelperContextType = {
  orderCount?: number;
  incrementOrderCount: () => void;
};
export const AnalyticsHelperContext = createContext<AnalyticsHelperContextType>({
  orderCount: 0,
  incrementOrderCount: () => {}
});
type CurrentUserContextProviderProps = {
  children: React.ReactNode;
};
export const AnalyticsHelperContextProvider = ({
  children
}: CurrentUserContextProviderProps) => {
  const {
    user
  } = useAuth();
  const {
    cart
  } = useCartContext();
  const {
    orderCount,
    updateOrderCount: _updateOrderCount
  } = useOrderCount(user?.phone_number || cart?.phone_number);
  const incrementOrderCount = useCallback(() => {
    if (user?.phone_number || cart?.phone_number) {
      _updateOrderCount(prevValue => ({
        ...prevValue,
        order_count: (prevValue.order_count || 0) + 1
      }));
    }
  }, [_updateOrderCount, cart?.phone_number, user?.phone_number]);
  return <AnalyticsHelperContext.Provider value={{
    orderCount,
    incrementOrderCount
  }} data-sentry-element="unknown" data-sentry-component="AnalyticsHelperContextProvider" data-sentry-source-file="AnalyticsHelperContext.tsx">
      {children}
    </AnalyticsHelperContext.Provider>;
};
export const useAnalyticsHelperContext = () => {
  const analyticsHelperContext = useContext(AnalyticsHelperContext);
  if (!analyticsHelperContext) {
    throw new Error("You may not use AnalyticsHelperContext outside of AnalyticsHelperContextProvider");
  } else {
    return analyticsHelperContext;
  }
};