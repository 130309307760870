import { DependencyList, useEffect, useRef } from "react";

export const useEffectOnce = (
  callback: (args: DependencyList) => void | (() => void),
  dependencies: DependencyList,
  condition?: boolean,
) => {
  const calledOnce = useRef(false);

  useEffect(() => {
    if (!calledOnce.current && condition) {
      calledOnce.current = true;
      return callback(dependencies);
    }

    return undefined;
  }, [callback, condition, dependencies]);
};
