import { useMemo } from "react";
import { useStoredPaymentMethodsQuery } from "../../../graphql/storedPaymentMethods.query.generated";
import { ensureDefinedItems } from "../../../utils/array";
import { User } from "../../Authentication/types";

export const useStoredPaymentMethods = (user?: User) => {
  const { data, refetch } = useStoredPaymentMethodsQuery({
    skip: !user,
  });

  const storedPaymentMethods = useMemo(
    () =>
      ensureDefinedItems(
        (data?.stored_payment_methods || []).filter((method) =>
          Boolean(method?.stripe_payment_method_id),
        ),
      ),
    [data?.stored_payment_methods],
  );

  return {
    storedPaymentMethods,
    refetch,
  };
};
