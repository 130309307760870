import React from "react";
import { classNames } from "../../utils/classNames";
type LoadingSpinnerProps = {
  size?: number;
  borderWidth?: number;
};
export const LoadingSpinner = React.memo(({
  size,
  borderWidth
}: LoadingSpinnerProps) => {
  return <div className={classNames("loader ease-linear rounded-full border-gray-200", borderWidth ? `border-${borderWidth}` : "border-4", borderWidth ? `border-t-${borderWidth}` : "border-t-4", size ? `h-${size} w-${size}` : "h-12 w-12")} />;
});