import { classNames } from "../../utils/classNames";
import { Text } from "../Typography";
type NoticeBadgeType = "error" | "warning" | "info";
interface NoticeBadgeProps {
  text: string;
  type: NoticeBadgeType;
  className?: string;
}
const NoticeBadge = ({
  text,
  type,
  className
}: NoticeBadgeProps) => {
  return <div className={classNames("rounded px-2 py-1 w-fit", type === "error" && "bg-new-orange-lighten-3", type === "warning" && "bg-new-yellow-lighten-3", type === "info" && "bg-new-grey-2-lighten-3", className)} data-sentry-component="NoticeBadge" data-sentry-source-file="NoticeBadge.tsx">
      <Text small component="p" data-sentry-element="Text" data-sentry-source-file="NoticeBadge.tsx">
        {text}
      </Text>
    </div>;
};
export default NoticeBadge;