import { Button } from "@ui/NewButton/Button";
import { classNames } from "@utils/classNames";
import { useCallback, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { ToastContext } from "../../../app-components/hooks/useToast";
import { checkLimit, getTotalItemPrice } from "../../../utils/cart";
import { handleAddingRecommendedPairingsToCart } from "../../../utils/recommendedPairingsUtils";
import { usdFormat } from "../../../utils/usdFormatter";
import { MediaInfo } from "../../BrandPage/types";
import { Cart, CartMenuItem } from "../../Cart/types";
import { GroupOrderContext } from "../../GroupOrder/context/GroupOrderContext";
import { PublicStore } from "../../Stores/types";
import { QuantitySelectorAddToCart } from "../QuantitySelectorAddToCart";
import { CartItemAction } from "../reducers/itemReducer";
import { PublicMenuItem } from "../types";
import MenuItemModalDivider from "./MenuItemModalDivider";
type ButtonGroupProps = {
  cartItem: CartMenuItem;
  trackAction: (event: string, properties: any) => void;
  hideModal: () => void;
  onAddItem: (cartId: string, locationSlug: string, item: CartMenuItem[]) => Promise<any>;
  onRemoveItem: (cartId: string, externalId: string) => Promise<any>;
  onUpdateItem: (cartId: string, externalId: string, item: CartMenuItem) => Promise<any>;
  isItemValid: boolean;
  cart: Cart;
  isModyfingItem?: boolean;
  locationSlug: string;
  store?: PublicStore;
  isOneClickAdd?: boolean;
  scrollToNextRequiredMod: () => void;
  mediaInfo: MediaInfo;
  cartItemDispatch: React.Dispatch<CartItemAction>;
  publicMenuItem: PublicMenuItem | undefined;
  recommendedPairingsItems?: PublicMenuItem[];
};
type HandleActionProps = {
  action: () => Promise<any>;
  successMessage: string;
  errorMessage: string;
  trackEvent: string;
  trackProperties?: object;
};
export const ButtonGroup = ({
  cartItem,
  cartItemDispatch,
  publicMenuItem,
  onRemoveItem,
  onUpdateItem,
  onAddItem,
  trackAction,
  hideModal,
  isItemValid,
  isModyfingItem,
  cart,
  locationSlug,
  store,
  isOneClickAdd,
  mediaInfo,
  scrollToNextRequiredMod,
  recommendedPairingsItems
}: ButtonGroupProps) => {
  const {
    showToast
  } = useContext(ToastContext);
  const {
    setShowMessageModal,
    participantId
  } = useContext(GroupOrderContext);
  const handleAction = useCallback(({
    action,
    successMessage,
    errorMessage,
    trackEvent,
    trackProperties
  }: HandleActionProps) => {
    hideModal();
    action().then(result => {
      if (result?.spendLimitExceeded) {
        return;
      }
      trackAction(trackEvent, trackProperties);
      showToast({
        description: successMessage,
        variant: "success"
      });
    }).catch((err: any) => {
      console.error(err);
      showToast({
        description: errorMessage,
        variant: "error",
        onClick: () => window.location.reload()
      });
    });
  }, [trackAction]);
  const addItemToCart = () => {
    const foundPairings = !!recommendedPairingsItems?.length;
    const parentItem: CartMenuItem = {
      ...cartItem,
      external_id: uuidv4(),
      store_id: store?.id || cartItem?.store_id,
      group_order_participant_id: participantId,
      is_one_click_add: isOneClickAdd
    };
    const items: CartMenuItem[] = [parentItem];
    if (foundPairings) {
      const {
        allCartItems
      } = handleAddingRecommendedPairingsToCart(cartItem, recommendedPairingsItems, store, participantId, isOneClickAdd);
      items.push(...allCartItems);
    }
    handleAction({
      action: () => onAddItem(cart.id || "", locationSlug, items),
      trackEvent: "Product Added",
      trackProperties: {
        cart_id: cart.id,
        quantity: cartItem.quantity,
        price: cartItem.price,
        group_order_participant_id: participantId,
        customer_id: cart.customer_id,
        participants: cart.participants?.map(participant => ({
          id: participant.id,
          customer_id: participant.customer_id,
          is_creator: participant.is_creator
        })),
        media_info: mediaInfo
      },
      successMessage: "Added to bag!",
      errorMessage: "We had trouble updating your bag. Click here to refresh."
    });
  };
  const updateItemInCart = () => handleAction({
    action: () => onUpdateItem(cart.id || "", cartItem.external_id || "", cartItem),
    trackEvent: "Product Updated",
    trackProperties: {
      cart_id: cart.id,
      is_one_click_add: isOneClickAdd,
      group_order_participant_id: participantId,
      customer_id: cart.customer_id,
      participants: cart.participants?.map(participant => ({
        id: participant.id,
        customer_id: participant.customer_id,
        is_creator: participant.is_creator
      }))
    },
    successMessage: "Item updated!",
    errorMessage: "We had trouble updating your bag. Click here to refresh."
  });
  const updateText = cartItem.quantity === 0 ? "Remove item" : "Update item";
  const handleUpdateItemClick = () => {
    if (cartItem.quantity === 0) {
      handleAction({
        action: () => onRemoveItem(cart.id || "", cartItem.external_id || ""),
        trackEvent: "Product Removed",
        trackProperties: {
          cart_id: cart.id
        },
        successMessage: `Item removed!`,
        errorMessage: "We had trouble updating your bag. Click here to refresh."
      });
    } else {
      if (checkLimit(cartItem)) {
        setShowMessageModal(true);
        return;
      }
      if (isItemValid) {
        updateItemInCart();
      }
    }
  };
  const submitAddToCart = () => {
    if (checkLimit(cartItem)) {
      setShowMessageModal(true);
      return;
    }
    if (isItemValid && cart.id) {
      addItemToCart();
    } else if (!cart.id) {
      showToast({
        description: "We had trouble updating your bag. Click here to refresh.",
        variant: "error",
        onClick: () => window.location.reload()
      });
    } else if (!isItemValid) {
      scrollToNextRequiredMod();
      if (cartItem.quantity === 0) {
        showToast({
          description: "Please add a minimum quantity greater than 0",
          variant: "warning"
        });
        return;
      }
      showToast({
        description: "Please make all required selections",
        variant: "warning"
      });
    }
  };
  return <div className="h-20 w-full sticky bottom-0 pb-3 bg-surface-lighter" data-sentry-component="ButtonGroup" data-sentry-source-file="ButtonGroup.tsx">
      <MenuItemModalDivider data-sentry-element="MenuItemModalDivider" data-sentry-source-file="ButtonGroup.tsx" />
      <div className="flex items-end justify-between mx-4 mt-3">
        <QuantitySelectorAddToCart cartItem={cartItem} cartItemDispatch={cartItemDispatch} publicMenuItem={publicMenuItem} brandId={store?.brand.id} key={cartItem.id} hideModal={hideModal} data-sentry-element="QuantitySelectorAddToCart" data-sentry-source-file="ButtonGroup.tsx" />

        {isModyfingItem ? <div className="flex shrink-0 md:relative">
            <Button type="button" onClick={handleUpdateItemClick} customLabel={<div className="flex">
                  <span className="mr-2">{updateText}</span>
                  <div>
                    {usdFormat(cartItem.quantity * getTotalItemPrice(cartItem))}
                  </div>
                </div>} mode="primary" size="large" />
          </div> : <div className="shrink-0 inset-x-0 md:relative">
            <Button onClick={submitAddToCart} className={classNames(!isItemValid && "opacity-60 cursor-not-allowed")} label={`Add to Bag \u00A0 ${usdFormat(cartItem.quantity * getTotalItemPrice(cartItem))} `} mode="primary" size="large" data-test-id="add-to-cart-button" />
          </div>}
      </div>
    </div>;
};