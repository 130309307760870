import { faArrowLeft, faChevronDown } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocationContext } from "@utils/contexts/LocationContext";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { useRouter } from "next/router";
import { useState } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { PrimaryNavbarProps } from "../../../ui/Navbar/components/PrimaryNavbar";
import { Header, Link, Text } from "../../../ui/Typography";
import { getHomePath } from "../../../utils/getHomePath";
import { MOBILE_HEADER_HEIGHT } from "../../../utils/navbar";
type CollapsedState = "expanded" | "collapsed";
type CheckoutMobileNavbarProps = Pick<PrimaryNavbarProps, "cartQuantity">;
const containerVariants = {
  collapsed: {
    y: -MOBILE_HEADER_HEIGHT
  },
  expanded: {
    y: 0
  }
};
const buttonVariants = {
  collapsed: {
    y: MOBILE_HEADER_HEIGHT
  },
  expanded: {
    y: 0
  }
};
const variants = {
  collapsed: {
    x: "-50%",
    left: "50%"
  },
  expanded: {
    x: "0%",
    left: "0%"
  }
};
const transition = {
  ease: "easeInOut"
};
const CheckoutMobileNavbar = ({
  cartQuantity
}: CheckoutMobileNavbarProps) => {
  const [collapsedState, setCollapsedState] = useState<CollapsedState>("expanded");
  const {
    scrollY
  } = useScroll();
  const router = useRouter();
  const {
    cartReceipt
  } = useCartContext();
  const {
    storeLocation: location
  } = useLocationContext();
  useMotionValueEvent(scrollY, "change", latest => {
    if (latest > 4 * MOBILE_HEADER_HEIGHT) {
      setCollapsedState("collapsed");
    } else {
      setCollapsedState("expanded");
    }
  });
  return <motion.nav className="p-4 gap-7 bg-surface-dark w-full" initial="expanded" variants={containerVariants} animate={collapsedState} transition={transition} style={{
    height: 2 * MOBILE_HEADER_HEIGHT
  }} data-sentry-element="unknown" data-sentry-component="CheckoutMobileNavbar" data-sentry-source-file="CheckoutMobileNavbar.tsx">
      <div className="relative h-full flex flex-col w-full">
        <motion.div initial="expanded" variants={buttonVariants} animate={collapsedState} transition={transition} data-sentry-element="unknown" data-sentry-source-file="CheckoutMobileNavbar.tsx">
          <Link variant="primary" size="small" onClick={() => router.push(getHomePath(location?.slug))} data-sentry-element="Link" data-sentry-source-file="CheckoutMobileNavbar.tsx">
            <FontAwesomeIcon icon={faArrowLeft} width={24} height={24} className="text-new-white" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="CheckoutMobileNavbar.tsx" />
          </Link>
        </motion.div>

        <motion.div className="absolute bottom-0 flex flex-row justify-start gap-2" variants={variants} transition={transition} data-sentry-element="unknown" data-sentry-source-file="CheckoutMobileNavbar.tsx">
          <Header h4 className="text-new-white" data-sentry-element="Header" data-sentry-source-file="CheckoutMobileNavbar.tsx">
            Checkout
          </Header>
          {!!cartQuantity && collapsedState === "expanded" && <div className="flex items-center px-2 rounded bg-new-black-lighten-2">
              <Text eyebrow className="text-new-white">
                {cartQuantity}
              </Text>
            </div>}
        </motion.div>
        {collapsedState === "expanded" && <div className="absolute bottom-0 right-0 flex flex-row gap-1 items-center">
            <Header h4 className="text-new-white">
              {cartReceipt?.line_items?.find(item => item.line_item_type === "Total")?.display_amount}
            </Header>
            <Link size="small" onClick={() => {
          scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
          });
        }}>
              <FontAwesomeIcon icon={faChevronDown} width={24} height={24} className="text-new-black-lighten-2" />
            </Link>
          </div>}
      </div>
    </motion.nav>;
};
export default CheckoutMobileNavbar;