/* eslint-disable no-case-declarations */
import cloneDeep from "lodash/cloneDeep";
import { PlacedOrderItemReorder } from "../../../graphql/types";
import { CartMenuItem, CartModifierItem } from "../../Cart/types";
import { PublicMenuItem, PublicModifierList } from "../types";

export const CLEAR_STATE = "clear-state";
export const UPDATE_ITEM_ACTION = "update-action";
export const UPDATE_ITEM_QUANTITY = "update-quantity";
export const SET_REORDER_ITEM = "set-reorder-item";
export const SELECT_MODIFIER_ITEM = "select-modifier-item";
export const DESELECT_MODIFIER_ITEM = "deselect-modifier-item";
export const INCREASE_MODIFIER_ITEM_QTY = "increase-modifier-item-qty";
export const DECREASE_MODIFIER_ITEM_QTY = "decrease-modifier-item-qty";

type UpdateItemAction = {
  type: typeof UPDATE_ITEM_ACTION;
  cartItem?: CartMenuItem;
  publicMenuItem: PublicMenuItem;
};
type UpdateItemQuantity = {
  type: typeof UPDATE_ITEM_QUANTITY;
  quantity: number;
};
type SelectModifierItem = {
  type: typeof SELECT_MODIFIER_ITEM;
  modifierListId: number;
  modifierItemId: number;
};
type DeselectModifierItem = {
  type: typeof DESELECT_MODIFIER_ITEM;
  modifierListId: number;
  modifierItemId: number;
};
type IncreaseModifierItemQty = {
  type: typeof INCREASE_MODIFIER_ITEM_QTY;
  modifierListId: number;
  modifierItemId: number;
};
type DecreaseModifierItemQty = {
  type: typeof DECREASE_MODIFIER_ITEM_QTY;
  modifierListId: number;
  modifierItemId: number;
};
type ClearStateAction = {
  type: typeof CLEAR_STATE;
};
type SetReorderItemAction = {
  type: typeof SET_REORDER_ITEM;
  reorderItem: PlacedOrderItemReorder;
};
export type CartItemAction =
  | ClearStateAction
  | UpdateItemAction
  | UpdateItemQuantity
  | SelectModifierItem
  | DeselectModifierItem
  | IncreaseModifierItemQty
  | DecreaseModifierItemQty
  | SetReorderItemAction;

export type ItemReducerState = {
  cartItem: CartMenuItem;
  publicMenuItem: PublicMenuItem;
};

export const initialState: ItemReducerState = {
  publicMenuItem: {
    id: 0,
    name: "",
    description: "",
    blurhash: undefined,
    image_url: undefined,
    price: 0,
    modifier_lists: [],
    special_instructions: "",
    brand_name: "",
    brand_slug: "",
    store_id: 0,
    inserted_at: "",
  },
  cartItem: {
    id: 0,
    external_id: "",
    name: "",
    blurhash: undefined,
    image_url: undefined,
    brand_name: "",
    store_id: undefined,
    quantity: 1,
    price: 0,
    modifier_items: [],
  },
};

export const getAvailableModifierItems = (
  cartModifiers: CartModifierItem[],
  publicMenuItem: PublicMenuItem,
) => {
  return cartModifiers.filter((itemModifier) =>
    publicMenuItem.modifier_lists.find((list) =>
      list.modifier_items.find(
        (modifier) => Number(modifier.id) == Number(itemModifier.id),
      ),
    ),
  );
};

export const cartItemReducer = (
  state: ItemReducerState,
  action: CartItemAction,
): ItemReducerState => {
  let modifierList: PublicModifierList | undefined;
  let newCartItem;
  let cartItem;
  let selectedCount;
  let modifierItemId: number;
  let hasModifierItem: boolean;

  // NB: For some reason some actions are being dispatched twice. Tired of debugging.
  // The actions don't seem to take effect more than once so I'm leaving this alone.
  switch (action.type) {
    case CLEAR_STATE:
      return initialState;
    case UPDATE_ITEM_ACTION:
      if (!action.cartItem?.id) {
        const menuItem = action.publicMenuItem;
        return {
          ...state,
          cartItem: {
            id: menuItem.id,
            external_id: menuItem.external_id ?? "",
            name: menuItem.name,
            image_url: menuItem.image_url,
            brand_name: menuItem.brand_name,
            store_id: menuItem.store_id,
            quantity: menuItem.quantity || 1,
            price: menuItem.price,
            modifier_items: [],
          },
          publicMenuItem: menuItem,
        };
      }
      cartItem = action.cartItem;
      const { publicMenuItem } = action;
      const modifierItems = getAvailableModifierItems(
        cartItem.modifier_items,
        publicMenuItem,
      );

      return {
        ...state,
        cartItem: {
          ...cartItem,
          modifier_items: modifierItems,
        },
        publicMenuItem,
      };
    case UPDATE_ITEM_QUANTITY:
      return {
        ...state,
        cartItem: {
          ...state.cartItem,
          quantity: action.quantity,
        },
      };
    case SELECT_MODIFIER_ITEM:
      modifierList = state.publicMenuItem.modifier_lists.find(
        (list) => list.id === action.modifierListId,
      );
      if (!modifierList) return state;

      cartItem = state.cartItem;
      newCartItem = cloneDeep(cartItem);

      modifierItemId = action.modifierItemId;
      hasModifierItem = Boolean(
        newCartItem.modifier_items.find(
          (modifier) => modifier.id === modifierItemId,
        ),
      );

      if (!hasModifierItem) {
        const modifier = modifierList.modifier_items.find(
          (m) => m.id === modifierItemId,
        );
        if (!modifier) return state;

        newCartItem.modifier_items.push({
          id: modifierItemId,
          quantity: 0,
          name: modifier.name,
          price: modifier.price,
        });
      }

      selectedCount = cartItem.modifier_items.reduce((count, item) => {
        if (
          modifierList?.modifier_items.find(
            (modifier_item) => modifier_item.id === item.id,
          )
        ) {
          return item.quantity ? count + item.quantity : count;
        }
        return count;
      }, 0);

      if (
        !(modifierList.max === 1 && modifierList.min === 1) &&
        modifierList.max > 0 &&
        selectedCount === modifierList.max
      ) {
        return state;
      }

      return {
        ...state,
        cartItem: {
          ...newCartItem,
          modifier_items: newCartItem.modifier_items.map((modifierItem) => {
            const isInModifierList = Boolean(
              modifierList?.modifier_items.find(
                (m) => m.id === modifierItem.id,
              ),
            );
            let defaultValue = modifierItem.quantity;
            if (isInModifierList && modifierList?.max === 1) {
              defaultValue = 0;
            }
            return {
              ...modifierItem,
              quantity:
                modifierItem.id === action.modifierItemId ? 1 : defaultValue,
            };
          }),
        },
      };
    case DESELECT_MODIFIER_ITEM:
      modifierList = state.publicMenuItem.modifier_lists.find(
        (list) => list.id === action.modifierListId,
      );
      if (!modifierList) return state;

      cartItem = state.cartItem;
      newCartItem = cloneDeep(cartItem);

      modifierItemId = action.modifierItemId;
      hasModifierItem = Boolean(
        newCartItem.modifier_items.find(
          (modifier) => modifier.id === modifierItemId,
        ),
      );

      if (!hasModifierItem) {
        const modifier = modifierList.modifier_items.find(
          (m) => m.id === modifierItemId,
        );
        if (!modifier) return state;

        newCartItem.modifier_items.push({
          id: modifierItemId,
          quantity: 0,
          name: modifier.name,
          price: modifier.price,
        });
      }

      return {
        ...state,
        cartItem: {
          ...newCartItem,
          modifier_items: newCartItem.modifier_items.map((modifierItem) => {
            return {
              ...modifierItem,
              quantity:
                modifierItem.id === action.modifierItemId
                  ? 0
                  : modifierItem.quantity,
            };
          }),
        },
      };
    case INCREASE_MODIFIER_ITEM_QTY:
      cartItem = state.cartItem;
      newCartItem = cloneDeep(cartItem);

      modifierList = state.publicMenuItem.modifier_lists.find(
        (list) => list.id === action.modifierListId,
      );
      if (!modifierList) return state;

      modifierItemId = action.modifierItemId;
      hasModifierItem = Boolean(
        newCartItem.modifier_items.find(
          (modifier) => modifier.id === modifierItemId,
        ),
      );

      if (!hasModifierItem) {
        const modifier = modifierList.modifier_items.find(
          (m) => m.id === modifierItemId,
        );
        if (!modifier) return state;

        newCartItem.modifier_items.push({
          id: modifierItemId,
          quantity: 0,
          name: modifier.name,
          price: modifier.price,
        });
      }

      selectedCount = cartItem.modifier_items.reduce((count, item) => {
        if (
          modifierList?.modifier_items.find(
            (modifier_item) => modifier_item.id === item.id,
          )
        ) {
          return item.quantity ? count + item.quantity : count;
        }
        return count;
      }, 0);

      if (
        !(modifierList.max === 1 && modifierList.min === 1) &&
        modifierList.max > 0 &&
        selectedCount === modifierList.max
      ) {
        return state;
      }

      return {
        ...state,
        cartItem: {
          ...newCartItem,
          modifier_items: newCartItem.modifier_items.map((modifierItem) => {
            return {
              ...modifierItem,
              quantity:
                modifierItem.id === action.modifierItemId
                  ? modifierItem.quantity + 1
                  : modifierItem.quantity,
            };
          }),
        },
      };
    case DECREASE_MODIFIER_ITEM_QTY:
      cartItem = state.cartItem;
      newCartItem = cloneDeep(cartItem);

      modifierList = state.publicMenuItem.modifier_lists.find(
        (list) => list.id === action.modifierListId,
      );
      if (!modifierList) return state;

      modifierItemId = action.modifierItemId;
      hasModifierItem = Boolean(
        newCartItem.modifier_items.find(
          (modifier) => modifier.id === modifierItemId,
        ),
      );

      if (!hasModifierItem) {
        const modifier = modifierList.modifier_items.find(
          (m) => m.id === modifierItemId,
        );
        if (!modifier) return state;

        newCartItem.modifier_items.push({
          id: modifierItemId,
          quantity: 0,
          name: modifier.name,
          price: modifier.price,
        });
      }

      modifierList = state.publicMenuItem.modifier_lists.find(
        (list) => list.id === action.modifierListId,
      );
      if (!modifierList) return state;

      return {
        ...state,
        cartItem: {
          ...newCartItem,
          modifier_items: newCartItem.modifier_items.map((modifierItem) => {
            return {
              ...modifierItem,
              quantity:
                modifierItem.id === action.modifierItemId
                  ? modifierItem.quantity - 1
                  : modifierItem.quantity,
            };
          }),
        },
      };
    case SET_REORDER_ITEM:
      const newModifiers = action.reorderItem.modifier_items;
      const menuItem = state.publicMenuItem;

      const availableModifiers = getAvailableModifierItems(
        newModifiers,
        menuItem,
      );

      return {
        ...state,
        cartItem: {
          ...state.cartItem,
          reorder_item_id: action.reorderItem.id,
          modifier_items: availableModifiers,
        },
      };
    default:
      return state;
  }
};
