import { AnimatePresence, AnimationDefinition, motion, PanInfo, useInView } from "framer-motion";
import { PropsWithChildren, useRef } from "react";
import { useHideBodyOverflow } from "../../features/MenuItemModal/hooks/useHideBodyOverflow";
import { classNames } from "../../utils/classNames";
import { Header } from "../Typography";
import Backdrop from "./Backdrop";
import ModalCloseButton, { ModalCloseButtonPosition } from "./ModalCloseButton";
import { ReactPortal } from "./ReactPortal";
export type ModalSize = "small" | "medium" | "large";
export interface ModalProps extends PropsWithChildren {
  closeModal: () => void;
  closeButtonPosition?: ModalCloseButtonPosition;
  size: ModalSize;
  title?: string;
  bodyClassName?: string;
  fullScreen?: boolean;
  hFull?: boolean;
  onAnimationComplete?: (definition: AnimationDefinition) => void;
}
const animationConfig = {
  hidden: {
    y: "100vh",
    opacity: 0
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      type: "spring",
      damping: 60,
      stiffness: 500
    }
  },
  exit: {
    y: "100vh",
    opacity: 0
  }
};
const ModalWindow = ({
  children,
  closeModal,
  closeButtonPosition = "top-right",
  size,
  title,
  bodyClassName,
  fullScreen,
  hFull,
  onAnimationComplete
}: ModalProps) => {
  const refStart = useRef<HTMLDivElement>(null);
  const isInView = useInView(refStart);
  const handleDragEnd = (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if (info.offset.y > 50 && isInView) {
      closeModal();
    }
  };
  return <Backdrop onClick={closeModal} data-sentry-element="Backdrop" data-sentry-component="ModalWindow" data-sentry-source-file="NewModal.tsx">
      <motion.div drag="y" dragConstraints={{
      top: 0,
      bottom: 0
    }} transition={{
      duration: 0.6
    }} onDragEnd={handleDragEnd} className={classNames(`relative mx-auto mt-auto sm:mb-auto pt-4 pb-8 bg-surface-lighter w-screen rounded-t-xl sm:rounded-xl shadow-xl`, size === "small" && "sm:w-[408px]", size === "medium" && "sm:w-[516px]", size === "large" && "sm:w-[624px]", fullScreen ? "h-full lg:h-auto" : "", hFull ? "h-full" : "")} onClick={e => e.stopPropagation()} onAnimationComplete={onAnimationComplete} variants={animationConfig} initial="hidden" animate="visible" exit="exit" data-sentry-element="unknown" data-sentry-source-file="NewModal.tsx">
        <div ref={refStart} />
        <ModalCloseButton position={closeButtonPosition} closeModal={closeModal} data-sentry-element="ModalCloseButton" data-sentry-source-file="NewModal.tsx" />
        {title && <div className="w-full grid content-center justify-center border-b border-new-grey-2-lighten-3 pb-4 px-6">
            <Header h4>{title}</Header>
          </div>}
        <div className={classNames("px-4 sm:px-6", bodyClassName)}>
          {children}
        </div>
      </motion.div>
    </Backdrop>;
};
interface ModalControllerProps extends Omit<ModalProps, "closeModal"> {
  showModal: boolean;
  closeModal: () => void;
  onModalClose?: () => void;
}
const Modal = ({
  showModal,
  closeModal,
  onModalClose,
  closeButtonPosition = "top-right",
  children,
  ...modalProps
}: ModalControllerProps) => {
  useHideBodyOverflow(showModal);
  return <ReactPortal data-sentry-element="ReactPortal" data-sentry-component="Modal" data-sentry-source-file="NewModal.tsx">
      <AnimatePresence initial mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="NewModal.tsx">
        {showModal && <ModalWindow {...modalProps} closeButtonPosition={closeButtonPosition} closeModal={() => {
        closeModal();
        onModalClose?.();
      }}>
            {children}
          </ModalWindow>}
      </AnimatePresence>
    </ReactPortal>;
};
export default Modal;