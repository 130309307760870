import { Transition } from "framer-motion";
import React, { useContext, useRef } from "react";
import useElementSize from "../../utils/hooks/useElementSize";
import useTailwindBreakpoints, { TailwindBreakpoint } from "../../utils/hooks/useTailwindBreakpoints";
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../../utils/navbar";
interface NavbarContextType {
  navbarHeight: number;
  navbarRef: React.RefObject<HTMLDivElement> | null;
}
export const TRANSITION: Transition = {
  ease: "easeInOut",
  duration: 0.2,
  delay: 0.1
};
export const NavbarContext = React.createContext<NavbarContextType>({
  navbarHeight: MOBILE_HEADER_HEIGHT,
  navbarRef: null
});
export const NavbarContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const breakpoint = useTailwindBreakpoints();
  const navbarRef = useRef<HTMLDivElement>(null);
  const {
    height: navbarHeight
  } = useElementSize(navbarRef);
  return <NavbarContext.Provider value={{
    navbarHeight: navbarHeight || (breakpoint === TailwindBreakpoint.mobile ? MOBILE_HEADER_HEIGHT : DESKTOP_HEADER_HEIGHT),
    navbarRef
  }} data-sentry-element="unknown" data-sentry-component="NavbarContextProvider" data-sentry-source-file="NavbarContext.tsx">
      {children}
    </NavbarContext.Provider>;
};
export const useNavbarContext = () => {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error("You may not use NavbarContext outside of NavbarContextProvider");
  } else {
    return context;
  }
};