import { faArrowLeft } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { useLoginContext } from "@features/Authentication/contexts/LoginContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocationContext } from "@utils/contexts/LocationContext";
import { useRouter } from "next/router";
import { useAuth } from "../../../features/Authentication/hooks/useAuth";
import { brandPageContentMargins } from "../../../features/BrandPage/utils/spacing";
import { classNames } from "../../../utils/classNames";
import { getHomePath } from "../../../utils/getHomePath";
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../../../utils/navbar";
import { LKLogo } from "../../Logo/LKLogo";
import { Button } from "../../NewButton/Button";
import { Header } from "../../Typography";
import { PrimaryNavbarProps } from "./PrimaryNavbar";
type SecondaryNavbarProps = {
  pageDisplayName?: string;
} & Pick<PrimaryNavbarProps, "isMobile">;
const SecondaryNavbar = ({
  isMobile,
  pageDisplayName
}: SecondaryNavbarProps) => {
  const {
    isAuthenticated
  } = useAuth();
  const {
    loginWithTrack: login
  } = useLoginContext();
  const {
    storeLocation: location
  } = useLocationContext();
  const router = useRouter();
  const isAuthPage = router.pathname.includes("/login") || router.pathname.includes("/signup");
  return <nav className="w-full border-b border-new-grey-2-lighten-2 bg-surface-lighter" style={{
    height: isMobile ? MOBILE_HEADER_HEIGHT : DESKTOP_HEADER_HEIGHT
  }} data-sentry-component="SecondaryNavbar" data-sentry-source-file="SecondaryNavbar.tsx">
      <div className={classNames("h-full", brandPageContentMargins)}>
        <div className="h-full relative flex flex-row items-center justify-center">
          <Button mode="text" label="Back to menu" labelStyle="hidden sm:block" size="small" className="absolute left-0" leadingIcon={<FontAwesomeIcon icon={faArrowLeft} width={20} height={20} />} onPress={() => router.push(getHomePath(location?.slug))} data-sentry-element="Button" data-sentry-source-file="SecondaryNavbar.tsx" />
          <LKLogo locationSlug={location?.slug} className="hidden sm:block h-16 w-48" color="black" data-sentry-element="LKLogo" data-sentry-source-file="SecondaryNavbar.tsx" />
          {pageDisplayName && <Header h5 className="sm:hidden">
              {pageDisplayName}
            </Header>}
          {!isAuthenticated && !isAuthPage ? <Button mode="primary" label="Log In/Sign Up" size="small" className="absolute right-0" onClick={() => login()} /> : null}
        </div>
      </div>
    </nav>;
};
export default SecondaryNavbar;