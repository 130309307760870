import { useContext, useState } from "react";
import { NewModal } from "../../../ui/NewModal/Modal";
import { User } from "../../Authentication/types";
import { useUpdateCustomer } from "../../Account/hooks/useUpdateCustomer";
import { GroupOrderContext } from "../context/GroupOrderContext";
interface CustomerModalProps {
  user?: User;
}
const CustomerModal = ({
  user
}: CustomerModalProps) => {
  const {
    mutate: updateCustomer,
    isPending
  } = useUpdateCustomer();
  const {
    showNameRequiredModal,
    setShowNameRequiredModal
  } = useContext(GroupOrderContext);
  const [firstName, setFirstName] = useState(user?.first_name || "");
  const [lastName, setLastName] = useState(user?.last_name || "");
  return <NewModal overlayClassName="backdrop-blur" header={"Group order"} show={showNameRequiredModal} hasClose body={<div className="flex flex-col w-screen px-8 sm:px-0 sm:w-124">
          <h3 className="font-semibold text-2.5xl mb-1 text-left">
            Start your group order
          </h3>
          <p className="text-base text-gray-550 font-light text-left">
            First, tell us your name to get started:
          </p>
          <div className="w-full mt-3 mb-3">
            <div className="mt-1 relative rounded">
              <input type="text" name="first_name" id="first_name" autoComplete="off" className="h-14 w-full leading-6 opacity-100 placeholder-gray-400 font-light placeholder-opacity-70 border rounded border-gray-350 focus:ring-lfg-primary focus:border-lfg-primary" placeholder="First name" value={firstName} onChange={e => setFirstName(e.target.value)} />
            </div>
          </div>
          <div className="w-full mt-3 mb-3">
            <div className="mt-1 relative rounded">
              <input type="text" name="last_name" id="last_name" autoComplete="off" className="h-14 w-full leading-6 opacity-100 placeholder-gray-400 font-light placeholder-opacity-70 border rounded border-gray-350 focus:ring-lfg-primary focus:border-lfg-primary" placeholder="Last name" value={lastName} onChange={e => setLastName(e.target.value)} />
            </div>
          </div>
        </div>} hasConfirm hasCancel isConfirmLoading={isPending} isConfirmDisabled={Boolean(firstName.length <= 0 || lastName.length <= 0)} onConfirm={() => {
    updateCustomer({
      first_name: firstName,
      last_name: lastName
    });
  }} confirmMessage="Continue" cancelMessage="Nevermind" onClose={() => setShowNameRequiredModal(false)} onCloseComplete={() => setShowNameRequiredModal(false)} onBlur={() => setShowNameRequiredModal(false)} data-sentry-element="NewModal" data-sentry-component="CustomerModal" data-sentry-source-file="CustomerModal.tsx" />;
};
export default CustomerModal;