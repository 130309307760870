import { gql, useQuery } from "@apollo/client";

export const PUBLIC_MENU_ITEM = gql`
  query PublicMenuItem(
    $id: Int
    $filter_active_modifiers: Boolean
    $is_delivery: Boolean
  ) {
    public_menu_item(id: $id, is_delivery: $is_delivery) {
      id
      menu_item_id
      name
      brand_name
      description
      image_url
      blurhash
      price
      tags {
        id
        name
        is_v2
        is_active
        slug
      }
      modifier_lists {
        id
        name
        min
        max
        is_single_select
        modifier_items(
          filter_active_modifiers: $filter_active_modifiers
          is_delivery: $is_delivery
        ) {
          id
          name
          price
          is_active
        }
      }
    }
  }
`;

export const usePublicMenuItem = (id?: number, isDelivery?: boolean) => {
  const { data, error, loading, refetch } = useQuery(PUBLIC_MENU_ITEM, {
    variables: { id, filter_active_modifiers: false, is_delivery: isDelivery },
    fetchPolicy: "cache-and-network",
    skip: !id,
  });
  return { data, error, loading, refetch };
};
