import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useState } from "react";
import { useWatch } from "./useWatch";

export const useRouterQueryState = (
  name: string,
  defaultValue?: string,
): [string | undefined, Dispatch<SetStateAction<string | undefined>>] => {
  const router = useRouter();

  const [state, setState] = useState(() => {
    const value = router.query[name];
    if (value === undefined) {
      return defaultValue;
    }
    return value as string;
  });

  // Sync state with query parameter
  useWatch(() => {
    if (
      !router.query[name] ||
      encodeURIComponent(state || "") === router.query[name]
    ) {
      return;
    }
    setState(decodeURIComponent(router.query[name] as string));
  }, [router.query[name]]);

  useWatch(() => {
    // We use window history API to update the URL without interrupting Next.js routing
    const search = new URLSearchParams(window.location.search);

    if (state === undefined) {
      if (search.get(name)) {
        search.delete(name);
        window.history.replaceState(
          null,
          "",
          `${window.location.pathname}?${search.toString()}`,
        );
      }
    } else {
      search.set(name, encodeURIComponent(state));
      window.history.replaceState(
        null,
        "",
        `${window.location.pathname}?${search.toString()}`,
      );
    }
  }, [state, name]);

  return [state, setState];
};
