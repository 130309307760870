import type { z } from "zod";
import { userSchema } from "../../features/Authentication/types";
import {
  AppleLoginParams,
  GoogleLoginParams,
  PasscodeLoginParams,
  PasscodeRequestParams,
  SignUpParams,
  ToggleCashRewardRequest,
  UpdateMeParams,
  appleLoginParamsSchema,
  currentRewardMultiplierSchema,
  googleLoginParamsSchema,
  loyaltyCashRewardStateSchema,
  loyaltySchema,
  passcodeLoginParamsSchema,
  passcodeRequestParamsSchema,
  postPasscodeSignupData,
  signUpParamsSchema,
  socialLoginProviderDataSchema,
  updateMeSchema,
} from "./types";

const SHOP_BACKEND_URL = process.env.NEXT_PUBLIC_SHOP_BACKEND_URL;

const requestShopBackend = async <T extends object, R extends object>({
  path,
  method,
  data,
  requestSchema,
  responseSchema,
  errorSchemas,
}: {
  path: string;
  method?: RequestInit["method"];
  data?: T;
  requestSchema?: z.ZodType<T>;
  responseSchema?: z.ZodType<R>;
  errorSchemas?: Record<number, z.ZodType>;
}) => {
  let url = `${SHOP_BACKEND_URL}${path}`;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  requestSchema?.parse(data);
  if (method === "get" && data) {
    url += `?${new URLSearchParams(data as Record<string, string>).toString()}`;
  }

  return fetch(url, {
    headers,
    method,
    body: method !== "get" ? JSON.stringify(data) : undefined,
    credentials: "include",
  }).then(async (response) => {
    if (!response.ok && !errorSchemas?.[response.status]) {
      return {
        response,
        data: null,
      };
    }

    if (!response.ok) {
      const errorData = await response.json();
      const parsedErrorData = errorSchemas?.[response.status].parse(errorData);
      return {
        response,
        data: parsedErrorData,
      };
    }

    if (!responseSchema) {
      return { response, data: null };
    }

    const data = await response.json();
    const parsedResponseData = responseSchema.parse(data);

    return { response, data: parsedResponseData };
  });
};

export class ShopBackendClient {
  constructor() {
    if (this instanceof ShopBackendClient) {
      throw Error("A static class cannot be instantiated.");
    }
  }

  static async checkUserExists(data: PasscodeRequestParams) {
    return requestShopBackend({
      path: "/auth/user/check-user",
      method: "get",
      data,
      requestSchema: passcodeRequestParamsSchema,
      errorSchemas: {
        404: postPasscodeSignupData,
      },
    });
  }

  static async requestPasscode(data: PasscodeRequestParams) {
    return requestShopBackend({
      path: "/auth/request-passcode",
      method: "post",
      data,
      requestSchema: passcodeRequestParamsSchema,
    });
  }

  static async passcodeLogin(data: PasscodeLoginParams) {
    return requestShopBackend({
      path: "/auth/passcode-login",
      method: "post",
      data,
      requestSchema: passcodeLoginParamsSchema,
      responseSchema: userSchema,
      errorSchemas: {
        404: postPasscodeSignupData,
      },
    });
  }

  static async requestUpdateIdentifierPasscode(data: PasscodeRequestParams) {
    return requestShopBackend({
      path: "/auth/request-update-identifier-passcode",
      method: "post",
      data,
      requestSchema: passcodeRequestParamsSchema,
    });
  }

  static async updateIdentifierPasscode(data: PasscodeLoginParams) {
    return requestShopBackend({
      path: "/auth/update-identifier-passcode",
      method: "post",
      data,
      requestSchema: passcodeLoginParamsSchema,
      responseSchema: userSchema,
      errorSchemas: {
        404: postPasscodeSignupData,
      },
    });
  }

  static async signUp(data: SignUpParams) {
    return requestShopBackend({
      path: "/auth/signup",
      method: "post",
      data,
      requestSchema: signUpParamsSchema,
      responseSchema: userSchema,
    });
  }

  static async googleLogin(data: GoogleLoginParams) {
    return requestShopBackend({
      path: `/auth/social-login/google`,
      method: "post",
      data,
      requestSchema: googleLoginParamsSchema,
      responseSchema: userSchema,
      errorSchemas: {
        404: socialLoginProviderDataSchema,
      },
    });
  }

  static async appleLogin(data: AppleLoginParams) {
    return requestShopBackend({
      path: `/auth/social-login/apple`,
      method: "post",
      data,
      requestSchema: appleLoginParamsSchema,
      responseSchema: userSchema,
      errorSchemas: {
        404: socialLoginProviderDataSchema,
      },
    });
  }

  static async logout() {
    return requestShopBackend({
      path: "/auth/logout",
      method: "post",
    });
  }

  static async me() {
    return requestShopBackend({
      path: "/auth/me",
      method: "get",
      responseSchema: userSchema,
    });
  }

  static async updateMe(data: UpdateMeParams) {
    return requestShopBackend({
      path: "/auth/me/update",
      method: "PATCH",
      data,
      requestSchema: updateMeSchema,
      responseSchema: userSchema,
    });
  }

  static async loyalty() {
    return requestShopBackend({
      path: `/api/loyalty/current-state`,
      method: "GET",
      responseSchema: loyaltySchema,
    });
  }

  static async loyaltyCashRewardState({ cart_id }: ToggleCashRewardRequest) {
    return requestShopBackend({
      path: `/api/loyalty/cash-reward-state?cart_id=${cart_id}`,
      method: "GET",
      responseSchema: loyaltyCashRewardStateSchema,
    });
  }

  static async currentRewardMultiplier() {
    return requestShopBackend({
      path: `/api/loyalty/multiplier`,
      method: "GET",
      responseSchema: currentRewardMultiplierSchema,
    });
  }

  static async loyaltyApplyCashReward({ cart_id }: ToggleCashRewardRequest) {
    return requestShopBackend({
      path: `/api/loyalty/apply-cash-reward`,
      method: "POST",
      data: {
        cart_id,
      },
      responseSchema: loyaltyCashRewardStateSchema,
    });
  }

  static async loyaltyRemoveCashReward({ cart_id }: ToggleCashRewardRequest) {
    return requestShopBackend({
      path: `/api/loyalty/remove-cash-reward`,
      method: "POST",
      data: {
        cart_id,
      },
      responseSchema: loyaltyCashRewardStateSchema,
    });
  }
}
