export const slugify = (name?: string): string => {
  if (!name) return "";
  return name
    .toLowerCase()
    .split(" ")
    .join("-")
    .replaceAll("&", "and")
    .replaceAll("/", "")
    .replaceAll("'", "")
    .replaceAll(/\./gi, "");
};

export const getExtension = (url?: string | null) => url?.split(".").pop();

export const getResponsiveImageUrl = (
  imageUrl?: string,
  res?: "320w" | "480w" | "640w" | "768w" | "1024w" | "1366w" | "1600w",
) => {
  if (!imageUrl || !res) return "";

  const extension = getExtension(imageUrl) || "jpg";
  return imageUrl.replace(`.${extension}`, `-${res}.${extension}`);
};
