import { useContext } from "react";
import { NewModal } from "../../../ui/NewModal/Modal";
import { User } from "../../Authentication/types";
import { GroupOrderContext } from "../context/GroupOrderContext";
interface LeaveGroupOrderModalProps {
  handleLeave: () => void;
  user?: User;
}
const LeaveGroupOrderModal = ({
  handleLeave,
  user
}: LeaveGroupOrderModalProps) => {
  const {
    showLeaveModal,
    setShowLeaveModal
  } = useContext(GroupOrderContext);
  return <NewModal header="Group order" mobileHeader="Leave group order?" body={<p className="text-left text-gray-550 font-light max-w-md">
          {user ? `You can always return to this group order using the original invite link. By clicking the button below, you will create a new personal bag.` : `Are you sure? You will be able to rejoin using the original invite link, but you will have to use a different name.`}
        </p>} show={showLeaveModal} cancelMessage="Cancel" confirmMessage="Create a personal bag" onConfirm={handleLeave} onCloseComplete={() => {
    setShowLeaveModal(false);
  }} onClose={() => {
    setShowLeaveModal(false);
  }} onBlur={() => {
    setShowLeaveModal(false);
  }} hasConfirm hasCancel hasClose data-sentry-element="NewModal" data-sentry-component="LeaveGroupOrderModal" data-sentry-source-file="LeaveGroupOrderModal.tsx" />;
};
export default LeaveGroupOrderModal;