import { useEffect } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { TipRateType } from "../../../types";
import { Header, Text } from "../../../ui/Typography";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { Cart } from "../../Cart/types";
import { useCalculateTipAmount } from "../hooks/useCalulateTipAmount";
import { isCustomTip } from "../utils/customTipUtils";
import { CUSTOM_TIP_INDEX, CUSTOM_TIP_RATES } from "../utils/tipDisplay";
import TipButton from "./TipButton";
interface TipProps {
  cart: Cart;
  onTipChange: (tipRate: number, tipValue?: number) => void;
  displayCustomTipModal?: () => void;
  initialTipRate: number;
  receipt?: boolean;
}
const Tip = ({
  cart,
  onTipChange,
  initialTipRate,
  displayCustomTipModal,
  receipt
}: TipProps) => {
  const {
    track
  } = useTrackWithFlags();
  const {
    selectedTipValue,
    setSelectedTipValue,
    cartReceiptLoading,
    subtotal
  } = useCartContext();
  const {
    fetchCustomTipAmount: fetchTipAmount,
    data: tipAmountData
  } = useCalculateTipAmount();

  // Set initial tip rate from parent
  useEffect(() => {
    const foundRate = CUSTOM_TIP_RATES.find(rate => rate.value === cart.tip);
    if (!foundRate) {
      setSelectedTipValue(initialTipRate);
      onTipChange(initialTipRate);
    }
  }, []);
  useEffect(() => {
    if (cartReceiptLoading) return;
    if (isCustomTip(cart?.tip_rate || 0)) {
      setSelectedTipValue(CUSTOM_TIP_INDEX);
      return;
    }

    // found in rates, so guest had selected a fixed tip_rate
    fetchTipAmount(cart?.tip_rate || 0, subtotal);
    setSelectedTipValue(cart?.tip_rate || 0);
  }, [cartReceiptLoading]);
  const handleClick = (tipRate: TipRateType) => {
    track({
      event: "Clicked Tip",
      properties: {
        cartId: cart.id,
        tipRate
      }
    });
    if (tipRate.value === CUSTOM_TIP_INDEX) {
      displayCustomTipModal?.();
      return;
    }
    setSelectedTipValue(tipRate.value);
    fetchTipAmount(tipRate.value, subtotal);
  };
  useEffect(() => {
    if (tipAmountData) {
      onTipChange(tipAmountData.tipRate, tipAmountData.tipValue);
    }
  }, [tipAmountData]);
  return <div data-sentry-component="Tip" data-sentry-source-file="Tip.tsx">
      {receipt ? <Header h4>Add tip</Header> : <Header h3>Add tip</Header>}
      <Text medium component="p" className="mt-2 mb-6 text-text-secondary" data-sentry-element="Text" data-sentry-source-file="Tip.tsx">
        100% goes to{" "}
        {cart?.is_delivery ? "your delivery driver" : "the team preparing your food"}
      </Text>
      <div className="flex flex-row w-full gap-2 items-start box-border">
        {CUSTOM_TIP_RATES.map((tipRate, idx: number) => <TipButton key={`tip-rate-${idx}`} onClick={() => handleClick(tipRate)} tipRate={tipRate} isSelected={tipRate.value === selectedTipValue} subtotal={subtotal} />)}
      </div>
    </div>;
};
export default Tip;