import { classNames } from "../utils/classNames";
type LineLoaderProps = {
  isLoading: boolean;
  className?: string;
};
const LineLoader = ({
  isLoading,
  className
}: LineLoaderProps) => {
  return <div className={classNames("min-h-[4px]", className)} data-sentry-component="LineLoader" data-sentry-source-file="LineLoader.tsx">
      {isLoading && <div className={`w-full h-[4px] relative overflow-hidden
            rounded-[20px] bg-border-secondary before:absolute
            before:rounded-[20px] before:left-1/2 before:h-[4px]
            before:w-[40%] before:animate-line-animation
            before:bg-border-secondary  before:content-['']`} />}
    </div>;
};
export default LineLoader;