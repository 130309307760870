import { useContext } from "react";
import { User } from "../Authentication/types";
import ConfirmGroupOrderModal from "./components/ConfirmGroupOrderModal";
import CustomerModal from "./components/CustomerModal";
import GroupOrderMessageModal from "./components/GroupOrderMessageModal";
import LeaveGroupOrderModal from "./components/LeaveGroupOrderModal";
import ShareGroupOrderModal from "./components/ShareGroupOrderModal";
import { GroupOrderContext } from "./context/GroupOrderContext";
import { useGroupOrder } from "./hooks/useGroupOrder";
interface GroupOrderControllerProps {
  user?: User;
}
export const GroupOrderController = ({
  user
}: GroupOrderControllerProps) => {
  const {
    setShowShareGroupOrderModal
  } = useContext(GroupOrderContext);
  const {
    copyUrl,
    shareLink,
    handleConfirmGroupOrder,
    leaveGroupOrder,
    makingGroupOrderCart
  } = useGroupOrder({
    user,
    clickSource: "brand-page"
  });
  return <>
      <CustomerModal user={user} data-sentry-element="CustomerModal" data-sentry-source-file="GroupOrderController.tsx" />
      <ConfirmGroupOrderModal handleConfirm={handleConfirmGroupOrder} loading={makingGroupOrderCart} data-sentry-element="ConfirmGroupOrderModal" data-sentry-source-file="GroupOrderController.tsx" />
      <LeaveGroupOrderModal user={user} handleLeave={leaveGroupOrder} data-sentry-element="LeaveGroupOrderModal" data-sentry-source-file="GroupOrderController.tsx" />
      <GroupOrderMessageModal data-sentry-element="GroupOrderMessageModal" data-sentry-source-file="GroupOrderController.tsx" />
      <ShareGroupOrderModal handleCopy={async () => {
      await copyUrl();
      setShowShareGroupOrderModal(false);
    }} handleShare={shareLink} data-sentry-element="ShareGroupOrderModal" data-sentry-source-file="GroupOrderController.tsx" />
    </>;
};