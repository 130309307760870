import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { ToastProps } from "../../../ui/Toast/Toast";
import { CustomTipDataType } from "../../Cart/types";
import { CUSTOM_TIP_INDEX, CUSTOM_TIP_RATES } from "./tipDisplay";

const DEFAULT_TIP_RATES = [0, 1000, 1500, 2000];

export const handleDisplayTipChange = (
  e: ChangeEvent<HTMLInputElement>,
  setDisplayTip: Dispatch<SetStateAction<string>>,
) => {
  const rawValue = e?.target?.value || "";
  const formattedValue = rawValue.trim();
  setDisplayTip(formattedValue);
};

export const findSelectedTipValue = (tipRate: number) =>
  DEFAULT_TIP_RATES.includes(tipRate) ? tipRate : CUSTOM_TIP_INDEX;

export const handleCustomTipDataUpdate = (
  showToast: (props: ToastProps) => void,
  onTipChange: (tipRate: number, tipValue?: number) => void,
  setSelectedTipValue: Dispatch<SetStateAction<number>>,
  setShowCustomTipType: Dispatch<SetStateAction<boolean>>,
  data?: CustomTipDataType,
) => {
  if (!data) return;
  const { tipRate, tipValue } = data;
  if (tipValue >= 10000000) {
    showToast({
      description:
        "Oops! This is a huge tip amount, and we don't want to take all your money. Please enter a smaller amount.",
      variant: "error",
      seconds: 2,
      onClose: () => {},
    });
    return;
  }

  onTipChange(tipRate, tipValue);
  setSelectedTipValue?.(findSelectedTipValue(tipRate));
  setShowCustomTipType(false);
};

export const isCustomTip = (tipRate: number): boolean =>
  !CUSTOM_TIP_RATES.some((rate) => rate.value === tipRate);

export const defaultTipRate = {
  tipRate: 0,
  tipValue: 0,
};
