import { faChevronDown } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { StoreLocation } from "@features/ChoosePickupDelivery/types";
import { useGroupOrderContext } from "@features/GroupOrder/context/GroupOrderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderType } from "@types";
import { Button } from "@ui/NewButton/Button";
import { classNames } from "@utils/classNames";
import { useOrderTypeContext } from "@utils/contexts/OrderTypeContext";
import React, { useMemo } from "react";
import { Header, Text } from "../Typography";
type LocationPickerProps = {
  storeLocation?: StoreLocation;
  deliveryStreetAddress?: string;
  className?: string;
};
export const LocationPicker = React.memo(({
  storeLocation,
  deliveryStreetAddress,
  className
}: LocationPickerProps) => {
  const {
    orderType,
    handleChangeOrderTypeClicked,
    setShowChooseOrderType
  } = useOrderTypeContext();
  const {
    isGroupOrder,
    isGroupOrderCreator
  } = useGroupOrderContext();
  const address = useMemo(() => {
    if (!orderType) return "";
    if (orderType === OrderType.DELIVERY) {
      return deliveryStreetAddress ?? "";
    } else if (storeLocation) {
      return `${storeLocation.display_name} Store`;
    }
    return "";
  }, [orderType, deliveryStreetAddress, storeLocation]);
  const handleTabChange = (activeTabName: string) => {
    if (activeTabName === "Delivery") {
      handleChangeOrderTypeClicked(OrderType.DELIVERY, "navbar");
      setShowChooseOrderType(true);
    } else {
      handleChangeOrderTypeClicked(OrderType.PICKUP, "navbar");
    }
  };
  const disablePicker = isGroupOrder && !isGroupOrderCreator;
  return <>
        <div className={classNames("hidden md:flex items-center w-fit text-sm truncate", className)}>
          <div className="flex text-nowrap space-x-2">
            <Button label="Pickup" shape="rounded" onClick={() => {
          handleTabChange("Pickup");
        }} mode={orderType === OrderType.PICKUP ? "toggleSelected" : "toggle"} size="small" />
            <Button label="Delivery" shape="rounded" onClick={() => {
          handleTabChange("Delivery");
        }} mode={orderType === OrderType.DELIVERY ? "toggleSelected" : "toggle"} size="small" />
          </div>

          {!!address && <button className="flex items-center justify-center ml-4" disabled={disablePicker} onClick={() => setShowChooseOrderType(true)}>
              <Text medium className="text-new-black-base capitalize truncate">
                {address}
              </Text>
              <FontAwesomeIcon icon={faChevronDown} className="ml-1 h-4 w-4 text-lfg-secondary" />
            </button>}
        </div>
        <button className={classNames("md:hidden flex items-center w-fit text-sm truncate", className)} disabled={disablePicker} onClick={() => setShowChooseOrderType(true)}>
          {!!orderType && <Header h5 className="text-text-secondary capitalize">
              {orderType}
            </Header>}
          {/* Spacer */}
          {!!address && <Header h5 className="text-new-black-base mx-1">
              ·
            </Header>}
          {!!address && <Header h5 className="text-new-black-base capitalize truncate">
              {address}
            </Header>}
          {!!orderType && <FontAwesomeIcon icon={faChevronDown} className="ml-1 h-4 w-4 text-lfg-secondary" />}
        </button>
      </>;
});