import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { LocationWithDistance, OrderType } from "../../../../types";
import { LoadingSpinner } from "../../../../ui/Loading/LoadingSpinner";
import { useLocationContext } from "../../../../utils/contexts/LocationContext";
import { useOrderTypeContext } from "../../../../utils/contexts/OrderTypeContext";
import { useIsGoogleMapsLoaded } from "../../../../utils/hooks/useIsGoogleMapsLoaded";
import { useTrackWithFlags } from "../../../../utils/hooks/useTrackWithFlags";
import useDiscoveryQueryParams from "../../hooks/useDiscoveryQueryParams";
import { GoogleMapWrapper } from "./GoogleMapWrapper";
const returnTo = process.env.NEXT_PUBLIC_LANDING_URL;
interface LocalKitchensMapProps {
  orderType: OrderType | undefined;
}
export const LocalKitchensMap = ({
  orderType
}: LocalKitchensMapProps) => {
  const router = useRouter();
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const {
    track
  } = useTrackWithFlags();
  const {
    locations,
    selectedLocation,
    deliverableLocations,
    onLocationClick,
    searchedAddress,
    storeLocation: currentLocation,
    loadingDeliveryEstimates
  } = useLocationContext();
  const {
    setShowChooseOrderType
  } = useOrderTypeContext();
  const isGoogleLoaded = useIsGoogleMapsLoaded();
  const shouldClose = useMemo(() => {
    return router.pathname === "/order";
  }, [router.pathname]);
  const queryParams = useDiscoveryQueryParams(orderType, searchedAddress);
  const onClose = useCallback(() => {
    if (shouldClose && !selectedLocation) {
      window.location.href = returnTo || "";
    } else if (selectedLocation && currentLocation?.slug !== selectedLocation?.slug) {
      track({
        event: "Map close icon clicked with location selected",
        properties: {
          slug: selectedLocation.slug
        }
      });
      window.location.href = `/order/store/${selectedLocation.slug}/popular${queryParams}`;
    } else {
      setShowChooseOrderType(false);
    }
  }, [shouldClose, currentLocation, setShowChooseOrderType, selectedLocation, queryParams, track]);
  const shownLocations = useMemo(() => {
    return !loadingDeliveryEstimates && Array.isArray(deliverableLocations) ? deliverableLocations : locations;
  }, [deliverableLocations, locations, loadingDeliveryEstimates]);
  useEffect(() => {
    const allLocations = (shownLocations as LocationWithDistance[]);
    if (!allLocations?.length || !isGoogleLoaded) return;
    const tempBounds = new google.maps.LatLngBounds();
    allLocations.forEach(location => {
      const latLng = new google.maps.LatLng(location.lat, location.lng);
      tempBounds.extend(latLng);
    });
    if (searchedAddress?.geocodes?.lat && searchedAddress?.geocodes?.lng) {
      const searchedAddressLatLng = new google.maps.LatLng(searchedAddress.geocodes?.lat, searchedAddress.geocodes?.lng);
      tempBounds.extend(searchedAddressLatLng);
    }
    setBounds(tempBounds);
  }, [shownLocations, searchedAddress, isGoogleLoaded]);
  const handleLocationClick = useCallback((location: LocationWithDistance) => {
    track({
      event: "Location Clicked on Map",
      properties: {
        location: location.slug
      }
    });
    onLocationClick(location);
  }, [onLocationClick, track]);
  const handleLocationLinkClick = useCallback((location: LocationWithDistance) => {
    track({
      event: "Location Marker double clicked",
      properties: {
        slug: location.slug
      }
    });
    if (currentLocation?.slug === location.slug) {
      // close the map
      setShowChooseOrderType(false);
    } else {
      const currentPath = router.asPath;
      const targetPath = `/order/store/${location.slug}/popular${queryParams}`;
      if (currentPath !== targetPath) {
        router.push(targetPath, `/order/store/${location.slug}/popular`);
      }
    }
  }, [track, currentLocation?.slug, setShowChooseOrderType, router, queryParams]);
  if (!isGoogleLoaded) {
    return <div className="h-full w-full flex items-center justify-center">
        <LoadingSpinner />
      </div>;
  }
  return <GoogleMapWrapper bounds={bounds} locations={shownLocations} onLocationClick={handleLocationClick} onLocationDoubleClick={handleLocationLinkClick} shouldClose={shouldClose} onClose={onClose} data-sentry-element="GoogleMapWrapper" data-sentry-component="LocalKitchensMap" data-sentry-source-file="LocalKitchensMap.tsx" />;
};