import { Popover } from "@headlessui/react";
type NavbarHeaderProps = {
  children: React.ReactNode;
};
export const NAVBAR_HEADER_ID = "nav-header";
export function NavbarHeader({
  children
}: NavbarHeaderProps) {
  return <header id={NAVBAR_HEADER_ID} className="w-full" data-sentry-component="NavbarHeader" data-sentry-source-file="NavbarHeader.tsx">
      <Popover className="w-full h-full flex items-center pr-0" data-sentry-element="Popover" data-sentry-source-file="NavbarHeader.tsx">
        {children}
      </Popover>
    </header>;
}