import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { useLocalStorage } from "../../../utils/hooks/useLocalStorage";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { parsePhoneNumberWithoutCountryCode } from "../../../utils/phoneNumber";
import { useAuth } from "../../Authentication/hooks/useAuth";
import useUpdateCartWithUser from "../../Checkout/hooks/useUpdateCartWithUser";
import { CLICK_SOURCE } from "../types";
type GroupOrderContextType = {
  showNameRequiredModal: boolean;
  setShowNameRequiredModal: (show: boolean, clickSource?: CLICK_SOURCE) => void;
  showLeaveModal: boolean;
  setShowLeaveModal: (show: boolean, clickSource?: CLICK_SOURCE) => void;
  showConfirmationModal: boolean;
  setShowConfirmationModal: (show: boolean, clickSource?: CLICK_SOURCE) => void;
  showShareGroupOrderModal: boolean;
  setShowShareGroupOrderModal: (show: boolean, clickSource?: CLICK_SOURCE) => void;
  message: string;
  setMessage: (value: string) => void;
  showMessageModal: boolean;
  setShowMessageModal: (show: boolean, clickSource?: CLICK_SOURCE) => void;
  participantId?: string;
  setParticipantId: (val: string) => void;
  spendLimit?: number;
  setSpendLimit: (val?: number) => void;
  getGroupOrderTrackProperties: () => Record<string, any>;
  isGroupOrder: boolean;
  isGroupOrderCreator: boolean;
};
export const GroupOrderContext = createContext<GroupOrderContextType>({
  showNameRequiredModal: false,
  setShowNameRequiredModal: () => {},
  showLeaveModal: false,
  setShowLeaveModal: () => {},
  showConfirmationModal: false,
  setShowConfirmationModal: () => {},
  showShareGroupOrderModal: false,
  setShowShareGroupOrderModal: () => {},
  showMessageModal: false,
  setShowMessageModal: () => {},
  setParticipantId: () => {},
  spendLimit: undefined,
  setSpendLimit: () => {},
  message: "",
  setMessage: () => {},
  getGroupOrderTrackProperties: () => ({}),
  isGroupOrder: false,
  isGroupOrderCreator: false
});
export function GroupOrderContextProvider({
  children
}: PropsWithChildren) {
  const {
    cart
  } = useCartContext();
  const [message, setMessage] = useState("");
  const [showNameRequiredModal, _setShowNameRequiredModal] = useState(false);
  const [showConfirmationModal, _setShowConfirmationModal] = useState(false);
  const [showLeaveModal, _setShowLeaveModal] = useState(false);
  const [showShareGroupOrderModal, _setShowShareGroupOrderModal] = useState(false);
  const [showMessageModal, _setShowMessageModal] = useState(false);
  const [participantId, setParticipantId] = useLocalStorage("lfg_participant_id", "");
  const [spendLimit, setSpendLimit] = useState<number | undefined>();
  const {
    user
  } = useAuth();
  const {
    track
  } = useTrackWithFlags();
  const updateCartWithCustomer = useUpdateCartWithUser();
  const setShowNameRequiredModal = (show: boolean, clickSource?: CLICK_SOURCE) => {
    if (show) {
      track({
        event: "Viewed Group Order Name Modal",
        properties: {
          clickSource
        }
      });
    }
    _setShowNameRequiredModal(show);
  };
  const setShowMessageModal = (show: boolean, clickSource?: CLICK_SOURCE) => {
    if (show) {
      track({
        event: "Group Order Exceeded limit Modal",
        properties: {
          clickSource
        }
      });
    }
    _setShowMessageModal(show);
  };
  const setShowConfirmationModal = (show: boolean, clickSource?: CLICK_SOURCE) => {
    if (show) {
      track({
        event: "Viewed Group Order Confirmation Modal",
        properties: {
          clickSource
        }
      });
    }
    _setShowConfirmationModal(show);
  };
  const setShowShareGroupOrderModal = (show: boolean, clickSource?: CLICK_SOURCE) => {
    if (show) {
      track({
        event: "Viewed Group Order Share Modal",
        properties: {
          clickSource
        }
      });
    }
    _setShowShareGroupOrderModal(show);
  };
  const setShowLeaveModal = (show: boolean, clickSource?: CLICK_SOURCE) => {
    if (show) {
      track({
        event: "Viewed Group Order Leave Modal",
        properties: {
          clickSource
        }
      });
    }
    _setShowLeaveModal(show);
  };
  const getGroupOrderTrackProperties = useCallback(() => {
    return {
      is_group_order: cart?.is_group_order,
      customer_id: cart?.customer_id,
      participants: cart?.participants?.map(participant => ({
        id: participant.id,
        customer_id: participant.customer_id,
        is_creator: participant.is_creator
      }))
    };
  }, [cart]);
  const isGroupOrder = useMemo(() => {
    return Boolean(cart?.is_group_order);
  }, [cart?.is_group_order]);
  const isGroupOrderCreator = useMemo(() => {
    return isGroupOrder && cart.participants?.find(participant => participant.is_creator)?.id === participantId;
  }, [isGroupOrder, cart?.participants, participantId]);
  useEffect(() => {
    if (!cart.id || isGroupOrder && !isGroupOrderCreator || !user) {
      return;
    }
    if (user.email === cart.email && user.first_name === cart.first_name && user.last_name === cart.last_name && parsePhoneNumberWithoutCountryCode(user.phone_number ?? undefined) === cart.phone_number && user.id === cart.customer_id) {
      return;
    }
    updateCartWithCustomer(cart, user);
  }, [cart, user, isGroupOrder, isGroupOrderCreator]);
  return <GroupOrderContext.Provider value={{
    showNameRequiredModal,
    setShowNameRequiredModal,
    setShowConfirmationModal,
    showConfirmationModal,
    showShareGroupOrderModal,
    setShowShareGroupOrderModal,
    showLeaveModal,
    setShowLeaveModal,
    showMessageModal,
    setShowMessageModal,
    participantId,
    setParticipantId,
    spendLimit,
    setSpendLimit,
    message,
    setMessage,
    getGroupOrderTrackProperties,
    isGroupOrder,
    isGroupOrderCreator
  }} data-sentry-element="unknown" data-sentry-component="GroupOrderContextProvider" data-sentry-source-file="GroupOrderContext.tsx">
      {children}
    </GroupOrderContext.Provider>;
}
export const useGroupOrderContext = () => {
  const groupOrderContext = useContext(GroupOrderContext);
  if (!groupOrderContext) {
    throw new Error("You may not user GroupOrderContext outside of GroupOrderContextProvider");
  } else {
    return groupOrderContext;
  }
};