import { classNames } from "../utils/classNames";
interface CheckoutDividerProps {
  className?: string;
  variant?: keyof typeof variants;
}
const variants = ({
  light: "border-border-secondary border-t my-6",
  lightWitMediumMargin: "border-border-secondary border-t my-6 md:my-8",
  bold: "border border-t-4 border-black my-12",
  lightWithBigMargin: "border-border-secondary border-t my-12",
  withoutMargin: "border-border-secondary border-t"
} as const);
const Divider = ({
  className,
  variant = "light"
}: CheckoutDividerProps) => <hr className={classNames("w-full block", variants[variant], className)} data-sentry-component="Divider" data-sentry-source-file="Divider.tsx" />;
export default Divider;