import { motion } from "framer-motion";
import { PropsWithChildren } from "react";
interface BackdropProps extends PropsWithChildren {
  onClick: () => void;
}
const Backdrop = ({
  children,
  onClick
}: BackdropProps) => {
  return <motion.div onClick={onClick} className="bg-opacity-40 !m-0 bg-new-black-base fixed flex inset-0 overflow-y-auto pt-8 sm:pb-8 z-60" initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }} exit={{
    opacity: 0
  }} style={{
    // Workaround for Safari to avoid flickering upon animating opacity
    WebkitPerspective: 1000
  }} data-sentry-element="unknown" data-sentry-component="Backdrop" data-sentry-source-file="Backdrop.tsx">
      {children}
    </motion.div>;
};
export default Backdrop;