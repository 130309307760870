import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { classNames } from "@utils/classNames";
import React from "react";
type CustomFontAwesomeIcon = Omit<FontAwesomeIconProps, "size">;
export type IconCustomProps = CustomFontAwesomeIcon | IconDefinition | React.ReactNode;
const iconSizes = ({
  small: "w-4 h-4",
  large: "w-6 h-6"
} as const);
type IconSize = keyof typeof iconSizes;
const isIconProp = (icon: IconCustomProps): icon is IconDefinition => {
  return !!(icon as IconDefinition).iconName && !!(icon as IconDefinition).prefix;
};
const isFontAwesomeIconProps = (icon: IconCustomProps): icon is CustomFontAwesomeIcon => {
  return !!(icon as CustomFontAwesomeIcon).icon;
};
export const Icon = ({
  icon,
  className,
  size = "large"
}: {
  icon: IconCustomProps;
  className?: string;
  size?: IconSize;
}) => {
  if (isIconProp(icon)) {
    return <FontAwesomeIcon icon={icon} className={classNames(iconSizes[size], className)} />;
  }
  if (isFontAwesomeIconProps(icon)) {
    return <FontAwesomeIcon className={classNames(iconSizes[size], className)} {...icon} />;
  }
  if (React.isValidElement(icon)) {
    return icon;
  }
  throw new Error(`Invalid icon prop, ${icon}`);
};