import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "@ui/Typography";
type Props = {
  label?: string;
  onPress: () => void;
  disabled?: boolean;
};
export function EditAddressButton({
  label,
  onPress,
  disabled
}: Props) {
  return <Link onClick={onPress} size="small" className="hover:opacity-80 !font-semibold" underLine="solid" disabled={disabled} data-sentry-element="Link" data-sentry-component="EditAddressButton" data-sentry-source-file="EditAddressButton.tsx">
      <div className="flex items-center">
        <PencilSquareIcon className="text-lfg-primary h-5 w-5 mr-1" data-sentry-element="PencilSquareIcon" data-sentry-source-file="EditAddressButton.tsx" />
        {label ?? "Edit address"}
      </div>
    </Link>;
}