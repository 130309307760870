import { useCallback, useEffect, useState } from "react";
import defaultTheme from "tailwindcss/defaultTheme";

export enum TailwindBreakpoint {
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
  mobile = "mobile",
}

interface Query {
  breakpoint: TailwindBreakpoint;
  query: string;
}

const queries: Query[] = Object.entries(defaultTheme.screens).map(
  ([breakpoint, value]) => ({
    breakpoint:
      TailwindBreakpoint[breakpoint as keyof typeof TailwindBreakpoint],
    query: `(min-width: ${value})`,
  }),
);

const getMatchingBreakpoint = (): TailwindBreakpoint => {
  if (typeof window !== "undefined") {
    let result = TailwindBreakpoint.mobile;

    queries.forEach(({ breakpoint, query }) => {
      if (window.matchMedia(query).matches) {
        result = breakpoint;
      }
    });

    return result;
  }

  return TailwindBreakpoint.mobile;
};

// Returns current tailwind breakpoint and rerenders page when breakpoint changes
const useTailwindBreakpoints = () => {
  const [currentBreakpoint, setCurrentBreakpoint] =
    useState<TailwindBreakpoint>(TailwindBreakpoint.mobile);

  const handleChange = useCallback(() => {
    setCurrentBreakpoint(getMatchingBreakpoint());
  }, []);

  useEffect(() => {
    const matchMedias: MediaQueryList[] = [];
    queries.forEach((query) => {
      const matchMedia = window.matchMedia(query.query);

      matchMedia.addEventListener("change", handleChange);
      matchMedias.push(matchMedia);
    });

    // Triggered at the first client-side load and if query changes
    handleChange();

    return () => {
      matchMedias.forEach((matchMedia) =>
        matchMedia.removeEventListener("change", handleChange),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentBreakpoint;
};

export default useTailwindBreakpoints;
