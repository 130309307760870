import { useCartContext } from "@appComponents/hooks/useCartContext";
import { ToastContext } from "@appComponents/hooks/useToast";
import VerifyCodeModal from "@features/Authentication/components/VerifyCodeModal";
import useAuth from "@features/Authentication/hooks/useAuth";
import useRequestPasscode from "@features/Authentication/hooks/useRequestPasscode";
import { PasscodeLoginMethod, User } from "@features/Authentication/types";
import { useQueryClient } from "@tanstack/react-query";
import { CashRewardLoyalty } from "@types";
import { useContext, useEffect, useState } from "react";
import CheckIcon from "../../../ui/Icons/CheckIcon";
import { LoadingSpinner } from "../../../ui/Loading/LoadingSpinner";
import { Header, Text } from "../../../ui/Typography";
import { classNames } from "../../../utils/classNames";
import { useLoyaltyContext } from "../../../utils/contexts/LoyaltyContext";
const NEXT_PUBLIC_GUEST_WEB_URL = process.env.NEXT_PUBLIC_GUEST_WEB_URL;
interface Props {
  disabled?: boolean;
}
export const CashRewardRedeemToggle = ({
  disabled = false
}: Props) => {
  const {
    cart,
    subtotal
  } = useCartContext();
  const hasCoupon = !!cart.coupon;
  const {
    isCashRewardRedeemEnabled,
    toggleCashRewardRedeem,
    loading,
    cashRewardLoyalty
  } = useLoyaltyContext();
  const {
    user
  } = useAuth();
  const {
    showToast
  } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [showVerifyCodeModal, setShowVerifyCodeModal] = useState(false);
  const [isSignupRewardUsable, setIsSignupRewardUsable] = useState(false);
  const [isTogglingReward, setIsTogglingReward] = useState(false);
  const [isSignupReward, setIsSignupReward] = useState(true);
  const confettiStyle = {
    backgroundImage: isCashRewardRedeemEnabled && !disabled && !hasCoupon ? undefined : `url('${NEXT_PUBLIC_GUEST_WEB_URL}/guest_web_assets/reward-confetti.webp')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    backgroundPosition: "center"
  };
  const isUseSignupReward = (cashRewardLoyalty: CashRewardLoyalty | undefined) => {
    if (!cashRewardLoyalty) return false;
    const signupRewardsUsed = Math.max(cashRewardLoyalty.total_cash_rewards_used_count - cashRewardLoyalty.total_cash_rewards_received_count, 0);
    const cashRewardsLeft = Math.max(cashRewardLoyalty.total_cash_rewards_received_count - (cashRewardLoyalty.total_cash_rewards_used_count - signupRewardsUsed), 0);
    return cashRewardsLeft === 0;
  };
  const {
    mutate: requestPasscode
  } = useRequestPasscode(() => {
    setShowVerifyCodeModal(true);
  }, () => {
    showToast({
      variant: "error",
      description: "Having trouble sending the code. Please try again."
    });
  });
  const isDisabled = loading || disabled || hasCoupon || !isSignupRewardUsable;
  useEffect(() => {
    const checkSignupReward = () => {
      if (isUseSignupReward(cashRewardLoyalty)) {
        setIsSignupReward(true);
        if (subtotal < 20 * 100) {
          if (isCashRewardRedeemEnabled && !isTogglingReward) {
            setIsTogglingReward(true);
            setTimeout(() => showToast({
              description: "Signup reward invalidated and removed from cart.",
              variant: "error"
            }), 2000);
            toggleCashRewardRedeem();
            setIsTogglingReward(false);
          }
          setIsSignupRewardUsable(false);
        } else {
          setIsSignupRewardUsable(true);
        }
      } else {
        setIsSignupReward(false);
        setIsSignupRewardUsable(true);
      }
    };
    checkSignupReward();
  }, [cashRewardLoyalty, subtotal, isCashRewardRedeemEnabled, isTogglingReward, setIsSignupRewardUsable]);
  const onApply = () => {
    if (!cashRewardLoyalty) {
      throw new Error("Loyalty is not available");
    }
    if (!user.is_phone_number_verified && user.phone_number && isUseSignupReward(cashRewardLoyalty)) {
      requestPasscode({
        method: PasscodeLoginMethod.Sms,
        identifier: user.phone_number
      });
    } else {
      toggleCashRewardRedeem();
    }
  };
  const onSuccessfulVerify = (user: User) => {
    queryClient.setQueryData(["me"], {
      data: user
    });
    toggleCashRewardRedeem();
  };
  return <div className={classNames((hasCoupon || !isSignupRewardUsable) && !disabled ? "opacity-50" : "opacity-100")} data-sentry-component="CashRewardRedeemToggle" data-sentry-source-file="CashRewardRedeemToggle.tsx">
      <button className={classNames("w-full px-4 py-3 rounded-xl flex justify-between items-center cursor-pointer z-10", isCashRewardRedeemEnabled && !disabled && !hasCoupon ? "border-new-green-base bg-new-green-lighten-2 border-2" : "border-border-primary border")} style={confettiStyle} disabled={isDisabled} onClick={onApply}>
        <div className="flex items-center">
          <Header h5 component="p" className={classNames("p-3 rounded-xl select-none bg-new-black-base text-white")} data-sentry-element="Header" data-sentry-source-file="CashRewardRedeemToggle.tsx">
            $10 reward
          </Header>

          {isCashRewardRedeemEnabled && !disabled && !hasCoupon && <div className="w-5 h-5 mx-3 bg-new-green-base rounded-full flex items-center justify-center">
              <CheckIcon className="text-white" aria-hidden="true" />
            </div>}
        </div>
        {loading ? <div className="px-8">
            <LoadingSpinner size={6} />
          </div> : <Text buttonSmall className="select-none px-1 text-new-black-base">
            {disabled || hasCoupon || !isSignupRewardUsable ? "" : isCashRewardRedeemEnabled ? "Remove" : "Apply"}
          </Text>}
      </button>
      {!disabled && isSignupReward && <Text large component="p" className="mt-2 text-text-secondary">
          $20 minimum to use signup reward
        </Text>}
      <VerifyCodeModal identifier={user?.phone_number} method={PasscodeLoginMethod.Sms} showModal={showVerifyCodeModal} closeModal={() => setShowVerifyCodeModal(false)} onSuccess={onSuccessfulVerify} data-sentry-element="VerifyCodeModal" data-sentry-source-file="CashRewardRedeemToggle.tsx" />
    </div>;
};