import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "../utils/classNames";
import { Header } from "./Typography/Header";
interface QuantitySelectorProps {
  onPlusClick: () => void;
  onMinusClick: () => void;
  quantity: number;
  plusDisabled?: boolean;
  minusDisabled?: boolean;
  className?: string;
}
const QuantitySelector = ({
  onPlusClick,
  onMinusClick,
  quantity,
  plusDisabled,
  minusDisabled,
  className
}: QuantitySelectorProps) => {
  return <div className={classNames("flex items-center justify-between w-20 shrink-0", className)} data-sentry-component="QuantitySelector" data-sentry-source-file="QuantitySelector.tsx">
      <button type="button" onClick={onMinusClick} disabled={minusDisabled}>
        <MinusCircleIcon width={24} height={24} className={minusDisabled ? "text-text-secondary" : ""} data-sentry-element="MinusCircleIcon" data-sentry-source-file="QuantitySelector.tsx" />
      </button>
      <Header h4 component="p" data-sentry-element="Header" data-sentry-source-file="QuantitySelector.tsx">
        {quantity}
      </Header>
      <button type="button" disabled={plusDisabled} onClick={onPlusClick}>
        <PlusCircleIcon width={24} height={24} className={plusDisabled ? "text-text-secondary" : ""} data-sentry-element="PlusCircleIcon" data-sentry-source-file="QuantitySelector.tsx" />
      </button>
    </div>;
};
export default QuantitySelector;