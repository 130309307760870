import { ArrowLeftIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { UserGroupOrderIcon } from "../../../ui/Icons/UserGroupOrderIcon";
import { LoadingSpinner } from "../../../ui/Loading/LoadingSpinner";
import { Header } from "../../../ui/Typography";
import { classNames } from "../../../utils/classNames";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { useLoginContext } from "../../Authentication/contexts/LoginContext";
import { User } from "../../Authentication/types";
type CartHeaderProps = {
  title: string;
  closeCart: () => void;
  isGroupOrder: boolean;
  user?: User;
  makeGroupOrderClick: () => void;
  loadingRefreshGroupOrder: boolean;
  refreshGroupOrder: () => void;
  isStickedToTop: boolean;
};
const variants = {
  visible: {
    y: 0
  },
  hidden: {
    y: "-100%"
  }
};
export function CartHeader({
  title,
  closeCart,
  isGroupOrder,
  user,
  makeGroupOrderClick,
  loadingRefreshGroupOrder,
  isStickedToTop,
  refreshGroupOrder
}: CartHeaderProps) {
  const {
    login
  } = useLoginContext();
  const {
    track
  } = useTrackWithFlags();
  const handleGroupOrder = () => {
    if (!user) {
      track({
        event: "Clicked Group Order Login",
        properties: {
          clickSource: "cart"
        }
      });
      login();
    } else {
      makeGroupOrderClick();
    }
  };
  return <div className={classNames("flex items-center justify-between px-6 transition-all", isStickedToTop && "shadow-lg bg-surface-lighter")} data-sentry-component="CartHeader" data-sentry-source-file="CartHeader.tsx">
      <button type="button" onClick={closeCart} className="text-text-secondary hover:text-text-secondary my-4">
        <span className="sr-only">Close order panel</span>
        <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" data-sentry-element="ArrowLeftIcon" data-sentry-source-file="CartHeader.tsx" />
      </button>
      <motion.div variants={variants} initial="hidden" animate={isStickedToTop ? "visible" : "hidden"} className="h-full flex items-center" data-sentry-element="unknown" data-sentry-source-file="CartHeader.tsx">
        <Header h5 data-sentry-element="Header" data-sentry-source-file="CartHeader.tsx">{title}</Header>
      </motion.div>
      {!isGroupOrder && <button className="text-text-secondary hover:text-text-secondary my-4" onClick={handleGroupOrder}>
          <UserGroupOrderIcon className="w-6 h-6" />
        </button>}
      {isGroupOrder && (loadingRefreshGroupOrder ? <LoadingSpinner size={5} borderWidth={2} /> : <ArrowPathIcon onClick={refreshGroupOrder} className="w-5 mt-0 self-center text-lfg-secondary" aria-hidden="true" />)}
    </div>;
}