import { Transition } from "@headlessui/react";
import React from "react";
type ModalProps = {
  header: string | React.ReactNode;
  body: React.ReactNode;
  show: boolean;
  onBlur?: () => void;
  onCloseComplete?: () => void;
  hasCancel?: boolean;
  cancelMessage?: string;
  onCancel?: () => void;
  hasClose?: boolean;
  overlayClassName?: string;
  icon?: JSX.Element;
};

/**
 * @deprecated use Modal component from NewModal file instead
 */
export const Modal = ({
  header,
  body,
  show,
  onBlur,
  onCloseComplete,
  hasCancel,
  cancelMessage,
  onCancel,
  hasClose,
  overlayClassName,
  icon
}: ModalProps) => {
  return <Transition show={show} data-sentry-element="Transition" data-sentry-component="Modal" data-sentry-source-file="Modal.tsx">
      <div className="fixed z-50 inset-0 bg-fixed" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-center justify-center h-full w-full p-4">
          <Transition.Child className={`fixed inset-0 bg-gray-800 bg-opacity-75 transition-all ${overlayClassName}`} enter="transition ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-1" leave="transition ease-in duration-300" leaveFrom="opacity-1" leaveTo="opacity-0" aria-hidden="true" onClick={onBlur} data-sentry-element="unknown" data-sentry-source-file="Modal.tsx" />
          <Transition.Child enter="transition ease-out duration-300" enterFrom="transform opacity-0" enterTo="transform opacity-100" leave="transition ease-in duration-300" leaveFrom="transform opacity-100" leaveTo="transform opacity-0" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
            {body && <div className="inline-block align-bottom bg-surface-lighter rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-3 pr-4">
                  {hasClose && <button type="button" onClick={() => {
                if (typeof onCloseComplete === "function") {
                  onCloseComplete();
                }
              }} className="bg-surface-lighter rounded-md text-gray-400 hover:text-text-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lfg-primary">
                      <span className="sr-only">Close</span>
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>}
                </div>
                <div className="flex flex-col items-center sm:flex-row sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div className="flex flex-col items-center sm:flex-row">
                      {icon}
                      <h3 className="text-lg mx-4 leading-6 font-lfg-normal text-gray-900" id="modal-title">
                        {header}
                      </h3>
                    </div>

                    <div className="mt-2">{body}</div>
                  </div>
                </div>
                {hasCancel && <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button onClick={() => {
                if (typeof onCancel === "function") {
                  onCancel();
                }
                if (typeof onCloseComplete === "function") {
                  onCloseComplete();
                }
              }} type="button" className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-lfg-primary text-base font-semibold text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lfg-primary sm:mt-0 sm:w-auto sm:text-sm">
                      {cancelMessage || "Cancel"}
                    </button>
                  </div>}
              </div>}
          </Transition.Child>
        </div>
      </div>
    </Transition>;
};