import { MenuItemTags, PublicMenuItem, Tag } from "../../MenuItemModal/types";
import { PublicStore } from "../../Stores/types";

export const MOST_POPULAR_TAG = {
  id: 0,
  name: "Popular",
  menu_items: [],
  slug: "popular",
  is_active: true,
};

export const matchMenuItems = (store: any, matchingIds: number[]) => {
  return store.menus[0].menu_categories
    .map((category: any) => category.menu_items)
    .flat()
    .filter((item: any) => matchingIds.includes(item.id));
};

export const getMostPopularItems = ({ public_stores }: any) => {
  return public_stores
    .map((store: any) => {
      const mostPopularIds = store.most_popular.map((item: any) => item.id);

      return matchMenuItems(store, mostPopularIds).map((menuItem: any) => {
        const [mostPopular] = store.most_popular.filter(
          (item: any) => item.id === menuItem.id,
        );
        return {
          ...menuItem,
          tags: [...(menuItem.tags || []), MOST_POPULAR_TAG],
          count: mostPopular.count,
          brand_name: store.brand.name,
          brand_slug: store.brand.slug,
          store_id: store.id,
        };
      });
    })
    .flat()
    .sort((a: any, b: any) => b.count - a.count);
};

// TODO: Generalize get most popular item above
export const getTaggedItems = ({ public_stores }: any, tagData: Tag) => {
  return public_stores
    .map((store: any) => {
      const taggedIds = tagData.menu_items.map((item: any) => item.id);

      return matchMenuItems(store, taggedIds).map((menuItem: any) => {
        const [tagMenuItem] = tagData.menu_items.filter(
          (item: any) => item.id === menuItem.id,
        );

        return {
          ...menuItem,
          count: tagMenuItem.count,
          brand_name: store.brand.name,
          brand_slug: store.brand.slug,
          store_id: store.id,
        };
      });
    })
    .flat()
    .sort((a: any, b: any) => b.count - a.count);
};

export const filterItemsByTags = (
  items: PublicMenuItem[],
  activeTags: Record<number, boolean>,
) => {
  const tagIds: number[] = [];

  Object.keys(activeTags).forEach((id) => {
    if (activeTags[parseInt(id, 10)]) {
      tagIds.push(parseInt(id, 10));
    }
  });

  return items.filter((item) => {
    const itemTagIds = item.tags?.map((tag) => tag.id);
    const shouldIncludeItem = !tagIds.find(
      (tagId) => !itemTagIds?.includes(tagId),
    );
    return Boolean(shouldIncludeItem);
  });
};

// TODO: Move logic to server, maybe on a brand new endpoint
export const getRecentlyAddedItems = (
  publicStores: PublicStore[] | undefined,
  limit = 10,
): PublicMenuItem[] => {
  if (!publicStores) {
    return [];
  }

  /* the incoming data looks like this (this is pseudo-data but approximately correct)
    [
      [dumpling 1, dumpling 2, dumpling 3],
      [taco 1, taco 2, taco 3]
    ]

   which will lead to chunks of brands if we call flat()
    [
      dumpling 1, dumpling 2, dumpling 3
      taco 1, taco 2,
    ]

   That's not the product experience we want, and need a way to round-robin. 
   Transposing can act as the round-robin function for us.
   
   after transpose():
    [
      [dumpling 1, taco 1],
      [dumpling 2, taco 2],
      [dumpling 3, taco 3]
    ]
  
   now we can call flat()
    [
      dumpling 1, taco 1,
      dumpling 2, taco 2,
      dumpling 3, taco 3,
    ]
   */
  const data = publicStores
    .map((store) => store.most_recently_added ?? [])
    .filter((arr) => arr.length > 0)
    .sort(
      (a: Array<PublicMenuItem>, b: Array<PublicMenuItem>) =>
        Number(b[0].inserted_at) - Number(a[0].inserted_at),
    );
  return transpose(data).flat().filter(Boolean).slice(0, limit);
};

function transpose<T>(matrix: Array<Array<T>>): Array<Array<T>> {
  if (matrix.length === 0) return [];

  const rows = matrix.length;
  const cols = matrix[0].length;
  const grid = [];
  for (let j = 0; j < cols; j++) {
    grid[j] = Array(rows);
  }
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      grid[j][i] = matrix[i][j];
    }
  }
  return grid;
}

export const sortByActiveStatus = (
  items: PublicMenuItem[],
): PublicMenuItem[] => {
  return items.sort((a, b) => Number(b.is_active) - Number(a.is_active));
};

export const sortItemsByTagOrder = (
  items: PublicMenuItem[],
  itemTags: MenuItemTags[],
): PublicMenuItem[] => {
  const orderMap: Record<string, number> = {};

  itemTags.forEach((tag) => {
    orderMap[tag.menu_item_id] = tag.ordinal ?? 0;
  });

  return [...items].sort((a, b) => {
    const aOrder = a.menu_item_id
      ? orderMap[a.menu_item_id] ?? Number.MAX_SAFE_INTEGER
      : Number.MAX_SAFE_INTEGER;
    const bOrder = b.menu_item_id
      ? orderMap[b.menu_item_id] ?? Number.MAX_SAFE_INTEGER
      : Number.MAX_SAFE_INTEGER;

    return aOrder - bOrder;
  });
};
