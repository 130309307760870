import SectionModule from "../../../ui/SectionModule/SectionModule";
import CartSection from "./CartSection";
type AddUtensilsType = {
  addUtensils: () => void;
};
export function AddUtensils({
  addUtensils
}: AddUtensilsType) {
  return <CartSection data-sentry-element="CartSection" data-sentry-component="AddUtensils" data-sentry-source-file="AddUtensils.tsx">
      <SectionModule title="Add utensils" onClick={addUtensils} data-sentry-element="SectionModule" data-sentry-source-file="AddUtensils.tsx" />
    </CartSection>;
}