import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { PublicMenuItem } from "../../features/MenuItemModal/types";
import { useStoresMenus } from "../../features/Stores/hooks/useStoresMenus";
import { PublicStore } from "../../features/Stores/types";
import { getAllMenuItems } from "../../features/Stores/utils/pageGeneration";
import { useLocationContext } from "./LocationContext";
import { useOrderTypeContext } from "./OrderTypeContext";
type StoresContextType = {
  publicStores?: PublicStore[];
  clientAllMenuItems: PublicMenuItem[];
  storesMap: Record<number, PublicStore>;
};
export const StoresContext = createContext<StoresContextType>({
  clientAllMenuItems: [],
  storesMap: {}
});
export const StoresContextProvider = ({
  children
}: PropsWithChildren) => {
  const {
    orderType
  } = useOrderTypeContext();
  const {
    storeLocation: location
  } = useLocationContext();
  const {
    publicStores
  } = useStoresMenus(location?.slug, orderType);
  const clientAllMenuItems = useMemo(() => getAllMenuItems(publicStores), [publicStores]);
  const storesMap = useMemo(() => {
    return publicStores?.reduce<{
      [key: number]: PublicStore;
    }>((acum, store) => {
      if (!acum[store.id]) {
        acum[store.id] = store;
      }
      return acum;
    }, {}) || {};
  }, [publicStores]);
  return <StoresContext.Provider value={{
    publicStores,
    clientAllMenuItems,
    storesMap
  }} data-sentry-element="unknown" data-sentry-component="StoresContextProvider" data-sentry-source-file="StoresContext.tsx">
      {children}
    </StoresContext.Provider>;
};
export const useStoresContext = () => {
  const storesContext = useContext(StoresContext);
  if (!storesContext) {
    throw new Error("You may not user StoresContext outside of StoresContextProvider");
  } else {
    return storesContext;
  }
};