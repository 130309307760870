import React from "react";
import { twMerge } from "tailwind-merge";
import { HeaderVariant, RequireExactlyOne, TypographyBaseProps } from "./types";
type ExactlyOneHeaderVariant = RequireExactlyOne<{
  display1: boolean;
  display2: boolean;
  h1: boolean;
  h2: boolean;
  h3: boolean;
  h4: boolean;
  h5: boolean;
  h6: boolean;
}>;
type HeaderTextProps = ExactlyOneHeaderVariant & React.HTMLProps<HTMLHeadingElement> & TypographyBaseProps;
export const headerStyles = {
  display1: "display1 font-bold text-[24px] leading-[29px] tracking-[0.012px] sm:text-[64px] sm:leading-[77px] sm:tracking-[0.064px]",
  display2: "display2 font-bold text-[24px] leading-[29px] tracking-[0.012px] sm:text-[48px] sm:leading-[58px] sm:tracking-[0.024px]",
  h1: "h1 font-bold text-[20px] leading-[26px] tracking-[0.004px] sm:text-[26px] sm:leading-[36px] sm:tracking-[0.006px]",
  h2: "h2 font-bold text-[18px] leading-[23px] tracking-[0.004px] sm:text-[22px] sm:leading-[29px] sm:tracking-[0.004px]",
  h3: "h3 font-semibold text-[16px] leading-[21px] tracking-[0.003px] sm:text-[18px] sm:leading-[23px] sm:tracking-[0.004px]",
  h4: "h4 font-medium text-[14px] leading-[18px] tracking-[0.003px] sm:text-[16px] sm:leading-[21px] sm:tracking-[0.003px]",
  h5: "h5 font-medium text-[12px] leading-[16px] tracking-[0.002px] sm:text-[14px] sm:leading-[18px] sm:tracking-[0.003px]"
};
const variantComponents = {
  display1: "h1",
  display2: "h1",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5"
};
export const Header = ({
  component,
  display1,
  display2,
  h1,
  h2,
  h3,
  h4,
  h5,
  className,
  ...rest
}: HeaderTextProps) => {
  const variant: HeaderVariant = display1 && "display1" || display2 && "display2" || h1 && "h1" || h2 && "h2" || h3 && "h3" || h4 && "h4" || h5 && "h5" || "h1";
  const Component = component || variantComponents[variant];
  return React.createElement(Component, {
    className: twMerge(headerStyles[variant], className),
    ...rest
  }, rest?.children);
};