import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import type { AppProps } from "next/app";
import { Instrument_Sans } from "next/font/google";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { ApolloWrapper } from "../app-components/ApolloWrapper";
import AppContexts from "../app-components/AppContexts";
import { LoginContextProvider } from "../features/Authentication/contexts/LoginContext";
import "../styles/tailwind.css";
import LKHead from "../utils/LKHead";
import { LocationContextProvider } from "../utils/contexts/LocationContext";
import StatsigWrapper from "../utils/contexts/StatsigWrapper";
const instrumentSans = Instrument_Sans({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700"],
  display: "swap",
  variable: "--instrument-sans"
});
config.autoAddCss = false;
const queryClient = new QueryClient();
const ipUrl = "https://api.ipify.org?format=json";
const LocalKitchensApp = ({
  router,
  Component,
  pageProps
}: AppProps) => {
  // Enables receiving a tracking handler from a child component. Tracking
  // requires data from graphql which will not be populated at
  // the time of rendering this component.
  const [ipAddr, setIpAddr] = useState("");
  useEffect(() => {
    // See more: https://medium.com/@icjoseph/using-react-to-understand-abort-controllers-eb10654485df
    const controller = new AbortController();
    axios.get(ipUrl, {
      signal: controller.signal
    }).then(res => setIpAddr(res.data.ip));
    return () => controller.abort();
  }, []);
  const canonicalUrl = `${process.env.NEXT_PUBLIC_LANDING_URL}${router.asPath === "/" ? "" : router.asPath}`.split("?")[0];
  return <>
      <main className={`
        ${instrumentSans.variable}
        font-sans
        `}>
        <div id="portal" />
        <Tooltip id="tag-description" className="rounded-md border w-max max-w-xs z-60 !opacity-100" style={{
        background: "#fbf7ec",
        color: "#231f1f"
      }} place="bottom" data-sentry-element="Tooltip" data-sentry-source-file="_app.tsx" />
        <LKHead data-sentry-element="LKHead" data-sentry-source-file="_app.tsx">
          {/* Add canonical link metadata for SEO reasons */}
          <link rel="canonical" href={canonicalUrl} key="canonical-url" />
        </LKHead>
        <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
          <ApolloWrapper data-sentry-element="ApolloWrapper" data-sentry-source-file="_app.tsx">
            <LocationContextProvider data-sentry-element="LocationContextProvider" data-sentry-source-file="_app.tsx">
              <LoginContextProvider data-sentry-element="LoginContextProvider" data-sentry-source-file="_app.tsx">
                <StatsigWrapper data-sentry-element="StatsigWrapper" data-sentry-source-file="_app.tsx">
                  <AppContexts ip={ipAddr} router={router} pageProps={pageProps} Component={Component} data-sentry-element="AppContexts" data-sentry-source-file="_app.tsx" />
                </StatsigWrapper>
              </LoginContextProvider>
            </LocationContextProvider>
          </ApolloWrapper>
        </QueryClientProvider>
      </main>
    </>;
};
export default LocalKitchensApp;