import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddressDeliveryEstimatesQueryVariables = Types.Exact<{
  delivery_address?: Types.InputMaybe<Types.DeliveryAddressInput>;
  location_ids?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>>;
}>;


export type AddressDeliveryEstimatesQuery = { __typename?: 'Query', address_delivery_estimates?: Array<{ __typename?: 'DeliveryEstimate', id?: number | undefined, fee?: number | undefined, delivery_time?: string | undefined, pickup_time?: string | undefined, location_id?: number | undefined, status?: string | undefined } | undefined> | undefined };


export const AddressDeliveryEstimatesDocument = gql`
    query AddressDeliveryEstimates($delivery_address: DeliveryAddressInput, $location_ids: [Int]) {
  address_delivery_estimates(
    location_ids: $location_ids
    delivery_address: $delivery_address
  ) {
    id
    fee
    delivery_time
    pickup_time
    location_id
    status
  }
}
    `;

/**
 * __useAddressDeliveryEstimatesQuery__
 *
 * To run a query within a React component, call `useAddressDeliveryEstimatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressDeliveryEstimatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressDeliveryEstimatesQuery({
 *   variables: {
 *      delivery_address: // value for 'delivery_address'
 *      location_ids: // value for 'location_ids'
 *   },
 * });
 */
export function useAddressDeliveryEstimatesQuery(baseOptions?: Apollo.QueryHookOptions<AddressDeliveryEstimatesQuery, AddressDeliveryEstimatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressDeliveryEstimatesQuery, AddressDeliveryEstimatesQueryVariables>(AddressDeliveryEstimatesDocument, options);
      }
export function useAddressDeliveryEstimatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressDeliveryEstimatesQuery, AddressDeliveryEstimatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressDeliveryEstimatesQuery, AddressDeliveryEstimatesQueryVariables>(AddressDeliveryEstimatesDocument, options);
        }
export type AddressDeliveryEstimatesQueryHookResult = ReturnType<typeof useAddressDeliveryEstimatesQuery>;
export type AddressDeliveryEstimatesLazyQueryHookResult = ReturnType<typeof useAddressDeliveryEstimatesLazyQuery>;
export type AddressDeliveryEstimatesQueryResult = Apollo.QueryResult<AddressDeliveryEstimatesQuery, AddressDeliveryEstimatesQueryVariables>;