import { useEffect, useRef, useState } from "react";
import { useIsGoogleMapsLoaded } from "../../../utils/hooks/useIsGoogleMapsLoaded";

export default function useAddressPredictions(input: string) {
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const autocomplete = useRef<google.maps.places.AutocompleteService>();

  const isGoogleLoaded = useIsGoogleMapsLoaded();

  useEffect(() => {
    if (isGoogleLoaded) {
      autocomplete.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isGoogleLoaded]);

  function getPlacePredictions(input: string) {
    if (autocomplete.current) {
      autocomplete.current.getPlacePredictions(
        { input, componentRestrictions: { country: "us" } },
        (predictions) => {
          setPredictions(predictions || []);
        },
      );
    }
  }

  useEffect(() => {
    if (input.trim()) {
      getPlacePredictions(input);
    }
  }, [input]);

  return { predictions, setPredictions };
}
