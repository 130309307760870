import { useToast } from "../../../app-components/hooks/useToast";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { hasMobileShareAPI } from "../../../utils/userAgent";
import { CLICK_SOURCE } from "../types";

const useInviteToGroupOrder = (cart: any, clickSource?: CLICK_SOURCE) => {
  const { track } = useTrackWithFlags();
  const { showToast } = useToast();
  const url = `${process.env.NEXT_PUBLIC_GUEST_WEB_URL}/order/cart/${cart.id}`;
  const copyUrl = async () => {
    track({
      event: "Copied Link on Group Order Modal",
      properties: {
        clickSource,
        cartId: cart.id,
        is_group_order: cart.is_group_order,
      },
    });
    await navigator?.clipboard?.writeText(url);
    showToast({
      variant: "success",
      description: "Link copied to clipboard",
    });
  };

  const shareLink = async () => {
    if (hasMobileShareAPI()) {
      track({
        event: "Clicked Share on Group Order Modal",
        properties: {
          clickSource,
          cartId: cart.id,
          url,
          is_group_order: cart.is_group_order,
        },
      });
      try {
        await navigator.share({
          text: "Join Group Order at Local Kitchens",
          url,
        });
        track({
          event: "Successfully Shared Group Order Link",
          properties: {
            clickSource,
            cartId: cart.id,
            is_group_order: cart.is_group_order,
          },
        });
      } catch (err) {
        track({
          event: "Failed to Share Group Order Link",
          properties: {
            clickSource,
            err,
            cartId: cart.id,
            is_group_order: cart.is_group_order,
          },
        });
        await copyUrl();
      }
    } else {
      await copyUrl();
    }
  };
  return { url, copyUrl, shareLink };
};

export default useInviteToGroupOrder;
