import { useLazyQuery } from "@apollo/client";
import { useCartReceipt } from "@features/OrderDetail/hooks/useCartReceipt";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect } from "react";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import { useTrackWithFlags } from "../../../utils/hooks/useTrackWithFlags";
import { hasMobileShareAPI } from "../../../utils/userAgent";
import { useUpdateCustomer } from "../../Account/hooks/useUpdateCustomer";
import { User } from "../../Authentication/types";
import { CART_QUERY, useClearCart } from "../../Cart/hooks/useCartLoader";
import { GroupOrderContext } from "../context/GroupOrderContext";
import { CLICK_SOURCE } from "../types";
import useInviteToGroupOrder from "./useInviteToGroupOrder";
import { useMakeGroupOrder } from "./useMakeGroupOrder";

export type UseGroupOrderProps = {
  user?: User;
  clickSource: CLICK_SOURCE;
};

export const useGroupOrder = ({ user, clickSource }: UseGroupOrderProps) => {
  const router = useRouter();
  const { cart, setCart } = useCartContext();
  const clearCart = useClearCart();
  const {
    setShowNameRequiredModal,
    setShowShareGroupOrderModal,
    setShowConfirmationModal,
    setParticipantId,
    setSpendLimit,
    spendLimit,
  } = useContext(GroupOrderContext);
  const customerHasName = Boolean(user?.first_name);
  const { url, copyUrl, shareLink } = useInviteToGroupOrder(cart, clickSource);
  const { track } = useTrackWithFlags();
  const { onRefetch: refetchCartReceipt } = useCartReceipt(cart);

  const [loadCart, { called, data, loading: loadingRefreshGroupOrder }] =
    useLazyQuery(CART_QUERY, {
      fetchPolicy: "network-only",
    });

  const leaveGroupOrder = () => {
    if (cart?.location) {
      clearCart(cart.location, cart.pickup_type);
      track({
        event: "Left Group Order",
        properties: {
          clickSource,
          cartId: cart.id,
          is_group_order: cart.is_group_order,
        },
      });
      router.push(`/order/store/${cart.location}`);
    }
  };

  const refreshGroupOrder = () => {
    track({
      event: "Refresh Group Order Cart",
      properties: {
        clickSource,
        cartId: cart.id,
        is_group_order: cart.is_group_order,
      },
    });
    loadCart({
      variables: {
        id: cart.id,
        location_slug: cart.location,
      },
    });
    refetchCartReceipt();
  };

  useEffect(() => {
    if (called && data?.cart) {
      setCart({
        ...data.cart,
        items: data.cart.items
          ? data.cart.items.map((item: string) => JSON.parse(item))
          : [],
        participants: data.cart.participants
          ? data.cart.participants.map((participant: any) => ({
              ...participant,
              items: participant.items
                ? participant.items.map((item: string) => JSON.parse(item))
                : [],
            }))
          : [],
      });
    }
  }, [data, called, setCart]);

  const { onMakeGroupOrderCart, makingGroupOrderCart } = useMakeGroupOrder({
    onUpdateComplete: (data: any) => {
      track({
        event: "Created Group Order",
        properties: {
          clickSource,
          cartId: data.make_cart_group_order?.id,
          participants: data.make_cart_group_order?.participants,
          is_group_order: data.make_cart_group_order?.is_group_order,
        },
      });
      setShowNameRequiredModal(false);
      setParticipantId(data.make_cart_group_order?.participants?.[0]?.id || "");
      if (hasMobileShareAPI()) {
        shareLink();
      } else {
        setShowShareGroupOrderModal(true, clickSource);
      }
    },
  });

  const handleMakeNamelessGroupOrderCart = useCallback(
    async (firstName?: string, lastName?: string) => {
      if (!cart.id) return;

      await onMakeGroupOrderCart({
        id: cart.id,
        isGroupOrder: true,
        firstName,
        lastName,
        phoneNumber: user?.phone_number ?? undefined,
        spendLimit,
      });
    },
    [onMakeGroupOrderCart, cart.id, user?.phone_number, spendLimit],
  );

  const handleMakeGroupOrderCart = useCallback(
    async (limit?: number) => {
      await onMakeGroupOrderCart({
        id: cart.id!,
        isGroupOrder: true,
        firstName: user?.first_name,
        lastName: user?.last_name,
        phoneNumber: user?.phone_number ?? undefined,
        spendLimit: limit,
      });
    },
    [
      onMakeGroupOrderCart,
      cart.id,
      user?.first_name,
      user?.last_name,
      user?.phone_number,
    ],
  );

  const makeGroupOrderClick = () => {
    setShowConfirmationModal(true, clickSource);
  };

  const handleConfirmGroupOrder = useCallback(
    async (limit?: number) => {
      setSpendLimit(limit);
      if (!customerHasName) {
        setShowNameRequiredModal(true, clickSource);
      } else {
        await handleMakeGroupOrderCart(limit);
      }
    },
    [
      setSpendLimit,
      customerHasName,
      setShowNameRequiredModal,
      clickSource,
      handleMakeGroupOrderCart,
    ],
  );

  const onCustomerUpdated = async (data: User) => {
    const { first_name, last_name } = data;
    await handleMakeNamelessGroupOrderCart(first_name, last_name);
  };

  const { isPending, mutate: onUpdateCustomer } =
    useUpdateCustomer(onCustomerUpdated);

  return {
    onMakeGroupOrderCart,
    makingGroupOrderCart,
    customerHasName,
    handleMakeGroupOrderCart,
    handleMakeNamelessGroupOrderCart,
    copyUrl,
    inviteUrl: url,
    shareLink,
    makeGroupOrderClick,
    leaveGroupOrder,
    refreshGroupOrder,
    updatingCustomer: isPending,
    onUpdateCustomer,
    handleConfirmGroupOrder,
    loadingRefreshGroupOrder,
  };
};
