import { BrandContent, LocationWithDistance, OrderType } from "@types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCartContext } from "../../app-components/hooks/useCartContext";
import { useLocationContext } from "../../utils/contexts/LocationContext";
import { ChangeOrderTypeFeature } from "../../utils/contexts/OrderTypeContext";
import { addDistanceToLocation } from "../../utils/location";
import { useAuth } from "../Authentication/hooks/useAuth";
import { DeliveryAddress } from "../Cart/types";
import { useDeliveryAddresses } from "../Profile/hooks/useDeliveryAddresses";
import { LocationDiscovery } from "./LocationDiscovery";
import { ActiveBrands } from "./hooks/useActiveBrands";
import { useDeliveryAddressMutations } from "./hooks/useDeliveryAddressMutations";
import { useDeliveryAddressHandlers } from "./hooks/useDeliveryUtils";
type LocationDiscoveryControllerProps = {
  show: boolean;
  locations: LocationWithDistance[];
  activeBrandsIds?: ActiveBrands[];
  orderType: OrderType;
  handleChangeOrderTypeClicked: (orderType: OrderType, source: ChangeOrderTypeFeature) => void;
};
const MAX_DELIVERY_RANGE_MILES = 15;
export const LocationDiscoveryController = ({
  show,
  locations: _locations,
  activeBrandsIds,
  orderType,
  handleChangeOrderTypeClicked
}: LocationDiscoveryControllerProps) => {
  const {
    user
  } = useAuth();
  const {
    cart
  } = useCartContext();
  const [showOrderTypeModal, setShowOrderTypeModal] = useState<boolean>(false);

  // TODO: an improvement here would be to associate delivery addresses with anonymous guests too
  const {
    deliveryAddresses,
    loading: isLoadingDeliveryAddresses
  } = useDeliveryAddresses();
  const {
    locations: clientLocations,
    setDeliverableLocations,
    searchedAddress,
    setSearchedAddress,
    brandsContentMap,
    deliveryEstimates,
    onDeliveryEstimates,
    loadingDeliveryEstimates
  } = useLocationContext();
  const locations = clientLocations?.length ? clientLocations : _locations;
  const {
    onUpdateDeliveryAddress,
    loading: isLoadingAddressMutation
  } = useDeliveryAddressMutations();
  const {
    handleAddressEstimation
  } = useDeliveryAddressHandlers(cart, onUpdateDeliveryAddress, onDeliveryEstimates, user);
  const brands: BrandContent[] = useMemo(() => {
    if (Object.keys(brandsContentMap).length) {
      const tempBrands = activeBrandsIds ? activeBrandsIds.map(activeBrand => brandsContentMap[activeBrand.id]) : Object.values(brandsContentMap);
      return tempBrands.filter((brand: BrandContent) => brand !== undefined && brand?.slug !== "localkitchens");
    }
    return [];
  }, [activeBrandsIds, brandsContentMap]);

  // SECTION: HANDLERS
  // ----------------------------------------

  const handleSelectAddress = useCallback(async (address?: DeliveryAddress) => {
    setSearchedAddress(address);
    if (address) {
      const storesInRange = addDistanceToLocation(locations, address.geocodes).filter(loc => (loc.distance || 0) <= MAX_DELIVERY_RANGE_MILES).map(loc => loc.id);
      handleAddressEstimation(address, storesInRange);
    }
  }, [setSearchedAddress, locations, handleAddressEstimation]);

  // TODO: Eventually should consolidate with other handleUpdateAddress elsewhere in code
  const handleUpdateAddress = useCallback(async (newAddress: DeliveryAddress) => {
    return await onUpdateDeliveryAddress({
      input: {
        ...newAddress,
        customer_id: user?.id
      },
      cart_id: cart?.id
    });
  }, [cart?.id, user?.id, onUpdateDeliveryAddress]);

  // SECTION: USE EFFECTS
  // ----------------------------------------
  useEffect(() => {
    if (orderType === OrderType.DELIVERY && searchedAddress && Array.isArray(deliveryEstimates) && !loadingDeliveryEstimates) {
      const filteredDeliverableLocations = locations.map(location => {
        const deliverable = deliveryEstimates.find(estimate => estimate.status === "ok" && estimate.location_id === location.id);
        return {
          ...location,
          fee: deliverable?.fee ?? null,
          deliverable: Boolean(deliverable)
        };
      }).filter((location: LocationWithDistance) => {
        return location.distance && location.distance < MAX_DELIVERY_RANGE_MILES && location.deliverable;
      });
      if (filteredDeliverableLocations.length > 0 && JSON.stringify(filteredDeliverableLocations) !== JSON.stringify(locations)) {
        setDeliverableLocations(filteredDeliverableLocations);
      } else {
        if (deliveryEstimates.length > 0) {
          setDeliverableLocations([]);
        }
      }
    }
  }, [setDeliverableLocations, loadingDeliveryEstimates, locations, orderType, deliveryEstimates, searchedAddress]);
  useEffect(() => {
    if (!cart?.delivery_address) return;
    setSearchedAddress(cart?.delivery_address);
  }, [cart?.delivery_address, setSearchedAddress]);
  return <LocationDiscovery show={show} orderType={orderType} handleChangeOrderType={orderType => handleChangeOrderTypeClicked(orderType, "location-discovery-side-panel")} loadingDeliveryAddresses={isLoadingDeliveryAddresses} deliveryAddresses={deliveryAddresses} onSelectDeliveryAddress={handleSelectAddress} onUpdateDeliveryAddress={handleUpdateAddress} showOrderTypeModal={showOrderTypeModal} handleCloseOrderTypeModal={() => setShowOrderTypeModal(false)} cart={cart} brands={brands} locations={locations} isLoadingAddressMutation={isLoadingAddressMutation} data-sentry-element="LocationDiscovery" data-sentry-component="LocationDiscoveryController" data-sentry-source-file="LocationDiscoveryController.tsx" />;
};