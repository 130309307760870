import React, { ButtonHTMLAttributes } from "react";
import { classNames } from "../../utils/classNames";
import { LoadingSpinner } from "../Loading/LoadingSpinner";
import { ButtonText } from "../Typography";
import { Icon, IconCustomProps } from "@ui/Icon";
export type ButtonProps = {
  id?: string;
  onPress?: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;
  customLabel?: React.ReactNode;
  iconOnly?: boolean;
  icon?: IconCustomProps;
  loading?: boolean;
  className?: string;
  labelStyle?: any;
  leadingIcon?: IconCustomProps;
  trailingIcon?: IconCustomProps;
  disabled?: boolean;
  mode: "primary" | "secondary" | "ghost" | "text" | "toggleSelected" | "toggle";
  size: "large" | "small";
  shape?: "rounded" | "default";
  type?: "button" | "submit" | "reset";
  onMouseOver?: () => void;
  onMouseOut?: () => void;
} & ButtonHTMLAttributes<HTMLButtonElement>;

// See: https://www.figma.com/file/2j3dIkonWGe8VRmWvzX9YG/Local-Kitchens-Design-Library?node-id=183%3A262 (tab: Buttons)
export const Button = ({
  id,
  loading,
  onPress,
  onClick,
  label,
  customLabel,
  className,
  labelStyle,
  leadingIcon,
  trailingIcon,
  disabled,
  mode,
  iconOnly,
  icon,
  size,
  type = "button",
  shape = "default",
  onMouseOver,
  onMouseOut,
  ...props
}: ButtonProps) => {
  return <button id={id} type={type} disabled={loading || disabled} onClick={onPress || onClick} className={classNames(mode !== "text" && styles[size], loading && "relative", styles.container, styles[`${mode}Container`], disabled && styles[`${mode}Disabled`], iconOnly && styles[`${size}IconOnly`], shape !== "default" && styles[shape], className)} onMouseOver={onMouseOver} onFocus={onMouseOver} onMouseOut={onMouseOut} onBlur={onMouseOut} {...props} data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {loading && <div className="absolute flex justify-center items-center inset-0">
          <LoadingSpinner size={5} borderWidth={2} />
        </div>}
      {!iconOnly && [leadingIcon && <div className={loading ? styles.labelLoading : ""} key={"button-leading-icon"}>
            <Icon icon={leadingIcon} size={size} />
          </div>, customLabel ? <div className={loading ? styles.labelLoading : ""}>
            {customLabel}
          </div> : label && <ButtonText key={`button-label`} size={size} className={classNames(loading && styles.labelLoading, labelStyle, shape == "rounded" && "px-2")}>
              {label}
            </ButtonText>, trailingIcon && <div className={loading ? styles.labelLoading : ""} key={"button-trailing-icon"}>
            <Icon size={size} icon={trailingIcon} />
          </div>]}
      {!loading && iconOnly && icon && <Icon size={size} icon={icon} />}
    </button>;
};
const styles: Record<string, string> = {
  small: "min-h-[40px] px-4 py-3 rounded-xl gap-2",
  large: "min-h-[56px] px-6 py-4 rounded-xl gap-2",
  rounded: "!rounded-full",
  smallIconOnly: "!rounded-full !p-0 !w-[40px] !h-[40px]",
  largeIconOnly: "!rounded-full !p-4",
  container: "flex items-center justify-center",
  primaryContainer: "bg-button-filled-primary hover:bg-button-filled-primary_hover text-text-primary border border-border-primary",
  secondaryContainer: "border border-border-primary bg-surface-lighter hover:bg-button-outlined-secondary_hover active:bg-button-outlined-secondary_hover",
  toggleSelectedContainer: "border-2 !font-semibold border-border-primary text-text-primary bg-surface-light hover:bg-button-outlined-secondary_hover active:bg-button-outlined-secondary_hover",
  toggleContainer: "border border-border-secondary text-text-secondary  hover:bg-button-outlined-secondary_hover active:bg-button-outlined-secondary_hover",
  ghostContainer: "hover:bg-new-grey-1-lighten-2 active:bg-new-grey-1-base",
  primaryDisabled: "disabled:bg-button-filled-primary_disabled disabled:text-text-disabled disabled:border-border-disabled",
  secondaryDisabled: "disabled:bg-button-outlined-secondary_disabled disabled:text-icon-disabled disabled:border-border-disabled",
  ghostDisabled: "disabled:bg-new-grey-1-base disabled:text-new-black-lighten-3",
  textDisabled: "disabled:text-new-black-lighten-3",
  labelLoading: "opacity-0"
};