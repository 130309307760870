import { useAuth } from "@features/Authentication/hooks/useAuth";
import { DeliveryAddress } from "../../../graphql/types";
import { useDeliveryAddressesQueryQuery } from "./useDeliveryAddresses.generated";
export const useDeliveryAddresses = () => {
  const {
    isAuthenticated
  } = useAuth();
  const {
    data,
    error,
    loading,
    refetch
  } = useDeliveryAddressesQueryQuery({
    skip: !isAuthenticated,
    fetchPolicy: "cache-first"
  });
  const deliveryAddresses = ((data?.delivery_addresses || []) as DeliveryAddress[]);
  return {
    deliveryAddresses,
    error,
    loading,
    refetch
  };
};