import React from "react";
import { classNames } from "../../utils/classNames";
import Modal, { ModalSize } from "../Modal/NewModal";
import { Button, ButtonProps } from "../NewButton/Button";
import { Text } from "../Typography";
type ConfirmationModalProps = {
  header?: string;
  message: string | React.ReactNode;
  show: boolean;
  size?: ModalSize;
  closeModal: () => void;
  cancelButton?: Omit<ButtonProps, "size" | "mode">;
  confirmButton: Omit<ButtonProps, "size" | "mode">;
};
export const ConfirmationModal = ({
  header,
  message,
  show,
  size = "small",
  closeModal,
  cancelButton,
  confirmButton
}: ConfirmationModalProps) => {
  return <Modal size={size} title={header} showModal={show} closeModal={closeModal} data-sentry-element="Modal" data-sentry-component="ConfirmationModal" data-sentry-source-file="ConfirmationModal.tsx">
      <div className="w-full mt-6">
        <Text component="p" large data-sentry-element="Text" data-sentry-source-file="ConfirmationModal.tsx">
          {message}
        </Text>
        <div className="flex flex-col gap-4 mt-6">
          {confirmButton && <Button {...confirmButton} mode="primary" size="large" className={classNames("w-full", confirmButton.className)} data-test-id="confirm-button" />}
          {cancelButton && <Button {...cancelButton} mode="ghost" size="large" className={classNames("w-full", cancelButton.className)} data-test-id="cancel-button" />}
        </div>
      </div>
    </Modal>;
};