import { CSSProperties, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { getExtension, slugify } from "../../utils/images";
const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL;
const SIZES = ([320, 480, 640, 768, 1024, 1366, 1600, 1920, 2560] as const);
type ResponsiveImageProps = {
  brandName?: string;
  itemName?: string;
  imageUrl?: string;
  portraitImageUrl?: string;
  containerClassName?: string;
  className?: string;
  loading?: "eager" | "lazy";
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  alt?: string;
  maxSize?: typeof SIZES[number];
  variant?: Variant;
};
const VARIANTS = (["rounded", "default"] as const);
export type Variant = typeof VARIANTS[number];
const styles: Record<Variant, string> = {
  rounded: "outline outline-1 outline-offset-[-1px] outline-border-images rounded-lg",
  default: "outline outline-1 outline-offset-[-1px] outline-border-images"
};
export const ResponsiveImage = ({
  brandName,
  itemName,
  imageUrl,
  portraitImageUrl,
  containerClassName,
  containerStyle,
  maxSize = 1600,
  className,
  loading,
  style,
  alt,
  variant = "default"
}: ResponsiveImageProps) => {
  const landscapeSrcSet = useMemo(() => {
    return SIZES.filter(size => size <= maxSize).map(size => {
      const width = `${size}w`;
      const extension = getExtension(imageUrl) || "jpg";
      const url = `${imageUrl ? imageUrl.split(`.${extension}`)[0] : `${CDN_URL}/${slugify(brandName)}/${slugify(itemName)}`}`;
      return `${url}-${width}.${extension} ${width}`;
    }).join(", ");
  }, [brandName, maxSize, imageUrl, itemName]);
  const portraitSrcSet = useMemo(() => {
    if (!portraitImageUrl) return "";
    return SIZES.filter(size => size <= maxSize).map(size => {
      const width = `${size}w`;
      const extension = getExtension(portraitImageUrl) || "jpg";
      const url = portraitImageUrl.split(`.${extension}`)[0];
      return `${url}-${width}.${extension} ${width}`;
    }).join(", ");
  }, [maxSize, portraitImageUrl]);
  const defaultWidth = "640w";
  const defaultExt = getExtension(imageUrl) || "jpg";
  const defaultSrc = `${imageUrl ? imageUrl.split(`.${defaultExt}`)[0] : `${CDN_URL}/${slugify(brandName)}/${slugify(itemName)}`}-${defaultWidth}.${defaultExt}`;
  return brandName && itemName || imageUrl ? <picture className={containerClassName} style={containerStyle}>
      {portraitSrcSet ? <source media="(orientation: portrait)" srcSet={portraitSrcSet} /> : null}
      <source srcSet={landscapeSrcSet} />
      <img style={style} src={defaultSrc} className={twMerge("block object-cover w-full h-full", styles[variant], className)} alt={alt} loading={loading} />
    </picture> : <div className={className} />;
};