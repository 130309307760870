import SectionModule from "../../../ui/SectionModule/SectionModule";
import CartSection from "./CartSection";
type AddCondimentsType = {
  addCondiments: () => void;
};
export function AddCondiments({
  addCondiments
}: AddCondimentsType) {
  return <CartSection data-sentry-element="CartSection" data-sentry-component="AddCondiments" data-sentry-source-file="AddCondiments.tsx">
      <SectionModule title="Add condiments" onClick={addCondiments} data-sentry-element="SectionModule" data-sentry-source-file="AddCondiments.tsx" />
    </CartSection>;
}