import { PickupType } from "@graphql/types";
import { useOrderTypeContext } from "@utils/contexts/OrderTypeContext";
import SectionModule from "../../../ui/SectionModule/SectionModule";
import { Header, Link, Text } from "../../../ui/Typography";
import { classNames } from "../../../utils/classNames";
import { useLocationContext } from "../../../utils/contexts/LocationContext";
import { useGroupOrderContext } from "../../GroupOrder/context/GroupOrderContext";
import { CLICK_SOURCE } from "../../GroupOrder/types";
import { AvailableOrderTime, Cart } from "../types";
import { getClosestOrderTimeText } from "../utils";
type OrderControlsProps = {
  cart: Cart;
  cartTitle: string;
  availableOrderTimes?: AvailableOrderTime[];
  shareLink: () => void;
  setShowLeaveGroupOrderModal: (show: boolean, clickSource?: CLICK_SOURCE) => void;
  onLocationClick: () => void;
};
export function OrderControls({
  cartTitle,
  cart,
  availableOrderTimes,
  shareLink,
  setShowLeaveGroupOrderModal,
  onLocationClick
}: OrderControlsProps) {
  const {
    setLeavePoolsideOrderModalConfig
  } = useOrderTypeContext();
  const {
    storeLocation
  } = useLocationContext();
  const {
    isGroupOrder,
    isGroupOrderCreator
  } = useGroupOrderContext();
  const isPoolside = cart.pickup_type === PickupType.Poolside;
  return <div className="px-6 pb-5 pt-6 bg-surface-lighter" data-sentry-component="OrderControls" data-sentry-source-file="OrderControls.tsx">
      <Header h3 data-sentry-element="Header" data-sentry-source-file="OrderControls.tsx">{cartTitle}</Header>
      {isGroupOrder && <Text medium className="text-text-secondary">
          {cart.spend_limit ? `$${cart.spend_limit} spend limit per participant` : "No spending limit per person set"}
        </Text>}
      {(isPoolside || isGroupOrder) && <div className={classNames("flex gap-4 mt-2", isPoolside && isGroupOrder ? "flex-col items-start" : "flex-row")}>
          {isGroupOrder && <Link underLine="solid" size="small" variant="primary" onClick={shareLink}>
              Add people
            </Link>}
          <div className="flex flex-row gap-4">
            {isGroupOrder && <Link underLine="solid" size="small" variant="primary" className="text-new-error" onClick={() => {
          setShowLeaveGroupOrderModal(true, "cart");
        }}>
                Leave group order
              </Link>}
            {isPoolside && <Link underLine="solid" size="small" variant="primary" className="text-new-error" onClick={() => {
          setLeavePoolsideOrderModalConfig({
            show: true
          });
        }}>
                Leave poolside order
              </Link>}
          </div>
        </div>}
      <div className="mt-4">
        {!!availableOrderTimes?.length && <Text medium className="text-text-secondary">
            {getClosestOrderTimeText(cart, availableOrderTimes)}
          </Text>}
        <SectionModule onClick={onLocationClick} disabled={isGroupOrder && !isGroupOrderCreator} title={cart.is_delivery && cart.delivery_address?.street ? cart.delivery_address.street : `Local Kitchens ${storeLocation?.display_name}`} data-sentry-element="SectionModule" data-sentry-source-file="OrderControls.tsx" />
      </div>
    </div>;
}