import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalculateTipAmountQueryVariables = Types.Exact<{
  subtotal?: Types.InputMaybe<Types.Scalars['Int']>;
  tipRate?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CalculateTipAmountQuery = { __typename?: 'Query', calculate_tip_amount?: { __typename?: 'TipAmount', tipRate: number, tipValue: number } | undefined };


export const CalculateTipAmountDocument = gql`
    query CalculateTipAmount($subtotal: Int, $tipRate: Int) {
  calculate_tip_amount(subtotal: $subtotal, tipRate: $tipRate) {
    tipRate
    tipValue
  }
}
    `;

/**
 * __useCalculateTipAmountQuery__
 *
 * To run a query within a React component, call `useCalculateTipAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateTipAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateTipAmountQuery({
 *   variables: {
 *      subtotal: // value for 'subtotal'
 *      tipRate: // value for 'tipRate'
 *   },
 * });
 */
export function useCalculateTipAmountQuery(baseOptions?: Apollo.QueryHookOptions<CalculateTipAmountQuery, CalculateTipAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculateTipAmountQuery, CalculateTipAmountQueryVariables>(CalculateTipAmountDocument, options);
      }
export function useCalculateTipAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculateTipAmountQuery, CalculateTipAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculateTipAmountQuery, CalculateTipAmountQueryVariables>(CalculateTipAmountDocument, options);
        }
export type CalculateTipAmountQueryHookResult = ReturnType<typeof useCalculateTipAmountQuery>;
export type CalculateTipAmountLazyQueryHookResult = ReturnType<typeof useCalculateTipAmountLazyQuery>;
export type CalculateTipAmountQueryResult = Apollo.QueryResult<CalculateTipAmountQuery, CalculateTipAmountQueryVariables>;