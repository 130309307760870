import { useQuery } from "@tanstack/react-query";
import { ShopBackendClient } from "../../../utils/shopBackend/apiClient";

const THREE_MINUTES = 3 * 60 * 1000;

export const useAuth = () => {
  const { data, isFetched } = useQuery({
    queryKey: ["me"],
    queryFn: ShopBackendClient.me,
    retry: 3,
    refetchOnWindowFocus: false,
    staleTime: THREE_MINUTES,
  });

  const user = data?.data;

  return {
    isAuthenticated: !!user,
    isVerifiedForPromo: user?.is_verified_for_promo,
    isLKEmployee: user?.email?.endsWith("@localkitchens.com") ?? false,
    authFinished: isFetched,
    user,
  };
};

export default useAuth;
