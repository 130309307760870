import TagIcon from "../../../../ui/Icons/Tagicon";
import Modal from "../../../../ui/Modal/NewModal";
import { Button } from "../../../../ui/NewButton/Button";
import TextInput from "../../../../ui/TextInput/TextInput";
interface PromoCodeModalProps {
  showModal: boolean;
  closeModal: () => void;
  onChangePromoCode: (value: string) => void;
  onApplyPromoCode: () => Promise<void>;
  promoCode: string;
  promoCodeError?: string;
  loadingApplyCoupon: boolean;
}
const PromoCodeModal = ({
  showModal,
  loadingApplyCoupon,
  closeModal,
  promoCode,
  promoCodeError,
  onChangePromoCode,
  onApplyPromoCode
}: PromoCodeModalProps) => {
  return <Modal showModal={showModal} closeModal={closeModal} size="small" closeButtonPosition="top-right" title="Promo Code" data-sentry-element="Modal" data-sentry-component="PromoCodeModal" data-sentry-source-file="PromoCodeModal.tsx">
      <form className="w-full mt-8" onSubmit={event => {
      event.preventDefault();
      onApplyPromoCode();
    }}>
        <TextInput placeholder="Enter Promo Code" label="Promo Code" className="w-full" startIcon={<TagIcon />} value={promoCode} onChange={e => onChangePromoCode(e.target.value)} error={promoCodeError} data-sentry-element="TextInput" data-sentry-source-file="PromoCodeModal.tsx" />
        <Button className="w-full mt-6" size="large" mode="primary" label="Apply" loading={loadingApplyCoupon} onPress={onApplyPromoCode} disabled={!!promoCodeError} data-sentry-element="Button" data-sentry-source-file="PromoCodeModal.tsx" />
      </form>
    </Modal>;
};
export default PromoCodeModal;