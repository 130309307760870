import { faChevronRight } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";
import { Header, Text } from "../Typography";
interface SectionModuleProps {
  title: string;
  subtitle?: string;
  icon?: React.ReactNode;
  rightComponent?: React.ReactNode;
  subtitleClassName?: string;
  disabled?: boolean;
  onClick?: () => void;
}
const SectionModule = ({
  title,
  subtitle,
  icon,
  subtitleClassName,
  rightComponent = <FontAwesomeIcon icon={faChevronRight} className="text-text-secondary" width={24} height={24} />,
  disabled,
  onClick
}: SectionModuleProps) => {
  const Component = onClick ? "button" : "div";
  return <Component type="button" className="w-full flex flex-row justify-between items-center" onClick={onClick} disabled={disabled} data-sentry-element="Component" data-sentry-component="SectionModule" data-sentry-source-file="SectionModule.tsx">
      <div className="flex flex-row items-center gap-4 text-left">
        {icon}
        <div>
          <Header h4 data-sentry-element="Header" data-sentry-source-file="SectionModule.tsx">{title}</Header>
          {subtitle && <Text medium className={twMerge("text-text-secondary", subtitleClassName)}>
              {subtitle}
            </Text>}
        </div>
      </div>
      {rightComponent}
    </Component>;
};
export default SectionModule;