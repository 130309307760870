import { faArrowLeft } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef } from "react";
import { PublicStore } from "../../../features/Stores/types";
import { BrandContent } from "../../../types";
import { classNames } from "../../../utils/classNames";
import { useLocationContext } from "../../../utils/contexts/LocationContext";
import { useRouterQueryParam } from "../../../utils/hooks/useRouterQueryParam";
import { MOBILE_HEADER_HEIGHT } from "../../../utils/navbar";
import { Button } from "../../NewButton/Button";
import { BrandPageMobileNavbarPill } from "./BrandPageMobileNavbarPill";
export const BRAND_PAGE_NAVBAR_ID = "brand-page-mobile-navbar";
export const BrandPageMobileNavbar = ({
  stores
}: {
  stores?: PublicStore[];
}) => {
  const router = useRouter();
  const locationSlug = useRouterQueryParam("locationSlug");
  const brandSlug = useRouterQueryParam("params");
  const {
    brandsContentMap
  } = useLocationContext();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  // Declare a ref for the Map object that will hold a reference to each tab element
  const tabsRef = useRef<Map<string, HTMLElement>>(new Map());
  function getMap(): Map<string, HTMLElement> {
    return tabsRef.current;
  }
  const goToPopularPage = () => {
    router.push(`/order/store/${locationSlug}/popular`);
  };
  const goToBrandPage = (brandSlug: string) => {
    router.push(`/order/store/${locationSlug}/${brandSlug}`);
  };
  const brands: BrandContent[] = useMemo(() => {
    const brandContents = stores?.filter(store => store.id > 0).map(store => brandsContentMap?.[store.brand.id]) ?? Object.values(brandsContentMap);
    return brandContents.filter(Boolean).filter((brand: BrandContent) => brand?.slug !== "localkitchens");
  }, [brandsContentMap, stores]);
  useEffect(() => {
    // Snap the scroll to the currently selected tab when brandSlug changes
    const selectedTab = getMap().get(brandSlug);
    if (selectedTab && scrollRef?.current) {
      const backButtonContainer = scrollRef.current.querySelector("#navbar-back-button-container");
      const backButtonWidth = backButtonContainer?.getBoundingClientRect().width ?? 0;
      const paddingBetweenTabs = 8;
      const scrollOptions = {
        top: 0,
        left: selectedTab.offsetLeft - scrollRef.current.offsetLeft - backButtonWidth - paddingBetweenTabs,
        behavior: ("smooth" as ScrollBehavior)
      };
      scrollRef.current.scrollTo(scrollOptions);
    }
  }, [brandSlug]);
  return <div id={BRAND_PAGE_NAVBAR_ID} ref={scrollRef} className={classNames("w-full z-40 bg-surface-lighter flex flex-nowrap gap-2 whitespace-nowrap justify-start items-center py-2 scrollbar-none !overflow-x-scroll overscroll-x-none border-b border-new-grey-2-lighten-3")} style={{
    height: MOBILE_HEADER_HEIGHT
  }} data-sentry-component="BrandPageMobileNavbar" data-sentry-source-file="BrandPageMobileNavbar.tsx">
      <div id="navbar-back-button-container" className="sticky pl-4 left-0 z-30 bg-surface-lighter rounded-r-full">
        <Button id="navbar-back-button" iconOnly mode="secondary" size="small" onPress={goToPopularPage} icon={<FontAwesomeIcon icon={faArrowLeft} className="h-5 w-5 text-inherit" aria-hidden="true" />} className="sm:hidden !w-10 !h-10" data-sentry-element="Button" data-sentry-source-file="BrandPageMobileNavbar.tsx" />
      </div>

      {brands?.map((brand, idx) => <BrandPageMobileNavbarPill key={brand.slug} brand={brand} isSelected={brand?.slug === brandSlug} goToBrandPage={goToBrandPage} position={idx} ref={node => {
      // See ref-callback pattern:
      // https://beta.reactjs.org/reference/react-dom/components/common#ref-callback
      const map = getMap();
      if (node) {
        map.set(brand.slug, node);
      } else {
        map.delete(brand.slug);
      }
    }} />)}
    </div>;
};