import { faXmarkLarge } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "../../utils/classNames";
import { Button } from "../NewButton/Button";
export type ModalCloseButtonPosition = "top-right" | "top-left";
export interface ModalCloseButtonProps {
  closeModal: () => void;
  position: ModalCloseButtonPosition;
}
const closeButtonPositions: Record<ModalCloseButtonPosition, string> = {
  "top-right": "top-0 right-0",
  "top-left": "top-0 left-0"
};
const ModalCloseButton = ({
  closeModal,
  position
}: ModalCloseButtonProps) => {
  return <Button className={classNames("absolute z-10", closeButtonPositions[position])} iconOnly onPress={closeModal} mode="text" size="large" icon={<FontAwesomeIcon icon={faXmarkLarge} className="text-text-secondary hover:text-new-grey-3-darken-3" width={24} height={24} />} data-sentry-element="Button" data-sentry-component="ModalCloseButton" data-sentry-source-file="ModalCloseButton.tsx" />;
};
export default ModalCloseButton;