import { Dispatch, SetStateAction, useEffect } from "react";
import { brandPageContentMargins } from "../../../features/BrandPage/utils/spacing";
import { faCreditCardBlank, faGift, faLocationDot, faReceipt, faRightFromBracket, faRightToBracket, faUser } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { useLoginContext } from "@features/Authentication/contexts/LoginContext";
import { useAuth } from "@features/Authentication/hooks/useAuth";
import { useGroupOrderContext } from "@features/GroupOrder/context/GroupOrderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocationContext } from "@utils/contexts/LocationContext";
import { useNavToCashRewardsWithTrack } from "@utils/hooks/useNavToCashRewardsWithTrack";
import { useCartContext } from "../../../app-components/hooks/useCartContext";
import MakeGroupOrderCTA from "../../../features/GroupOrder/components/MakeGroupOrderCTA";
import { useGroupOrder } from "../../../features/GroupOrder/hooks/useGroupOrder";
import { classNames } from "../../../utils/classNames";
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../../../utils/navbar";
import { LKLogo } from "../../Logo/LKLogo";
import { ProfileDropdown } from "../../ProfileDropdown";
import { LocationPicker } from "../LocationPicker";
import { UserNavigation } from "../NavbarController";
import { ShoppingCartInNavForLargerScreens } from "./ShoppingCart";
import { useLoyaltyContext } from "@utils/contexts/LoyaltyContext";
import { useRouter } from "next/router";
export type PrimaryNavbarProps = {
  setShowCart: Dispatch<SetStateAction<boolean>>;
  showLocationPicker: boolean;
  showCart: boolean;
  isCartValid: boolean;
  cartQuantity: number;
  isMobile: boolean;
  showGroupOrder: boolean;
};
export function PrimaryNavbar({
  setShowCart,
  showLocationPicker,
  showCart,
  isCartValid,
  cartQuantity,
  isMobile,
  showGroupOrder
}: PrimaryNavbarProps) {
  const {
    user,
    isAuthenticated
  } = useAuth();
  const router = useRouter();
  const {
    loginWithTrack: login,
    logout
  } = useLoginContext();
  const navToCashRewards = useNavToCashRewardsWithTrack();
  const {
    storeLocation: location,
    locations,
    searchedAddress
  } = useLocationContext();
  const {
    cart
  } = useCartContext();
  const {
    setParticipantId
  } = useGroupOrderContext();
  const {
    cashRewardLoyalty,
    refetchCashRewardLoyalty
  } = useLoyaltyContext();
  const {
    makeGroupOrderClick
  } = useGroupOrder({
    user,
    clickSource: "navbar"
  });
  const isAuthPage = router.pathname.includes("/login") || router.pathname.includes("/signup");
  const preferredLocation = locations?.find(location => location.id === user?.preferred_location_id);
  useEffect(() => {
    refetchCashRewardLoyalty();
  }, [refetchCashRewardLoyalty]);
  const locationSlug = location?.slug || preferredLocation?.slug;
  const userNavigation: UserNavigation = isAuthenticated ? [{
    icon: <FontAwesomeIcon icon={faUser} className="h-5 w-5" />,
    name: "Account",
    href: "/order/account"
  }, {
    icon: <FontAwesomeIcon icon={faGift} className="h-5 w-5" />,
    name: "Rewards",
    href: "#",
    onClick: event => {
      event?.preventDefault();
      navToCashRewards("Profile dropdown");
    },
    notifBadgeIndicator: cashRewardLoyalty?.cash_rewards_available ?? 0
  }, {
    icon: <FontAwesomeIcon icon={faReceipt} className="h-5 w-5" />,
    name: "Order History",
    href: "/order/order-history"
  }, {
    icon: <FontAwesomeIcon icon={faLocationDot} className="h-5 w-5" />,
    name: "Delivery Addresses",
    href: "/order/delivery-addresses"
  }, {
    icon: <FontAwesomeIcon icon={faCreditCardBlank} className="h-5 w-5" />,
    name: "Payment Methods",
    href: "/order/saved-payment-methods"
  }, {
    icon: <FontAwesomeIcon icon={faRightFromBracket} className="h-5 w-5" />,
    name: "Sign Out",
    href: "#",
    onClick: () => {
      setParticipantId("");
      logout(locationSlug);
    }
  }] : [{
    icon: <FontAwesomeIcon icon={faRightToBracket} className="h-5 w-5" />,
    name: "Log In/Join",
    href: "#",
    onClick: () => login()
  }];
  const deliveryStreetAddress = cart?.delivery_address?.street || searchedAddress?.street;
  return <div className="w-full h-full bg-surface-lighter" data-sentry-component="PrimaryNavbar" data-sentry-source-file="PrimaryNavbar.tsx">
      <div className={classNames("w-full h-full flex flex-row justify-start items-center", brandPageContentMargins)} style={{
      height: isMobile ? MOBILE_HEADER_HEIGHT : DESKTOP_HEADER_HEIGHT
    }}>
        <LKLogo className="hidden sm:block h-16 w-48" color="black" locationSlug={location?.slug} data-sentry-element="LKLogo" data-sentry-source-file="PrimaryNavbar.tsx" />

        <ProfileDropdown userNavigation={userNavigation} onLogin={login} className="sm:hidden" isAuthPage={isAuthPage} data-sentry-element="ProfileDropdown" data-sentry-source-file="PrimaryNavbar.tsx" />

        {showLocationPicker && <LocationPicker storeLocation={location} deliveryStreetAddress={deliveryStreetAddress} className="w-min max-w-60 px-4" />}

        {/* Spacer */}
        <div className="flex-1" />
        {user && showGroupOrder && !isAuthPage && <div className="flex flex-row sm:flex-row-reverse items-center">
            <MakeGroupOrderCTA handleClick={makeGroupOrderClick} label="Start group order" clickSource="navbar" className="mx-4" />
          </div>}

        {showCart && <ShoppingCartInNavForLargerScreens isCartValid={isCartValid} cartQuantity={cartQuantity} onClick={() => setShowCart(true)} className="justify-self-end" />}

        <ProfileDropdown userNavigation={userNavigation} onLogin={login} className="hidden sm:block ml-4" isAuthPage={isAuthPage} data-sentry-element="ProfileDropdown" data-sentry-source-file="PrimaryNavbar.tsx" />
      </div>
    </div>;
}