import { Transition } from "@headlessui/react";
import { BaseSyntheticEvent, Dispatch, forwardRef, SetStateAction, useCallback, useMemo, useRef, useState } from "react";
import { useCartContext } from "../../app-components/hooks/useCartContext";
import { useGuestWebPairingsQuery } from "../../graphql/menuItemPairings.query.generated";
import { PlacedOrderItemReorder } from "../../graphql/types";
import MediaCarousel from "../../ui/MediaCarousel";
import { ResponsiveImage } from "../../ui/ResponsiveImage";
import { checkItemValid, getInvalidModList } from "../../utils/cart";
import { classNames } from "../../utils/classNames";
import { useLocationContext } from "../../utils/contexts/LocationContext";
import { useStoresContext } from "../../utils/contexts/StoresContext";
import { useRouterQueryParam } from "../../utils/hooks/useRouterQueryParam";
import { MediaInfo, MenuItemVideoConfig } from "../BrandPage/types";
import { CartMenuItem } from "../Cart/types";
import { PublicStore } from "../Stores/types";
import { ButtonGroup } from "./components/ButtonGroup";
import YourUsuals from "./components/YourUsuals";
import { Modifiers } from "./Modifiers";
import { NameAndDescription } from "./NameAndDescription";
import { RecommendedPairings } from "./RecommendedPairings";
import { CartItemAction } from "./reducers/itemReducer";
import { PublicMenuItem } from "./types";
export const EXP_NAME = "pdp_pairs";
type MenuItemModalProps = {
  setItemInFocus: Dispatch<SetStateAction<CartMenuItem | undefined>>;
  publicMenuItem?: PublicMenuItem;
  setPublicMenuItem: Dispatch<SetStateAction<PublicMenuItem | undefined>>;
  cartItemId?: string;
  onAddItem: (cartId: string, locationSlug: string, item: CartMenuItem[]) => Promise<any>;
  onRemoveItem: (cartId: string, externalId: string) => Promise<any>;
  onUpdateItem: (cartId: string, externalId: string, item: CartMenuItem) => Promise<any>;
  store?: PublicStore;
  videoConfig?: MenuItemVideoConfig;
  mediaInfo: MediaInfo;
  setMediaInfo?: Dispatch<SetStateAction<MediaInfo | undefined>>;
  disableVideos?: boolean;
  yourUsualsItems?: PlacedOrderItemReorder[];
  onYourUsualsClick: (item: PlacedOrderItemReorder) => void;
  setYourUsualsItem: Dispatch<SetStateAction<PlacedOrderItemReorder | undefined>>;
  yourUsualsItem?: PlacedOrderItemReorder;
  trackAction: (event: string, properties?: object) => void;
  cartItem: CartMenuItem;
  cartItemDispatch: Dispatch<CartItemAction>;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
};
export const MenuItemModal = ({
  setItemInFocus,
  publicMenuItem,
  setPublicMenuItem,
  cartItemId,
  onAddItem,
  onRemoveItem,
  onUpdateItem,
  store,
  videoConfig,
  mediaInfo,
  setMediaInfo,
  disableVideos,
  yourUsualsItems,
  onYourUsualsClick,
  setYourUsualsItem,
  yourUsualsItem,
  trackAction,
  cartItem,
  cartItemDispatch,
  show,
  setShow
}: MenuItemModalProps) => {
  const locationSlug = useRouterQueryParam("locationSlug");
  const {
    cart
  } = useCartContext();
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [isImageVisible, setIsImageVisible] = useState(true);
  const {
    clientAllMenuItems: allMenuItemsContext
  } = useStoresContext();
  const {
    storeLocation: location
  } = useLocationContext();
  const menu_item_id = publicMenuItem?.menu_item_id;
  const location_slug = location?.slug;
  const {
    data: pairings
  } = useGuestWebPairingsQuery({
    skip: !menu_item_id || !location_slug,
    variables: {
      menu_item_id,
      location_slug
    }
  });
  const [recommendedPairingsItems, setRecommendedPairingItems] = useState<PublicMenuItem[]>([]);
  // If iOS, then must be an iPad (otherwise MenuItemModalMobile would render)
  // Used for setting height of modal; see Shorcut ticket 2320

  const isItemValid = useMemo(() => {
    return checkItemValid(publicMenuItem, cartItem);
  }, [publicMenuItem, cartItem]);
  const nextInvalidModList = useMemo(() => {
    return getInvalidModList(publicMenuItem, cartItem);
  }, [publicMenuItem, cartItem]);
  const nextInvalidModRef = useRef<any>(null);
  const isModyfingItem = Boolean(cartItemId);
  const pairingPublicMenuItems = (pairings?.client_menu_item_pairings ?? []).flatMap(pairing => allMenuItemsContext.filter(menuItem => menuItem.id === pairing?.id));
  const scrollToNextRequiredMod = () => nextInvalidModRef.current?.scrollIntoView({
    behavior: "smooth",
    block: "start",
    nline: "nearest"
  });
  const hideModal = useCallback(() => {
    // unfortunately order matters here, setShow(false) should be last
    setYourUsualsItem(undefined);
    setItemInFocus(undefined);
    setPublicMenuItem(undefined);
    setMediaInfo?.(undefined);
    setShow(false);
    setRecommendedPairingItems([]);
  }, [setItemInFocus, setMediaInfo, setPublicMenuItem, setShow, setYourUsualsItem, setRecommendedPairingItems]);
  return <Transition show={show} data-sentry-element="Transition" data-sentry-component="MenuItemModal" data-sentry-source-file="MenuItemModal.tsx">
      {/* Desktop modal */}
      <div className="fixed z-50 inset-0 bg-fixed" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={hideModal}>
        <Transition.Child enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="MenuItemModal.tsx">
          {/* This is the modal's gray background */}
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity " aria-hidden="true" />
        </Transition.Child>

        <Transition.Child enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95" enterTo="opacity-100 translate-y-0 md:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 md:scale-100" leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95" className="absolute inset-0 flex justify-center align-center" data-sentry-element="unknown" data-sentry-source-file="MenuItemModal.tsx">
          <div className="flex flex-col justify-center h-screen-safe md:h-[95vh] w-screen absolute md:relative inset-0 md:max-w-lg place-self-center align-bottom bg-surface-lighter md:rounded-3xl text-left overflow-hidden shadow-xl transform transition-all md:align-middle" onClick={(e: BaseSyntheticEvent) => e.stopPropagation()}>
            <div id="menu-item-container-desktop" className="overflow-x-hidden flex flex-col grow overflow-y-auto no-scrollbar">
              <ItemImage ref={imageContainerRef} publicMenuItem={publicMenuItem} videoConfig={!disableVideos ? videoConfig : undefined} data-sentry-element="ItemImage" data-sentry-source-file="MenuItemModal.tsx" />

              {/* Close modal button */}
              <button type="button" onClick={hideModal} className={classNames("z-50 bg-surface-lighter rounded-full p-1 top-4 right-4 absolute text-black hover:opacity-80 focus:outline-none", isImageVisible ? "shadow-md" : "")}>
                <span className="sr-only">Close item panel</span>
                {/* <!-- Heroicon name: outline/x --> */}
                <svg className="h-7 w-7" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-source-file="MenuItemModal.tsx">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" data-sentry-element="path" data-sentry-source-file="MenuItemModal.tsx" />
                </svg>
              </button>

              <NameAndDescription publicMenuItem={publicMenuItem} brandId={store?.brand.id || 0} hideModal={hideModal} cartItem={cartItem} store={store} imageContainerRef={imageContainerRef} setIsImageVisible={setIsImageVisible} data-sentry-element="NameAndDescription" data-sentry-source-file="MenuItemModal.tsx" />

              <YourUsuals itemPrice={publicMenuItem?.price || 0} items={yourUsualsItems} onItemClick={onYourUsualsClick} selectedItemId={yourUsualsItem?.id} data-sentry-element="YourUsuals" data-sentry-source-file="MenuItemModal.tsx" />

              <Modifiers trackAction={trackAction} cartItem={cartItem} cartItemDispatch={cartItemDispatch} publicMenuItem={publicMenuItem} nextInvalidModRef={nextInvalidModRef} nextInvalidModList={nextInvalidModList} data-sentry-element="Modifiers" data-sentry-source-file="MenuItemModal.tsx" />

              {pairingPublicMenuItems.length > 0 && <RecommendedPairings pairingPublicMenuItems={pairingPublicMenuItems} parentMenuItem={publicMenuItem} recommendedPairingsItems={recommendedPairingsItems} setRecommendedPairingsItems={setRecommendedPairingItems} />}

              <ButtonGroup cartItem={cartItem} publicMenuItem={publicMenuItem} cartItemDispatch={cartItemDispatch} onRemoveItem={onRemoveItem} onUpdateItem={onUpdateItem} onAddItem={onAddItem} trackAction={trackAction} hideModal={hideModal} isItemValid={isItemValid} isModyfingItem={isModyfingItem} cart={cart} locationSlug={locationSlug} store={store} isOneClickAdd={publicMenuItem?.is_one_click_add} mediaInfo={mediaInfo} scrollToNextRequiredMod={scrollToNextRequiredMod} recommendedPairingsItems={recommendedPairingsItems} data-sentry-element="ButtonGroup" data-sentry-source-file="MenuItemModal.tsx" />
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>;
};
type ItemImageProps = {
  publicMenuItem?: PublicMenuItem;
  videoConfig?: MenuItemVideoConfig;
};
const ItemImage = forwardRef<HTMLDivElement, ItemImageProps>(({
  publicMenuItem,
  videoConfig
}, ref) => {
  return <div id="pdp-image-container" className="relative h-56 md:h-72 w-full" ref={ref}>
        {videoConfig ? <MediaCarousel>
            <ResponsiveImage brandName={publicMenuItem?.brand_name} itemName={publicMenuItem?.name} imageUrl={publicMenuItem?.image_url} className="h-56 md:h-72 object-cover pr-2" style={{
        width: "80%"
      }} />
            <video src={videoConfig.videoUrl} poster={videoConfig.posterUrl} className="h-56 md:h-72 object-cover" controls={false} playsInline muted autoPlay loop style={{
        width: "100%"
      }} />
          </MediaCarousel> : <ResponsiveImage brandName={publicMenuItem?.brand_name} itemName={publicMenuItem?.name} imageUrl={publicMenuItem?.image_url} className="w-full h-full" />}
      </div>;
});