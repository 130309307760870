import { uuid4 } from "@sentry/utils";
import React, { useEffect, useState } from "react";
import { StatsigProvider, StatsigUser } from "statsig-react";
import { useAuth } from "../../features/Authentication/hooks/useAuth";
import { useLocationContext } from "./LocationContext";
const StatsigWrapper = ({
  children
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const {
    user
  } = useAuth();
  const {
    storeLocation: location
  } = useLocationContext();
  const [statsigUser, setStatsigUser] = useState<StatsigUser>({
    userID: user?.email ?? undefined,
    customIDs: {
      anonymousID: uuid4()
    },
    custom: {
      auth0_user_id: user?.id,
      provider: "guest-web",
      location: location?.slug ?? ""
    }
  });

  // Syncs analytics anonymousID with statsig user object
  useEffect(() => {
    analytics.ready(() => {
      const anonymousId = analytics.user().anonymousId();
      setStatsigUser(user => ({
        ...user,
        customIDs: {
          anonymousID: anonymousId
        }
      }));
    });
  }, []);

  // Syncs user's email and auth0_user_id with statsig user object
  useEffect(() => {
    if (user?.email) {
      setStatsigUser(statsigUser => ({
        ...statsigUser,
        userID: user.email ?? undefined,
        custom: {
          ...statsigUser.custom,
          auth0_user_id: user.id
        }
      }));
    }
  }, [user?.email, user?.id]);
  return <StatsigProvider sdkKey={process.env.NEXT_PUBLIC_STATSIG_CLIENT_SECRET ?? ""} user={statsigUser} setUser={setStatsigUser} options={{
    environment: {
      tier: process.env.NEXT_PUBLIC_STATSIG_ENVIRONMENT || "production"
    }
  }} data-sentry-element="StatsigProvider" data-sentry-component="StatsigWrapper" data-sentry-source-file="StatsigWrapper.tsx">
      {children}
    </StatsigProvider>;
};
export default StatsigWrapper;