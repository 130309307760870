import Head from "next/head";
import React from "react";
interface LKHeadProps {
  title?: string;
  description?: string;
  image?: string;
  children?: React.ReactNode;
}
export default function LKHead({
  title = "Your Favorite Restaurants Under One Roof",
  description = "Order for takeout, delivery, or dine-in from favorites like Square Pie Guys, The Melt, Sushirrito, and more.",
  image = "https://cdn.localkitchens.co/s3/landing-images/logo-1725036901866-1024w.webp",
  children
}: LKHeadProps) {
  return <Head data-sentry-element="Head" data-sentry-component="LKHead" data-sentry-source-file="LKHead.tsx">
      <title>{title}</title>
      <meta name="title" content={title} data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta name="description" content={description} key="description" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />

      {/* OG Meta Tags */}
      <meta property="og:title" content={title} key="title" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta property="og:description" content={description} key="og-description" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta property="og:type" content="website" key="type" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta property="og:image" content={image} key="image" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta property="og:image:width" content="1200" key="image-width" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta property="og:image:height" content="630" key="image-height" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta property="og:url" content="https://localkitchens.com" key="og-url" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta property="og:site_name" content="Local Kitchens" key="site_name" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />

      {/* <!-- Twitter Meta Tags --> */}
      <meta property="twitter:title" content={title} key="twitter-title" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta property="twitter:description" content={description} key="twitter-description" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta name="twitter:image" content={image} key="twitter-image" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta name="twitter:domain" content="https://www.localkitchens.com" key="twitter-domain" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta name="twitter:url" content="https://localkitchens.com" key="twitter-url" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />
      <meta name="twitter:card" content="summary_large_image" key="twitter-card" data-sentry-element="meta" data-sentry-source-file="LKHead.tsx" />

      {children}
    </Head>;
}