import { createContext, useContext } from "react";
import { useAuth } from "../../features/Authentication/hooks/useAuth";
import { useCashRewardLoyalty } from "../../features/CashRewards/hooks/useCashRewardLoyalty";
import { useCashRewardStateToggle } from "../../features/CashRewards/hooks/useCashRewardStateToggle";
import { CashRewardLoyalty } from "../../types";
export type LoyaltyContextType = {
  cashRewardLoyalty?: CashRewardLoyalty;
  refetchCashRewardLoyalty: () => void;
  isCashRewardRedeemEnabled?: boolean;
  toggleCashRewardRedeem: () => void;
  loading: boolean;
};
export const LoyaltyContext = createContext<LoyaltyContextType>({
  cashRewardLoyalty: undefined,
  refetchCashRewardLoyalty: () => {},
  isCashRewardRedeemEnabled: false,
  toggleCashRewardRedeem: async () => {},
  loading: false
});
export const LoyaltyProvider = ({
  children
}: {
  children: JSX.Element;
}) => {
  const {
    authFinished
  } = useAuth();
  const {
    data: cashRewardLoyalty,
    loading: loadingCashRewardLoyalty,
    refetch: refetchCashRewardLoyalty
  } = useCashRewardLoyalty();
  const {
    isCashRewardRedeemEnabled,
    toggleCashRewardRedeem,
    loading: loadingCashRewardRedeemState
  } = useCashRewardStateToggle();
  return <LoyaltyContext.Provider value={{
    cashRewardLoyalty,
    refetchCashRewardLoyalty,
    isCashRewardRedeemEnabled,
    toggleCashRewardRedeem,
    loading: loadingCashRewardLoyalty || loadingCashRewardRedeemState || !authFinished
  }} data-sentry-element="unknown" data-sentry-component="LoyaltyProvider" data-sentry-source-file="LoyaltyContext.tsx">
      {children}
    </LoyaltyContext.Provider>;
};
export const useLoyaltyContext = () => {
  const loyaltyProvider = useContext(LoyaltyContext);
  if (!loyaltyProvider) {
    throw new Error("You may not use LoyaltyContext outside of LoyaltyProvider");
  } else {
    return loyaltyProvider;
  }
};