import { PickupType } from "@graphql/types";
import Divider from "@ui/Divider";
import { AnimatePresence, motion } from "framer-motion";
import uniq from "lodash/uniq";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useCartContext } from "../../app-components/hooks/useCartContext";
import { ToastContext } from "../../app-components/hooks/useToast";
import { OrderType } from "../../types";
import LineLoader from "../../ui/LineLoader";
import { Button } from "../../ui/NewButton/Button";
import { Text } from "../../ui/Typography";
import { calculateCartQuanity, getIsCartItemsValid, getIsCartPromoValid } from "../../utils/cart";
import { useMenuItemModal } from "../../utils/contexts/MenuItemModalContext";
import { useOrderTypeContext } from "../../utils/contexts/OrderTypeContext";
import { getDisplayName } from "../../utils/getDisplayName";
import { useLocalStorage } from "../../utils/hooks/useLocalStorage";
import { useTrackWithFlags } from "../../utils/hooks/useTrackWithFlags";
import { getResponsiveImageUrl } from "../../utils/images";
import { MOBILE_HEADER_HEIGHT } from "../../utils/navbar";
import { usdFormat } from "../../utils/usdFormatter";
import { useLoginContext } from "../Authentication/contexts/LoginContext";
import { useAuth } from "../Authentication/hooks/useAuth";
import { User } from "../Authentication/types";
import Receipt from "../Checkout/components/Receipt";
import ConfirmRemoveModal from "../Checkout/components/modals/ConfirmRemoveModal";
import { LargeOrderModal } from "../Checkout/components/modals/LargeOrderModal";
import ReceiptLineItemTooltipModal from "../Checkout/components/modals/ReceiptLineItemTooltipModal";
import { GroupOrderContext, useGroupOrderContext } from "../GroupOrder/context/GroupOrderContext";
import { useGroupOrder } from "../GroupOrder/hooks/useGroupOrder";
import { useHideBodyOverflow } from "../MenuItemModal/hooks/useHideBodyOverflow";
import { PublicStore } from "../Stores/types";
import { AddCondiments } from "./components/AddCondiments";
import { AddUtensils } from "./components/AddUtensils";
import { CartHeader } from "./components/CartHeader";
import CartItemList from "./components/CartItemList";
import GroupOrderParticipantList from "./components/GroupOrderParticipantList";
import { OrderControls } from "./components/OrderControls";
import UseBag from "./components/UseBag";
import { useAvailableOrderTimes } from "./hooks/useAvailableOrderTimes";
import { useCartValidation } from "./hooks/useCartValidation";
import { useCondiments } from "./hooks/useCondiments";
import { useUtensils } from "./hooks/useUtensils";
import { CartMenuItem, CartReceiptLineItem, Cart as CartType } from "./types";
export const getItemList = (items: CartMenuItem[]) => {
  const uniqItems = items?.length ? uniq(items.filter(item => item?.brand_name && item?.brand_name !== "Local Kitchens").map(item => item.name)) : [];
  if (uniqItems.length < 2) {
    return uniqItems.join("");
  }
  if (uniqItems.length === 2) {
    return uniqItems.join(" & ");
  }
  const len = uniqItems.length;
  const lastItem = uniqItems[len - 1];
  const otherItems = uniqItems.slice(0, len - 1);
  return [otherItems.join(", "), lastItem].join(" & ");
};
const getCartTitle = (cart: CartType, isGroupOrderCreator: boolean) => {
  if (cart.pickup_type === PickupType.Poolside && cart.is_group_order) {
    return "Review your poolside group order";
  }
  if (cart.pickup_type === PickupType.Poolside) {
    return "Review your poolside order";
  }
  if (cart.is_group_order && isGroupOrderCreator) {
    return "Review your group order";
  }
  if (cart.is_group_order && !isGroupOrderCreator) {
    return `${getDisplayName(cart.first_name, cart.last_name)}'s Group Order`;
  }
  return "Review your order";
};
type CartProps = {
  show: boolean;
  setShowCart: Dispatch<SetStateAction<boolean>>;
  onRemoveItem: (cartId: string, externalId: string) => Promise<any>;
  onUpdateItem: (cartId: string, externalId: string, item: CartMenuItem) => Promise<any>;
  stores: Record<number, PublicStore>;
  user?: User;
  isLoadingRemoveItem: boolean;
  isLoadingUpdateItem: boolean;
};
export const Cart = ({
  show,
  setShowCart,
  onRemoveItem,
  onUpdateItem,
  stores,
  user,
  isLoadingRemoveItem,
  isLoadingUpdateItem
}: CartProps) => {
  const router = useRouter();
  const {
    login
  } = useLoginContext();
  const {
    isAuthenticated
  } = useAuth();
  const {
    setShowChooseOrderType,
    orderType
  } = useOrderTypeContext();
  const [showSmallCartTitle, setShowSmallCartTitle] = useState(false);
  const {
    cart,
    cartReceipt,
    total
  } = useCartContext();
  const {
    trackUpdateItem
  } = useTrackWithFlags();
  const {
    setShowLeaveModal: setShowLeaveGroupOrderModal
  } = useContext(GroupOrderContext);
  const [, setCartIds] = useLocalStorage(`lfg_cart_id`, {});
  const [showLargeOrderModal, setShowLargeOrderModal] = useState(false);
  const [tooltipModalItem, setTooltipModalItem] = useState<CartReceiptLineItem>();
  useHideBodyOverflow(show);
  const {
    data: availableOrderTimes,
    mandatoryAdvancedScheduling,
    refetch: refetchScheduling
  } = useAvailableOrderTimes(cart, true);
  const {
    makeGroupOrderClick,
    shareLink,
    refreshGroupOrder,
    loadingRefreshGroupOrder
  } = useGroupOrder({
    user,
    clickSource: "cart"
  });
  useEffect(() => {
    if (cart?.id && cart?.location) {
      refetchScheduling();
    }
  }, [cart, refetchScheduling]);
  const {
    setItemInFocus,
    setPublicMenuItem
  } = useMenuItemModal();
  const [itemToRemove, setItemToRemove] = useState<CartMenuItem | undefined>();
  const {
    track,
    trackRemoveItem
  } = useTrackWithFlags();
  const {
    refetchCart
  } = useCartValidation(cart);
  const condimentsStoreMenuItem = useCondiments();
  const utensilsStoreMenuItem = useUtensils();
  const {
    showToast
  } = useContext(ToastContext);
  const closeCart = useCallback(() => {
    setShowCart(false);
    setItemInFocus(undefined);
    setItemToRemove(undefined);
    setPublicMenuItem(undefined);
  }, [setShowCart, setItemInFocus, setItemToRemove, setPublicMenuItem]);
  useEffect(() => {
    if (cart.items.length === 0) {
      closeCart();
    }
  }, [cart, closeCart]);
  const cartIncludesItem = useMemo(() => (itemName: string) => {
    return cart.items?.filter(item => item.name.includes(itemName)).length ?? 0;
  }, [cart]);
  const isCartItemsValid = getIsCartItemsValid(cart);
  const isCartPromoValid = getIsCartPromoValid(cart);
  useEffect(() => {
    if (!isCartPromoValid) {
      track({
        event: "Coupon Invalidated",
        properties: {}
      });
      setTimeout(() => showToast({
        description: "Promo code invalidated and removed from cart.",
        variant: "error"
      }), 2000);
      if (refetchCart) {
        refetchCart();
      }
    }
  }, [isCartPromoValid, refetchCart, showToast, track]);
  const cartQuantity = useMemo(() => {
    return calculateCartQuanity(cart);
  }, [cart]);
  const trackAction = useCallback((eventName: string) => {
    // follows Segment Ecommerce spec
    track({
      event: eventName,
      properties: {
        orderId: cart.id,
        products: cart.items.map(item => ({
          product_id: item.id,
          sku: item.id,
          name: item.name,
          brand: item.brand_name,
          price: item.price / 100,
          quantity: item.quantity,
          image_url: getResponsiveImageUrl(item.image_url, "1024w"),
          currency: "USD",
          category: "Food & Drink" // Some destinations require a category field
        })),

        cartId: cart.id,
        location: cart.location,
        cartContents: getItemList(cart.items)
      }
    });
  }, [cart, track]);
  useEffect(() => {
    if (show) {
      trackAction("Cart Viewed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  const {
    isGroupOrder,
    isGroupOrderCreator
  } = useGroupOrderContext();
  const goToCheckout = () => {
    const checkoutPath = `/order/store/${cart.location}/checkout`;
    closeCart();
    if (!isAuthenticated) {
      login(checkoutPath);
    } else {
      router.push(checkoutPath);
    }
  };
  const handleCheckoutClicked = () => {
    if (!isCartItemsValid) {
      return;
    }

    // If this is a group order, only the creator can checkout
    if (isGroupOrder && !isGroupOrderCreator) {
      return;
    }

    // This is unexpected and should never happen; clear cart IDs from local storage and track
    if (cart.location && window.location.pathname.includes("store/") && !window.location.pathname.includes(cart.location)) {
      trackAction("Checkout at Wrong Location");
      setCartIds({});
      window.confirm("Oops! It looks like there is a problem with your order. Please try again.");
      window.location.reload();
      return;
    }

    // Can checkout!
    goToCheckout();
  };
  const onUpdateItemQuantity = (item: CartMenuItem | undefined, quantity: number) => {
    if (!cart.id || !item) return;
    if (quantity === 0) {
      setItemToRemove(item);
      return;
    }
    onUpdateItem(cart.id, item.external_id, {
      ...item,
      quantity
    }).then(({
      spendLimitExceeded
    }) => {
      if (!spendLimitExceeded) {
        trackUpdateItem(item, cart.location);
        showToast({
          variant: "success",
          description: "Item updated"
        });
      }
    });
  };
  const removeItem = async (cart: CartType, item: CartMenuItem) => {
    try {
      if (!cart.id) return Promise.reject();
      await onRemoveItem(cart.id, item.external_id);
      trackRemoveItem(item, cart.location);
      showToast({
        variant: "success",
        description: "Item removed"
      });
    } catch {
      showToast({
        variant: "error",
        description: "Error removing item"
      });
    }
  };
  const isCartEmpty = cartQuantity === 0 && !isGroupOrder;
  const canEditOrCheckout = !isGroupOrder || isGroupOrder && isGroupOrderCreator;
  const cartTitle = getCartTitle(cart, isGroupOrderCreator);
  const showAddCondiments = !isCartEmpty && !cartIncludesItem("Condiments") && cart.pickup_type !== PickupType.Poolside && orderType !== OrderType.PICKUP && condimentsStoreMenuItem && condimentsStoreMenuItem.is_active;
  const showAddUtensils = !isCartEmpty && !cartIncludesItem("Utensils") && cart.pickup_type !== PickupType.Poolside && orderType !== OrderType.PICKUP && utensilsStoreMenuItem && utensilsStoreMenuItem.is_active;
  const showOrderControls = !isCartEmpty || isGroupOrder;
  const showUseBagButton = !isCartEmpty && canEditOrCheckout && cart.pickup_type !== PickupType.Poolside && orderType !== OrderType.DELIVERY;
  const showReceipt = !isCartEmpty;
  const showCheckoutButton = cartQuantity > 0 && canEditOrCheckout;
  return <>
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="Cart.tsx">
        {show && <section className="fixed z-50 inset-0" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
            <div>
              {/* Background overlay */}
              <motion.div className="absolute inset-0 bg-gray-800" onClick={closeCart} initial={{
            opacity: 0
          }} animate={{
            opacity: 0.5
          }} exit={{
            opacity: 0
          }} aria-hidden="true" transition={{
            ease: "easeInOut"
          }} />

              {/* Cart contents */}
              <motion.div id="cart-slide-inout" className="absolute h-full w-screen md:max-w-md top-0 right-0" initial={{
            x: "100vw"
          }} // Start from offscreen right
          animate={{
            x: 0
          }} // Move to the right edge of the screen
          exit={{
            x: "100vw"
          }} // Exit offscreen right
          transition={{
            ease: "easeInOut"
          }}>
                <div className="w-full h-full flex flex-col bg-surface-lighter shadow-xl">
                  <CartHeader title={cartTitle} isGroupOrder={isGroupOrder} closeCart={closeCart} isStickedToTop={showSmallCartTitle} user={user} makeGroupOrderClick={makeGroupOrderClick} loadingRefreshGroupOrder={loadingRefreshGroupOrder} refreshGroupOrder={refreshGroupOrder} />

                  <div className="grow overflow-y-auto flex flex-col" onScroll={event => {
                setShowSmallCartTitle(event.currentTarget.scrollTop > MOBILE_HEADER_HEIGHT * 2);
              }}>
                    {showOrderControls ? <OrderControls cartTitle={cartTitle} cart={cart} availableOrderTimes={availableOrderTimes} shareLink={shareLink} setShowLeaveGroupOrderModal={setShowLeaveGroupOrderModal} onLocationClick={() => {
                  setShowChooseOrderType(true);
                  closeCart();
                }} /> : null}
                    <div className="mx-6">
                      <Divider variant="withoutMargin" />
                      <LineLoader isLoading={isLoadingUpdateItem} />
                    </div>

                    {isGroupOrder ? <GroupOrderParticipantList cart={cart} cartReceipt={cartReceipt} stores={stores} removeItem={removeItem} setItemInFocus={setItemInFocus} setItemToRemove={setItemToRemove} onUpdateItemQuantity={onUpdateItemQuantity} /> : <CartItemList cart={cart} cartReceipt={cartReceipt} stores={stores} removeItem={removeItem} setItemInFocus={setItemInFocus} setItemToRemove={setItemToRemove} onUpdateItemQuantity={onUpdateItemQuantity} />}

                    {showUseBagButton ? <UseBag cart={cart} /> : null}

                    {showAddUtensils ? <AddUtensils addUtensils={() => {
                  setPublicMenuItem(utensilsStoreMenuItem);
                }} /> : null}

                    {showAddCondiments ? <AddCondiments addCondiments={() => {
                  setPublicMenuItem(condimentsStoreMenuItem);
                }} /> : null}

                    {showReceipt ? <>
                        <div className="mx-6">
                          <Divider variant="withoutMargin" className="mt-6" />
                        </div>
                        <Receipt cart={cart} cartReceipt={cartReceipt} showCartLineItems={false} canMutateCart={canEditOrCheckout} mandatoryAdvancedScheduling={mandatoryAdvancedScheduling} setTooltipModalItem={setTooltipModalItem} setShowLargeOrderModal={setShowLargeOrderModal} isCheckout={false} />
                      </> : null}

                    {isCartEmpty ? <div className="flex w-full h-full justify-center items-center">
                        <Text large className="text-text-secondary">
                          Add items to your bag to checkout
                        </Text>
                      </div> : null}
                  </div>

                  <div className="sticky bottom-0 bg-surface-lighter shrink-0 inset-x-0 px-6 py-4 shadow-md">
                    {showCheckoutButton ? <Button size="large" mode="primary" label={`Continue to checkout - ${usdFormat(total)}`} onPress={handleCheckoutClicked} disabled={!canEditOrCheckout || !isCartItemsValid} className="w-full" data-test-id="checkout-button" /> : <Button size="large" onPress={closeCart} mode="primary" label="Browse menu" className="w-full" />}
                  </div>
                </div>
              </motion.div>
            </div>
          </section>}
      </AnimatePresence>
      <LargeOrderModal show={showLargeOrderModal} copy={mandatoryAdvancedScheduling?.copy ?? ""} closeModal={() => setShowLargeOrderModal(false)} data-sentry-element="LargeOrderModal" data-sentry-source-file="Cart.tsx" />
      <ReceiptLineItemTooltipModal item={tooltipModalItem} setItem={setTooltipModalItem} data-sentry-element="ReceiptLineItemTooltipModal" data-sentry-source-file="Cart.tsx" />
      <ConfirmRemoveModal showModal={!!itemToRemove} isLoading={isLoadingRemoveItem} closeModal={() => {
      setItemToRemove(undefined);
    }} onConfirm={() => {
      if (!itemToRemove?.external_id || !cart.id) return Promise.reject();
      return removeItem(cart, itemToRemove).then(() => setItemToRemove(undefined));
    }} data-sentry-element="ConfirmRemoveModal" data-sentry-source-file="Cart.tsx" />
    </>;
};