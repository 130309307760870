import { OrderType } from "@types";
import { DeliveryAddress } from "../../Cart/types";

const useDiscoveryQueryParams = (
  orderType: string | undefined,
  searchedAddress?: DeliveryAddress,
) => {
  return `?is-delivery=${orderType === OrderType.DELIVERY}${
    orderType === OrderType.DELIVERY && searchedAddress?.id
      ? `&address-id=${btoa(searchedAddress?.id)}`
      : ""
  }`;
};

export default useDiscoveryQueryParams;
