import { useEffect, useMemo } from "react";
import { Header } from "../../ui/Typography";
import { getStatusMessageWithBrandName, getTotalItemPrice } from "../../utils/cart";
import { classNames } from "../../utils/classNames";
import { useLocationContext } from "../../utils/contexts/LocationContext";
import { usdFormat } from "../../utils/usdFormatter";
import { CartMenuItem } from "../Cart/types";
import { PublicStore } from "../Stores/types";
import { TagDescriptors } from "../Tooltips/DietaryTooltips";
import { BrandName } from "./components/BrandName";
import { useShadows } from "./hooks/useShadows";
import { PublicMenuItem } from "./types";
type NameAndDescriptionProps = {
  publicMenuItem?: PublicMenuItem;
  brandId: number;
  hideModal?: () => void;
  cartItem: CartMenuItem;
  store?: PublicStore;
  imageContainerRef: React.RefObject<HTMLDivElement>;
  setIsImageVisible: (isImageVisible: boolean) => void;
};
export const NameAndDescription = ({
  publicMenuItem,
  brandId,
  hideModal,
  cartItem,
  store,
  imageContainerRef,
  setIsImageVisible
}: NameAndDescriptionProps) => {
  const {
    brandsContentMap
  } = useLocationContext();
  const {
    bottomShadow
  } = useShadows(imageContainerRef);
  useEffect(() => setIsImageVisible(!bottomShadow), [bottomShadow, setIsImageVisible]);
  const statusMessage = useMemo(() => {
    if (!store) return null;
    const message = getStatusMessageWithBrandName(store);
    return message ? <dd className="sm:pr-2 md:pr-2 text-orange-800 text-sm">{message}</dd> : null;
  }, [store]);
  const getMenuItemDescription = () => {
    if (!publicMenuItem?.description) {
      return "";
    }
    return publicMenuItem?.description + " ";
  };
  return <>
      <div className={classNames("sticky top-0 w-full pt-4 bg-surface-lighter mb-0.5 z-30", bottomShadow ? "border-b" : "")} style={bottomShadow ? {
      boxShadow: "rgba(0, 0, 0, 0.2) 0px -1px 15px"
    } : {}} id="item-name-desktop">
        <div className="flex flex-1 flex-row justify-between mt-2 px-4">
          {publicMenuItem && <BrandName onClick={hideModal} brandSlug={publicMenuItem.brand_slug} brandName={publicMenuItem.brand_name} brandLogoUrl={brandsContentMap[brandId]?.logo?.url} />}
          <div className="flex gap-1">
            {!bottomShadow && <TagDescriptors menuItem={publicMenuItem} />}
          </div>
        </div>
        <Header h2 className="mb-2 text-lg font-semibold px-4 mt-2" data-sentry-element="Header" data-sentry-source-file="NameAndDescription.tsx">
          {publicMenuItem?.name}
        </Header>
      </div>
      <p className="font-lfg-normal text-sm px-4 -mt-1">
        {usdFormat(getTotalItemPrice(cartItem))}
      </p>
      {statusMessage && <p className="mt-0.5 font-lfg-normal text-sm text-new-orange-base px-4">
          {statusMessage}
        </p>}
      <dl className="flex flex-col mt-2 px-4">
        <dt className="sr-only">{publicMenuItem?.description}</dt>
        <dd className="pb-4 text-text-secondary text-sm">
          {getMenuItemDescription()}
        </dd>
      </dl>
    </>;
};