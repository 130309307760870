import { uniqueId } from "lodash";
import { useEffect, useRef } from "react";
const visibleModals = new Set<string>();
export const useHideBodyOverflow = (show: boolean) => {
  const modalIdRef = useRef(uniqueId("modal-"));

  // hook to prevent scrooling behind modals and slideovers
  useEffect(() => {
    const modalId = modalIdRef.current;
    if (!show && !visibleModals.has(modalId)) return () => {};
    if (show) {
      visibleModals.add(modalId);
      document.body.style.overflowY = "hidden";
    } else {
      visibleModals.delete(modalId);
      if (visibleModals.size === 0) {
        document.body.style.overflowY = "unset";
      }
    }
    return () => {
      visibleModals.delete(modalId);
      if (visibleModals.size === 0) {
        document.body.style.overflowY = "unset";
      }
    };
  }, [show]);
};