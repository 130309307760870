import React, { Ref } from "react";
import { twMerge } from "tailwind-merge";
import { RequireExactlyOne, TypographyBaseProps } from "./types";
type ExactlyOneTextVariant = RequireExactlyOne<{
  xlarge: boolean;
  large: boolean;
  medium: boolean;
  small: boolean;
  eyebrow: boolean;
  buttonLarge: boolean;
  buttonSmall: boolean;
  linkLarge: boolean;
  linkSmall: boolean;
}>;
type TextProps = ExactlyOneTextVariant & React.HTMLAttributes<any> & TypographyBaseProps & {
  ref?: Ref<any>;
};
export const textStyles = {
  xlarge: "body1 text-[16px] leading-[24px] tracking-[0.002px] sm:text-[20px] leading-[30px] sm:tracking-[0.02px] font-normal",
  large: "body2 text-[14px] leading-[20px] tracking-[0.001px] sm:text-[16px] sm:leading-[24px] sm:tracking-[0.002px]  font-normal",
  medium: "body3 text-[14px] leading-[20px] tracking-[0.001px] sm:text-[14px] sm:leading-[20px] sm:tracking-[0.001px] font-normal",
  // sm:leading-[20px] was 21
  small: "body4 text-[12px] leading-[18px] tracking-[0.001px] sm:text-[12px] sm:leading-[18px] sm:tracking-[0.001px] font-normal",
  eyebrow: "eyebrow text-[12px] leading-[14px] tracking-[0.012px] sm:text-[12px] sm:leading-[14px] font-medium uppercase ",
  buttonLarge: "button-large text-[16px] leading-[19px] tracking-[0.016px] font-semibold",
  buttonSmall: "button-small text-[14px] leading-[17px] tracking-[0.001px] font-medium",
  linkLarge: "linkLarge text-[16px] leading-[24px] tracking-[0.002px] font-normal underline",
  linkSmall: "linkSmall text-[14px] leading-[21px] tracking-[0.001px] font-normal underline"
};
export const Text = React.forwardRef<any, TextProps>(({
  component,
  xlarge,
  large,
  medium,
  small,
  eyebrow,
  buttonLarge,
  buttonSmall,
  linkLarge,
  linkSmall,
  className,
  ...rest
}: TextProps, ref) => {
  const variant = xlarge && "xlarge" || large && "large" || small && "small" || medium && "medium" || buttonLarge && "buttonLarge" || buttonSmall && "buttonSmall" || linkSmall && "linkSmall" || linkLarge && "linkLarge" || eyebrow && "eyebrow" || "large";
  const Component = component || "span";
  return <Component ref={ref} className={twMerge(textStyles[variant], className)} {...rest} />;
});
Text.displayName = "Text";