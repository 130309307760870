import { faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderType } from "../../../types";
import { classNames } from "../../../utils/classNames";
import { Button } from "@ui/NewButton/Button";
type OrderMethodSelectorProps = {
  orderType: OrderType | undefined;
  handleChangeOrderType: (orderType: OrderType) => void;
  showMap: boolean;
  isDiscoveryRoute: boolean;
  isLoadingCartMutation: boolean;
  isLoadingAddressMutation: boolean;
  handleToggleShowMap: () => void;
};
export const OrderMethodSelector = ({
  orderType,
  showMap,
  isDiscoveryRoute,
  isLoadingCartMutation,
  isLoadingAddressMutation,
  handleChangeOrderType,
  handleToggleShowMap
}: OrderMethodSelectorProps) => {
  return <div id="order-method-selector" className="m-5 flex justify-between gap-2" data-sentry-component="OrderMethodSelector" data-sentry-source-file="OrderMethodSelector.tsx">
      <div className="flex w-full space-x-2">
        <Button type="button" shape="rounded" className="grow !p-2" disabled={isLoadingCartMutation || isLoadingAddressMutation} onClick={() => {
        handleChangeOrderType(OrderType.PICKUP);
      }} label="Pickup" mode={orderType === OrderType.PICKUP ? "toggleSelected" : "toggle"} size="small" data-sentry-element="Button" data-sentry-source-file="OrderMethodSelector.tsx" />
        <Button label="Delivery" shape="rounded" className="grow !p-2" type="button" disabled={isLoadingCartMutation || isLoadingAddressMutation} onClick={() => {
        handleChangeOrderType(OrderType.DELIVERY);
      }} mode={orderType === OrderType.DELIVERY ? "toggleSelected" : "toggle"} size="small" data-sentry-element="Button" data-sentry-source-file="OrderMethodSelector.tsx" />
      </div>
      <div className={classNames("flex border rounded-lg w-10 items-center justify-center text-text-tertiary md:hover:text-lfg-secondary cursor-pointer md:hover:border-lfg-secondary", showMap ? "border-lfg-secondary text-lfg-secondary" : "border-border-secondary text-text-tertiary", isDiscoveryRoute ? "md:hidden" : "")}>
        <FontAwesomeIcon size="1x" icon={faMap} onClick={event => {
        event.stopPropagation();
        handleToggleShowMap();
      }} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="OrderMethodSelector.tsx" />
      </div>
    </div>;
};