export const VIDEO_CONFIG = {
  menuItemsVideoUrls: [
    {
      name: "Regular Square",
      videoUrl:
        "https://cdn.localkitchens.co/Videos/RegularSquare_compressed.mp4",
      posterUrl: "https://cdn.localkitchens.co/Videos/RegularSquarePoster.jpg",
    },
    {
      name: "The 6x8",
      videoUrl: "https://cdn.localkitchens.co/Videos/The6x8_compressed.mp4",
      posterUrl: "https://cdn.localkitchens.co/Videos/The6x8Poster.jpg",
    },
    {
      name: "How Mush-Room",
      videoUrl:
        "https://cdn.localkitchens.co/Videos/HowMushRoom_compressed.mp4",
      posterUrl: "https://cdn.localkitchens.co/Videos/HowMushRoom.jpg",
    },
    {
      name: "The Big Von",
      videoUrl: "https://cdn.localkitchens.co/Videos/TheBigVon_compressed.mp4",
      posterUrl: "https://cdn.localkitchens.co/Videos/TheBigVonPoster.jpg",
    },
  ],
};
