import { TrashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { ConfirmationModal } from "../../../ui/ConfirmationModal/ConfirmationModal";
import { Button } from "../../../ui/NewButton/Button";
import { classNames } from "../../../utils/classNames";
import { DeliveryAddress } from "../../Cart/types";
import { EditAddressButton } from "../../EnterAddress/EditAddressButton";
type AddressProps = {
  onClick: () => void;
  onEdit: () => void;
  onRemove?: () => void;
  address: DeliveryAddress;
  handleSetDefaultAddress?: () => void;
  isLoadingUpdate?: boolean;
  isLoadingDelete?: boolean;
};
export const Address = ({
  onRemove,
  onEdit: _onEdit,
  onClick: _onClick,
  address,
  handleSetDefaultAddress,
  isLoadingUpdate = false,
  isLoadingDelete = false
}: AddressProps) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const isLoading = isLoadingUpdate || isLoadingDelete;
  const onEdit = () => {
    if (!isLoading) {
      _onEdit();
    }
  };
  const onClick = () => {
    if (!isLoading) {
      _onClick();
    }
  };
  return <>
      <div onClick={onClick} className="text-black py-4 flex justify-between w-full items-start space-x-2">
        <div className="w-full">
          <div className="truncate">
            <div className="font-medium truncate">
              {address.street}
              {address.unit ? `, ${address.unit}` : ""}
            </div>
            <div className="text-sm font-lfg-normal truncate">
              {address.city}, {address.state} {address.zipcode}
            </div>
          </div>

          <div className="flex flex-row justify-center items-start w-full mt-2 space-x-2">
            {handleSetDefaultAddress ? <DefaultAddressButton address={address} handleSetDefaultAddress={handleSetDefaultAddress} /> : null}
            <EditAddressButton label={"Edit"} onPress={onEdit} disabled={isLoading} data-sentry-element="EditAddressButton" data-sentry-source-file="Address.tsx" />

            {typeof onRemove === "function" && <Button disabled={isLoading} onPress={() => setShowDeleteConfirmation(true)} size="small" mode="text" loading={isLoadingDelete} leadingIcon={<TrashIcon className="h-5 w-5" />} label="Remove" className="flex items-center text-red-600 font-medium hover:opacity-80 cursor-pointer underline" />}
          </div>
        </div>
      </div>
      <ConfirmationModal show={showDeleteConfirmation} header="Are you sure?" message="Click 'Delete' to confirm and remove this address from your profile." closeModal={() => setShowDeleteConfirmation(false)} confirmButton={{
      label: "Delete",
      onClick: () => {
        onRemove?.();
        setShowDeleteConfirmation(false);
      }
    }} cancelButton={{
      label: "Cancel",
      onClick: () => {
        setShowDeleteConfirmation(false);
      }
    }} data-sentry-element="ConfirmationModal" data-sentry-source-file="Address.tsx" />
    </>;
};
type DefaultAddressButtonProps = {
  address: DeliveryAddress;
  handleSetDefaultAddress: () => void;
};
function DefaultAddressButton({
  address,
  handleSetDefaultAddress
}: DefaultAddressButtonProps) {
  return <div className="cursor-pointer flex items-center w-full space-x-2" onClick={e => {
    e.stopPropagation();
    if (!address.is_default) {
      handleSetDefaultAddress();
    }
  }} data-sentry-component="DefaultAddressButton" data-sentry-source-file="Address.tsx">
      <label className="inline-flex items-center">
        <input type="radio" className="form-radio text-lfg-primary focus:ring-lfg-primary" name="radio-delivery-addresses" checked={Boolean(address?.is_default)} value={address?.id} />
        <div className={classNames("cursor-pointer ml-2", address?.is_default ? "text-text-secondary" : "text-text-tertiary italic")}>
          <span className="hidden sm:block">Default delivery address</span>
          <span className="sm:hidden">Default</span>
        </div>
      </label>
    </div>;
}