import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";
import { useGate } from "statsig-react";
import { useAuth } from "../../features/Authentication/hooks/useAuth";
import { MediaInfo } from "../../features/BrandPage/types";
import { CartMenuItem } from "../../features/Cart/types";
import { MenuItemModalController } from "../../features/MenuItemModal";
import { usePublicMenuItemReordersQuery } from "../../features/MenuItemModal/hooks/usePublicMenuItemReorders.generated";
import { PublicMenuItem } from "../../features/MenuItemModal/types";
import { PublicStore } from "../../features/Stores/types";
import { OrderType } from "../../types";
import { useLocationContext } from "./LocationContext";
import { useOrderTypeContext } from "./OrderTypeContext";
import { useStoresContext } from "./StoresContext";
type MenuItemModalContextType = {
  itemInFocus?: CartMenuItem;
  setItemInFocus: Dispatch<SetStateAction<CartMenuItem | undefined>>;
  itemMediaInfo?: MediaInfo;
  setItemMediaInfo: Dispatch<SetStateAction<MediaInfo | undefined>>;
  publicMenuItem?: PublicMenuItem;
  setPublicMenuItem: Dispatch<SetStateAction<PublicMenuItem | undefined>>;
};
const findStoreForPopularItem = (stores: PublicStore[], menuItem: PublicMenuItem | undefined): PublicStore | undefined => {
  if (!menuItem) return undefined;
  return stores.filter(store => store.id > 0).find(store => {
    return store.menus[0].menu_categories.map(category => category.menu_items).flat().find(item => item.id === menuItem.id);
  });
};
export const MenuItemModalContext = createContext<MenuItemModalContextType>({
  setItemInFocus: () => {},
  setItemMediaInfo: () => {},
  setPublicMenuItem: () => {}
});
export const MenuItemModalProvider = ({
  children
}: PropsWithChildren) => {
  const {
    value: allowReorderItems
  } = useGate("pdp_your_usuals_section");
  const {
    storeLocation
  } = useLocationContext();
  const {
    user
  } = useAuth();
  const {
    orderType
  } = useOrderTypeContext();
  const {
    data: menuItemReorders
  } = usePublicMenuItemReordersQuery({
    skip: !user || !allowReorderItems || !storeLocation?.id || !orderType,
    variables: {
      locationId: storeLocation?.id,
      isDelivery: orderType === OrderType.DELIVERY
    }
  });
  const [itemInFocus, setItemInFocus] = useState<CartMenuItem | undefined>();
  const [publicMenuItem, setPublicMenuItem] = useState<PublicMenuItem>();
  const [itemMediaInfo, setItemMediaInfo] = useState<MediaInfo>();
  const {
    publicStores
  } = useStoresContext();
  return <MenuItemModalContext.Provider value={{
    itemInFocus,
    setItemInFocus,
    itemMediaInfo,
    setItemMediaInfo,
    publicMenuItem,
    setPublicMenuItem
  }} data-sentry-element="unknown" data-sentry-component="MenuItemModalProvider" data-sentry-source-file="MenuItemModalContext.tsx">
      {children}
      <MenuItemModalController setMediaInfo={setItemMediaInfo} mediaInfo={itemMediaInfo} publicMenuItem={publicMenuItem} setPublicMenuItem={setPublicMenuItem} itemInFocus={itemInFocus} setItemInFocus={setItemInFocus} store={findStoreForPopularItem(publicStores || [], publicMenuItem)} menuItemReorders={menuItemReorders?.public_menu_item_reorders} data-sentry-element="MenuItemModalController" data-sentry-source-file="MenuItemModalContext.tsx" />
    </MenuItemModalContext.Provider>;
};
export const useMenuItemModal = () => {
  const context = useContext(MenuItemModalContext);
  if (!context) {
    throw new Error("useMenuItemModalContext must be used within a MenuItemModalProvider");
  }
  return context;
};