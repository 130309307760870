import { ConfirmationModal } from "../../../../ui/ConfirmationModal/ConfirmationModal";
import { CartReceiptLineItem } from "../../../Cart/types";
interface ReceiptLineItemTooltipModalProps {
  item?: CartReceiptLineItem;
  setItem: (item?: CartReceiptLineItem) => void;
}
const ReceiptLineItemTooltipModal = ({
  item,
  setItem
}: ReceiptLineItemTooltipModalProps) => {
  return <ConfirmationModal show={!!item} size="small" closeModal={() => setItem(undefined)} header={item?.label} message={item?.tooltip_description} confirmButton={{
    label: "Got it",
    onPress: () => setItem(undefined)
  }} data-sentry-element="ConfirmationModal" data-sentry-component="ReceiptLineItemTooltipModal" data-sentry-source-file="ReceiptLineItemTooltipModal.tsx" />;
};
export default ReceiptLineItemTooltipModal;