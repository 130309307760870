import { Dispatch, SetStateAction } from "react";
import { User } from "../../Authentication/types";
import { Cart, DeliveryAddress, DeliveryEstimate } from "../../Cart/types";
import { CreateOrUpdateDeliveryAddressMutationVariables } from "./useDeliveryAddressMutations.generated";

export enum EDIT_ADDRESS_MODE {
  CREATE,
  EDIT,
  NOT_EDITING,
}

export const useDeliveryAddressHandlers = (
  cart: Cart,
  onUpdateDeliveryAddress: (
    updateArgs: CreateOrUpdateDeliveryAddressMutationVariables,
  ) => Promise<DeliveryAddress | undefined>,
  onDeliveryEstimates: (
    deliveryAddress: DeliveryAddress,
    locationIds?: number[] | undefined,
  ) => Promise<any>,
  user?: User,
  setEditMode?: Dispatch<SetStateAction<EDIT_ADDRESS_MODE>>,
) => {
  const handleAddressEstimation = async (
    address: DeliveryAddress,
    storesWithinRange: number[],
  ) => {
    const res = await onDeliveryEstimates(address, storesWithinRange);
    const estimates = res?.data?.address_delivery_estimates;
    const validEstimate = estimates?.find(
      (estimate: DeliveryEstimate) => estimate.status === "ok",
    );
    if (validEstimate) {
      const deliveryAddressProps = {
        ...(user?.id && { customerId: user.id }),
        ...address,
        cartId: cart?.id,
      };
      onUpdateDeliveryAddress({
        input: deliveryAddressProps,
        cart_id: cart?.id,
      });
      setEditMode?.(EDIT_ADDRESS_MODE.NOT_EDITING);
    }
  };

  return {
    handleAddressEstimation,
  };
};
