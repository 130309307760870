import { faXmarkLarge } from "@awesome.me/kit-6c131891bd/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useLocationContext } from "../../../utils/contexts/LocationContext";
import { DeliveryAddress } from "../../Cart/types";
import useAddressPredictions from "../../ChoosePickupDelivery/hooks/useAddressPredictions";
import { usePlaceDetails } from "../../ChoosePickupDelivery/hooks/usePlaceDetails";
interface SearchAddressProps {
  address?: DeliveryAddress;
  setAddress: Dispatch<SetStateAction<DeliveryAddress | undefined>>;
  className?: string;
}
const SearchAddress = ({
  address,
  setAddress,
  className
}: SearchAddressProps) => {
  const {
    setSearchedAddress
  } = useLocationContext();
  const [userAddressInput, setUserAddressInput] = useState("");
  const [keysDown, setKeysDown] = useState(0);
  const [prediction, setPrediction] = useState<google.maps.places.AutocompletePrediction>();
  const {
    predictions,
    setPredictions
  } = useAddressPredictions(userAddressInput);
  const {
    place
  } = usePlaceDetails(prediction?.place_id);
  useEffect(() => {
    if (address) {
      setUserAddressInput(address.street || address.city || "");
      if (address?.street || address?.city) {
        setSearchedAddress(address);
      }
    }
  }, [address, setUserAddressInput, setSearchedAddress]);
  useEffect(() => {
    if (place?.street || place?.city) {
      setAddress(place);
      setUserAddressInput(place?.street || place?.city || "");
    }
    setKeysDown(0);
  }, [place]);
  useEffect(() => {
    return () => {
      setPrediction(undefined);
    };
  }, []);
  const handlePredictionSelection = useCallback((predictionSelected: google.maps.places.AutocompletePrediction) => {
    if (predictionSelected) {
      setUserAddressInput("");
      setPrediction(predictionSelected);
      setPredictions([]);
    }
  }, []);
  const handleResetSearch = () => {
    setUserAddressInput("");
    setSearchedAddress(undefined);
    setPredictions([]);
    setPrediction(undefined);
  };
  return <div className={`relative w-full ${className ?? ""} h-full`} data-sentry-component="SearchAddress" data-sentry-source-file="SearchAddress.tsx">
      <div className="w-full sm:text-sm border-border-secondary flex border rounded-lg px-1 h-full">
        <input type="text" name="street_address" id="street_address" placeholder="Enter an address" autoComplete="off" className="bg-surface-lighter sm:text-sm rounded-md flex-auto leading-normal w-px flex-1 border-0 h-full px-3 relative self-center text-xl focus:ring-0" value={userAddressInput || ""} onChange={event => {
        setKeysDown(val => val + 1);
        setUserAddressInput(event.target.value);
      }} />
        <div className="flex cursor-pointer" onClick={handleResetSearch}>
          <span className="flex items-center leading-normal bg-surface-lighter rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-text-secondary">
            <FontAwesomeIcon icon={faXmarkLarge} className="h-5 w-5" data-sentry-element="FontAwesomeIcon" data-sentry-source-file="SearchAddress.tsx" />
          </span>
        </div>
      </div>
      <Transition show={Boolean(predictions && predictions.length && keysDown > 0 && userAddressInput.length)} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" data-sentry-element="Transition" data-sentry-source-file="SearchAddress.tsx">
        <div className="z-10 mt-1 absolute w-full rounded-md shadow-lg bg-surface-lighter ring-1 ring-black ring-opacity-5 focus:outline-none z-30" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <div className="py-1" role="none">
            {predictions?.map((prediction, idx) => <div key={`prediction-${idx}`} className="cursor-pointer block px-4 py-2 text-sm text-text-secondary hover:bg-button-outlined-secondary_hover hover:text-gray-900" role="menuitem" onClick={() => handlePredictionSelection(prediction)} tabIndex={0}>
                {prediction.description}
              </div>)}
          </div>
        </div>
      </Transition>
    </div>;
};
export default SearchAddress;