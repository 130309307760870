import { useMutation } from "@tanstack/react-query";
import { useToast } from "../../../app-components/hooks/useToast";
import { ShopBackendClient } from "../../../utils/shopBackend/apiClient";

const useLogout = () => {
  const { showToast } = useToast();

  return useMutation({
    mutationFn: ShopBackendClient.logout,
    onError: (error) => {
      showToast({
        description: "Something went wrong. Please try again later.",
        variant: "error",
      });
      throw new Error(error?.message || "Unknown error");
    },
  });
};

export default useLogout;
